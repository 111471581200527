<template>
  <div class='mouse-following-tooltip-container'>
    <div class='mouse-following-tooltip' :style="{ left: mouse.left + 'px', top: mouse.top + 'px' }">
      {{ content }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'MouseFollowingTooltip',
  props: {
    content: {
      type: [String, Number],
      required: true
    },
    mouse: {
      type: Object,
      required: false,
      default: () => {
        return {
          left: 0,
          top: 0
        }
      }
    }
  }
}
</script>

<style scoped lang='scss'>
.mouse-following-tooltip-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 3;
  pointer-events: none;
  left: 0;
  top: 0;
  transition: all 0.3s;

  .mouse-following-tooltip {
    position: absolute;
    overflow:hidden;
    background: $color_gray_100;
    border: 1px solid $color_gray_400;
    border-radius: 4px;
    font-size: 12px;
    line-height: 20px;
    padding: 0 8px;
    white-space: nowrap;
  }
}
</style>
