<template>
  <div>
    <div class="twoColumn">
      <template v-for="(line, index) in localValue.linkageGoodsLine">
        <template v-for="group in linkageGoodsLine">
          <tw-process-group-list-edit-goods-line-item
            v-for="item in group.children"
            v-show="index === selectGoodsIndex"
            :key="item.key + '_' + index"
            :item="item"
            :selectGoodsIndex="index"
            :tableName="'linkageGoodsLine'"
            :groupName="group.variableName"
            :parentGroup="group"
            v-model="localValue"
            :serialItems="serialsParent.linkageGoodsLine[index][group.variableName].serialItems"
            :serialIndexes="serialsParent.linkageGoodsLine[index][group.variableName].serialIndexes"
            :index="index"
            :readonly="group.variableName === 'referenceNoGrp'"
            @add-index="addIndex"
          />
        </template>
      </template>

      <template v-if="selectGoodsLineSeq && needPriceTable">
        <div class="table_name">Goods Line Price</div>
        <template v-for="(line, index) in localValue.linkageGoodsLinePrice">
          <template v-for="group in linkageGoodsLinePrice">
            <tw-process-group-list-edit-goods-line-item
              v-for="item in group.children"
              v-show="index === selectGoodsLinePriceIndex"
              :key="group.variableName + '_' + item.key + '_' + index"
              :item="item" :selectGoodsIndex="index" :tableName="'linkageGoodsLinePrice'"
              :groupName="group.variableName"
              :parentGroup="group"
              v-model="localValue"
              :serialItems="serialsParent.linkageGoodsLinePrice[index][group.variableName].serialItems"
              :serialIndexes="serialsParent.linkageGoodsLinePrice[index][group.variableName].serialIndexes"
              :index="index"
              @add-index="addIndex"
            />
          </template>
        </template>
      </template>
      <!-- 輸入商品価格個別テーブル -->
      <template v-if="selectGoodsLineSeq && needImportGoodsLinePriceSeparateTable">
        <div class="table_name">Goods Price Undisclosed</div>
        <template v-for="(line, index) in localValue.importGoodsLinePriceSeparate">
          <template v-for="group in importGoodsLinePriceSeparate">
            <tw-process-group-list-edit-goods-line-item
              v-for="item in group.children"
              v-show="index === selectImportGoodsLinePriceSeparateIndex"
              :key="'importGoodsLinePriceSeparate_' + group.variableName + '_' + item.key + '_' + index"
              :item="item" :selectGoodsIndex="index" :tableName="'importGoodsLinePriceSeparate'"
              :groupName="group.variableName"
              :parentGroup="group"
              v-model="localValue"
              :serialItems="serialsParent.importGoodsLinePriceSeparate[index][group.variableName].serialItems"
              :serialIndexes="serialsParent.importGoodsLinePriceSeparate[index][group.variableName].serialIndexes"
              :index="index"
              @add-index="addIndex"
            />
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import TwProcessGroupListEditGoodsLineItem from '@/components/molecules/TwProcessGroupListEditGoodsLineItem';
import { serialGrpName, getSerialGrpItems, getSerialGrpIndex, getKey } from '@/utils/processedSerialItems';

// 表外表示 goodsGrp
const goodsGrp = [
  'goodsNameEn',
  'productSpec',
  'exportLicence',
  'exportLicenceNo',
  'importLicence',
  'importLicenceNo',
  // goodsTypeは商品属性の方に表示
  // 'goodsType',
];

export default {
  name: 'TwProcessGroupListEditGoodsLinePrice',
  props: {
    value: Object,
    selectGoodsLineSeq: Number,
  },
  inject: ['s'],
  components: {
    TwProcessGroupListEditGoodsLineItem,
  },
  data() {
    return {
      customers: [],
      pageSize: 20,
      dialogAddGoodsVisible: false,
      searchForm: {
        customerId: '',
        customerName: '',
        companyRole: [],
        keyword: '',
      },
      sections: [],
      init: false,
      items: [],
      override: false,
      serialsParent: {}, // 連番項目グループ
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    multipleSelect() {
      return (model) => {
        return _.isArray(model) && model.length > 1;
      };
    },
    linkageGoodsLine() {
      const linkageGoodsLine = _.find(this.s.schemas.tables, {variableName: 'linkageGoodsLine'});
      let ret = [
        _.find(linkageGoodsLine.groups, {variableName: 'goodsGrp'}),
        _.find(linkageGoodsLine.groups, {variableName: 'referenceNoGrp'}) || [],
        _.find(linkageGoodsLine.groups, {variableName: 'quantityGrp'}),
        _.find(linkageGoodsLine.groups, {variableName: 'remarksGrp'}),
      ];
      ret[0] = {
        ...ret[0],
        children: _.map(goodsGrp, key => _.find(ret[0].children, o => {
          return o.key === key;
        })),
      };
      ret[2] = {
        ...ret[2],
        children: _.filter(ret[2].children, o => {
          return o.key === 'unitRoundType1' || o.key === 'unitRoundType2';
        }),
      };
      return ret;
    },
    linkageGoodsLinePrice() {
      const linkageGoodsLinePrice = _.find(this.s.schemas.tables, {variableName: 'linkageGoodsLinePrice'});
      const ret = [
        // _.find(linkageGoodsLinePrice.groups, {variableName: 'priceGrp'}),
        // _.find(linkageGoodsLinePrice.groups, {variableName: 'amountGrp'}),
        // _.find(linkageGoodsLinePrice.groups, {variableName: 'commercialGrp'}),
        _.find(linkageGoodsLinePrice.groups, {variableName: 'remarksGrp'}),
      ];
      return ret;
    },
    importGoodsLinePriceSeparate() {
      const importGoodsLinePriceSeparate = _.find(this.s.schemas.tables, {variableName: 'importGoodsLinePriceSeparate'});
      const ret = [
        _.find(importGoodsLinePriceSeparate.groups, {variableName: 'expensesGrp'}),
        _.find(importGoodsLinePriceSeparate.groups, {variableName: 'remarksGrp'}),
      ];
      return ret;
    },
    // 選択した商品
    selectGoods() {
      if (!_.get(this, 'localValue.linkageGoodsLine')) {
        return false;
      }
      const selectGoods = _.find(this.localValue.linkageGoodsLine, goods => {
        return _.get(goods, 'keyGrp.goodsLineSeq') === this.selectGoodsLineSeq;
      });
      return selectGoods;
    },
    // 選択した商品ラインのインデックス
    selectGoodsIndex() {
      if (!_.get(this, 'localValue.linkageGoodsLine')) {
        return false;
      }
      const selectGoodsIndex = _.findIndex(this.localValue.linkageGoodsLine, goods => {
        return _.get(goods, 'keyGrp.goodsLineSeq') === this.selectGoodsLineSeq;
      });
      return selectGoodsIndex;
    },
    // 選択した商品価格のインデックス
    selectGoodsLinePriceIndex() {
      if (!_.get(this, 'localValue.linkageGoodsLinePrice')) {
        return false;
      }
      const selectGoodsLinePriceIndex = _.findIndex(this.localValue.linkageGoodsLinePrice, price => {
        return _.get(price, 'keyGrp.goodsLineSeq') === this.selectGoodsLineSeq;
      });
      return selectGoodsLinePriceIndex;
    },
    // 選択した輸入商品価格個別のインデックス
    selectImportGoodsLinePriceSeparateIndex() {
      if (!_.get(this, 'localValue.importGoodsLinePriceSeparate')) {
        return false;
      }
      const selectImportGoodsLinePriceSeparateIndex = _.findIndex(this.localValue.importGoodsLinePriceSeparate, line => {
        return _.get(line, 'keyGrp.goodsLineSeq') === this.selectGoodsLineSeq;
      });
      return selectImportGoodsLinePriceSeparateIndex;
    },
    needPriceTable() {
      const table = _.find(this.s.schemas.tables, {variableName: 'linkageGoodsLinePrice'});
      if (!table) {
        return false;
      }
      return _.some(table.groups, group => {
        return this.s.checkTypeOfWorks(group);
      });
    },
    // 輸入商品価格個別テーブルの表示可否
    needImportGoodsLinePriceSeparateTable() {
      const table = _.find(this.s.schemas.tables, {variableName: 'importGoodsLinePriceSeparate'});
      if (!table) {
        return false;
      }
      return _.some(table.groups, group => {
        return this.s.checkTypeOfWorks(group);
      });
    },
  },
  watch: {
    // goods追加時、連番項目を初期化
    'localValue.linkageGoodsLine': {
      handler() {
        this.initSerial('linkageGoodsLine');
      }
    },
    'localValue.linkageGoodsLinePrice': {
      handler() {
        this.initSerial('linkageGoodsLinePrice');
      }
    },
    'localValue.importGoodsLinePriceSeparate': {
      handler() {
        this.initSerial('importGoodsLinePriceSeparate');
      }
    },
  },
  created() {
    // 連番項目init処理
    this.initSerial('linkageGoodsLine');
    this.initSerial('linkageGoodsLinePrice');
    this.initSerial('importGoodsLinePriceSeparate');
  },
  methods: {
    // remarks等の連番項目をグループ化し可変にします
    initSerial(t_name) {
      this.serialsParent[t_name] = [];
      _.forEach(this.localValue[t_name], (items, parent_key) => {
        this.serialsParent[t_name][parent_key] = {};
        _.forEach(items, (item, key) => {
          const res = this.localValue[t_name][parent_key][key];
          const serialItems = serialGrpName.includes(key) ? getSerialGrpItems(res, key) : {};
          let serialIndexes = {};
          if (Object.keys(serialItems).length > 0) serialIndexes = getSerialGrpIndex(serialItems);
          this.serialsParent[t_name][parent_key][key] = { serialItems: serialItems, serialIndexes: serialIndexes };
        });
      });
    },
    // 連番項目を追加 or 削除します
    addIndex(t_name, index, g_name, name, num) {
      const key = getKey(name);
      this.serialsParent[t_name][index][g_name].serialIndexes[key] += num;
      this.serialsParent = this.s.cloneDeep(this.serialsParent);
    },
  }
};
</script>

<style lang="scss" scoped>
  .twoColumn {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    padding-left: 16px;
  }

  .table_name {
    width: 100%;
    vertical-align: middle;
    padding-top: 10px;
    font-size: 16px;
    line-height: 24px;
    color: $color_gray_800;
    white-space: nowrap;
    border-top: 1px solid $color_gray_300;
    margin-bottom: 32px;
  }
</style>
