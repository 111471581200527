<template>
  <div class="tw_process_group_list_edit_item" :class="[{ add_serial: isAddSerial && !serialGroupName, remove_serial: isRemoveSerial && !serialGroupName, full: ['text_full', 'select_full', 'textarea'].includes(item.inputType) || item.key === 'exCustomsTotalQuantity' || item.key === 'imCustomsTotalQuantity' || item.key === 'laytime' || item.key.includes('departureFlightNo'), edit_infinite: addInfiniteItems[item.key], document_name: groupName === 'documentsGrp' && item.key.startsWith('documentName'), payment_document_terms_value: isPaymentDocumentTermsValue, no_label: isPaymentDocumentTermsValue && !item.key.includes('Original'), arrival_time: groupName === 'airGrp' && item.key.startsWith('arrivalAirportName') && itemIndex !== serialIndex, expiry_date: groupName === 'bookingReferenceGrp' && item.key.startsWith('expiryDate') && itemIndex !== serialIndex }, getClassName]" >
    <!-- containerNo, sealNo等のセットで無制限追加可能な項目群 -->
    <TwProcessGroupListInfinite
      v-if="addInfiniteItems[item.key]"
      :item="item"
      :tableName="tableName"
      :groupName="groupName"
      :isEdit="true"
      v-model="localValue"
    />
    <el-form-item
      v-else-if="isVisible && isSerialItem && isNestItem"
      v-show="!hidden"
      :label="getLabel"
      :prop="`${tableName}.${groupName}.${item.key}`"
      :show-message="!hideErrorMessage"
      :class="{hide_error: hideErrorMessage, with_id: !$parent.group && customerNameInputCondition && unitTargetModel}"
      ref="formItem"
      :rules="rules"
    >
    <template v-if="isInActiveTab">
      <!-- テキストエリア -->
      <el-input
        v-if="item.inputType === 'textarea'"
        type="textarea"
        :autosize="{ minRows: 2, maxRows: 6}"
        placeholder=""
        v-model="inputModel"
        show-word-limit
        :maxlength="getMaxLength"
      />
      <!-- 決済書類条件の数値 -->
      <tw-input-number-max
        v-else-if="isPaymentDocumentTermsValue"
        v-model="numberModel"
        :maxlength="2"
      />
      <!-- 数値 -->
      <TwInputFormatNumber
        v-else-if="item.inputType === 'number'"
        v-model="numberModel"
      />
      <!-- 数値文字列 -->
      <TwInputFormatNumberString
        v-else-if="item.inputType === 'numberString'"
        v-model="numberStringModel"
        @change="onNumberStringChange"
      />
      <!-- 数量 単位 -->
      <template v-else-if="item.inputType === 'quantity'">
        <TwInputFormatNumber
          v-model="numberModel"
          class="narrow"
        />
        <TwSelect class="unit" v-model="localValue[tableName][groupName][getTarget()]" @change="onUnitChange" filterable allow-create default-first-option clearable>
          <el-option
            v-for="unit in SYS_MST_UNIT"
            :key="unit.id"
            :label="unit.value"
            :value="unit.value">
          </el-option>
        </TwSelect>
      </template>
      <!-- 数量 単位の集計値 -->
      <template v-else-if="item.inputType === 'totalQuantity'">
        <TwInputFormatNumber
          v-model="numberModel"
          class="narrow"
          :class="{warning: warningMessage}"
          @change="onTotalQuantityChange"
        />
        <TwSelect class="unit" ref="totalQuantityUnit" v-model="localValue[tableName][groupName][getTarget()]" :class="{warning: warningMessage}" @change="onTotalQuantityUnitChange" filterable allow-create default-first-option clearable>
          <el-option
            v-for="unit in SYS_MST_UNIT"
            :key="unit.id"
            :label="unit.value"
            :value="unit.value">
          </el-option>
        </TwSelect>
        <div v-if="warningMessage" class="warning_message" v-html="warningMessage" />
      </template>
      <!-- NET重量のの集計値 -->
      <template v-else-if="item.inputType === 'totalNetWeight'">
        <TwInputFormatNumber
          v-model="numberModel"
          class="narrow"
          :class="{warning: warningMessage}"
          @change="onTotalNetWeightChange"
        />
        <TwSelect class="unit" ref="totalWeightUnit" v-model="localValue[tableName][groupName][getTarget()]" :class="{warning: warningMessage}" @change="onTotalWeightUnitChange" filterable allow-create default-first-option clearable>
          <el-option
            v-for="unit in SYS_MST_UNIT"
            :key="unit.id"
            :label="unit.value"
            :value="unit.value">
          </el-option>
        </TwSelect>
        <div v-if="warningMessage" class="warning_message" v-html="warningMessage" />
      </template>
      <!-- GROSS重量のの集計値 -->
      <template v-else-if="item.inputType === 'totalGrossWeight'">
        <TwInputFormatNumber
          v-model="numberModel"
          class="narrow"
          :class="{warning: warningMessage}"
          @change="onTotalGrossWeightChange"
        />
        <TwSelect class="unit" ref="totalWeightUnit" v-model="localValue[tableName][groupName][getTarget()]" :class="{warning: warningMessage}" @change="onTotalWeightUnitChange" filterable allow-create default-first-option clearable>
          <el-option
            v-for="unit in SYS_MST_UNIT"
            :key="unit.id"
            :label="unit.value"
            :value="unit.value">
          </el-option>
        </TwSelect>
        <div v-if="warningMessage" class="warning_message" v-html="warningMessage" />
      </template>
      <!-- 容積の集計値 -->
      <template v-else-if="item.inputType === 'totalMeasurement'">
        <TwInputFormatNumber
          v-model="numberModel"
          class="narrow"
          :class="{warning: warningMessage}"
          @change="onTotalMeasurementChange"
        />
        <TwSelect class="unit" ref="totalMeasurementUnit" v-model="localValue[tableName][groupName][getTarget()]" :class="{warning: warningMessage}" @change="onTotalMeasurementUnitChange" filterable allow-create default-first-option clearable>
          <el-option
            v-for="unit in SYS_MST_UNIT"
            :key="unit.id"
            :label="unit.value"
            :value="unit.value">
          </el-option>
        </TwSelect>
        <div v-if="warningMessage" class="warning_message measurement" v-html="warningMessage" />
      </template>
      <!-- 貨物重量 -->
      <template v-else-if="item.inputType === 'chargeableWeight'">
        <TwInputFormatNumber
          v-model="numberModel"
          class="narrow"
        />
        <TwSelect class="unit" ref="totalWeightUnit" v-model="localValue[tableName][groupName][getTarget()]" disabled>
          <el-option
            v-for="unit in SYS_MST_UNIT"
            :key="unit.id"
            :label="unit.value"
            :value="unit.value">
          </el-option>
        </TwSelect>
      </template>
      <!-- GROSS見積重量 -->
      <template v-else-if="item.inputType === 'estimatedTotalGrossWeight'">
        <TwInputFormatNumber
          v-model="numberModel"
          class="narrow"
        />
        <TwSelect class="unit" v-model="localValue[tableName][groupName][getTarget()]" @change="onEstimateWeightUnitChange" filterable clearable>
          <el-option
            v-for="unit in CARGO_GROSS_WEIGHT_UNIT_VARIABLES"
            :key="unit.code"
            :label="unit.label"
            :value="unit.label">
          </el-option>
        </TwSelect>
      </template>
      <!-- 見積容積合計 -->
      <template v-else-if="item.inputType === 'estimatedTotalMeasurement'">
        <TwInputFormatNumber
          v-model="numberModel"
          class="narrow"
        />
        <TwSelect class="unit" v-model="localValue[tableName][groupName][getTarget()]" @change="onEstimateVolumeUnitChange" filterable clearable>
          <el-option
            v-for="unit in CARGO_GROSS_VOLUME_UNIT_VARIABLES"
            :key="unit.code"
            :label="unit.label"
            :value="unit.label">
          </el-option>
        </TwSelect>
      </template>
      <!-- 金額 通貨 -->
      <template v-else-if="item.inputType === 'currency'">
        <TwInputFormatNumber
          v-model="numberModel"
          class="narrow"
          @change="onCurrencyRefChange"
        />
        <TwSelect class="unit" v-model="localValue[tableName][groupName][getTarget()]" @change="onCurrencyChange" filterable allow-create default-first-option clearable>
          <el-option
            v-for="unit in SYS_MST_CURRENCY"
            :key="unit.alphabeticCode"
            :label="unit.symbol"
            :value="unit.symbol">
          </el-option>
        </TwSelect>
      </template>
      <!-- 金額、通貨は参照のみ -->
      <template v-else-if="item.inputType === 'currencyRef'">
        <TwInputFormatNumber
          v-model="numberModel"
          class="narrow"
          @change="onCurrencyRefChange"
        />
        <TwSelect class="unit" v-model="localValue[parseTarget()[0]][parseTarget()[1]][parseTarget()[2]]" @change="onCurrencyChange" allow-create default-first-option clearable disabled>
          <el-option
            v-for="unit in SYS_MST_CURRENCY"
            :key="unit.alphabeticCode"
            :label="unit.symbol"
            :value="unit.symbol">
          </el-option>
        </TwSelect>
      </template>
      <!-- 合計金額の集計値 -->
      <template v-else-if="item.inputType === 'totalAmount'">
        <TwInputFormatNumber
          v-model="numberModel"
          class="narrow"
          :class="{warning: warningMessage}"
          @change="onTotalAmountChange"
        />
        <TwSelect class="unit" ref="totalCurrency" v-model="localValue[tableName][groupName][getTarget()]" :class="{warning: warningMessage}" @change="onTotalCurrencyChange" filterable allow-create default-first-option clearable>
          <el-option
            v-for="unit in SYS_MST_CURRENCY"
            :key="unit.alphabeticCode"
            :label="unit.symbol"
            :value="unit.symbol">
          </el-option>
        </TwSelect>
        <div v-if="warningMessage" class="warning_message" v-html="warningMessage" />
      </template>
      <!-- 無償商品の参考価格の集計値 -->
      <template v-else-if="item.inputType === 'noCommercialValueAmount'">
        <TwInputFormatNumber
          v-model="numberModel"
          class="narrow"
          :class="{warning: warningMessage}"
          @change="onNoCommercialValueAmountChange"
        />
        <TwSelect class="unit" ref="totalCurrency" v-model="localValue[tableName][groupName][getTarget()]" :class="{warning: warningMessage}" @change="onTotalCurrencyChange" filterable allow-create default-first-option clearable>
          <el-option
            v-for="unit in SYS_MST_CURRENCY"
            :key="unit.alphabeticCode"
            :label="unit.symbol"
            :value="unit.symbol">
          </el-option>
        </TwSelect>
        <div v-if="warningMessage" class="warning_message" v-html="warningMessage" />
      </template>
      <!-- 貨物重量（グロス） -->
      <template v-else-if="item.inputType === 'grossWeight'">
        <TwInputFormatNumberString
          v-model="numberStringModel"
          class="narrow"
          @change="onNumberStringChange"
        />
        <el-select class="unit" v-model="unitTargetModel" filterable clearable>
          <el-option
            v-for="unit in WEIGHT_UNIT_BL_VARIABLES"
            :key="unit.code"
            :label="unit.label"
            :value="unit.code">
          </el-option>
        </el-select>
      </template>
      <!-- 総容積 -->
      <template v-else-if="item.inputType === 'grossVolume'">
        <TwInputFormatNumber
          v-model="numberModel"
          class="narrow"
        />
        <el-select class="unit" v-model="unitTargetModel" filterable clearable>
          <el-option
            v-for="unit in VOLUME_UNIT_VARIABLES"
            :key="unit.code"
            :label="unit.label"
            :value="unit.code">
          </el-option>
        </el-select>
      </template>
      <!-- 単位 -->
      <TwSelect v-else-if="item.inputType === 'unit'" v-model="inputModel" @change="onUnitChange2" filterable allow-create default-first-option clearable>
        <el-option
          v-for="unit in SYS_MST_UNIT"
          :key="unit.id"
          :label="unit.value"
          :value="unit.value">
        </el-option>
      </TwSelect>
      <!-- 通貨 -->
      <TwSelect v-else-if="item.inputType === 'currencyId'" v-model="inputModel" @change="onCurrencyChange2" filterable allow-create default-first-option clearable>
        <el-option
          v-for="unit in SYS_MST_CURRENCY"
          :key="unit.alphabeticCode"
          :label="unit.symbol"
          :value="unit.symbol">
        </el-option>
      </TwSelect>
      <!-- 港湾、地名 -->
      <!-- <TwPortAndPlace v-else-if="item.code === 'SYS_MST_PORT_AND_PLACE'" v-model="inputModel" @input="onPlaceChange" :allowCreate="!this.item.required" /> -->
      <TwPortAndPlace v-else-if="item.code === 'SYS_MST_PORT_AND_PLACE'" v-model="inputModel" @input="onPlaceChange" />
      <!-- 国名 -->
      <TwSelect v-else-if="item.code === 'SYS_MST_COUNTRY'" v-model="inputModel" @change="onCountryChange" filterable allow-create default-first-option clearable>
        <el-option
          v-for="country in SYS_MST_COUNTRY"
          :key="country.countryCd"
          :label="country.name"
          :value="country.name">
        </el-option>
      </TwSelect>
      <!-- コンテナタイプ区分 NOTE: 無制限追加可能なContainer Infoに移植 -->
      <!-- <TwSelect v-else-if="item.code === 'SYS_MST_CONTAINER_TYPE'" v-model="containerTypeModel" placeholder="" filterable clearable>
        <el-option
          v-for="type in SYS_MST_CONTAINER_TYPE"
          :key="type.code"
          :label="type.description"
          :value="type.code">
        </el-option>
      </TwSelect> -->
      <!-- 荷姿ID -->
      <TwSelect v-else-if="item.code === 'SYS_MST_PACKAGE_TYPE'" v-model="inputModel" @change="onPackingChange" filterable allow-create default-first-option clearable>
        <el-option
          v-for="type in SYS_MST_PACKAGE_TYPE"
          :key="type.value"
          :label="type.value"
          :value="type.value">
        </el-option>
      </TwSelect>
      <!-- リファレンスタイプ -->
      <el-select v-else-if="item.code === 'REFERENCE_TYPE'" v-model="pullDownModel" filterable clearable>
        <el-option
          v-for="unit in REFERENCE_TYPE_VARIABLES"
          :key="unit.code"
          :label="unit.label"
          :value="unit.code">
        </el-option>
      </el-select>
      <!-- B/Lリファレンスタイプ -->
      <el-select v-else-if="item.code === 'BL_REFERENCE_TYPE'" v-model="pullDownModel" filterable clearable>
        <el-option
          v-for="unit in BL_REFERENCE_TYPE_VARIABLES"
          :key="unit.code"
          :label="unit.label"
          :value="unit.code">
        </el-option>
      </el-select>
      <!-- チャージ種別 -->
      <el-select v-else-if="item.code === 'CHARGE_TYPE'" v-model="pullDownModel" filterable clearable>
        <el-option
          v-for="unit in CHARGE_TYPE_VARIABLES"
          :key="unit.code"
          :label="unit.label"
          :value="unit.code">
        </el-option>
      </el-select>
      <!-- SCAC -->
      <el-select v-else-if="item.code === 'SCAC'" v-model="pullDownModel" filterable clearable>
        <el-option
          v-for="carrier in SCAC_VARIABLES"
          :key="carrier.code"
          :label="carrier.label"
          :value="carrier.code">
        </el-option>
      </el-select>
      <!-- CO品目コード -->
      <el-select v-else-if="item.code === 'CO_ITEM_CODE'" v-model="pullDownModel" filterable clearable>
        <el-option
          v-for="item in CO_ITEM_CODE_VARIABLES"
          :key="item.code"
          :label="item.label"
          :value="item.code">
        </el-option>
      </el-select>
      <!-- CO原産地_署名者 -->
      <TwSelect v-else-if="item.code === 'CO_DOCUMENT_SIGNER'" v-model="pullDownModel" :disabled="isCoDocumentSignerCodeDisabled" @change="onSignerChange" filterable allow-create default-first-option clearable>
        <el-option
          v-for="user in CO_DOCUMENT_SIGNERS"
          :key="user.code"
          :label="user.label"
          :value="user.label">
        </el-option>
      </TwSelect>
      <!-- 本紙引取要否区分 -->
      <el-radio-group v-else-if="item.code === 'BL_PICKUP'" v-model="inputModel">
        <el-radio v-for="radio in BL_PICKUP_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- 貿易条件区分 -->
      <el-radio-group v-else-if="item.code === 'DELIVERY_TERMS'" v-model="inputModel">
        <el-radio v-for="radio in DELIVERY_TERMS_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- 無償フラグ -->
      <el-radio-group v-else-if="item.code === 'FREE_CHARGE_FLG'" v-model="inputModel">
        <el-radio v-for="radio in FREE_CHARGE_FLG_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- 内陸輸送費の負担要否区分 -->
      <el-radio-group v-else-if="item.code === 'INLAND_DELIVERY_FARE'" v-model="inputModel">
        <el-radio v-for="radio in INLAND_DELIVERY_FARE_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- 分納可否 -->
      <el-radio-group v-else-if="item.code === 'PARTIAL_SHIPMENT_ALLOWED_NOT_ALLOWED'" v-model="inputModel">
        <el-radio v-for="radio in PARTIAL_SHIPMENT_ALLOWED_NOT_ALLOWED_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- 決済条件区分 -->
      <el-radio-group v-else-if="item.code === 'PAYMENT_TERMS'" v-model="inputModel">
        <el-radio v-for="radio in PAYMENT_TERMS_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- 積替可否 -->
      <template v-else-if="item.code === 'TRANSHIPMENT_ALLOWED_NOT_ALLOWED'">
        <el-radio-group v-model="inputModel">
          <el-radio v-for="radio in TRANSHIPMENT_ALLOWED_NOT_ALLOWED_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
        </el-radio-group>
        <div v-if="transhipmentWarningMessage" class="warning_message" v-html="transhipmentWarningMessage" />
      </template>
      <!-- 注文No区分 -->
      <el-radio-group v-else-if="item.code === 'PO_NO_UNIT'" v-model="inputModel">
        <el-radio v-for="radio in PO_NO_UNIT_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- 特約フラグ -->
      <el-radio-group v-else-if="item.code === 'SPECIAL_TERMS'" v-model="inputModel">
        <el-radio v-for="radio in SPECIAL_TERMS_FLG_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- 国内運送保険 -->
      <el-radio-group v-else-if="item.code === 'TRANSPORT_INSURANCE_INLAND'" v-model="inputModel">
        <el-radio v-for="radio in TRANSPORT_INSURANCE_INLAND_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- 記載条件 -->
      <el-radio-group v-else-if="item.code === 'BL_MENTION_AS_ARRANGED'" v-model="inputModel">
        <el-radio v-for="radio in BL_MENTION_AS_ARRANGED_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- 運賃元払 -->
      <el-radio-group v-else-if="item.code === 'FREIGHT_PREPAID_PAYABLE_AT'" v-model="inputModel">
        <el-radio v-for="radio in FREIGHT_PREPAID_PAYABLE_AT_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- 輸送手段 -->
      <el-radio-group v-else-if="item.code === 'TRANSPORTATION'" v-model="inputModel">
        <el-radio v-for="radio in TRANSPORTATION_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- 積地（DOOR/CY/CFS)、揚地（DOOR/CY/CFS) -->
      <el-radio-group v-else-if="item.code === 'TYPE_OF_SERVICE'" v-model="inputModel">
        <el-radio v-for="radio in TYPE_OF_SERVICE_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- 積地/揚地（FCL/LCL） -->
      <el-radio-group v-else-if="item.code === 'TYPE_OF_SERVICE_FCL_LCL'" v-model="inputModel">
        <el-radio v-for="radio in TYPE_OF_SERVICE_FCL_LCL_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- B/L条件 -->
      <el-radio-group v-else-if="item.code === 'BL_TO_BE_NEGOTIATED'" v-model="inputModel">
        <el-radio v-for="radio in BL_TO_BE_NEGOTIATED_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- 輸送サービスレベル -->
      <el-radio-group v-else-if="item.code === 'TRANSPORTATION_SERVICE_LEVEL'" v-model="inputModel">
        <el-radio v-for="radio in TRANSPORTATION_SERVICE_LEVEL_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- Booking依頼先区分 -->
      <el-radio-group v-else-if="item.code === 'BOOKING_TYPE'" v-model="inputModel">
        <el-radio v-for="radio in BOOKING_TYPE_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- 保冷フラグ -->
      <el-radio-group v-else-if="item.code === 'TEMPERATURE_CONTROLLED'" v-model="inputModel">
        <el-radio v-for="radio in TEMPERATURE_CONTROLLED_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- ドライアイスフラグ -->
      <el-radio-group v-else-if="item.code === 'DRYICE_FLAG'" v-model="inputModel">
        <el-radio v-for="radio in DRYICE_FLAG_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- AMS FILER -->
      <el-radio-group v-else-if="item.code === 'AMS_FILER'" v-model="inputModel">
        <el-radio v-for="radio in AMS_FILER_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- 重量単位（B/L） -->
      <el-radio-group v-else-if="item.code === 'WEIGHT_UNIT_BL'" v-model="inputModel">
        <el-radio v-for="radio in WEIGHT_UNIT_BL_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- 容積単位 -->
      <el-radio-group v-else-if="item.code === 'VOLUME_UNIT'" v-model="inputModel">
        <el-radio v-for="radio in VOLUME_UNIT_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- B/Lステータス -->
      <el-radio-group v-else-if="item.code === 'BL_STATUS'" v-model="inputModel">
        <el-radio v-for="radio in BL_STATUS_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- Bookingステータス -->
      <el-radio-group v-else-if="item.code === 'BOOKING_STATUS'" v-model="inputModel">
        <el-radio v-for="radio in BOOKING_STATUS_VARIABLES" :key="radio.code" :label="radio.code" border disabled>{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- 移動区分 -->
      <el-radio-group v-else-if="item.code === 'TYPE_OF_MOVE'" v-model="inputModel">
        <el-radio v-for="radio in TYPE_OF_MOVE_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- 証明依頼書_証明種類 -->
      <el-radio-group v-else-if="item.code === 'CO_DOCUMENT_CATEGORY'" v-model="inputModel">
        <el-radio v-for="radio in CO_DOCUMENT_CATEGORY_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- CO決済方法 -->
      <el-radio-group v-else-if="item.code === 'CO_SETTLEMENT_METHOD'" v-model="inputModel">
        <el-radio v-for="radio in CO_SETTLEMENT_METHOD_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- 日付 -->
      <el-date-picker
        v-else-if="item.inputType === 'date'"
        style="width:320px"
        v-model="inputModel"
        type="date"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        placeholder=""
        @change.native="onDateChange"
      />
      <!-- Ph4 -->
      <el-date-picker
        v-else-if="item.inputType === 'dateTime'"
        style="width:320px"
        v-model="formatResponseDateTime"
        type="datetime"
        format="yyyy-MM-dd HH:mm"
        value-format="yyyy-MM-dd HH:mm"
        placeholder=""
        @change.native="onDateTimeChange"
      />
      <!-- 時刻 -->
      <el-time-picker
        v-else-if="item.inputType === 'time'"
        style="width:320px"
        v-model="inputModel"
        placeholder="Select time"
        format="HH:mm"
        value-format="HH:mm:00"
        @change.native="onTimeChange"
      />
      <!-- 取引先検索 -->
      <template v-else-if="customerNameInputCondition">
        <el-input style="width: 268px" v-model="inputModel" class="show_word_limit" placeholder="" show-word-limit :maxlength="getMaxLength" @change="onNameChange" />
        <el-tooltip v-if="unitTargetModel" placement="top" popper-class="customer_button" :tabindex="-1">
          <div slot="content">Delete</div>
          <tw-button type="secondary" size="medium" icon="trash" @click="deletePlayerInfo" style="margin-top: -4px;margin-left: 12px;padding-left: 9px;padding-right: 1px;}"></tw-button>
        </el-tooltip>
        <el-tooltip v-else placement="top" popper-class="customer_button" :tabindex="-1">
          <div slot="content">Search</div>
          <tw-button type="secondary" size="medium" icon="search" @click="onCustomerSearch" style="margin-top: -4px;margin-left: 12px;padding-left: 9px;padding-right: 1px;}"></tw-button>
        </el-tooltip>
      </template>
      <!-- フル幅INPUT -->
      <el-input v-else-if="item.inputType === 'text_full'" class="text_full show_word_limit" v-model="inputModel" :placeholder="placeholder" show-word-limit :maxlength="getMaxLength" />
      <!-- 通常INPUT -->
      <el-input v-else style="width: 320px" v-model="inputModel" class="show_word_limit" :placeholder="placeholder" :disabled="isDisabled || isScacDisabled" show-word-limit :maxlength="getMaxLength" @change="onInputChange" />

      <!-- × 連番項目削除ボタン -->
      <img class="remove_button" src="@/assets/images/icons/times.svg" v-if="isRemoveSerial && !serialGroupName" @click="addIndex(-1)">
      <!-- + 連番項目追加ボタン -->
      <tw-button type="secondary" size="medium" icon="plus" v-if="isAddSerial && !serialGroupName" @click="addIndex(1)" class="add_button" :class="{group_button: isGroupButton}"></tw-button>
    </template>
    </el-form-item>

    <!-- 取引先選択ダイアログ -->
    <el-dialog v-if="item.code === 'CUSTOMER' && (item.key.endsWith('Name') || item.key.endsWith('Name2') || item.key.endsWith('warehouse') || item.key.endsWith('From') || item.key.endsWith('To'))" :title="$t('Label.Select Customer')" :visible.sync="dialogAddGoodsVisible" :close-on-click-modal="false" class="header_border" width="944px" :append-to-body="true">
      <el-form class="search_unit" ref="searchUnit" :model="searchForm" label-position="top">
        <div class="row">
          <el-form-item :label="$t('Label.Customer ID')" prop="customerId">
            <el-input type="text" v-model="searchForm.customerId" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Customer Name')" prop="customerName">
            <el-input type="text" v-model="searchForm.customerName" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Branch Name')" prop="branchName">
            <el-input type="text" v-model="searchForm.branchName" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Company Role')" prop="companyRole">
            <el-select v-model="searchForm.companyRole" placeholder="Select" :class="{multiple_selected: multipleSelect(searchForm.companyRole)}" multiple collapse-tags clearable>
              <el-option
                v-for="role in COMPANY_ROLE_CD_VARIABLES_OPTIONS"
                :key="role.code"
                :label="role.label"
                :value="role.code">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('Label.Keyword')" prop="keyword" style="margin-right:0">
            <el-input type="text" v-model="searchForm.keyword" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <div>
            <tw-button
              type="secondary"
              size="medium"
              icon="search"
              @click="search"
              style="margin:8px 0 16px"
            >
              Search
            </tw-button>
            <tw-button
              type="default"
              size="medium"
              @click="clear"
              style="margin: 8px 16px 16px;"
            >
              Clear
            </tw-button>
          </div>
        </div>
      </el-form>
      <div style="min-height: 388px">
        <tw-table-system :height="300" :schemas="schemas" :items="customers" :pageSize="limit" :totalCount="totalCount" :offset="offset" :serverPagination="true" @row-click="register" @paging="onPaging" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import {
  COMPANY_ROLE_CD,
  RESPONSIBLE_FLG,
  BL_PICKUP_VARIABLES,
  DELIVERY_TERMS_VARIABLES,
  FREE_CHARGE_FLG_VARIABLES,
  INLAND_DELIVERY_FARE_VARIABLES,
  PARTIAL_SHIPMENT_ALLOWED_NOT_ALLOWED_VARIABLES,
  PAYMENT_TERMS_VARIABLES,
  TRANSHIPMENT_ALLOWED_NOT_ALLOWED,
  TRANSHIPMENT_ALLOWED_NOT_ALLOWED_VARIABLES,
  PO_NO_UNIT_VARIABLES,
  SPECIAL_TERMS_FLG_VARIABLES,
  TRANSPORT_INSURANCE_INLAND_VARIABLES,
  BL_TO_BE_NEGOTIATED_VARIABLES,
  PROCESS_TYPE,
  OWN_COMPANY_FLG,
  BL_MENTION_AS_ARRANGED_VARIABLES,
  FREIGHT_PREPAID_PAYABLE_AT_VARIABLES,
  TYPE_OF_SERVICE_VARIABLES,
  TYPE_OF_SERVICE_FCL_LCL_VARIABLES,
  TRANSPORTATION,
  TRANSPORTATION_VARIABLES,
  CUSTOMERS_SELECT_SORT_TARGET,
  DEFAULT_PAGENATION,
  TRANSPORTATION_SERVICE_LEVEL_VARIABLES,
  BOOKING_TYPE_VARIABLES,
  TEMPERATURE_CONTROLLED_VARIABLES,
  DRYICE_FLAG_VARIABLES,
  REFERENCE_TYPE_VARIABLES,
  AMS_FILER_VARIABLES,
  WEIGHT_UNIT_BL_VARIABLES,
  VOLUME_UNIT_VARIABLES,
  BL_REFERENCE_TYPE_VARIABLES,
  CHARGE_TYPE_VARIABLES,
  BL_STATUS_VARIABLES,
  BOOKING_STATUS_VARIABLES,
  BOOKING_STATUS,
  CARGO_GROSS_WEIGHT_UNIT_VARIABLES,
  CARGO_GROSS_VOLUME_UNIT_VARIABLES,
  TYPE_OF_MOVE_VARIABLES,
  CO_DOCUMENT_CATEGORY_VARIABLES,
  CO_SETTLEMENT_METHOD_VARIABLES,
  CO_ITEM_CODE_VARIABLES,
  DELETE_FLG,
  TRANSPORTATION_SERVICE_LEVEL
} from 'lib-tw-common';
import { formatRequestDate, formatRequestDateTime, formatResponseDate, formatResponseDateTime, getMaxLength, onDateChange, onDateTimeChange, onTimeChange, clearSearchForm } from '@/utils/searchUtil.js';
import TwButton from '@/components/atoms/TwButton';
import TwInputNumberMax from '@/components/atoms/TwInputNumberMax';
import TwInputFormatNumber from '@/components/atoms/TwInputFormatNumber';
import TwInputFormatNumberString from '@/components/atoms/TwInputFormatNumberString';
import TwPortAndPlace from '@/components/atoms/TwPortAndPlace';
import TwSelect from '@/components/atoms/TwSelect';
import TwTableSystem from '@/components/organisms/TwTableSystem';
import TwProcessGroupListInfinite from '@/components/molecules/TwProcessGroupListInfinite';
import schemas from '@/dictionaries/addCustomerSchema.json';
import customerRole from '@/dictionaries/customerRole.json';
import { normallyHiddenItem, checkExceptionallyItems, addInfiniteItems } from '@/dictionaries/addInfiniteItems';
import { getKey, getSerialIndex, getItemIndex, getMaxIndex } from '@/utils/processedSerialItems';

export default {
  name: 'TwProcessGroupListEdit',
  props: {
    item: Object,
    tableName: String,
    groupName: String,
    value: Object,
    serialIndexes: Object,
    serialItems: Object,
    hidden: Boolean,
  },
  inject: ['s', 'tab', 'activeTab'],
  components:{
    TwButton,
    TwInputNumberMax,
    TwInputFormatNumber,
    TwInputFormatNumberString,
    TwPortAndPlace,
    TwSelect,
    TwTableSystem,
    TwProcessGroupListInfinite,
  },
  data() {
    return {
      BL_PICKUP_VARIABLES,
      DELIVERY_TERMS_VARIABLES,
      FREE_CHARGE_FLG_VARIABLES,
      INLAND_DELIVERY_FARE_VARIABLES,
      PARTIAL_SHIPMENT_ALLOWED_NOT_ALLOWED_VARIABLES,
      PAYMENT_TERMS_VARIABLES,
      TRANSHIPMENT_ALLOWED_NOT_ALLOWED_VARIABLES,
      PO_NO_UNIT_VARIABLES,
      SPECIAL_TERMS_FLG_VARIABLES,
      TRANSPORT_INSURANCE_INLAND_VARIABLES,
      BL_TO_BE_NEGOTIATED_VARIABLES,
      BL_MENTION_AS_ARRANGED_VARIABLES,
      FREIGHT_PREPAID_PAYABLE_AT_VARIABLES,
      TRANSPORTATION_VARIABLES,
      TYPE_OF_SERVICE_VARIABLES,
      TYPE_OF_SERVICE_FCL_LCL_VARIABLES,
      TRANSPORTATION_SERVICE_LEVEL,
      TRANSPORTATION_SERVICE_LEVEL_VARIABLES,
      BOOKING_TYPE_VARIABLES,
      TEMPERATURE_CONTROLLED_VARIABLES,
      DRYICE_FLAG_VARIABLES,
      REFERENCE_TYPE_VARIABLES,
      AMS_FILER_VARIABLES,
      WEIGHT_UNIT_BL_VARIABLES,
      VOLUME_UNIT_VARIABLES,
      BL_REFERENCE_TYPE_VARIABLES,
      CHARGE_TYPE_VARIABLES,
      BL_STATUS_VARIABLES,
      BOOKING_STATUS_VARIABLES,
      CARGO_GROSS_WEIGHT_UNIT_VARIABLES,
      CARGO_GROSS_VOLUME_UNIT_VARIABLES,
      TYPE_OF_MOVE_VARIABLES,
      CO_DOCUMENT_CATEGORY_VARIABLES,
      CO_SETTLEMENT_METHOD_VARIABLES,
      CO_ITEM_CODE_VARIABLES,
      checks: [],
      customers: [],
      schemas: schemas,
      dialogAddGoodsVisible: false,
      searchForm: {
        customerId: '',
        customerName: '',
        branchName: '',
        companyRole: [],
        keyword: '',
      },
      sections: [],
      init: false,
      override: false, // 自動計算結果の上書きを許可
      overrideUnit: false, // 単位の自動集計結果の上書きを許可
      addInfiniteItems: addInfiniteItems, // 配列に無制限追加可能な項目群
      limit: DEFAULT_PAGENATION.LIMIT,
      offset: 0,
      sort: null,
      count: 0,
      totalCount: 0,
      pageCount: 0,
      lastQuery: {},
    };
  },
  filters: {
    nullFormat: value => {
      if (value === null || '') {
        return undefined;
      }
      if (_.isNaN(Number(value))) {
        return undefined;
      }
      return Number(value);
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    model() {
      return this.localValue[this.tableName][this.groupName][this.item.key];
    },
    inputModel: {
      get() {
        return this.model;
      },
      set(value) {
        this.setValue(value);
      }
    },
    numberModel: {
      get() {
        return this.nullFormat(this.model);
      },
      set(value) {
        if (value === undefined) {
          this.setValue(null);
        } else {
          this.setValue(value);
        }
      }
    },
    numberStringModel: {
      get() {
        return this.model;
      },
      set(value) {
        if (value === undefined) {
          this.setValue(null);
        } else {
          this.setValue('' + value);
        }
      }
    },
    pullDownModel: {
      get() {
        return this.model;
      },
      set(value) {
        if (!value) {
          this.setValue(null);
        } else {
          this.setValue(value);
        }
      }
    },
    unitTargetModel: {
      get() {
        return this.localValue[this.tableName][this.groupName][this.getTarget()];
      },
      set(value) {
        if (!value) {
          this.setTargetValue(this.getTarget(), null);
        } else {
          this.setTargetValue(this.getTarget(), value);
        }
      }
    },
    // containerTypeModel: {
    //   get() {
    //     if (this.item.code === 'SYS_MST_CONTAINER_TYPE') {
    //       return (_.get(this.localValue, this.item.unitTarget) || '') + (this.model ? this.model : '');
    //     }
    //     return this.model;
    //   },
    //   set(value) {
    //     const type = _.get(_.find(_.get(this, '$store.state.systemProperties.SYS_MST_CONTAINER_TYPE') || [], {code: value}), 'type') || null;
    //     const size = _.get(_.find(_.get(this, '$store.state.systemProperties.SYS_MST_CONTAINER_TYPE') || [], {code: value}), 'size') || null;

    //     this.setValue(type);
    //     this.setTargetValue(this.getTarget(), size);
    //   }
    // },
    warningMessage() {
      if (this.item.inputType === 'totalQuantity') {
        if (_.size(this.localValue.linkageGoodsLine) && this.s.totalQuantity === false) {
          return '単位を統一していないため、自動計算されません。';
        } else if (this.isOverFlow(this.s.totalQuantity)) {
          return '自動計算結果の桁は整数11桁、小数4桁以内でお願いします。<br>また、商品の入力に誤りがないか確認してください。';
        }
        return '';
      } else if (this.item.inputType === 'totalAmount') {
        if (_.some(this.s.amountOverflow, flg => flg)) {
          return '自動計算結果の桁は整数13桁、小数2桁以内でお願いします。<br>また、商品の入力に誤りがないか確認してください。';
        } else if (_.size(this.localValue.linkageGoodsLine) && this.s.totalAmount === false) {
          return '単位を統一していないため、自動計算されません。';
        } else if (this.isOverFlow(this.s.totalAmount, 13, 2)) {
          return '自動計算結果の桁は整数13桁、小数2桁以内でお願いします。<br>また、商品の入力に誤りがないか確認してください。';
        }
        return '';
      } else if (this.item.inputType === 'noCommercialValueAmount') {
        if (_.some(this.s.amountOverflow, flg => flg)) {
          return '自動計算結果の桁は整数13桁、小数2桁以内でお願いします。<br>また、商品の入力に誤りがないか確認してください。';
        } else if (_.size(this.localValue.linkageGoodsLine) && this.s.noCommercialValueAmount === false) {
          return '単位を統一していないため、自動計算されません。';
        } else if (this.isOverFlow(this.s.noCommercialValueAmount, 13, 2)) {
          return '自動計算結果の桁は整数13桁、小数2桁以内でお願いします。<br>また、商品の入力に誤りがないか確認してください。';
        }
        return '';
      } else if (this.item.inputType === 'totalNetWeight') {
        if (_.size(this.localValue.linkageGoodsLinePacking) && this.s.totalNetWeight === false) {
          return '単位を統一していないため、自動計算されません。';
        } else if (this.isOverFlow(this.s.totalNetWeight)) {
          return '自動計算結果の桁は整数11桁、小数4桁以内でお願いします。<br>また、商品の入力に誤りがないか確認してください。';
        }
        return '';
      } else if (this.item.inputType === 'totalGrossWeight') {
        if (_.size(this.localValue.linkageGoodsLinePacking) && this.s.totalGrossWeight === false) {
          return '単位を統一していないため、自動計算されません。';
        } else if (this.isOverFlow(this.s.totalGrossWeight)) {
          return '自動計算結果の桁は整数11桁、小数4桁以内でお願いします。<br>また、商品の入力に誤りがないか確認してください。';
        }
        return '';
      } else if (this.item.inputType === 'totalMeasurement') {
        if (_.some(this.s.measurementOverflow, flg => flg)) {
          return '自動計算結果の桁は整数9桁、小数6桁以内でお願いします。<br>また、商品の入力に誤りがないか確認してください。';
        } else if (_.size(this.localValue.linkageGoodsLinePacking) && this.s.totalMeasurement === false) {
          return '単位を統一していないため、自動計算されません。';
        } else if (this.isOverFlow(this.s.totalMeasurement, 9, 6)) {
          return '自動計算結果の桁は整数9桁、小数6桁以内でお願いします。<br>また、商品の入力に誤りがないか確認してください。';
        }
        return '';
      }
      return '';
    },
    // 積載可否の警告文言を返却します
    transhipmentWarningMessage() {
      // フラグがNOT ALLOWEDの場合、経由地が入力されていたらALERT
      if (this.model === TRANSHIPMENT_ALLOWED_NOT_ALLOWED.NOT_ALLOWED) {
        const hasVia = _.some(_.range(1, 6), n => {
          const port = _.get(this.localValue, `linkageTransportation.logisticsViaGrp.viaName${n}`);
          const country = _.get(this.localValue, `linkageTransportation.logisticsViaGrp.viaCountryName${n}`);
          if (port || country) {
            return true;
          }
          return false;
        });
        if (hasVia) {
          return '経由地が入力されています。';
        }
        return null;
      }
      return null;
    },
    formatResponseDate: {
      get() {
        // return dayjs(this.localValue[this.tableName][this.groupName][this.item.key])
        return formatResponseDate(this.localValue[this.tableName][this.groupName][this.item.key]);
      },
      set(value) {
        value = formatRequestDate(value);
        this.setValue(value);
      }
    },
    formatResponseDateTime: {
      get() {
        return formatResponseDateTime(this.localValue[this.tableName][this.groupName][this.item.key]);
      },
      set(value) {
        value = formatRequestDateTime(value);
        this.setValue(value);
      }
    },
    isVisible() {
      if (!this.localValue) {
        return false;
      }
      if (this.item.hide) {
        if (this.hidden) {
          return true;
        }
        return false;
      }
      if (normallyHiddenItem.includes(this.item.key) && checkExceptionallyItems(this.tableName, this.groupName, this.item.key)) {
        return false;
      }

      // bookingInformationAirGrpの輸送サービスレベルは輸送手段がAirと未選択時のみ表示
      if (this.groupName === 'bookingInformationAirGrp' && this.item.key.includes('transportationServiceLevel') && this.hideTransportationServiceLevel()) {
        return false;
      }
      return this.item[this.s.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM ? 'from' : 'to'];
    },
    hideErrorMessage() {
      return ['totalQuantity', 'totalAmount', 'noCommercialValueAmount', 'totalNetWeight', 'totalGrossWeight', 'totalMeasurement'].includes(this.item.inputType) && !this.override && !this.model && this.model !== 0;
    },
    multipleSelect() {
      return (model) => {
        return _.isArray(model) && model.length > 1;
      };
    },
    SYS_MST_UNIT() {
      return this.$store.getters.getSysMstUnit;
    },
    SYS_MST_CURRENCY() {
      return this.$store.getters.getSysMstCurrency;
    },
    SYS_MST_PORT_AND_PLACE() {
      return this.$store.getters.getSysMstPortAndPlace;
    },
    SYS_MST_COUNTRY() {
      return this.$store.getters.getSysMstCountry;
    },
    // SYS_MST_CONTAINER_TYPE() {
    //   return _.get(this.$store.state.systemProperties, 'SYS_MST_CONTAINER_TYPE') || [];
    // },
    SYS_MST_PACKAGE_TYPE() {
      return this.$store.getters.getSysMstPackageType;
    },
    COMPANY_ROLE_CD_VARIABLES_OPTIONS() {
      return this.$store.getters.getCompanyRoleCdVariablesOptions;
    },
    SCAC_VARIABLES() {
      return this.$store.getters.getScacVariables;
    },
    CO_DOCUMENT_SIGNERS() {
      // CO申請者はログインユーザーに紐づいたeCOID所有者のみを選択可能とする
      if(this.item.key === 'coApplicantName1') return this.$store.state.coApplicants;
      return this.$store.state.coDocumentSigners;
    },
    // 連番項目を可変にし追加可能にする対応--------------------------
    isSerialGrp() {
      return Object.keys(this.serialItems).length > 0;
    },
    isSerialItem() {
      if (this.serialIndex && this.itemIndex || this.serialIndex === 0) return this.serialIndex >= this.itemIndex;
      else return true;
    },
    serialIndex() {
      if (this.serialIndexes && Object.keys(this.serialIndexes).length > 0) return getSerialIndex(this.serialIndexes, this.serialGroupName ? this.serialGroupName : this.item.key);
      else return false;
    },
    itemIndex() {
      if (this.serialItems && this.isSerialGrp) return getItemIndex(this.serialItems, this.item.key);
      else return false;
    },
    isAddSerial() {
      return this.isSerialGrp && this.itemIndex === this.serialIndex && this.serialIndex < getMaxIndex(this.serialItems, this.item.key);
    },
    isRemoveSerial() {
      if (Object.keys(this.serialItems).length > 1) {
        return this.checkGroupValues();
      } else {
        return this.isSerialGrp && this.itemIndex === this.serialIndex && this.serialIndex > 0 && !(this.model || this.model === 0);
      }
    },
    serialGroupName() {
      return _.get(this.serialItems[getKey(this.item.key)], 'groupName') || false;
    },
    isNestItem() {
      const nestGroupName = _.get(this.serialItems[getKey(this.item.key)], 'nestGroupName');
      if (nestGroupName && this.serialIndexes && Object.keys(this.serialIndexes).length > 0) {
        const subIndex = Number(getKey(this.item.key).replace(/[^0-9]/g, '')) - 1;
        return getSerialIndex(this.serialIndexes, nestGroupName) >= subIndex;
      } else return true;
    },
    isGroupButton() {
      return _.get(this.serialItems[getKey(this.item.key)], 'groupButton');
    },
    placeholder() {
      if (this.item.key === 'transportationServiceLevelOther') return 'Super Express, Deferred, etc.'
      return ''
    },
    isDisabled() {
      // transportationServiceLevelがOTHER以外の場合はdisabled
      if (this.item.key === 'transportationServiceLevelOther'
        && this.localValue[this.tableName][this.groupName].transportationServiceLevel !== TRANSPORTATION_SERVICE_LEVEL.OTHER
      ) {
        return true;
      }
      return false;
    },
    isScacDisabled() {
      // Booking依頼プロセスかつINTTRAリクエストステータスがPending, ConfirmedのときはCarrier Scacコード変更不能
      if (this.item.key !== 'carrierScac') return false;
      if (this.s.processType !== PROCESS_TYPE.TPATPRB) return false;
      const bookingStatus = _.get(this, 's.bookingLatest.bookingStatus');
      return bookingStatus && (bookingStatus === BOOKING_STATUS.PENDING || bookingStatus === BOOKING_STATUS.CONFIRMED);
    },
    isCoDocumentSignerCodeDisabled() {
      return this.item.key === 'coInvoiceSignerName1' && this.s.processType === PROCESS_TYPE.FDCOR;
    },
    // 決済書類条件の数値項目か
    isPaymentDocumentTermsValue() {
      if (this.groupName !== 'paymentDocumentTermsGrp') {
        return false;
      }
      if (/documentsRequired\d{1,2}Name/.test(this.item.key)) {
        return false;
      }
      return true;
    },
    // 決済書類条件のクラス名
    getClassName() {
      if (this.groupName === 'certificateOfOriginGrp' && this.item.key.includes('coSignerTitle1')) {
        return 'co_signer_title';
      }
      if (this.groupName !== 'paymentDocumentTermsGrp') {
        if (this.item.key === 'estimateOthers' || this.item.key === 'actualOthers') {
          return 'estimate_others';
        }
        if (this.item.key === 'estimateOtherCharge' || this.item.key === 'actualOtherCharge') {
          return 'estimate_other_charge';
        }
        return undefined;
      }
      if (/documentsRequired\d{1,2}Name/.test(this.item.key)) {
        return 'payment_document_terms_name';
      }
      if (this.item.key.includes('OfficeCopy') || this.item.key.includes('Officecopy')) {
        return 'office_copy';
      }

      return undefined;
    },
    // 決済書類条件のラベル
    getLabel() {
      // Office copy
      // 書類作成：表示
      // 書類送付：非表示
      // 輸入荷捌：非表示
      // 輸入許可書：非表示
      if (this.groupName === 'paymentDocumentTermsGrp' && this.item.key.includes('Original')) {
        if (_.get(_.find(this.$parent.group.children, {key: 'invOfficecopy'}), 'hide')) {
          return this.item.label + '/Copy';
        }
        return this.item.label + '/Copy/Office';
      }
      return this.item.label;
    },
    getMaxLength() {
      return getMaxLength(this.item.validationId);
    },
    rules() {
      if (['PT_TPATPRB'].includes(this.s.processType) && this.s.isTargetItem('linkageTransportation.blRequestGrp.amsFiler', this.tableName, this.groupName, this.item.key)) {
        return this.createRules();
      }
      return undefined;
    },
    logicalError() {
      if (!this.model && this.model !== 0) {
        return undefined;
      }
      if (!this.item.required || !this.item.unitTarget) {
        return undefined;
      }
      const unitTarget = this.unitTargetModel;
      if (!unitTarget && unitTarget !== 0) {
        if (this.item.code === 'CUSTOMER') {
          return '取引先マスタから選択しなおしてください';
        }
        const idTarget = _.find(this.$parent.group.children, {key: this.getTarget()});
        if (idTarget.required) {
          return 'プルダウンから選択しなおしてください';
        }
      }

      return undefined;
    },
    // アクティブなタブ配下にいるか
    isInActiveTab() {
      // if (this.s.mode !== 'edit') {
      //   return false;
      // }
      if (!this.tab && this.tab !== 0) {
        return true;
      }

      return _.get(this.activeTab, 'value') === this.tab;
    },
    // 取引先名Inputの条件
    customerNameInputCondition() {
      return this.item.code === 'CUSTOMER' && !this.item.key.endsWith('BranchName') && !this.item.key.endsWith('CityName') && !this.item.key.endsWith('CountryName') && (this.item.key.endsWith('Name') || this.item.key.endsWith('Name2') || this.item.key.endsWith('warehouse') || this.item.key.endsWith('From') || this.item.key.endsWith('To'));
    },
  },
  watch: {
    // バリデーションルール変更時にバリデーションを走らせる
    rules() {
      this.s.$refs.form.validateField(`${this.tableName}.${this.groupName}.${this.item.key}`);
    },
    // 's.estimateTotalCharge': {
    //   handler(value) {
    //     this.onTotalAirFreightChange(value);
    //   },
    // },
    // 's.actualTotalCharge': {
    //   handler(value) {
    //     this.onTotalAirFreightChange(value);
    //   },
    // },
  },
  created() {
    if (this.s.processType === PROCESS_TYPE.ECREQ && this.item.key === 'exCustomInvoiceNo' && !_.get(this.localValue, 'linkageFinalDocs.commercialInvoiceGrp.commercialInvoiceNo')) {
      // 輸出船積依頼プロセスのInvoice No.
      this.s.setValue('linkageFinalDocs', 'commercialInvoiceGrp', 'commercialInvoiceNo', this.model);
    } else if (this.s.processType === PROCESS_TYPE.ICREQ && this.item.key === 'commercialInvoiceNo' && !_.get(this.localValue, 'linkageImportCustoms.importCustomsClearanceGrp.imCustomInvoiceNo')) {
      // 輸入荷捌依頼プロセスのInvoice No.
      this.s.setValue('linkageImportCustoms', 'importCustomsClearanceGrp', 'imCustomInvoiceNo', this.model);
    }
    this.s.$on('onTotalChange', this.onTotalChange);
    this.s.$on('onPackingMeasurementChange', this.onPackingMeasurementChange);
    this.s.$on('onTotalPackingChange', this.onTotalPackingChange);
    this.s.$on('onExpensesTotalAmountChange', this.onExpensesTotalAmountChange);
    this.s.$on('onAdjustedAmountChange', this.onAdjustedAmountChange);
    this.s.$on('onTotalFreightChange', this.onTotalFreightChange);
  },
  beforeDestroy() {
    this.s.$off('onTotalChange', this.onTotalChange);
    this.s.$off('onPackingMeasurementChange', this.onPackingMeasurementChange);
    this.s.$off('onTotalPackingChange', this.onTotalPackingChange);
    this.s.$off('onExpensesTotalAmountChange', this.onExpensesTotalAmountChange);
    this.s.$off('onAdjustedAmountChange', this.onAdjustedAmountChange);
    this.s.$off('onTotalFreightChange', this.onTotalFreightChange);
  },
  methods: {
    // スコープのモデルに値をセットします
    setValue(value) {
      this.s.setValue(this.tableName, this.groupName, this.item.key, value);
    },
    // スコープ外のモデルに値をセットします
    setTargetValue(target, value) {
      this.s.setValue(this.tableName, this.groupName, target, value);
    },
    getTarget(target = this.item) {
      return _.last(target.unitTarget.split('.'));
    },
    parseTarget(target = this.item) {
      const ary = target.unitTarget.split('.');
      return ary;
    },
    // 商品の集計が変わった時
    onTotalChange() {
      requestAnimationFrame(() => {
        if (this.item.inputType === 'totalQuantity') {
          if (this.s.totalQuantity === false || this.isOverFlow(this.s.totalQuantity)) {
            this.setValue(null);
            this.setTargetValue(this.getTarget(), null);
            this.onUnitChange(null);
            this.override = false;
            this.overrideUnit = false;
          } else {
            this.override = false;
            this.overrideUnit = false;
            this.setValue(this.s.totalQuantity);
            this.setTargetValue(this.getTarget(), this.s.totalQuantityUnit || null);
            this.onUnitChange(this.s.totalQuantityUnit || null);
          }
        } else if (this.item.inputType === 'totalAmount') {
          if (this.s.totalAmount === false || this.isOverFlow(this.s.totalAmount, 13, 2)) {
            this.setValue(null);
            this.setTargetValue(this.getTarget(), null);
            this.onUnitChange(null);
            this.override = false;
            this.overrideUnit = false;
          } else {
            this.override = false;
            this.overrideUnit = false;
            this.setValue(this.s.totalAmount || null);
            this.setTargetValue(this.getTarget(), this.s.totalCurrency === false ? null : this.s.totalCurrency);
            this.onCurrencyChange(this.s.totalCurrency || null);
          }
        } else if (this.item.inputType === 'noCommercialValueAmount') {
          if (this.s.noCommercialValueAmount === false || this.isOverFlow(this.s.noCommercialValueAmount, 13, 2)) {
            this.setValue(null);
            this.setTargetValue(this.getTarget(), null);
            this.onUnitChange(null);
            this.override = false;
            this.overrideUnit = false;
          } else {
            this.override = false;
            this.overrideUnit = false;
            this.setValue(this.s.noCommercialValueAmount);
            this.setTargetValue(this.getTarget(), this.s.totalCurrency === false ? null : this.s.totalCurrency);
            this.onCurrencyChange(this.s.totalCurrency || null);
          }
        }
      })

    },
    // 商品の集計が変わった時
    onTotalPackingChange(value) {
      if (this.item.inputType === 'totalNetWeight') {
        this.override = false;
        this.overrideUnit = false;
        if (value === false || this.isOverFlow(this.s.totalNetWeight)) {
          this.setValue(null);
          this.setTargetValue(this.getTarget(), null);
          this.onUnitChange(null);
        } else {
          this.setValue(this.s.totalNetWeight);
          this.setTargetValue(this.getTarget(), this.s.totalWeightUnit || null);
          this.onUnitChange(this.s.totalWeightUnit);
        }
      } else if (this.item.inputType === 'totalGrossWeight') {
        this.override = false;
        this.overrideUnit = false;
        if (value === false || this.isOverFlow(this.s.totalGrossWeight)) {
          this.setValue(null);
          this.setTargetValue(this.getTarget(), null);
          this.onUnitChange(null);
        } else {
          this.setValue(this.s.totalGrossWeight);
          this.setTargetValue(this.getTarget(), this.s.totalWeightUnit || null);
          this.onUnitChange(this.s.totalWeightUnit);
        }
      }
    },
    // 商品の集計が変わった時
    onPackingMeasurementChange(value) {
      if (this.item.inputType === 'totalMeasurement') {
        this.override = false;
        this.overrideUnit = false;
        if (value === false || this.isOverFlow(this.s.totalMeasurement, 9, 6)) {
          this.setValue(null);
          this.setTargetValue(this.getTarget(), null);
          this.onUnitChange(null);
        } else {
          this.setValue(this.s.totalMeasurement);
          this.setTargetValue(this.getTarget(), this.s.totalMeasurementUnit || null);
          this.onUnitChange(this.s.totalMeasurementUnit);
        }
      }
    },
    // 加減情報群の金額が変わった場合
    onExpensesTotalAmountChange(value) {
      if (this.item.key === 'expensesTotalAmount') {
        this.override = false;
        this.setValue(value);
        // バリデーション
        this.s.$refs.form.validateField(`${this.tableName}.${this.groupName}.${this.item.key}`);
      }
    },
    // 加減情報群の合計金額が変わった場合
    onAdjustedAmountChange(value) {
      if (this.item.key === 'adjustedAmount') {
        this.override = false;

        this.setValue(value);
        // バリデーション
        requestAnimationFrame(() => {
          this.s.$refs.form.validateField(`${this.tableName}.${this.groupName}.${this.item.key}`);
        });
      }
    },
    // 見積もり運賃、実質運賃の合計金額が変わった場合
    onTotalFreightChange(value) {
      if (['estimateQuotationOfTotalFreight', 'actualQuotationOfTotalFreight'].includes(this.item.key)) {
        this.override = false;
        this.setValue(value);
        // バリデーション
        this.s.$refs.form.validateField(`${this.tableName}.${this.groupName}.${this.item.key}`);
      }
    },
    // 見積もり運賃(AIR)、実質運賃(AIR)の合計金額が変わった場合
    onTotalAirFreightChange(value) {
      if (['estimateTotalCharge', 'actualTotalCharge'].includes(this.item.key)) {
        this.override = false;
        this.setValue(value);
        // バリデーション
        this.s.$refs.form.validateField(`${this.tableName}.${this.groupName}.${this.item.key}`);
      }
    },
    onUnitChange(value) {
      const unitId = _.get(_.find(this.SYS_MST_UNIT, {value: value}), 'code');
      const idTarget = _.find(this.$parent.group.children, {key: this.getTarget()});
      const unitTarget = this.getTarget(idTarget);
      if (unitId) {
        this.setTargetValue(unitTarget, unitId);
      } else {
        this.setTargetValue(unitTarget, null);
      }
    },
    onUnitChange2(value) {
      const unitId = _.get(_.find(this.SYS_MST_UNIT, {value: value}), 'code');
      const unitTarget = this.getTarget();
      if (unitId || unitId === 0) {
        this.setTargetValue(unitTarget, unitId);
      } else {
        this.setTargetValue(unitTarget, null);
      }
    },
    onEstimateWeightUnitChange(value) {
      const unitId = _.get(_.find(CARGO_GROSS_WEIGHT_UNIT_VARIABLES, {label: value}), 'code');
      const idTarget = _.find(this.$parent.group.children, {key: this.getTarget()});
      const unitTarget = this.getTarget(idTarget);
      if (unitId) {
        this.setTargetValue(unitTarget, unitId);
      } else {
        this.setTargetValue(unitTarget, null);
      }
      if (this.item.tsValidationId) {
        this.s.$refs.form.validateField(`${this.tableName}.${this.groupName}.${this.item.key}`);
      }
    },
    onEstimateVolumeUnitChange(value) {
      const unitId = _.get(_.find(CARGO_GROSS_VOLUME_UNIT_VARIABLES, {label: value}), 'code');
      const idTarget = _.find(this.$parent.group.children, {key: this.getTarget()});
      const unitTarget = this.getTarget(idTarget);
      if (unitId) {
        this.setTargetValue(unitTarget, unitId);
      } else {
        this.setTargetValue(unitTarget, null);
      }
    },
    onTotalValueChange(value, target) {
      if (value !== this.s[target] && !this.override) {
        this.$store
          .dispatch('SHOW_CONFIRM', 'Difference between your input and automatic calculation. Are you sure you want to submit the data?')
          .then(() => {
            this.override = true;
            this.setValue(value);
          })
          .catch(() => {

            // if (['expensesTotalAmount', 'adjustedAmount', 'estimateQuotationOfTotalFreight', 'actualQuotationOfTotalFreight', 'estimateTotalCharge', 'actualTotalCharge'].includes(this.item.key)) {
            if (['expensesTotalAmount', 'adjustedAmount', 'estimateQuotationOfTotalFreight', 'actualQuotationOfTotalFreight'].includes(this.item.key)) {
              this.setValue(this.s[target]);
            } else {
              this.setValue(this.s[target] === false || this.isOverFlow(this.s[target]) ? null : this.s[target]);
            }
          });
      } else {
        if (value === false) {
          value = null;
        }
        this.setValue(value);
      }
    },
    onTotalUnitValueChange(value, target) {
      if (value !== this.s[target] && !this.overrideUnit) {
        this.$store
          .dispatch('SHOW_CONFIRM', 'Difference between your input and automatic calculation. Are you sure you want to submit the data?')
          .then(() => {
            this.$refs[target].blur();
            this.overrideUnit = true;
            this.setTargetValue(this.getTarget(), value);
            this.onUnitChange(value);
          })
          .catch(() => {
            this.$refs[target].blur();
            this.setTargetValue(this.getTarget(), this.s[target] || null);
            this.onUnitChange(this.s[target]);
          });
      } else {
        if (value === false) {
          value = null;
        }
        this.setTargetValue(this.getTarget(), value);
        this.onUnitChange(value);
      }
    },
    onTotalQuantityChange(value) {
      this.onTotalValueChange(value, 'totalQuantity');
    },
    onTotalQuantityUnitChange(value) {
      this.onTotalUnitValueChange(value, 'totalQuantityUnit');
    },
    onTotalNetWeightChange(value) {
      this.onTotalValueChange(value, 'totalNetWeight');
    },
    onTotalGrossWeightChange(value) {
      this.onTotalValueChange(value, 'totalGrossWeight');
    },
    onTotalWeightUnitChange(value) {
      this.onTotalUnitValueChange(value, 'totalWeightUnit');
    },
    onTotalMeasurementChange(value) {
      this.onTotalValueChange(value, 'totalMeasurement');
    },
    onTotalMeasurementUnitChange(value) {
      this.onTotalUnitValueChange(value, 'totalMeasurementUnit');
    },
    onTotalAmountChange(value) {
      this.onTotalValueChange(value, 'totalAmount');
    },
    onNoCommercialValueAmountChange(value) {
      this.onTotalValueChange(value, 'noCommercialValueAmount');
    },
    onCurrencyRefChange(value) {
      // if (['expensesTotalAmount', 'adjustedAmount', 'estimateQuotationOfTotalFreight', 'actualQuotationOfTotalFreight', 'estimateTotalCharge', 'actualTotalCharge'].includes(this.item.key)) {
      if (['expensesTotalAmount', 'adjustedAmount', 'estimateQuotationOfTotalFreight', 'actualQuotationOfTotalFreight'].includes(this.item.key)) {
        this.onTotalValueChange(value, this.item.key);
      }
    },
    onCurrencyChange(value) {
      const unitId = _.get(_.find(this.SYS_MST_CURRENCY, {symbol: value}), 'alphabeticCode');
      const idTarget = _.find(this.$parent.group.children, {key: this.getTarget()});
      const unitTarget = this.getTarget(idTarget);
      if (unitId) {
        this.setTargetValue(unitTarget, unitId);
      } else {
        this.setTargetValue(unitTarget, null);
      }
    },
    onCurrencyChange2(value) {
      const unitId = _.get(_.find(this.SYS_MST_CURRENCY, {symbol: value}), 'alphabeticCode');
      const unitTarget = this.getTarget();
      if (unitId) {
        this.setTargetValue(unitTarget, unitId);
      } else {
        this.setTargetValue(unitTarget, null);
      }
    },
    onTotalCurrencyChange(value) {
      if (this.s.totalCurrencyId !== false && value !== this.s.totalCurrency && !this.overrideUnit) {
        this.$store
          .dispatch('SHOW_CONFIRM', 'Difference between your input and automatic calculation. Are you sure you want to submit the data?')
          .then(() => {
            this.$refs.totalCurrency.blur();
            this.overrideUnit = true;
            this.setTargetValue(this.getTarget(), value);
            this.onCurrencyChange(value);
          })
          .catch(() => {
            this.$refs.totalCurrency.blur();
            this.setTargetValue(this.getTarget(), this.s.totalCurrency || null);
            this.onCurrencyChange(value === false ? null : value);
          });

      } else {
        this.onCurrencyChange(value);
      }
    },
    onPlaceChange(value) {
      const unitId = _.get(_.find(this.SYS_MST_PORT_AND_PLACE, {name: value}), 'code');
      const unitTarget = this.getTarget();
      if (unitTarget) {
        if (unitId || unitId === 0) {
          this.setTargetValue(unitTarget, unitId);
        } else {
          this.setTargetValue(unitTarget, null);
        }
      }
      if (this.item.key === 'priceQuotationPlaceName') {
        // 建値条件地名
        this.setPriceQuotationName();
      } else if (this.item.key === 'deliveryTermsPlaceName') {
        // 受渡条件地名
        this.setDeliveryTermsText();
      }
    },
    onCountryChange(value) {
      const unitId = _.get(_.find(this.SYS_MST_COUNTRY, {name: value}), 'countryCd');
      const unitTarget = this.getTarget();
      if (unitId || unitId === 0) {
        this.setTargetValue(unitTarget, unitId);
      } else {
        this.setTargetValue(unitTarget, null);
      }
      if (this.item.key === 'priceQuotationCountryName') {
        // 建値条件国名
        this.setPriceQuotationName();
      } else if (this.item.key === 'deliveryTermsCountryName') {
        // 受渡条件国名
        this.setDeliveryTermsText();
      }
    },
    onPackingChange(value) {
      const unitId = _.get(_.find(this.SYS_MST_PACKAGE_TYPE, {value: value}), 'code');
      const unitTarget = this.getTarget();
      if (unitId || unitId === 0) {
        this.setTargetValue(unitTarget, unitId);
      } else {
        this.setTargetValue(unitTarget, null);
      }
    },
    onNameChange(value) {
      if (value) {
        // 取引先名が入力されたら取引先情報群のアコーディオンを開く
        this.$emit('on-name-change');
      }
    },
    onSignerChange(value) {
      const unitId = _.get(_.find(this.CO_DOCUMENT_SIGNERS, {label: value}), 'code');
      const unitTarget = this.getTarget();
      if (unitTarget) {
        if (unitId || unitId === 0) {
          this.setTargetValue(unitTarget, unitId);
        } else {
          this.setTargetValue(unitTarget, null);
        }
      }
    },
    onRadioClick(val, key) {
      if (this.model === val) {
        this.setTargetValue(key, null);
      } else {
        this.setTargetValue(key, val);
      }
      if (this.item.key === 'priceQuotationId') {
        // 建値条件ID
        this.setPriceQuotationName();
      } else if (this.item.key === 'deliveryTermsId') {
        // 受渡条件ID
        this.setDeliveryTermsText();
      }
      if (this.item.key === 'transportationServiceLevel' && this.model !== TRANSPORTATION_SERVICE_LEVEL.OTHER) {
        this.setTargetValue('transportationServiceLevelOther', null);
        this.s.$refs.form.validateField('linkageTransportation.bookingInformationAirGrp.transportationServiceLevelOther')
      }
    },
    onCheck() {
      const value = this.checks.join(',');
      this.setValue(value);
    },
    onCustomerSearch() {
      if (!this.$parent.group) {
        // CUSTOMERテンプレートの時
        this.$emit('search');
      } else {
        this.showDialog();
      }
    },
    // 取引先選択
    fetch(query) {
      // bff_customer_10 取引先検索(トラン用) BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-user-entity-bff-api',
          apiCode: 'get_/v1/{companyId}/customers/search/transaction',
          path: {
            companyId: this.$store.state.companyId,
          },
          query: query || {
            ownCompanyFlg: this.item.key === 'freightBillingTo' ? OWN_COMPANY_FLG.ON : OWN_COMPANY_FLG.OFF,
            limit: this.limit,
            offset: this.offset,
            sort: this.sort,
            deleteFlg: DELETE_FLG.NOT_DELETED,
          }
        }
      };

      this.lastQuery = this.s.cloneDeep(params.lslConfig.query);

      $api
        .request(params)
        .then(res => {
          // const lang = this.$store.getters.getLanguageKey;
          this.customers = res.customers ? _.map(res.customers, o => {
            return {
              ...o,
              ...o.en,
            };
          }) : [];
          this.totalCount = res.totalCount;
        })
        .catch(err => {
          this.customers = [];
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
    search() {
      const query = {
        customerId: this.searchForm.customerId ? [this.searchForm.customerId] : [],
        customerName: this.searchForm.customerName,
        branchName: this.searchForm.branchName,
        companyRole: this.searchForm.companyRole,
        keyword: this.searchForm.keyword,
        ownCompanyFlg: this.item.key === 'freightBillingTo' ? OWN_COMPANY_FLG.ON : OWN_COMPANY_FLG.OFF,
        limit: this.limit,
        offset: this.offset,
        sort: this.sort,
        deleteFlg: DELETE_FLG.NOT_DELETED,
      };
      this.$store.commit('SET_QUERIES', {key: 'CUSTOMER', queries: this.s.cloneDeep(this.searchForm)});
      this.customers = null;
      this.fetch(query);
    },
    clear() {
      this.searchForm = clearSearchForm(this.searchForm);
      this.$store.commit('SET_QUERIES', {key: 'CUSTOMER', queries: this.s.cloneDeep(this.searchForm)});
    },
    onPaging({target, sortType, currentPage}) {
      if (target && sortType) {
        const key = _.get(_.find(this.schemas, {key: target}), 'sortTarget');
        this.sort = {
          target: [CUSTOMERS_SELECT_SORT_TARGET[key] || target],
          sortType: [sortType],
        };
      } else {
        this.sort = null;
      }

      this.offset = currentPage * this.limit - this.limit;
      const queries = {
        ...this.lastQuery,
        limit: this.limit,
        offset: this.offset,
        sort: this.sort,
      };
      this.fetch(queries);
    },
    // 取引先マスタから情報を取り込みます
    register(row) {
      const idTarget = this.getTarget();
      if (idTarget) {
        this.setTargetValue(this.getTarget(), row.customerId || row.companyId || null);
        const countryTarget = this.getTarget(_.find(this.$parent.group.children, {key:idTarget}));
        if (countryTarget) {
          this.setTargetValue(countryTarget, row.countryCd || null);
          const countryName = _.get(_.find(this.SYS_MST_COUNTRY, {countryCd: row.countryCd}), 'name');
          const unitTarget = this.getTarget(_.find(this.$parent.group.children, {key:countryTarget}));
          if (unitTarget) {
            this.setTargetValue(unitTarget, countryName);
          }
        }

      }
      this.setValue(row.customerShortName || null);
      this.dialogAddGoodsVisible = false;
    },
    // 取引先IDをクリアします
    deletePlayerInfo() {
      if (!this.$parent.group) {
        // CUSTOMERテンプレートの時
        this.$emit('delete-player-info');
        return;
      }
      const idTarget = this.getTarget();
      if (idTarget) {
        this.setTargetValue(idTarget, null);
      }
      this.setValue(null);
    },
    showDialog() {
      getSelection().removeAllRanges(); // テキストの選択をクリアします
      const queries = this.$store.getters.getQueries('CUSTOMER');
      if (queries) {
        this.searchForm = queries;
        this.setCompanyRole();
        this.search();
      } else if (!this.init) {
        this.setCompanyRole();
        this.fetch();
      }

      this.init = true;
      this.dialogAddGoodsVisible = true;
    },
    nullFormat(value) {
      if (value === null || '') {
        return undefined;
      }
      if (_.isNaN(Number(value))) {
        return undefined;
      }
      return Number(value);
    },
    // 値が指定桁を超えているかどうかを返却します
    isOverFlow(value, integer = 11, decimal = 4) {
      const ary = (value + '').split('.');
      if (ary[0] && ary[0].length > integer) {
        return true;
      } else if (ary[1] && ary[1].length > decimal) {
        return true;
      }
      return false;
    },
    // 連番項目を追加・削除します
    addIndex(num) {
      this.$emit('add-index', this.item.key, num);
    },
    // 連番項目削除のグループチェック
    checkGroupValues() {
      if (!(this.isSerialGrp && this.itemIndex === this.serialIndex && this.serialIndex > 0)) {
        return false;
      }
      if (_.get(this.serialItems, `${getKey(this.item.key)}.nestGroupName`)) {
        const value = _.get(this.localValue, `${this.tableName}.${this.groupName}.${this.item.key}`);
        if (value || value === 0) {
          return false;
        } else return true;
      }
      if (_.some(this.serialItems, o => {
        if (o.nestGroupName) {
          return _.some(o.items, item => {
            const key = item.key;
            const value = _.get(this.localValue, `${this.tableName}.${this.groupName}.${key}`);
            if (value || value === 0) return true;
            else return false;
          })
        } else {
          const key = _.get(o, `items[${this.itemIndex}].key`);
          const value = _.get(this.localValue, `${this.tableName}.${this.groupName}.${key}`);
          if (key && (value || value === 0)) return true;
          else return false;
        }
      })) {
        return false;
      } else return true;
    },
    // 文字列から同じ項目かどうかを判定します
    isEqualItem(str) {
      const ary = str.split('.');
      return this.tableName === ary[0] && this.groupName === ary[1] && this.item.key === ary[2];
    },
    // 通常Inputの値が変わったとき
    onInputChange(value) {
      if (this.s.processType === PROCESS_TYPE.ECREQ && this.item.key === 'exCustomInvoiceNo') {
        // 輸出船積依頼プロセスのInvoice No.
        this.s.setValue('linkageFinalDocs', 'commercialInvoiceGrp', 'commercialInvoiceNo', value);
      } else if (this.s.processType === PROCESS_TYPE.ICREQ && this.item.key === 'commercialInvoiceNo') {
        // 輸入荷捌依頼プロセスのInvoice No.
        this.s.setValue('linkageImportCustoms', 'importCustomsClearanceGrp', 'imCustomInvoiceNo', value);
      } else if (this.item.key === 'contractGroupOfGoods' && _.get(this.localValue, 'linkageTransportation.transportationGoodsGrp')) {
        // 輸送プロセスの代表商品名称（輸送）
        this.s.setValue('linkageTransportation', 'transportationGoodsGrp', 'transportationGroupOfGoods', value);
      }
    },
    // 数値文字列の値が変わったとき
    onNumberStringChange(value) {
      this.setValue(value);
    },
    // 建値条件文言（条件 地名, 国）をセットします
    setPriceQuotationName() {
      const id = _.get(_.find(DELIVERY_TERMS_VARIABLES, {code: _.get(this.localValue, 'linkageContract.priceQuotationGrp.priceQuotationId')}), 'label') || '';
      const place = _.get(this.localValue, 'linkageContract.priceQuotationGrp.priceQuotationPlaceName') || '';
      const country = _.get(this.localValue, 'linkageContract.priceQuotationGrp.priceQuotationCountryName') || '';
      const text = `${id ? id + ' ' : ''}${place && country ? place + ', ' : place}${country}`;
      this.s.setValue('linkageContract', 'priceQuotationGrp', 'priceQuotationName', text);

      if (_.get(this.localValue, 'linkageContract.deliveryTermsGrp')) {
        this.s.setValue('linkageContract', 'deliveryTermsGrp', 'deliveryTermsId', _.get(this.localValue, 'linkageContract.priceQuotationGrp.priceQuotationId'));
        this.s.setValue('linkageContract', 'deliveryTermsGrp', 'deliveryTermsPlaceId', _.get(this.localValue, 'linkageContract.priceQuotationGrp.priceQuotationPlaceId'));
        this.s.setValue('linkageContract', 'deliveryTermsGrp', 'deliveryTermsPlaceName', _.get(this.localValue, 'linkageContract.priceQuotationGrp.priceQuotationPlaceName'));
        this.s.setValue('linkageContract', 'deliveryTermsGrp', 'deliveryTermsCountryId', _.get(this.localValue, 'linkageContract.priceQuotationGrp.priceQuotationCountryId'));
        this.s.setValue('linkageContract', 'deliveryTermsGrp', 'deliveryTermsCountryName', _.get(this.localValue, 'linkageContract.priceQuotationGrp.priceQuotationCountryName'));
        this.s.setValue('linkageContract', 'deliveryTermsGrp', 'deliveryTermsText', text);
      }
    },
    // 受渡条件文言（条件 地名, 国）をセットします
    setDeliveryTermsText() {
      const id = _.get(_.find(DELIVERY_TERMS_VARIABLES, {code: _.get(this.localValue, 'linkageContract.deliveryTermsGrp.deliveryTermsId')}), 'label') || '';
      const place = _.get(this.localValue, 'linkageContract.deliveryTermsGrp.deliveryTermsPlaceName') || '';
      const country = _.get(this.localValue, 'linkageContract.deliveryTermsGrp.deliveryTermsCountryName') || '';
      const text = `${id ? id + ' ' : ''}${place && country ? place + ', ' : place}${country}`;
      this.s.setValue('linkageContract', 'deliveryTermsGrp', 'deliveryTermsText', text);
    },
    // 検索元の情報群毎にカンパニーロールの初期値をセットします
    setCompanyRole() {
      const filter = _.find(customerRole, o => {
        const ary = o.group.split('.');
        return this.tableName === ary[0] && this.groupName === ary[1];
      }) || [];
      const roles = _.compact(_.map(filter.roles, key => {
        return COMPANY_ROLE_CD[key];
      }));
      this.searchForm.companyRole = roles;
    },
    // airGrpの輸送サービスレベルは輸送手段がAirと未選択時のみ表示
    hideTransportationServiceLevel() {
      const transportation = _.get(this.localValue, 'linkageTransportation.transferTermsGrp.transportation');
      if ((transportation || transportation === 0) && transportation !== TRANSPORTATION.AIRCRAFT) {
        return true;
      }
      return false;
    },
    // countryOfDischargingIdが「US（USA）, CA（CAN）, MX（MEX）, PR（Puerto Rico）」のいずれかの時にAMS Filerを必須とする
    createRules() {
      if (['US', 'CA', 'MX', 'PR'].includes(_.get(this.localValue, 'linkageTransportation.logisticsDischargingGrp.countryOfDischargingId'))) {
        return [
          {
            required: this.s.draftValid ? false : true,
            lakeelMessageSourceFlg: true,
            validationId: this.s.draftValid ? _.reject(this.item.validationId, s => s === 'tw-isNotEmpty') : [...this.item.validationId, 'tw-isNotEmpty'],
            validator: this.s.onEventValidation, trigger: (['select', 'radio', 'checkbox', 'unit', 'currencyId'].includes(this.item.inputType) || ['SYS_MST_PORT_AND_PLACE', 'SYS_MST_COUNTRY'].includes(this.item.code)) ? 'change' : 'blur',
          }
        ];
      }
    },
    onDateChange,
    onDateTimeChange,
    onTimeChange,
  }
};
</script>

<style lang="scss" scoped>
  .tw_process_group_list_edit_item {
    // margin-bottom: 24px;

    &.full {
      width: 100%;

      & ::v-deep .el-form-item__content, ::v-deep .el-form-item__error {
        max-width: 100%;
      }
    }

    // Document情報 情報群
    &.document_name {
      width: 100%;

      .el-form-item {
        border-top: 1px solid $color_gray_300;
        padding-top: 24px;
      }

      &:first-child .el-form-item {
        border-top: 0;
        padding-top: 0;
      }
    }

    // 決済書類条件 情報群
    &.payment_document_terms_value {
      width: 196px;
      padding-right: 10px;

      ::v-deep .el-form-item {
         .el-input-number {
           width: 60px;
         }
      }

      ::v-deep .el-form-item {
        min-width: 0;

        label.el-form-item__label {
          padding-right: 0;
        }
      }

      &.no_label {
        width: 70px;

        ::v-deep .el-form-item {
          label.el-form-item__label {
            display: none;
          }
        }
      }

      &.office_copy {
        width: calc(50% - 70px - 196px);
      }
    }

    &.payment_document_terms_name {
      &.add_serial .el-input,
      &.add_serial.remove_serial .el-input {
        width: 320px !important;
      }

      .tw_button {
        position: absolute;
        right: -426px;
        z-index: 2;
      }
      .remove_button {
        position: absolute;
        top: 10px;
        right: -380px;
        z-index: 2;
      }
    }

    // 航空 情報群、Bookingリファレンス情報群
    &.arrival_time, &.expiry_date {
      // width: 100%;

      .el-form-item {
        position: relative;
        overflow-x: visible;
        padding-bottom: 24px;

        &:after {
          position: absolute;
          display: block;
          width: 200%;
          right: 0;
          bottom: 0;
          border-bottom: 1px solid $color_gray_300;
          content: '';
        }
      }

      // .el-form-item:last-child  {
      //   padding-bottom: 0;

      //   &:after {
      //     content: none;
      //   }
      // }
    }

    // 見積もり運賃
    &.estimate_others {

      .el-form-item {
        position: relative;
        overflow-x: visible;
        padding-bottom: 24px;

        &:after {
          position: absolute;
          display: block;
          width: 200%;
          right: 0;
          bottom: 0;
          border-bottom: 1px solid $color_gray_300;
          content: '';
        }
      }
    }

    &.estimate_other_charge {
      width: 100%;

      .el-form-item {
        position: relative;
        overflow-x: visible;
        padding-bottom: 24px;

        &:after {
          position: absolute;
          display: block;
          width: 100%;
          right: 0;
          bottom: 0;
          border-bottom: 1px solid $color_gray_300;
          content: '';
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    ::v-deep .el-form-item {
      margin-bottom: 0;
      display: flex;

      label.el-form-item__label {
        display: block;
        width: 224px;
        font-size: 12px;
        line-height: 18px;
        color: #9191A4;
        margin-right: 8px;
        padding-top: 2px;
        flex-shrink: 0;
      }

      &.with_id label.el-form-item__label {
        position: relative;
      }

      &.with_id label.el-form-item__label:before {
        position: absolute;
        top: 0;
        left: 38px;
        font-size: 12px;
        font-weight: bold;
        color: white;
        line-height: 1;
        padding: 4px 8px;
        border-radius: 10px;
        background: #5185C5;
        content: 'with ID';
      }

      &.with_id.is-required label.el-form-item__label:before {
        position: absolute;
        top: 20px;
        left: 0;
      }

    }

    ::v-deep .el-form-item__content {
      font-size: 14px;
      line-height: 20px;
      color: #3E3A39;
      vertical-align: top;
      flex: 1;
    }

    ::v-deep textarea {
      resize: none;
    }

    .el-select {
      width: 320px;
    }

    .el-select.unit {
      width: 100px;
      margin-left: 12px;
    }

    // .el-input-number {
    //   width: 188px;
    // }

    .el-radio-group .el-radio.is-bordered:last-child {
      margin-right: 0;
    }

    .warning ::v-deep .el-input__inner, .hide_error.warning ::v-deep .el-input__inner {
      border-color: $color_draft;
    }

    .warning_message {
      // height: 18px;
      font-size: 12px;
      line-height: 1;
      padding-top: 4px;
      padding-left: 16px;
      color: $color_draft;
      background: url(../../assets/images/icons/icon_alert_warn.svg) no-repeat 0 2px;
      background-size: 14px auto;

      &.measurement {
        max-width: 320px;
      }
    }

    &.remove_serial {
      .el-input,
      .el-select {
        width: calc(320px - 20px) !important;
      }
      .el-textarea,
      .text_full {
        width: calc(100% - 20px) !important;
      }
      .remove_button {
        cursor: pointer;
        width: 20px;
        vertical-align: middle;
      }
      &.full {
        .remove_button {
          margin-bottom: 16px;
        }
      }
    }
    &.add_serial {
      .el-input, .el-select {
        width: calc(320px - 48px) !important;
      }
      .el-textarea,
      .text_full {
        width: calc(100% - 48px) !important;
      }
      .add_button {
        vertical-align: initial;
        margin: 0 0 0 8px;
        padding-left: 9px;
        padding-right: 1px;
        &.group_button {
          border-radius: 10px;
        }
      }
      &.remove_serial {
        .el-input, .el-select {
          width: calc(320px - 68px) !important;
        }
        .el-textarea,
        .text_full {
          width: calc(100% - 68px) !important;
        }
      }
      &.full {
        .add_button {
          margin-bottom: 8px;
        }
        .remove_button {
          margin-bottom: 6px;
        }
      }
    }

    .el-form-item {
      min-width: 462px;
      margin-bottom: 24px;
      // width: 50%;
      display: flex;
      padding-right: 16px;

      &:last-child {
        margin-bottom: 20px;
      }

      &.full {
        width: 100%;
      }

      ::v-deep label.el-form-item__label {
        display: block;
        width: 118px;
        font-size: 12px;
        line-height: 18px;
        color: #9191A4;
        margin-right: 8px;
        padding-top: 2px;
      }
    }


    ::v-deep .el-form-item__content {
      font-size: 14px;
      line-height: 20px;
      color: #3E3A39;
      vertical-align: top;
      flex: 1;

      .el-form-item__error {
        position: relative;
        top: 0;
        max-width: 320px;
      }
    }

    ::v-deep textarea {
      resize: none;
    }

    &.full {
      & .el-select {
        width: 100%;
      }
    }

    .el-select {
      width: 320px;
    }

    .el-select.unit {
      width: 90px;
      margin-left: 12px;
    }

    .el-select.packing {
      margin-left: 12px;
    }

    .el-input-number.narrow, .tw_input_number_string.narrow {
      width: 218px;
    }

    .el-input-number, .el-input-number.amount {
      width: 320px;
    }

    .warning ::v-deep .el-input__inner {
      border-color: $color_draft;
    }

    // .el-radio-group label:last-child {
    //   margin-right: 0;
    // }
    &.edit_infinite {
      width: 100%;
    }
  }
</style>
