<template>
  <div style="display: flex;flex-wrap: wrap;padding: 0 0 0 16px">
    <div class="tw_process_group_list_item full">
      <div class="label">Inner Packing</div>
      <div class="value">{{innerPacking}}</div>
    </div>
    <div class="tw_process_group_list_item">
      <div class="label">Package No. From</div>
      <div class="value">{{packageFrom}}</div>
    </div>
    <div class="tw_process_group_list_item">
      <div class="label">Package No. To</div>
      <div class="value">{{packageTo}}</div>
    </div>
    <div class="tw_process_group_list_item full">
      <div class="label">Shipping Mark 1</div>
      <div class="value" v-html="sanitize(shippingMark1)" />
    </div>
    <!-- NOTE 以下チケット対応で、フロント側ではshippingMark2~10は完全に非表示とする-->
    <!-- https://tradewaltz.backlog.com/view/FY2023V2PH7-427 -->
    <!-- <div v-show="showShippingMark('shippingMark2')" class="tw_process_group_list_item full">
      <div class="label">Shipping Mark 2</div>
      <div class="value" v-html="sanitize(shippingMark2)" />
    </div>
    <div v-show="showShippingMark('shippingMark3')" class="tw_process_group_list_item full">
      <div class="label">Shipping Mark 3</div>
      <div class="value" v-html="sanitize(shippingMark3)" />
    </div>
    <div v-show="showShippingMark('shippingMark4')" class="tw_process_group_list_item full">
      <div class="label">Shipping Mark 4</div>
      <div class="value" v-html="sanitize(shippingMark4)" />
    </div>
    <div v-show="showShippingMark('shippingMark5')" class="tw_process_group_list_item full">
      <div class="label">Shipping Mark 5</div>
      <div class="value" v-html="sanitize(shippingMark5)" />
    </div>
    <div v-show="showShippingMark('shippingMark6')" class="tw_process_group_list_item full">
      <div class="label">Shipping Mark 6</div>
      <div class="value" v-html="sanitize(shippingMark6)" />
    </div>
    <div v-show="showShippingMark('shippingMark7')" class="tw_process_group_list_item full">
      <div class="label">Shipping Mark 7</div>
      <div class="value" v-html="sanitize(shippingMark7)" />
    </div>
    <div v-show="showShippingMark('shippingMark8')" class="tw_process_group_list_item full">
      <div class="label">Shipping Mark 8</div>
      <div class="value" v-html="sanitize(shippingMark8)" />
    </div>
    <div v-show="showShippingMark('shippingMark9')" class="tw_process_group_list_item full">
      <div class="label">Shipping Mark 9</div>
      <div class="value" v-html="sanitize(shippingMark9)" />
    </div>
    <div v-show="showShippingMark('shippingMark10')" class="tw_process_group_list_item full">
      <div class="label">Shipping Mark 10</div>
      <div class="value" v-html="sanitize(shippingMark10)" />
    </div>
    <div class="tw_process_group_list_item">
      <div class="label">Container No.</div>
      <div class="value">{{containerNo}}</div>
    </div> -->
    <div v-for="(item, key, index) in remarks" :key="index" v-show="index <= viewRemarks" class="tw_process_group_list_item full">
      <div class="label">{{key | startCase}}</div>
      <div class="value" v-html="sanitize(item)" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { formatQuantity, formatNumber } from '@/utils/searchUtil.js';
import { getGoodsSerialIndex } from '@/utils/processedSerialItems';

export default {
  name: 'TwProcessGroupListPreviewGoodsLinePrice',
  props: {
    selectGoodsIndex: Number,
  },
  inject: ['s'],
  data() {
    return {
    };
  },
  computed: {
    netTotal() {
      const quantity = _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.netTotal`);
      return formatQuantity(quantity, this.weightUnit);
    },
    grossTotal() {
      const quantity = _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.grossTotal`);
      return formatQuantity(quantity, this.weightUnit);
    },
    netLength() {
      const quantity = _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.netLength`);
      return formatQuantity(quantity, this.lwhUnit);
    },
    netWidth() {
      const quantity = _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.netWidth`);
      return formatQuantity(quantity, this.lwhUnit);
    },
    netHeight() {
      const quantity = _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.netHeight`);
      return formatQuantity(quantity, this.lwhUnit);
    },
    grossLength() {
      const quantity = _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.grossLength`);
      return formatQuantity(quantity, this.lwhUnit);
    },
    grossWidth() {
      const quantity = _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.grossWidth`);
      return formatQuantity(quantity, this.lwhUnit);
    },
    grossHeight() {
      const quantity = _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.grossHeight`);
      return formatQuantity(quantity, this.lwhUnit);
    },
    netMeasurementTotal() {
      const quantity = _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.netMeasurementTotal`);
      return formatQuantity(quantity, this.measurementUnit, 6);
    },
    grossMeasurementTotal() {
      const quantity = _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.grossMeasurementTotal`);
      return formatQuantity(quantity, this.measurementUnit, 6);
    },
    packageFrom() {
      return _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.packageFrom`) || '';
    },
    packageTo() {
      return _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.packageTo`) || '';
    },
    numberOfPackages() {
      const quantity = _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.numberOfPackages`) || '';
      return formatQuantity(quantity, this.packing);
    },
    numberOfContents() {
      const quantity = _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.numberOfContents`);
      return formatNumber(quantity);
    },
    packing() {
      const packageType = _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.packing`);
      if (packageType) {
        return packageType.split(',')[0];
      }
      return '';
    },
    innerPacking() {
      const packageType = _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.innerPacking`);
      if (packageType) {
        return packageType.split(',')[0];
      }
      return '';
    },
    containerNo() {
      return _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.containerNo`) || '';
    },
    weightUnit() {
      return _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.weightUnit`) || '';
    },
    lwhUnit() {
      return _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.lwhUnit`) || '';
    },
    measurementUnit() {
      return _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.measurementUnit`) || '';
    },
    shippingMark1() {
      return _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.shippingMark1`) || '';
    },
    // shippingMark2() {
    //   return _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.shippingMark2`) || '';
    // },
    // shippingMark3() {
    //   return _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.shippingMark3`) || '';
    // },
    // shippingMark4() {
    //   return _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.shippingMark4`) || '';
    // },
    // shippingMark5() {
    //   return _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.shippingMark5`) || '';
    // },
    // shippingMark6() {
    //   return _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.shippingMark6`) || '';
    // },
    // shippingMark7() {
    //   return _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.shippingMark7`) || '';
    // },
    // shippingMark8() {
    //   return _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.shippingMark8`) || '';
    // },
    // shippingMark9() {
    //   return _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.shippingMark9`) || '';
    // },
    // shippingMark10() {
    //   return _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp.shippingMark10`) || '';
    // },
    remarks() {
      return _.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].remarksGrp`) || '';
    },
    viewRemarks() {
      return this.remarks ? getGoodsSerialIndex(this.remarks) : 0;
    },
    // viewShippingMark() {
    //   const group = getSerialGrpItems(_.get(this.s.res, `linkageGoodsLinePacking[${this.selectGoodsIndex}].netGrossMeasurementGrp`), 'netGrossMeasurementGrp');
    //   return getSerialIndex(getSerialGrpIndex(group), 'shippingMark') + 1;
    // },
  },
  methods: {
    sanitize(str) {
      return this.$options.filters.newlines(str);
    },
    // showShippingMark(key) {
    //   return Number(key.replace(/[^0-9]/g, '')) <= this.viewShippingMark;
    // }
  }
};
</script>

<style lang="scss" scoped>
  .tw_process_group_list_item {
    width: 50%;
    min-width: 300px;
    display: flex;
    margin-bottom: 12px;
    padding-right: 16px;

    &.full {
      width: 100%;
    }

    &:last-child {
      margin-bottom: 24px;
    }

    .label {
      width: 118px;
      font-size: 12px;
      line-height: 18px;
      color: #9191A4;
      margin-right: 8px;
      vertical-align: top;
      padding-top: 2px;
    }

    .value {
      font-size: 14px;
      line-height: 20px;
      color: #3E3A39;
      vertical-align: top;
      flex: 1;
    }
  }
</style>
