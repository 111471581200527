<template>
  <div class="tw_process_group_list_infinite">
    <!-- プロセス登録 -->
    <template v-if="isEdit">
      <div class="column" v-for="(obj, arrayIndex) in localArray" :key="arrayIndex">
        <tw-process-group-list-infinite-edit-item
          v-for="(item, i) in items"
          :key="i"
          :item="item"
          :tableName="tableName"
          :groupName="groupName"
          :value="value"
          :arrayIndex="arrayIndex"
          :arrayName="keyItem.arrayName"
          :itemIndex="i"
          :isAddIcon="localArray.length === arrayIndex + 1"
          :max="keyItem.max"
          @add-item="addItem"
          :serialIndexes="serialIndexes"
          :serialItems="serialItems"
          @add-index="addIndex"
          :class="{ full: item.inputType === 'textarea' }"
        />
      </div>
    </template>
    <!-- プロセス詳細 -->
    <template v-else>
      <div class="column" v-for="(obj, arrayIndex) in viewItems" :key="arrayIndex">
        <tw-process-group-list-infinite-item
          v-for="(item, i) in items"
          :key="i"
          :item="item"
          :tableName="tableName"
          :groupName="groupName"
          :arrayIndex="arrayIndex"
          :arrayName="keyItem.arrayName"
          :serialIndexes="serialIndexes"
          :serialItems="serialItems"
        />
      </div>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import { addInfiniteItems } from '@/dictionaries/addInfiniteItems';
import TwProcessGroupListInfiniteItem from '@/components/molecules/TwProcessGroupListInfiniteItem';
import TwProcessGroupListInfiniteEditItem from '@/components/molecules/TwProcessGroupListInfiniteEditItem';
import { serialGrpName, getSerialGrpItems, getSerialGrpIndex, getKey } from '@/utils/processedSerialItems';

export default {
  name: 'TwProcessGroupListInfinite',
  props: {
    item: Object,
    tableName: String,
    groupName: String,
    value: Object,
    isEdit: Boolean
  },
  inject: ['s'],
  components: {
    // TwButton,
    TwProcessGroupListInfiniteItem,
    TwProcessGroupListInfiniteEditItem
  },
  data() {
    return {
      viewItems: [],
      serialIndexes: [], // 連番項目グループindex
      serialItems: [] // 連番項目グループ
    };
  },
  computed: {
    // 配列のkeyになる変数名から、セット項目のデータを取得します
    keyItem() {
      return addInfiniteItems[this.item.key];
    },
    // セット項目のスキーマ情報を配列にまとめます
    items() {
      let items = [];
      items.push(this.item);
      _.forEach(this.keyItem.setItems, key => {
        const table = _.find(this.s.schemas.tables, { variableName: this.tableName });
        const group = _.find(table.groups, { variableName: this.groupName });
        const item = _.find(group.children, { key: key });
        if (item) items.push(item);
      });
      return items;
    },
    // value内の該当の配列を返却します
    localArray() {
      const targetArray = _.get(this, `value[${this.tableName}][${this.groupName}][${this.keyItem.arrayName}]`, []);
      if (targetArray.length === 0) this.addItem();
      return targetArray;
    }
  },
  async created() {
    await this.initArray();
    await this.initSerial();
  },
  watch: {
    's.res': {
      async handler() {
        this.serialIndexes = [];
        await this.initArray();
        await this.initSerial();
      }
    }
  },
  methods: {
    /**
     * 配列の初期化処理
     * 中身が入っている最後のオブジェクトまで切り出します
     * プロセス登録・詳細で参照するオブジェクトが変わる
     */
    async initArray() {
      let localArray;
      if (this.isEdit) localArray = _.get(this, `value[${this.tableName}][${this.groupName}][${this.keyItem.arrayName}]`, []);
      else localArray = _.get(this.s.res, `${this.tableName}.${this.groupName}.${this.keyItem.arrayName}`) || [];
      // 中身が入っている最後のオブジェクトまでのindexを取得
      const lastIndex = _.findLastIndex(localArray, items => {
        const objValues = Object.values(items);
        return _.some(objValues, val => {
          return val !== null && val !== '' && !_.isObject(val);
        });
      });
      if (this.isEdit) {
        // プロセス登録の場合
        if (!localArray.length || lastIndex === -1) {
          // 配列のlengthがない場合または全ての中身がない場合は配列をリセットし、一つ目のオブジェクトを追加
          localArray.splice(0);
          await this.addItem();
        } else if (localArray.length) {
          // lengthがある場合は中身が入っているオブジェクトまで切り出し処理
          localArray.splice(lastIndex + 1);
        }
      } else {
        // プロセス詳細の場合
        if (!localArray.length || lastIndex === -1) {
          this.viewItems = [...localArray];
          this.viewItems.splice(0);
          let obj = {};
          _.forEach(this.items, item => {
            obj[`${item.key}`] = null;
          });
          this.viewItems.push(obj);
        } else if (localArray.length) {
          this.viewItems = [...localArray].splice(0, lastIndex + 1);
        }
      }
    },
    // 配列にセット項目を追加・削除します
    async addItem(type) {
      let localArray = this.value[this.tableName][this.groupName][this.keyItem.arrayName] || [];
      if (type === 'remove') {
        this.serialItems.splice(-1, 1);
        this.serialIndexes.splice(-1, 1);
        localArray.splice(-1, 1);
      } else {
        let obj = {};
        _.forEach(this.items, item => {
          obj[`${item.key}`] = null;
        });
        const serialGroupItems = getSerialGrpItems(obj, this.groupName);
        this.serialItems.push(serialGroupItems);
        this.serialIndexes.push(getSerialGrpIndex(serialGroupItems));
        localArray.push(obj);
      }
    },
    // sealNo等の連番項目をグループ化し可変にする
    async initSerial() {
      if (serialGrpName.includes(this.groupName)) {
        const keyName = _.get(addInfiniteItems, `${this.item.key}.arrayName`);
        const res = this.isEdit ? _.get(this, `value.${this.tableName}.${this.groupName}.${keyName}`) || [] : _.get(this, 'viewItems') || [];
        this.serialItems = _.map(res, item => {
          const serialGroupItems = getSerialGrpItems(item, this.groupName);
          if (Object.keys(serialGroupItems).length > 0) {
            this.serialIndexes.push(getSerialGrpIndex(serialGroupItems));
          }
          return serialGroupItems;
        });
      }
      // console.log(this.serialItems, this.serialIndexes)
    },
    // 連番項目を追加 or 削除する
    addIndex(name, num, arrayIndex) {
      const key = getKey(name);
      this.serialIndexes[arrayIndex][key] += num;
    }
  }
};
</script>

<style lang="scss" scoped>
.tw_process_group_list_infinite {
  > div.column {
    display: flex;
    flex-wrap: wrap;
    &:not(:first-child) {
      padding-top: 16px;
      border-top: 1px solid $color_gray_300;
    }
  }
  // margin-bottom: 24px;
  &.full {
    width: 100%;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .el-select {
    width: 320px;
  }
  .el-select.unit {
    width: 100px;
    margin-left: 12px;
  }
  // .el-input-number {
  //   width: 188px;
  // }
  .warning_message {
    // height: 18px;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    padding-left: 16px;
    color: $color_draft;
    background: url(../../assets/images/icons/icon_alert_warn.svg) no-repeat 0 2px;
    background-size: 14px auto;
  }
  .el-form-item {
    min-width: 462px;
    margin-bottom: 20px !important;
    // width: 50%;
    display: flex;
    padding-right: 16px;
    &:last-child {
      margin-bottom: 20px;
    }
    &.full {
      width: 100%;
    }
    ::v-deep label.el-form-item__label {
      display: block;
      width: 118px !important;
      font-size: 12px;
      line-height: 18px;
      color: #9191a4;
      margin-right: 8px;
      padding-top: 2px;
    }
  }
  .el-select {
    width: 320px;
  }
  .el-select.unit {
    width: 90px;
    margin-left: 12px;
  }
  .el-select.packing {
    margin-left: 12px;
  }
  .el-input-number.narrow {
    width: 218px;
  }
  .el-input-number,
  .el-input-number.amount {
    width: 320px;
  }
}
</style>
