<template>
  <div style="padding-left: 16px">
    <div class="tw_process_group_list_item">
      <div class="label">Name</div>
      <div class="value">{{name}}</div>
    </div>
    <template v-if="!isSurveyorGrp">
      <div class="tw_process_group_list_item">
        <div class="label">Branch</div>
        <div class="value">{{branchName}}</div>
      </div>
      <div class="tw_process_group_list_item">
        <div class="label">Address 1</div>
        <div class="value">{{address1}}</div>
      </div>
      <div class="tw_process_group_list_item">
        <div class="label">Address 2</div>
        <div class="value">{{address2}}</div>
      </div>
      <div class="tw_process_group_list_item">
        <div class="label">Address 3</div>
        <div class="value">{{address3}}</div>
      </div>
      <div class="tw_process_group_list_item">
        <div class="label">Address 4</div>
        <div class="value">{{address4}}</div>
      </div>
      <div class="tw_process_group_list_item">
        <div class="label">Address Street 1</div>
        <div class="value">{{addressStreet1}}</div>
      </div>
      <div class="tw_process_group_list_item">
        <div class="label">Address Street 2</div>
        <div class="value">{{addressStreet2}}</div>
      </div>
      <div class="tw_process_group_list_item">
        <div class="label">City</div>
        <div class="value">{{cityName}}</div>
      </div>
      <div class="tw_process_group_list_item">
        <div class="label">Country Subdivision</div>
        <div class="value">{{countrySubdivision}}</div>
      </div>
      <div class="tw_process_group_list_item">
        <div class="label">Postal Code</div>
        <div class="value">{{postalCode}}</div>
      </div>
      <div class="tw_process_group_list_item">
        <div class="label">Country</div>
        <div class="value">{{countryName}}</div>
      </div>
      <div class="tw_process_group_list_item">
        <div class="label">Tel / Fax</div>
        <div class="value"><span v-if="tel">TEL {{tel}}</span><span v-if="tel && fax"> / </span><span v-if="fax">FAX {{fax}}</span></div>
      </div>
      <div v-if="groupName === 'airfreightGrp'" class="tw_process_group_list_item">
        <div class="label">Agent's Iata Code</div>
        <div class="value">{{agentsIataCode}}</div>
      </div>
      <div v-if="groupName === 'carrierGrp'" class="tw_process_group_list_item">
        <div class="label">SCAC</div>
        <div class="value">{{carrierScac}}</div>
      </div>
      <div class="tw_process_group_list_item">
        <div class="label">Remarks</div>
        <div class="value" v-html="sanitize(remarks)" />
      </div>
      <div class="tw_process_group_list_item">
        <div class="label">Info</div>
        <div class="value" v-html="sanitize(info)" />
      </div>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'TwProcessGroupListItemCustomer',
  props: {
    items: Array,
    tableName: String,
    groupName: String
  },
  inject: ['s'],
  data() {
    return {
    };
  },
  computed: {
    SCAC_VARIABLES() {
      return this.$store.getters.getScacVariables;
    },
    label() {
      return this.items[1].label.split(' ').slice(0, -1).join(' ');
    },
    model() {
      return _.get(this.s.res, `${this.tableName}.${this.groupName}`) || {};
    },
    name() {
      return _.find(this.model, (value, key) => key.endsWith('Name') && !key.endsWith('BranchName') && !key.endsWith('CityName') && !key.endsWith('CountryName'));
    },
    branchName() {
      return _.find(this.model, (value, key) => key.endsWith('BranchName'));
    },
    address1() {
      return _.find(this.model, (value, key) => key.endsWith('Address1'));
    },
    address2() {
      return _.find(this.model, (value, key) => key.endsWith('Address2'));
    },
    address3() {
      return _.find(this.model, (value, key) => key.endsWith('Address3'));
    },
    address4() {
      return _.find(this.model, (value, key) => key.endsWith('Address4'));
    },
    addressStreet1() {
      return _.find(this.model, (value, key) => key.endsWith('AddressStreet1'));
    },
    addressStreet2() {
      return _.find(this.model, (value, key) => key.endsWith('AddressStreet2'));
    },
    cityName() {
      return _.find(this.model, (value, key) => key.endsWith('CityName'));
    },
    countryName() {
      return _.find(this.model, (value, key) => key.endsWith('CountryName'));
    },
    countrySubdivision() {
      return _.find(this.model, (value, key) => key.endsWith('CountrySubdivision'));
    },
    postalCode() {
      return _.find(this.model, (value, key) => key.endsWith('PostalCode'));
    },
    tel() {
      return _.find(this.model, (value, key) => key.endsWith('Tel'));
    },
    fax() {
      return _.find(this.model, (value, key) => key.endsWith('Fax'));
    },
    agentsIataCode() {
      return _.find(this.model, (value, key) => key.endsWith('agentsIataCode'));
    },
    carrierScac() {
      const model = _.find(this.model, (value, key) => key.endsWith('carrierScac'));
      return _.get(_.find(this.SCAC_VARIABLES, {code: model}), 'label') || '';
    },
    remarks() {
      return _.find(this.model, (value, key) => key.endsWith('Remarks'));
    },
    info() {
      return _.find(this.model, (value, key) => key.endsWith('Info'));
    },
    isSurveyorGrp() {
      // 海事検定機関はName(ID)のみ表示
      return this.groupName === 'surveyorGrp';
    }
  },
  methods: {
    sanitize(str) {
      return this.$options.filters.newlines(str);
    },
  }
};
</script>

<style lang="scss" scoped>
  .tw_process_group_list_item {
    display: flex;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    .label {
      width: 118px;
      font-size: 12px;
      line-height: 18px;
      color: #9191A4;
      margin-right: 8px;
      vertical-align: top;
      padding-top: 2px;
    }

    .value {
      font-size: 14px;
      line-height: 20px;
      color: #3E3A39;
      vertical-align: top;
      flex: 1;
    }
  }
</style>
