<template>
  <virtual-select
    v-model="inputModel"
    :items="SYS_MST_PORT_AND_PLACE"
    :buffer="32"
    :item-size="34"
    :disabled="disabled"
    filterable
    :allow-create="allowCreate"
    default-first-option
    :clearable="clearable"
    :reserve-keyword="false"
    filter-key="lowerName"
  >
    <template #default="{ item }">
      <el-option slot="default" :key="item.code" :label="item.name" :value="item.name"></el-option>
    </template>
  </virtual-select>
</template>

<script>
import VirtualSelect from '@/components/atoms/TwPortAndPlaceVirtual';

export default {
  name: 'TwPortAndPlace',
  props: {
    value: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    allowCreate: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    VirtualSelect,
  },
  computed: {
    inputModel: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    SYS_MST_PORT_AND_PLACE() {
      return this.$store.getters.getSysMstPortAndPlace;
    },
  },
};
</script>
