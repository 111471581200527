<template>
  <div style="display: flex;flex-wrap: wrap;padding: 0 0 0 16px">
    <div class="tw_process_group_list_item full">
      <div class="label">Goods Name</div>
      <div class="value">{{goodsNameEn}}</div>
    </div>
    <div class="tw_process_group_list_item full">
      <div class="label">Product Spec.</div>
      <div class="value">{{productSpec}}</div>
    </div>
    <div class="tw_process_group_list_item">
      <div class="label">Export Licence</div>
      <div class="value">{{exportLicence}}</div>
    </div>
    <div class="tw_process_group_list_item">
      <div class="label">Export Licence No</div>
      <div class="value">{{exportLicenceNo}}</div>
    </div>
    <div class="tw_process_group_list_item">
      <div class="label">Import Licence</div>
      <div class="value">{{importLicence}}</div>
    </div>
    <div class="tw_process_group_list_item">
      <div class="label">Import Licence No</div>
      <div class="value">{{importLicenceNo}}</div>
    </div>
    <!-- referenceNoGrp -->
    <template v-if="referenceNoGrp">
      <div class="tw_process_group_list_item">
        <div class="label">PO No</div>
        <div class="value">{{referenceNoGrp.poNo}}</div>
      </div>
      <div class="tw_process_group_list_item">
        <div class="label">Contract No</div>
        <div class="value">{{referenceNoGrp.contractNo}}</div>
      </div>
      <div class="tw_process_group_list_item">
        <div class="label">Export Invoice No</div>
        <div class="value">{{referenceNoGrp.exportInvoiceNo}}</div>
      </div>
      <div class="tw_process_group_list_item">
        <div class="label">Booking No</div>
        <div class="value">{{referenceNoGrp.bookingNo}}</div>
      </div>
      <div class="tw_process_group_list_item">
        <div class="label">B/L No</div>
        <div class="value">{{referenceNoGrp.blNo}}</div>
      </div>
      <div class="tw_process_group_list_item">
        <div class="label">Commercial Invoice No</div>
        <div class="value">{{referenceNoGrp.commercialInvoiceNo}}</div>
      </div>
      <div class="tw_process_group_list_item full">
        <div class="label">Import Invoice No</div>
        <div class="value">{{referenceNoGrp.importInvoiceNo}}</div>
      </div>
    </template>

    <div class="tw_process_group_list_item">
      <div class="label">UNIT Round Type 1</div>
      <div class="value">{{unitRoundType1}}</div>
    </div>
    <div class="tw_process_group_list_item">
      <div class="label">UNIT Round Type 2</div>
      <div class="value">{{unitRoundType2}}</div>
    </div>
    <div v-for="(item, key, index) in goodsRemarks" :key="`goodsRemarks-${index}`" v-show="index <= viewGoodsRemarks" class="tw_process_group_list_item full">
      <div class="label">{{key | startCase}}</div>
      <div class="value" v-html="sanitize(item)" />
    </div>
    <template v-if="needPriceTable">
      <div class="table_name">Goods Line Price</div>
      <div v-for="(item, key, index) in priceRemarks" :key="`priceRemarks-${index}`" v-show="index <= viewPriceRemarks" class="tw_process_group_list_item full">
        <div class="label">{{key | startCase}}</div>
        <div class="value" v-html="sanitize(item)" />
      </div>
    </template>
    <!-- 輸入商品価格個別テーブル -->
    <template v-if="needImportGoodsLinePriceSeparateTable">
      <div class="table_name">Goods Price Undisclosed</div>
      <!-- expensesGrp -->
      <div v-for="(item, key, index) in importGoodsLinePriceSeparateExpenses" :key="`importGoodsLinePriceSeparateExpenses-${index}`" v-show="getExpensesItemIndex(key) <= viewImportGoodsLinePriceSeparateExpenses && !key.includes('Currency')" class="tw_process_group_list_item">
        <div class="label">{{key | startCase}}</div>
        <div class="value">{{ item }}</div>
      </div>
      <!-- remarksGrp -->
      <div v-for="(item, key, index) in importGoodsLinePriceSeparateRemarks" :key="`importGoodsLinePriceSeparateRemarks-${index}`" v-show="index <= viewImportGoodsLinePriceSeparateRemarks" class="tw_process_group_list_item full">
        <div class="label">{{key | startCase}}</div>
        <div class="value" v-html="sanitize(item)" />
      </div>
    </template>

  </div>
</template>

<script>
import _ from 'lodash';
import { formatQuantity, formatCurrency } from '@/utils/searchUtil.js';
import { FREE_CHARGE_FLG_VARIABLES, UNIT_ROUND_TYPE_VARIABLES, EXPORT_IMPORT_LICENCE_VARIABLES } from 'lib-tw-common';
import { getGoodsSerialIndex, getSerialGrpItems, getSerialGrpIndex, serialGrpName, getSerialIndex, getItemIndex } from '@/utils/processedSerialItems';

export default {
  name: 'TwProcessGroupListPreviewGoodsLinePriceItem',
  props: {
    selectGoodsLineSeq: Number,
  },
  inject: ['s'],
  data() {
    return {
      serialsParent: {},
    };
  },
  computed: {
    // 選択した商品ラインのインデックス
    selectGoodsIndex() {
      if (!_.get(this.s.res, 'linkageGoodsLine')) {
        return false;
      }
      const selectGoodsIndex = _.findIndex(this.s.res.linkageGoodsLine, goods => {
        return _.get(goods, 'keyGrp.goodsLineSeq') === this.selectGoodsLineSeq;
      });
      return selectGoodsIndex;
    },
    // 選択した商品価格のインデックス
    selectGoodsLinePriceIndex() {
      if (!_.get(this.s.res, 'linkageGoodsLinePrice')) {
        return false;
      }
      const selectGoodsLinePriceIndex = _.findIndex(this.s.res.linkageGoodsLinePrice, price => {
        return _.get(price, 'keyGrp.goodsLineSeq') === this.selectGoodsLineSeq;
      });
      return selectGoodsLinePriceIndex;
    },
    // 選択した輸入商品価格個別のインデックス
    selectImportGoodsLinePriceSeparateIndex() {
      if (!_.get(this.s.res, 'importGoodsLinePriceSeparate')) {
        return false;
      }
      const selectImportGoodsLinePriceSeparateIndex = _.findIndex(this.s.res.importGoodsLinePriceSeparate, line => {
        return _.get(line, 'keyGrp.goodsLineSeq') === this.selectGoodsLineSeq;
      });
      return selectImportGoodsLinePriceSeparateIndex;
    },
    goodsShortName() {
      return _.get(this.s.res, `linkageGoodsLine[${this.selectGoodsIndex}].goodsGrp.goodsShortName`) || '';
    },
    goodsNameEn() {
      return _.get(this.s.res, `linkageGoodsLine[${this.selectGoodsIndex}].goodsGrp.goodsNameEn`) || '';
    },
    productSerialNo() {
      return _.get(this.s.res, `linkageGoodsLine[${this.selectGoodsIndex}].goodsGrp.productSerialNo`) || '';
    },
    productLotNo() {
      return _.get(this.s.res, `linkageGoodsLine[${this.selectGoodsIndex}].goodsGrp.productLotNo`) || '';
    },
    productSpec() {
      return _.get(this.s.res, `linkageGoodsLine[${this.selectGoodsIndex}].goodsGrp.productSpec`) || '';
    },
    countryOfOriginName() {
      return _.get(this.s.res, `linkageGoodsLine[${this.selectGoodsIndex}].goodsGrp.countryOfOriginName`) || '';
    },
    exportLicence() {
      const type = _.get(this.s.res, `linkageGoodsLine[${this.selectGoodsIndex}].goodsGrp.exportLicence`);
      return _.get(_.find(EXPORT_IMPORT_LICENCE_VARIABLES, {code: type}), 'label') || '';
    },
    exportLicenceNo() {
      return _.get(this.s.res, `linkageGoodsLine[${this.selectGoodsIndex}].goodsGrp.exportLicenceNo`) || '';
    },
    importLicence() {
      const type = _.get(this.s.res, `linkageGoodsLine[${this.selectGoodsIndex}].goodsGrp.importLicence`);
      return _.get(_.find(EXPORT_IMPORT_LICENCE_VARIABLES, {code: type}), 'label') || '';
    },
    importLicenceNo() {
      return _.get(this.s.res, `linkageGoodsLine[${this.selectGoodsIndex}].goodsGrp.importLicenceNo`) || '';
    },
    quantity1() {
      const quantity = _.get(this.s.res, `linkageGoodsLine[${this.selectGoodsIndex}].quantityGrp.quantity1`);
      const unit =  _.get(this.s.res, `linkageGoodsLine[${this.selectGoodsIndex}].quantityGrp.unit1`);
      return formatQuantity(quantity, unit);
    },
    quantity2() {
      const quantity = _.get(this.s.res, `linkageGoodsLine[${this.selectGoodsIndex}].quantityGrp.quantity2`);
      const unit =  _.get(this.s.res, `linkageGoodsLine[${this.selectGoodsIndex}].quantityGrp.unit2`);
      return formatQuantity(quantity, unit);
    },
    unitRoundDigit1() {
      return _.get(this.s.res, `linkageGoodsLine[${this.selectGoodsIndex}].quantityGrp.unitRoundDigit1`) || '';
    },
    unitRoundType1() {
      const type = _.get(this.s.res, `linkageGoodsLine[${this.selectGoodsIndex}].quantityGrp.unitRoundType1`);
      return _.get(_.find(UNIT_ROUND_TYPE_VARIABLES, {code: type}), 'label') || '';
    },
    unitRoundDigit2() {
      return _.get(this.s.res, `linkageGoodsLine[${this.selectGoodsIndex}].quantityGrp.unitRoundDigit2`) || '';
    },
    unitRoundType2() {
      const type = _.get(this.s.res, `linkageGoodsLine[${this.selectGoodsIndex}].quantityGrp.unitRoundType2`);
      return _.get(_.find(UNIT_ROUND_TYPE_VARIABLES, {code: type}), 'label') || '';
    },
    goodsRemarks() {
      return _.get(this.s.res, `linkageGoodsLine[${this.selectGoodsIndex}].remarksGrp`) || '';
    },
    price() {
      const value = _.get(this.s.res, `linkageGoodsLinePrice[${this.selectGoodsLinePriceIndex}].priceGrp.price`);
      return this.formatCurrency(value);
    },
    unit() {
      return _.get(this.s.res, `linkageGoodsLinePrice[${this.selectGoodsLinePriceIndex}].priceGrp.unit`) || '';
    },
    amount() {
      const value = _.get(this.s.res, `linkageGoodsLinePrice[${this.selectGoodsLinePriceIndex}].amountGrp.amount`) || '';
      return this.formatCurrency(value);
    },
    noCommercialFlag() {
      const flag = _.get(this.s.res, `linkageGoodsLinePrice[${this.selectGoodsLinePriceIndex}].commercialGrp.noCommercialFlag`);
      return _.get(_.find(FREE_CHARGE_FLG_VARIABLES, {code: flag}), 'label') || '';
    },
    priceRemarks() {
      return _.get(this.s.res, `linkageGoodsLinePrice[${this.selectGoodsLinePriceIndex}].remarksGrp`) || '';
    },
    importGoodsLinePriceSeparateRemarks() {
      return _.get(this.s.res, `importGoodsLinePriceSeparate[${this.selectImportGoodsLinePriceSeparateIndex}].remarksGrp`) || '';
    },
    // 輸入商品価格個別テーブルのexpenses
    importGoodsLinePriceSeparateExpenses() {
      const expenses = _.get(this.s.res, `importGoodsLinePriceSeparate[${this.selectImportGoodsLinePriceSeparateIndex}].expensesGrp`) || {};
      return _.reduce(expenses, (result, value, key) => {
        if (key.includes('Amount')) {
          // 金額項目の場合、通貨を付与
          const currencyId = _.get(expenses, `${key.replace('Amount', 'CurrencyId')}`, null);
          const currency = this.$store.getters.getCurrency(currencyId);
          if (currency) {
            result[key] = formatCurrency(value, currency.symbol, currency.minorUnit);
          } else {
            result[key] = formatCurrency(value);
          }
        } else {
          result[key] = value;
        }
        return result;
      }, {});
    },
    viewGoodsRemarks() {
      return this.goodsRemarks ? getGoodsSerialIndex(this.goodsRemarks) : 0;
    },
    viewPriceRemarks() {
      return this.priceRemarks ? getGoodsSerialIndex(this.priceRemarks) : 0;
    },
    viewImportGoodsLinePriceSeparateRemarks() {
      return this.importGoodsLinePriceSeparateRemarks ? getGoodsSerialIndex(this.importGoodsLinePriceSeparateRemarks) : 0;
    },
    // 輸入商品価格個別テーブルのexpenses表示件数
    viewImportGoodsLinePriceSeparateExpenses() {
      const serialIndexes = _.get(this.serialsParent, `importGoodsLinePriceSeparate[${this.selectImportGoodsLinePriceSeparateIndex}].expensesGrp.serialIndexes`, null);
      const serialGrpName = 'expensesTitle';
      return getSerialIndex(serialIndexes,  serialGrpName) || 0;
    },
    needPriceTable() {
      const table = _.find(this.s.schemas.tables, {variableName: 'linkageGoodsLinePrice'});
      if (!table) {
        return false;
      }
      return _.some(table.groups, group => {
        return this.s.checkTypeOfWorks(group);
      });
    },
    // 輸入商品価格個別テーブルの表示可否
    needImportGoodsLinePriceSeparateTable() {
      const table = _.find(this.s.schemas.tables, {variableName: 'importGoodsLinePriceSeparate'});
      if (!table) {
        return false;
      }
      return _.some(table.groups, group => {
        return this.s.checkTypeOfWorks(group);
      });
    },
    referenceNoGrp() {
      return _.get(this.s.res, `linkageGoodsLine[${this.selectGoodsIndex}].referenceNoGrp`);
    }
  },
  created() {
    // 連番項目init処理
    this.initSerial('importGoodsLinePriceSeparate');
  },
  methods: {
    formatCurrency(value) {
      const currencyId = _.get(this.s.res, `linkageGoodsLinePrice[${this.selectGoodsLinePriceIndex}].priceGrp.currencyId`);
      const currency = this.$store.getters.getCurrency(currencyId);
      if (currency) {
        return formatCurrency(value, currency.symbol, currency.minorUnit);
      }
      return formatCurrency(value);
    },
    sanitize(str) {
      return this.$options.filters.newlines(str);
    },
    initSerial(t_name) {
      this.serialsParent[t_name] = [];
      _.forEach(this.s.res[t_name], (items, parent_key) => {
        this.serialsParent[t_name][parent_key] = {};
        _.forEach(items, (item, key) => {
          const res = this.s.res[t_name][parent_key][key];
          const serialItems = serialGrpName.includes(key) ? getSerialGrpItems(res, key) : {};
          let serialIndexes = {};
          if (Object.keys(serialItems).length > 0) serialIndexes = getSerialGrpIndex(serialItems);
          this.serialsParent[t_name][parent_key][key] = { serialItems: serialItems, serialIndexes: serialIndexes };
        });
      });
    },
    // 輸入商品価格個別テーブルのexpensesのindexを取得
    getExpensesItemIndex(key) {
      const serialItems = _.get(this.serialsParent, `importGoodsLinePriceSeparate[${this.selectImportGoodsLinePriceSeparateIndex}].expensesGrp.serialItems`, []);
      return getItemIndex(serialItems, key);
    },
  }
};
</script>

<style lang="scss" scoped>
  .tw_process_group_list_item {
    width: 50%;
    min-width: 300px;
    display: flex;
    margin-bottom: 12px;
    padding-right: 16px;

    &.full {
      width: 100%;
    }

    &:last-child {
      margin-bottom: 24px;
    }

    .label {
      width: 118px;
      font-size: 12px;
      line-height: 18px;
      color: #9191A4;
      margin-right: 8px;
      vertical-align: top;
      padding-top: 2px;
    }

    .value {
      font-size: 14px;
      line-height: 20px;
      color: #3E3A39;
      vertical-align: top;
      flex: 1;
    }
  }

  .table_name {
    width: 100%;
    vertical-align: middle;
    padding-top: 10px;
    font-size: 16px;
    line-height: 24px;
    color: $color_gray_800;
    white-space: nowrap;
    border-top: 1px solid $color_gray_300;
    margin-bottom: 32px;
  }
</style>
