<template>
  <div style="display: flex;flex-wrap: wrap;padding: 0 0 0 16px">
    <div v-for="item in commonGoodsDetailsGrp" :key="item.key" class="tw_process_group_list_item">
      <div class="label">{{item.label}}</div>
      <div class="value">{{selectGoods.commonGoodsDetailsGrp[item.key]}}</div>
    </div>
    <template v-if="s.processType === PROCESS_TYPE.ECPERMIT">
      <div v-for="item in exportGoodsDetailsGrp" :key="item.key" class="tw_process_group_list_item">
        <div class="label">{{item.label}}</div>
        <div class="value">{{selectGoods.exportGoodsDetailsGrp[item.key]}}</div>
      </div>
    </template>
    <template v-else-if="s.processType === PROCESS_TYPE.ICPERMIT">
      <div v-for="item in importGoodsDetailsGrp" :key="item.key" class="tw_process_group_list_item">
        <div class="label">{{item.label}}</div>
        <div class="value">{{selectGoods.importGoodsDetailsGrp[item.key]}}</div>
      </div>
    </template>

  </div>
</template>

<script>
import _ from 'lodash';
import { PROCESS_TYPE } from 'lib-tw-common';

const omit = [
  'hsCode',
  'importHsCode',
  'goodsName',
  'quantity1',
  'quantity1UnitId',
  'quantity2',
  'quantity2UnitId',
  'declarationAmount',
  'customsAmount',
  'goodsCode',
  'originCountry',
  'tariffRate',
  'tariff',
  'tariffExemptionValue',
  'consumptionTaxRate1',
  'consumptionTaxValue1',
  'consumptionTaxExemptionValue1',
];

export default {
  name: 'TwProcessGroupListPreviewGoodsLineSeparateItem',
  props: {
    selectGoodsLineSeq: Number,
    isEditMode: Boolean,
    table: Object,
  },
  inject: ['s'],
  data() {
    return {
      PROCESS_TYPE
    };
  },
  computed: {
    // 選択した商品
    selectGoods() {
      const table = this.isEditMode ? this.s.cloneItems.goodsLineSeparate : this.s.res.goodsLineSeparate;
      if (table.goods && table.goods.length) {
        return _.find(table.goods, o => {
          return o.keyGrp.goodsLineSeparateSeq === this.selectGoodsLineSeq;
        }) || {};
      }
      return {};
    },
    commonGoodsDetailsGrp() {
      return this.getGroup('commonGoodsDetailsGrp');
    },
    exportGoodsDetailsGrp() {
      return this.getGroup('exportGoodsDetailsGrp');
    },
    importGoodsDetailsGrp() {
      return this.getGroup('importGoodsDetailsGrp');
    },
  },
  methods: {
    getGroup(groupName) {
      const group = _.find(this.table.groups, {'variableName': groupName});
      if (!group) {
        return false;
      }
      return _.reject(group.children, o => {
        return omit.includes(o.key);
      });
    },
  }
};
</script>

<style lang="scss" scoped>
  .tw_process_group_list_item {
    width: 50%;
    min-width: 300px;
    display: flex;
    margin-bottom: 12px;
    padding-right: 16px;
    padding-top: 24px;

    &.full {
      width: 100%;
    }

    &:last-child {
      margin-bottom: 24px;
    }

    .label {
      width: 118px;
      font-size: 12px;
      line-height: 18px;
      color: #9191A4;
      margin-right: 8px;
      vertical-align: top;
      padding-top: 2px;
    }

    .value {
      font-size: 14px;
      line-height: 20px;
      color: #3E3A39;
      vertical-align: top;
      flex: 1;
    }
  }

  .table_name {
    width: 100%;
    vertical-align: middle;
    padding-top: 10px;
    font-size: 16px;
    line-height: 24px;
    color: $color_gray_800;
    white-space: nowrap;
    border-top: 1px solid $color_gray_300;
    margin-bottom: 32px;
  }
</style>
