<template>
  <el-tooltip effect="dark" popper-class="mcu" :content="'' + content" :disabled="disabled" placement="top">
    <div style="width: 100%;overflow: hidden;text-overflow: ellipsis;">
      <span ref="span">{{content}}</span>
    </div>
  </el-tooltip>
</template>

<script>
let t;

// コンテナ幅をコンテンツ幅が超えたときにツールチップを表示します
export default {
  name: 'TwOverflowTooltip',
  props: {
    content: [String, Number],
  },
  data() {
    return {
      disabled: true,
      width: 0,
      scrollWidth: 0,
    };
  },
  watch: {
    width() {
      this.calcOverflow();
    },
    scrollWidth() {
      this.calcOverflow();
    },
  },
  mounted() {
    this.getWidth();
  },
  beforeDestroy() {
    clearTimeout(t);
  },
  methods: {
    calcOverflow() {
      if (this.width < this.scrollWidth) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    getWidth() {
      this.width = this.$el.clientWidth;
      this.scrollWidth = this.$el.scrollWidth;
      t = setTimeout(() => {
        this.getWidth();
      }, 1000);
    }
  }
};
</script>