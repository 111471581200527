<template>
  <el-form-item
    v-if="isVisible && isSerialItem && isNestItem"
    :label="item.label"
    :prop="`${tableName}[${selectGoodsIndex}].${groupName}.${item.key}`"
    :class="{add_serial: isAddSerial && !serialGroupName, remove_serial: isRemoveSerial && !serialGroupName, full: item.inputType === 'text_full' || item.inputType === 'textarea' || item.key === 'numberOfContents' || item.key === 'numberOfPackages' ||  item.key === 'netHeight' ||  item.key === 'grossHeight' || item.key === 'goodsType' || item.key === 'innerPacking' || item.key === 'importInvoiceNo', no_commercial: item.inputType === 'amount' && $parent.noCommercialFlag}"
    :show-message="!hideErrorMessage"
    :rules="rules"
    v-observe-visibility="visibilityChanged"
  >
  <template v-if="isInActiveTab && (!tableView || interSection)">
    <el-input
      v-if="item.inputType === 'textarea'"
      type="textarea"
      :autosize="{ minRows: 2, maxRows: 6}"
      placeholder=""
      v-model="inputModel"
      :disabled="isReadonly"
      show-word-limit
      :maxlength="getMaxLength"
    />
    <TwInputFormatNumber
      v-else-if="item.key.includes('unitRoundDigit')"
      style="width: 20px;"
      v-model="numberModel"
      :disabled="isReadonly"
    />
    <TwInputFormatNumber
      v-else-if="item.inputType === 'number'"
      v-model="numberModel"
      :disabled="isReadonly"
    />
    <el-input-number
      v-else-if="item.inputType === 'numberString'"
      type="number"
      :controls="false"
      placeholder=""
      v-model="numberStringModel"
      :disabled="isReadonly"
    />
    <template v-else-if="item.inputType === 'amount'">
      <TwInputFormatNumber
        ref="amount"
        class="amount format"
        v-model="numberModel"
        :class="{warning: warningMessage}"
        :disabled="isReadonly"
        @change="onAmountChange"
      />
      <div v-if="warningMessage" class="warning_message">{{warningMessage}}</div>
    </template>
    <template v-else-if="item.inputType === 'measurement'">
      <TwInputFormatNumber
        ref="measurement"
        v-model="numberModel"
        :class="{warning: warningMessage}"
        :disabled="isReadonly"
        @change="onMeasurementChange"
      />
      <!-- <TwSelect class="unit" v-model="localValue[tableName][selectGoodsIndex][groupName][getTarget()]" @change="onUnitChange" filterable allow-create default-first-option clearable>
        <el-option
          v-for="unit in SYS_MST_UNIT"
          :key="unit.id"
          :label="unit.value"
          :value="unit.value">
        </el-option>
      </TwSelect> -->
      <div v-if="warningMessage" class="warning_message">{{warningMessage}}</div>
    </template>
    <template v-else-if="item.inputType === 'quantity'">
      <TwInputFormatNumber
        v-model="numberModel"
        :disabled="isReadonly"
      />
      <!-- <TwSelect class="unit" v-model="localValue[tableName][selectGoodsIndex][groupName][getTarget()]" @change="onUnitChange" filterable allow-create default-first-option clearable>
        <el-option
          v-for="unit in SYS_MST_UNIT"
          :key="unit.id"
          :label="unit.value"
          :value="unit.value">
        </el-option>
      </TwSelect> -->
    </template>
    <template v-else-if="item.inputType === 'currency'">
      <TwInputFormatNumber
        v-model="numberModel"
        :disabled="isReadonly"
      />
      <!-- <TwSelect class="unit" v-model="localValue[tableName][selectGoodsIndex][groupName][getTarget()]" @change="onCurrencyChange" filterable allow-create default-first-option clearable>
        <el-option
          v-for="unit in SYS_MST_CURRENCY"
          :key="unit.alphabeticCode"
          :label="unit.symbol"
          :value="unit.symbol">
        </el-option>
      </TwSelect> -->
    </template>
    <template v-else-if="item.inputType === 'expensesCurrency'">
      <TwInputFormatNumber
        v-model="numberModel"
        :disabled="isReadonly"
        class="narrow"
      />
      <TwSelect class="unit" v-model="localValue[tableName][selectGoodsIndex][groupName][getTarget()]" @change="onCurrencyChange" filterable allow-create default-first-option clearable>
        <el-option
          v-for="unit in SYS_MST_CURRENCY"
          :key="unit.alphabeticCode"
          :label="unit.symbol"
          :value="unit.symbol">
        </el-option>
      </TwSelect>
    </template>
    <template v-else-if="item.inputType === 'numberOfPackages'">
      <TwInputFormatNumber
        v-model="numberModel"
        :disabled="isReadonly"
      />
    </template>
    <TwSelect v-else-if="item.inputType === 'unit'" v-model="inputModel" @change="onUnitChange2" filterable allow-create default-first-option clearable :disabled="isReadonly">
      <el-option
        v-for="unit in SYS_MST_UNIT"
        :key="unit.id"
        :label="unit.value"
        :value="unit.value">
      </el-option>
    </TwSelect>
    <TwSelect v-else-if="item.inputType === 'currencyId'" v-model="inputModel" @change="onCurrencyChange2" filterable allow-create default-first-option clearable :disabled="isReadonly">
      <el-option
        v-for="unit in SYS_MST_CURRENCY"
        :key="unit.alphabeticCode"
        :label="unit.symbol"
        :value="unit.symbol">
      </el-option>
    </TwSelect>
    <TwPortAndPlace v-else-if="item.code === 'SYS_MST_PORT_AND_PLACE'" v-model="inputModel" @input="onPlaceChange" :disabled="isReadonly" />
    <TwSelect v-else-if="item.code === 'SYS_MST_COUNTRY'" v-model="localValue[tableName][selectGoodsIndex][groupName][item.key]" @change="onCountryChange" filterable allow-create default-first-option clearable :disabled="isReadonly">
      <el-option
        v-for="country in SYS_MST_COUNTRY"
        :key="country.countryCd"
        :label="country.name"
        :value="country.name" >
      </el-option>
    </TwSelect>
    <TwSelect v-else-if="item.code === 'SYS_MST_PACKAGE_TYPE'" v-model="localValue[tableName][selectGoodsIndex][groupName][item.key]" @change="onPackingChange" filterable allow-create default-first-option clearable :disabled="isReadonly">
      <el-option
        v-for="type in SYS_MST_PACKAGE_TYPE"
        :key="type.value"
        :label="type.value"
        :value="type.value">
      </el-option>
    </TwSelect>
    <!-- 無償フラグ -->
    <el-select v-else-if="item.code === 'FREE_CHARGE_FLG'" v-model="inputModel" @change="onPulldownChange" clearable :disabled="isReadonly">
      <el-option
        v-for="unit in FREE_CHARGE_FLG_VARIABLES"
        :key="unit.code"
        :label="unit.label"
        :value="unit.code">
      </el-option>
    </el-select>
    <!-- 商品属性 -->
    <el-select v-else-if="item.code === 'GOODS_TYPE'" v-model="inputModel" @change="onGoodsTypeChange" clearable :disabled="isReadonly">
      <el-option
        v-for="type in GOODS_TYPE_VARIABLES"
        :key="type.code"
        :label="type.label"
        :value="type.code">
      </el-option>
    </el-select>
        <!-- 数量丸め区分 -->
    <el-radio-group v-else-if="item.code === 'UNIT_ROUND_TYPE'" v-model="inputModel">
      <el-radio v-for="radio in UNIT_ROUND_TYPE_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
    </el-radio-group>
    <el-radio-group v-else-if="item.code === 'BL_PICKUP'" v-model="inputModel">
      <el-radio v-for="radio in BL_PICKUP_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
    </el-radio-group>
    <el-radio-group v-else-if="item.code === 'DELIVERY_TERMS'" v-model="inputModel">
      <el-radio v-for="radio in DELIVERY_TERMS_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
    </el-radio-group>
    <!-- <el-radio-group v-else-if="item.code === 'FREE_CHARGE_FLG'" v-model="inputModel">
      <el-radio v-for="radio in FREE_CHARGE_FLG_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
    </el-radio-group> -->
    <el-radio-group v-else-if="item.code === 'INLAND_DELIVERY_FARE'" v-model="inputModel">
      <el-radio v-for="radio in INLAND_DELIVERY_FARE_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
    </el-radio-group>
    <el-radio-group v-else-if="item.code === 'PARTIAL_SHIPMENT_ALLOWED_NOT_ALLOWED'" v-model="inputModel">
      <el-radio v-for="radio in PARTIAL_SHIPMENT_ALLOWED_NOT_ALLOWED_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
    </el-radio-group>
    <el-radio-group v-else-if="item.code === 'PAYMENT_TERMS'" v-model="inputModel">
      <el-radio v-for="radio in PAYMENT_TERMS_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
    </el-radio-group>
    <el-radio-group v-else-if="item.code === 'TRANSHIPMENT_ALLOWED_NOT_ALLOWED'" v-model="inputModel">
      <el-radio v-for="radio in TRANSHIPMENT_ALLOWED_NOT_ALLOWED_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
    </el-radio-group>
    <!-- 輸出入許可書該否（外為法） -->
    <el-radio-group v-else-if="item.code === 'EXPORT_IMPORT_LICENCE'" v-model="inputModel">
      <el-radio v-for="radio in EXPORT_IMPORT_LICENCE_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
    </el-radio-group>
    <!-- チェックボックス -->
    <div v-else-if="item.inputType === 'checkbox'" class="goods_line_checkbox" :class="{ submit: item.key === 'coSubmitFlg' }" style="text-align: center">
      <el-checkbox v-model="inputModel" :true-label="1" :false-label="0" @change="onCheckboxClick" />
    </div>

    <el-date-picker
      v-else-if="item.inputType === 'date'"
      style="width:300px"
      v-model="inputModel"
      type="date"
      format="yyyy-MM-dd"
      value-format="yyyy-MM-dd"
      placeholder=""
      :disabled="isReadonly"
      @change.native="onDateChange"
    />
    <!-- shippingMark NOTE: ShippingMark欄は横幅が広いが、実業務では縦に長いので、入力エリアを改善 -->
    <el-input
      v-else-if="item.inputType === 'text_full' && item.key.includes('shippingMark')"
      class="shipping_mark"
      type="textarea"
      style="width: 50%;"
      v-model="inputModel"
      placeholder=""
      :autosize="{ minRows: 10, maxRows: 11}"
      show-word-limit
      :maxlength="getMaxLength"
    />
    <el-input v-else-if="item.inputType === 'text_full'" class="text_full show_word_limit" style="width: 100%" v-model="inputModel" placeholder="" show-word-limit :maxlength="getMaxLength" />
    <el-input v-else style="width: 320px" v-model="inputModel" class="show_word_limit" placeholder="" :disabled="isReadonly || isDisabled" :show-word-limit="!tableView"  :maxlength="getMaxLength" />

    <!-- × 連番項目削除ボタン -->
    <img class="remove_button" src="@/assets/images/icons/times.svg" v-if="isRemoveSerial && !serialGroupName" @click="addIndex(-1)">
    <!-- + 連番項目追加ボタン -->
    <tw-button type="secondary" size="medium" icon="plus" v-if="isAddSerial && !serialGroupName" @click="addIndex(1)" class="add_button"></tw-button>
  </template >
  </el-form-item>
</template>

<script>
import _ from 'lodash';
import BigNumber from 'bignumber.js';
import { RESPONSIBLE_FLG, BL_PICKUP_VARIABLES, DELIVERY_TERMS_VARIABLES, FREE_CHARGE_FLG_VARIABLES, INLAND_DELIVERY_FARE_VARIABLES, PARTIAL_SHIPMENT_ALLOWED_NOT_ALLOWED_VARIABLES, PAYMENT_TERMS_VARIABLES, TRANSHIPMENT_ALLOWED_NOT_ALLOWED_VARIABLES, UNIT_ROUND_TYPE_VARIABLES, EXPORT_IMPORT_LICENCE, EXPORT_IMPORT_LICENCE_VARIABLES, GOODS_TYPE_VARIABLES, PROCESS_TYPE } from 'lib-tw-common';
import { formatRequestDate, formatResponseDate, getMaxLength, onDateChange } from '@/utils/searchUtil.js';
import { createGoodsLineType } from '@/utils/createGoodsLine';
import TwButton from '@/components/atoms/TwButton';
import TwPortAndPlace from '@/components/atoms/TwPortAndPlace';
import TwSelect from '@/components/atoms/TwSelect';
import TwInputFormatNumber from '@/components/atoms/TwInputFormatNumber';
import { getKey, getSerialIndex, getItemIndex, getMaxIndex } from '@/utils/processedSerialItems';

BigNumber.config({EXPONENTIAL_AT: 1e+9});

export default {
  name: 'TwProcessGroupListEditGoodsLineItem',
  props: {
    item: Object,
    tableName: String,
    groupName: String,
    value: Object,
    selectGoodsIndex: Number,
    parentGroup: Object,
    serialIndexes: Object,
    serialItems: Object,
    index: Number,
    readonly: Boolean,
    tableView: Boolean,
  },
  inject: ['s', 'tab', 'activeTab'],
  components:{
    TwButton,
    TwInputFormatNumber,
    TwPortAndPlace,
    TwSelect,
  },
  data() {
    return {
      BL_PICKUP_VARIABLES,
      DELIVERY_TERMS_VARIABLES,
      FREE_CHARGE_FLG_VARIABLES,
      INLAND_DELIVERY_FARE_VARIABLES,
      PARTIAL_SHIPMENT_ALLOWED_NOT_ALLOWED_VARIABLES,
      PAYMENT_TERMS_VARIABLES,
      TRANSHIPMENT_ALLOWED_NOT_ALLOWED_VARIABLES,
      UNIT_ROUND_TYPE_VARIABLES,
      EXPORT_IMPORT_LICENCE_VARIABLES,
      GOODS_TYPE_VARIABLES,
      checks: [],
      interSection: false,
    };
  },
  filters: {
    nullFormat: value => {
      if (value === null || '') {
        return undefined;
      }
      if (_.isNaN(Number(value))) {
        return undefined;
      }
      return Number(value);
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    model() {
      return this.localValue[this.tableName][this.selectGoodsIndex][this.groupName][this.item.key];
    },
    inputModel: {
      get() {
        return this.model;
      },
      set(value) {
        this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, this.item.key, value);
      }
    },
    numberModel: {
      get() {
        return this.nullFormat(this.model);
      },
      set(value) {
        if (value === undefined) {
          this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, this.item.key, null);
        } else {
          this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, this.item.key, value);
          // this.$nextTick(() => {
          //   this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, this.item.key, BigNumber(value).dp(4, BigNumber.ROUND_DOWN).toNumber());
          // })
        }
      }
    },
    numberStringModel: {
      get() {
        return this.nullFormat(this.model);
      },
      set(value) {
        if (value === undefined) {
          this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, this.item.key, null);
        } else {
          this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, this.item.key, '' + value);
          // this.$nextTick(() => {
          //   this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, this.item.key, BigNumber(value).dp(4, BigNumber.ROUND_DOWN).toNumber());
          // })
        }
      }
    },
    formatResponseDate: {
      get() {
        return formatResponseDate(this.localValue[this.tableName][this.selectGoodsIndex][this.groupName][this.item.key]);
      },
      set(value) {
        value = formatRequestDate(value);
        this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, this.item.key, value);
      }
    },
    isVisible() {
      if (!this.localValue) {
        return false;
      }
      if (this.item.hide) {
        return false;
      }
      if (this.tableView) {
        return true;
      }
      return this.item[this.s.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM ? 'from' : 'to'];
    },
    isReadonly() {
      return this.readonly || this.tableView && !this.item[this.s.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM ? 'from' : 'to'];
    },
    warningMessage() {
      if (this.item.inputType === 'amount') {
        if (this.selectGoodsIndex !== this.$parent.selectGoodsLinePriceIndex ) {
          return '';
        }
        if (this.$parent.isOverFlow(this.$parent.getCalcAmount(), 13, 2)) {
          return '単価か数量に誤りがあります';
        }
        return '';
      } else if (this.item.inputType === 'measurement') {
        if (this.selectGoodsIndex !== this.$parent.selectPackingIndex ) {
          return '';
        }
        if (this.item.key === 'netMeasurementTotal') {
          if (this.$parent.isOverFlow(this.$parent.calcMeasurementTotal(), 9, 6)) {
            return '単価か数量に誤りがあります';
          }
        } else {
          if (this.$parent.isOverFlow(this.$parent.calcGrossMeasurementTotal(), 9, 6)) {
            return '単価か数量に誤りがあります';
          }
          return '';
        }
      }
      return '';
    },
    hideErrorMessage() {
      return this.warningMessage && !this.$parent.override;
    },
    multipleSelect() {
      return (model) => {
        return _.isArray(model) && model.length > 1;
      };
    },
    SYS_MST_UNIT() {
      return this.$store.getters.getSysMstUnit;
    },
    SYS_MST_CURRENCY() {
      return this.$store.getters.getSysMstCurrency;
    },
    SYS_MST_PORT_AND_PLACE() {
      return this.$store.getters.getSysMstPortAndPlace;
    },
    SYS_MST_COUNTRY() {
      return this.$store.getters.getSysMstCountry;
    },
    SYS_MST_PACKAGE_TYPE() {
      return this.$store.getters.getSysMstPackageType;
    },
    rules() {
      let ignore = false;
      if (this.s.processType === PROCESS_TYPE.FDCOR && this.$parent.ignore) {
        ignore = true;
      }

      const rules = [
        {
          required: (this.s.draftValid || ignore) ? false : this.item.validationId.includes('tw-isNotEmpty'),
          lakeelMessageSourceFlg: true,
          validationId: (this.s.draftValid || ignore) ? _.reject(this.item.validationId, s => s === 'tw-isNotEmpty') : this.item.validationId,
          validator: this.s.onEventValidation, trigger: (['select', 'radio', 'checkbox', 'unit', 'currencyId'].includes(this.item.inputType) || ['SYS_MST_PORT_AND_PLACE', 'SYS_MST_COUNTRY'].includes(this.item.code)) ? 'change' : 'blur',
        }
      ];

      if (this.item.tsValidationId) {
        const tsValidationIds = _.map(this.item.tsValidationId, tsValidationId => {
          return {
            required: this.s.draftValid ?  false : tsValidationId.startsWith('ts-isNotEmpty'),
            validator: this.s.tsValidation,
            tsValidationId: this.s.draftValid && (tsValidationId.startsWith('ts-isNotEmpty') && tsValidationId !== 'ts-isNotEmptyInvoiceAmountOrIpreqCargoAmount') ? undefined : tsValidationId,
            trigger: (['select', 'radio', 'checkbox', 'unit', 'currencyId'].includes(this.item.inputType) || ['SYS_MST_CONTAINER_TYPE','SYS_MST_PORT_AND_PLACE', 'SYS_MST_COUNTRY'].includes(this.item.code)) ? 'change' : 'blur',
          }
        });
        return [...tsValidationIds, ...rules];
      }
      return rules;
    },

    // 連番項目を可変にし追加可能にする対応--------------------------
    isSerialGrp() {
      return this.serialItems ? Object.keys(this.serialItems).length > 0 : false;
    },
    isSerialItem() {
      if (this.serialIndex && this.itemIndex || this.serialIndex === 0) return this.serialIndex >= this.itemIndex;
      else return true;
    },
    serialIndex() {
      if (this.serialIndexes && Object.keys(this.serialIndexes).length > 0) return getSerialIndex(this.serialIndexes, this.serialGroupName ? this.serialGroupName : this.item.key);
      else return false;
    },
    itemIndex() {
      if (this.serialItems && this.isSerialGrp) return getItemIndex(this.serialItems, this.item.key);
      else return false;
    },
    isAddSerial() {
      return this.isSerialGrp && this.itemIndex === this.serialIndex && this.serialIndex < getMaxIndex(this.serialItems, this.item.key);
    },
    isRemoveSerial() {
      if (this.serialItems && Object.keys(this.serialItems).length > 1) {
        return this.checkGroupValues();
      } else {
        return this.isSerialGrp && this.itemIndex === this.serialIndex && this.serialIndex > 0 && !(this.model || this.model === 0);
      }
    },
    serialGroupName() {
      return _.get(this.serialItems[getKey(this.item.key)], 'groupName') || false;
    },
    isNestItem() {
      if (!this.serialItems) {
        return true;
      }
      const nestGroupName = _.get(this.serialItems[getKey(this.item.key)], 'nestGroupName');
      if (nestGroupName && this.serialIndexes && Object.keys(this.serialIndexes).length > 0) {
        const subIndex = Number(getKey(this.item.key).replace(/[^0-9]/g, '')) - 1;
        return getSerialIndex(this.serialIndexes, nestGroupName) >= subIndex;
      } else return true;
    },
    isDisabled() {
      // Export Licence No.、Import Licence No.はREQUIREDが選択されているときのみ入力可能
      if (this.item.key === 'exportLicenceNo' || this.item.key === 'importLicenceNo') {
        if (_.get(this.localValue, `${this.tableName}.${this.selectGoodsIndex}.${this.groupName}.${this.getTarget()}`) !== EXPORT_IMPORT_LICENCE.REQUIRED) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    getMaxLength() {
      return getMaxLength(this.item.validationId);
    },
    // アクティブなタブ配下にいるか
    isInActiveTab() {
      if (!this.tab && this.tab !== 0) {
        return true;
      }

      return _.get(this.activeTab, 'value') === this.tab;
    },
  },
  methods: {
    nullFormat(value) {
      if (value === null || '') {
        return undefined;
      }
      if (_.isNaN(Number(value))) {
        return undefined;
      }
      return Number(value);
    },
    getTarget(target = this.item) {
      return _.last(target.unitTarget.split('.'));
    },
    onUnitChange(value) {
      const unitId = _.get(_.find(this.SYS_MST_UNIT, {value: value}), 'code');
      const idTarget = _.find(this.parentGroup.children, {key: this.getTarget()});
      const unitTarget = this.getTarget(idTarget);
      if (unitId || unitId === 0) {
        this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, unitTarget, unitId);
      } else {
        this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, unitTarget, null);
      }
    },
    onUnitChange2(value) {
      const unitId = _.get(_.find(this.SYS_MST_UNIT, {value: value}), 'code');
      const unitTarget = this.getTarget();
      if (unitId || unitId === 0) {
        this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, unitTarget, unitId);
      } else {
        this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, unitTarget, null);
      }
      // quantity1の時はgoodsLinePriceのunitに代入
      if (this.item.key === 'unit1' && this.localValue.linkageGoodsLinePrice) {
        if (unitId || unitId === 0) {
          this.s.setGoodsValue('linkageGoodsLinePrice', this.selectGoodsIndex, 'priceGrp', 'unit', value);
          this.s.setGoodsValue('linkageGoodsLinePrice', this.selectGoodsIndex, 'priceGrp', 'unitId', unitId);
        } else {
          this.s.setGoodsValue('linkageGoodsLinePrice', this.selectGoodsIndex, 'priceGrp', 'unit', null);
          this.s.setGoodsValue('linkageGoodsLinePrice', this.selectGoodsIndex, 'priceGrp', 'unitId', null);
        }
      }
    },
    onCurrencyChange(value) {
      const unitId = _.get(_.find(this.SYS_MST_CURRENCY, {symbol: value}), 'alphabeticCode');
      const idTarget = _.find(this.parentGroup.children, {key: this.getTarget()});
      const unitTarget = this.getTarget(idTarget);
      if (unitId || unitId === 0) {
        this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, unitTarget, unitId);
      } else {
        this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, unitTarget, null);
      }
    },
    onCurrencyChange2(value) {
      const unitId = _.get(_.find(this.SYS_MST_CURRENCY, {symbol: value}), 'alphabeticCode');
      const unitTarget = this.getTarget();
      if (unitId || unitId === 0) {
        this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, unitTarget, unitId);
      } else {
        this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, unitTarget, null);
      }
    },
    onPlaceChange(value) {
      const unitId = _.get(_.find(this.SYS_MST_PORT_AND_PLACE, {name: value}), 'code');
      const unitTarget = this.getTarget();
      if (unitId || unitId === 0) {
        this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, unitTarget, unitId);
      } else {
        this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, unitTarget, null);
      }
    },
    onCountryChange(value) {
      const unitId = _.get(_.find(this.SYS_MST_COUNTRY, {name: value}), 'countryCd');
      const unitTarget = this.getTarget();
      if (unitId || unitId === 0) {
        this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, unitTarget, unitId);
      } else {
        this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, unitTarget, null);
      }
    },
    onPackingChange(value) {
      const unitId = _.get(_.find(this.SYS_MST_PACKAGE_TYPE, {value: value}), 'code');
      const unitTarget = this.getTarget();
      if (unitId || unitId === 0) {
        this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, unitTarget, unitId);
      } else {
        this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, unitTarget, null);
      }
    },
    onPulldownChange(value) {
      if (!value && value !== 0) {
        this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, this.item.key, null);
      }
    },
    onRadioClick(val, key) {
      if (this.item.code !== 'UNIT_ROUND_TYPE' && this.localValue[this.tableName][this.selectGoodsIndex][this.groupName][key] === val) {
        this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, key, null);
      } else {
        this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, key, val);
      }
      if (this.item.key === 'exportLicence' || this.item.key === 'importLicence') {
        // Export Licence No.、Import Licence No.はREQUIRED以外の時にはクリアする
        if (this.model !== EXPORT_IMPORT_LICENCE.REQUIRED) {
          const unitTarget = this.getTarget();
          this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, unitTarget, null);
          // バリデーションをクリア
          const el = this.$el.nextElementSibling.__vue__.$refs.formItem;
          if (el) {
            el.clearValidate();
            const target = this.localValue[this.tableName][this.selectGoodsIndex][this.groupName][unitTarget];
            this.s.onValidate(target, true);
          }
        }
      }
    },
    onCheckboxClick() {
      if (this.tableName === 'linkageGoodsLineCo' && this.item.key === 'coSubmitFlg') {
        // CO情報の自動計算
        this.s.setCoGoodsLinePacking();
      }
    },
    onAmountChange(value) {
      // if (value === undefined) {
      //   this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, this.item.key, null);
      // } else {
      //   this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, this.item.key, BigNumber(value).dp(4).toNumber());
      // }

      if (!this.$parent.getCalcAmount) {
        return;
      }
      const total = this.$parent.getCalcAmount();
      if (!this.$parent.override && total !== value) {
        this.$store
          .dispatch('SHOW_CONFIRM', 'Difference between your input and automatic calculation. Are you sure you want to submit the data?')
          .then(() => {
            this.$parent.override = true;
            this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, this.item.key, value);
          })
          .catch(() => {
            this.$parent.setCalcAmount();
          });
      }
    },
    onMeasurementChange(value) {
      // if (value === undefined) {
      //   this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, this.item.key, null);
      // } else {
      //   this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, this.item.key, value);
      // }
      if (!this.$parent.calcMeasurementTotal) {
        return;
      }
      const total = this.$parent.calcMeasurementTotal();
      if (!this.$parent.override && _.isNumber(value) && total !== value) {
        this.$store
        .dispatch('SHOW_CONFIRM', 'Difference between your input and automatic calculation. Are you sure you want to submit the data?')
        .then(() => {
          this.$parent.override = true;
          this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, this.item.key, value);
        })
        .catch(() => {
          this.$parent.setMeasurementTotal();
          // this.localValue[this.tableName][this.selectGoodsIndex][this.groupName][this.item.key] = total;
        });
      }
    },
    onCheck() {
      const value = this.checks.join(',');
      this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, this.item.key, value);
    },
    addIndex(num) {
      this.$emit('add-index', this.tableName, this.index, this.groupName, this.item.key, num);
    },
    // 連番項目削除のグループチェック
    checkGroupValues() {
      if (!(this.isSerialGrp && this.itemIndex === this.serialIndex && this.serialIndex > 0)) {
        return false;
      }
      if (_.get(this.serialItems, `${getKey(this.item.key)}.nestGroupName`)) {
        const value = _.get(this.localValue, `${this.tableName}[${this.selectGoodsIndex}].${this.groupName}.${this.item.key}`);
        if (value || value === 0) {
          return false;
        } else return true;
      }
      if (_.some(this.serialItems, o => {
        if (o.nestGroupName) {
          return _.some(o.items, item => {
            const key = item.key;
            const value = _.get(this.localValue, `${this.tableName}[${this.selectGoodsIndex}].${this.groupName}.${key}`);
            if (value || value === 0) return true;
            else return false;
          })
        } else {
          const key = _.get(o, `items[${this.itemIndex}].key`);
          const value = _.get(this.localValue, `${this.tableName}[${this.selectGoodsIndex}].${this.groupName}.${key}`);
          if (key && (value || value === 0)) return true;
          else return false;
        }
      })) {
        return false;
      } else return true;
    },
    // 商品属性変更時
    onGoodsTypeChange(value) {
      const keyGrp = this.localValue[this.tableName][this.selectGoodsIndex].keyGrp;
      this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure you want to clear the goods type data?')
        .then(() => {
          // 商品属性から削除
          this.localValue.linkageGoodsLineType = _.reject(this.localValue.linkageGoodsLineType, goods => {
            return goods.keyGrp.goodsLineSeq === keyGrp.goodsLineSeq;
          });
          if (!value && value !== 0) {
            this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, this.item.key, null);
            return;
          }
          const linkageGoodsLineType = createGoodsLineType({goodsTypeId: value, goodsTypeMasterList: this.s.res.goodsTypeMasterListForResponse, keyGrp: keyGrp});
          // 新たな商品属性を追加
          this.localValue.linkageGoodsLineType = [...this.localValue.linkageGoodsLineType, ...linkageGoodsLineType];
        })
        .catch(() => {
          const oldVal = _.get(_.find(this.localValue.linkageGoodsLineType, goods => {
            return goods.keyGrp.goodsLineSeq === keyGrp.goodsLineSeq;
          }), 'keyGrp.goodsType') || null;
          this.s.setGoodsValue(this.tableName, this.selectGoodsIndex, this.groupName, this.item.key, oldVal);
        });
    },
    onDateChange,
    visibilityChanged (isVisible) {
      this.interSection = isVisible;
    },
  }
};
</script>

<style lang="scss" scoped>
  .el-form-item {
    margin-bottom: 24px;
    width: 50%;
    display: flex;
    padding-right: 16px;
    min-height: 40px;

    &:last-child {
      margin-bottom: 20px;
    }

    &.full {
      width: 100%;

      ::v-deep .el-form-item__content .el-form-item__error {
        max-width: 100%;
      }
    }

    ::v-deep label.el-form-item__label {
      display: block;
      width: 118px;
      font-size: 12px;
      line-height: 18px;
      color: #9191A4;
      margin-right: 8px;
      padding-top: 2px;
    }

    &.no_commercial {
      ::v-deep .el-input-number .el-input__inner {
        background: rgba($color_draft, 0.2);
      }

      ::v-deep label.el-form-item__label:after {
        display: block;
        content: 'No Commercial';
        color: $color_draft;
        font-weight: bold;
      }
    }

    &.remove_serial {
      .el-input,
      .el-select {
        width: calc(320px - 20px) !important;
      }
      .el-textarea,
      .text_full {
        width: calc(100% - 20px) !important;
      }
      .remove_button {
        cursor: pointer;
        width: 20px;
        vertical-align: middle;
      }
      &.full {
        .remove_button {
          margin-bottom: 16px;
        }
      }
    }
    &.add_serial {
      .el-input {
        width: calc(320px - 48px) !important;
      }
      .el-textarea,
      .text_full {
        width: calc(100% - 48px) !important;
      }
      .add_button {
        vertical-align: initial;
        margin: 0 0 0 8px;
        padding-left: 9px;
        padding-right: 1px;
        &.group_button {
          border-radius: 10px;
        }
      }
      &.remove_serial {
        .el-input {
          width: calc(320px - 68px) !important;
        }
        .el-textarea,
        .text_full {
          width: calc(100% - 68px) !important;
        }
      }
      &.full {
        .add_button {
          margin-bottom: 8px;
        }
        .remove_button {
          margin-bottom: 6px;
        }
      }
    }
  }



  ::v-deep .el-form-item__content {
    font-size: 14px;
    line-height: 20px;
    color: #3E3A39;
    vertical-align: top;
    flex: 1;

    .el-form-item__error {
      position: relative;
      top: 0;
      max-width: 320px;
    }

    .goods_line_checkbox {
      &.submit {
        min-height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  ::v-deep textarea {
    resize: none;
  }

  .el-select {
    width: 320px;
  }

  .el-select.unit {
    width: 90px;
    margin-left: 12px;
  }

  .el-select.packing {
    margin-left: 12px;
  }

  .el-input-number.narrow, .tw_input_number_string.narrow {
    width: 218px;
  }

  .el-input-number, .el-input-number.amount {
    width: 320px;
  }

  .warning ::v-deep .el-input__inner {
    border-color: $color_draft;
  }

  .warning_message {
    // height: 18px;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    padding-left: 16px;
    color: $color_draft;
    background: url(../../assets/images/icons/icon_alert_warn.svg) no-repeat 0 2px;
    background-size: 14px auto;
  }

</style>
