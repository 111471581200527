import _ from 'lodash';
import { IP_REGIST_TYPE, IP_DN_TYPE } from 'lib-tw-common';
import dayjs from 'dayjs';

const ipData = {
  policyGrp: {
    senderCompanyId: null,
    insurancePolicyNo: null,
    insurancePolicyBranchNo: null,
    ipDnType: null,
    trx: null,
    opOcProvNo: null,
    shipperReferenceNo: null,
  },
  insuranceCompanyGrp: {
    insuranceCompanyName: null,
    insuranceCompanyAddress: null,
    insuranceCompanyTelephoneNo: null,
    insuranceCompanyEmailAddress: null,
    notes: null,
  },
  assuredGrp: {
    assuredsEtc: null,
    assuredCode: null,
  },
  invoiceGrp: {
    invoiceNo: null,
    invoiceIssuer: null,
  },
  amountInsuredGrp: {
    cargoAmountInsuredCurrencyCode: null,
    cargoAmountInsured: null,
    dutyEtcAmountInsuredCurrencyCode: null,
    dutyEtcAmountInsured: null,
  },
  insuranceConditionsGrp: {
    insurancePaymentPlace: null,
    claimAgent: null,
    insuranceConditions: null,
    supplementaryNoteOfInsuranceConditions: null,
  },
  voyageGrp: {
    localVesselOrConveyance: null,
    interiorPortCodeOrPlaceOfLoadingCode: null,
    interiorPortNameOrPlaceOfLoadingName: null,
    vesselCode: null,
    vesselName: null,
    imoNo: null,
    voyageNo: null,
    portOfLoadingCode: null,
    portOfLoadingName: null,
    sailingOnOrAbout: null,
    transshipmentLocationCodeOrPortOfDischargeCode: null,
    transshipmentLocationNameOrPortOfDischargeName: null,
    finalDestinationCode: null,
    finalDestinationName: null,
  },
  subjectMatterInsuredGrp: {
    hsCode01: null,
    descriptionOfGoods01: null,
    quantity101: null,
    unitCodeOfQuantity101: null,
    quantity201: null,
    unitCodeOfQuantity201: null,
    hsCode02: null,
    descriptionOfGoods02: null,
    quantity102: null,
    unitCodeOfQuantity102: null,
    quantity202: null,
    unitCodeOfQuantity202: null,
    hsCode03: null,
    descriptionOfGoods03: null,
    quantity103: null,
    unitCodeOfQuantity103: null,
    quantity203: null,
    unitCodeOfQuantity203: null,
    hsCode04: null,
    descriptionOfGoods04: null,
    quantity104: null,
    unitCodeOfQuantity104: null,
    quantity204: null,
    unitCodeOfQuantity204: null,
    hsCode05: null,
    descriptionOfGoods05: null,
    quantity105: null,
    unitCodeOfQuantity105: null,
    quantity205: null,
    unitCodeOfQuantity205: null,
    hsCode06: null,
    descriptionOfGoods06: null,
    quantity106: null,
    unitCodeOfQuantity106: null,
    quantity206: null,
    unitCodeOfQuantity206: null,
    hsCode07: null,
    descriptionOfGoods07: null,
    quantity107: null,
    unitCodeOfQuantity107: null,
    quantity207: null,
    unitCodeOfQuantity207: null,
    hsCode08: null,
    descriptionOfGoods08: null,
    quantity108: null,
    unitCodeOfQuantity108: null,
    quantity208: null,
    unitCodeOfQuantity208: null,
    hsCode09: null,
    descriptionOfGoods09: null,
    quantity109: null,
    unitCodeOfQuantity109: null,
    quantity209: null,
    unitCodeOfQuantity209: null,
    hsCode10: null,
    descriptionOfGoods10: null,
    quantity110: null,
    unitCodeOfQuantity110: null,
    quantity210: null,
    unitCodeOfQuantity210: null,
  },
  lcGrp: {
    lcNo: null,
    informationOfLc: null,
  },
  coInsuranceGrp: {
    coInsuranceCompany01: null,
    coInsuranceShare01: null,
    coInsuranceCompany02: null,
    coInsuranceShare02: null,
    coInsuranceCompany03: null,
    coInsuranceShare03: null,
    coInsuranceCompany04: null,
    coInsuranceShare04: null,
    coInsuranceCompany05: null,
    coInsuranceShare05: null,
    coInsuranceCompany06: null,
    coInsuranceShare06: null,
    coInsuranceCompany07: null,
    coInsuranceShare07: null,
    coInsuranceCompany08: null,
    coInsuranceShare08: null,
    coInsuranceCompany09: null,
    coInsuranceShare09: null,
    coInsuranceCompany10: null,
    coInsuranceShare10: null,
    coInsuranceCompany11: null,
    coInsuranceShare11: null,
    coInsuranceCompany12: null,
    coInsuranceShare12: null,
  },
  additionalInformationGrp: {
    issuePlace: null,
    issueDate: null,
    numbersOfPoliciesIssued: null,
    iccBasicCondition: null,
    keyCode1: null,
    keyCode2: null,
    keyCode3: null,
    keyCode4: null,
    keyCode5: null,
    keyCode6: null,
    keyCode7: null,
    keyCode8: null,
    keyCode9: null,
    keyCode10: null,
    remarks: null,
  },
}

const dnData = {
  policyGrp: {
    senderCompanyId: null,
    insurancePolicyNo: null,
    insurancePolicyBranchNo: null,
    ipDnType: null,
    trx: null,
  },
  dnInfoGrp: {
    createdDate: null,
    upRate: null,
    dutyRate: null,
    exchangeRate: null,
  },
  rateOfCargoMarineRateGrp: {
    premiumRateOfCargoMarine: null,
    premiumForeignCurrencyOfCargoMarine: null,
    premiumYenCurrencyOfCargoMarine: null,
    netPremiumForeignCurrencyOfCargoMarine: null,
    netPremiumYenCurrencyOfCargoMarine: null,
    bonusRateOfCargoMarine: null,
  },
  rateOfCargoWarGrp: {
    premiumRateOfCargoWar: null,
    premiumForeignCurrencyOfCargoWar: null,
    premiumYenCurrencyOfCargoWar: null,
    netPremiumForeignCurrencyOfCargoWar: null,
    netPremiumYenCurrencyOfCargoWar: null,
    bonusRateOfCargoWar: null,
  },
  rateOfCargoTotalGrp: {
    premiumRateOfCargoTotal: null,
    premiumForeignCurrencyOfCargoTotal: null,
    premiumYenCurrencyOfCargoTotal: null,
    netPremiumForeignCurrencyOfCargoTotal: null,
    netPremiumYenCurrencyOfCargoTotal: null,
    bonusRateOfCargoTotal: null,
  },
  rateOfDutyEtcMarineGrp: {
    premiumRateOfDutyEtcMarine: null,
    premiumForeignCurrencyOfDutyEtcMarine: null,
    premiumYenCurrencyOfDutyEtcMarine: null,
    netPremiumForeignCurrencyOfDutyEtcMarine: null,
    netPremiumYenCurrencyOfDutyEtcMarine: null,
    bonusRateOfDutyEtcMarine: null,
  },
  rateOfDutyEtcWarGrp: {
    premiumRateOfDutyEtcWar: null,
    premiumForeignCurrencyOfDutyEtcWar: null,
    premiumYenCurrencyOfDutyEtcWar: null,
    netPremiumForeignCurrencyOfDutyEtcWar: null,
    netPremiumYenCurrencyOfDutyEtcWar: null,
    bonusRateOfDutyEtcWar: null,
  },
  rateOfDutyEtcTotalGrp:  {
    premiumRateOfDutyEtcTotal: null,
    premiumForeignCurrencyOfDutyEtcTotal: null,
    premiumYenCurrencyOfDutyEtcTotal: null,
    netPremiumForeignCurrencyOfDutyEtcTotal: null,
    netPremiumYenCurrencyOfDutyEtcTotal: null,
    bonusRateOfDutyEtcTotal: null,
  },
  apEtcGrp: {
    amountInsuredApEtc01: null,
    apEtcType01: null,
    amountInsuredApEtc02: null,
    apEtcType02: null,
    amountInsuredApEtc03: null,
    apEtcType03: null,
    amountInsuredApEtc04: null,
    apEtcType04: null,
    amountInsuredApEtc05: null,
    apEtcType05: null,
    amountInsuredApEtc06: null,
    apEtcType06: null,
  },
  rateOfApEtcMarineGrp: {
    premiumRateOfApEtcMarine01: null,
    premiumForeignCurrencyOfApEtcMarine01: null,
    premiumYenCurrencyOfApEtcMarine01: null,
    netPremiumForeignCurrencyOfApEtcMarine01: null,
    netPremiumYenCurrencyOfApEtcMarine01: null,
    bonusRateOfApEtcMarine01: null,
    premiumRateOfApEtcMarine02: null,
    premiumForeignCurrencyOfApEtcMarine02: null,
    premiumYenCurrencyOfApEtcMarine02: null,
    netPremiumForeignCurrencyOfApEtcMarine02: null,
    netPremiumYenCurrencyOfApEtcMarine02: null,
    bonusRateOfApEtcMarine02: null,
    premiumRateOfApEtcMarine03: null,
    premiumForeignCurrencyOfApEtcMarine03: null,
    premiumYenCurrencyOfApEtcMarine03: null,
    netPremiumForeignCurrencyOfApEtcMarine03: null,
    netPremiumYenCurrencyOfApEtcMarine03: null,
    bonusRateOfApEtcMarine03: null,
    premiumRateOfApEtcMarine04: null,
    premiumForeignCurrencyOfApEtcMarine04: null,
    premiumYenCurrencyOfApEtcMarine04: null,
    netPremiumForeignCurrencyOfApEtcMarine04: null,
    netPremiumYenCurrencyOfApEtcMarine04: null,
    bonusRateOfApEtcMarine04: null,
    premiumRateOfApEtcMarine05: null,
    premiumForeignCurrencyOfApEtcMarine05: null,
    premiumYenCurrencyOfApEtcMarine05: null,
    netPremiumForeignCurrencyOfApEtcMarine05: null,
    netPremiumYenCurrencyOfApEtcMarine05: null,
    bonusRateOfApEtcMarine05: null,
    premiumRateOfApEtcMarine06: null,
    premiumForeignCurrencyOfApEtcMarine06: null,
    premiumYenCurrencyOfApEtcMarine06: null,
    netPremiumForeignCurrencyOfApEtcMarine06: null,
    netPremiumYenCurrencyOfApEtcMarine06: null,
    bonusRateOfApEtcMarine06: null,
  },
  rateOfApEtcWarGrp:  {
    premiumRateOfApEtcWar01: null,
    premiumForeignCurrencyOfApEtcWar01: null,
    premiumYenCurrencyOfApEtcWar01: null,
    netPremiumForeignCurrencyOfApEtcWar01: null,
    netPremiumYenCurrencyOfApEtcWar01: null,
    bonusRateOfApEtcWar01: null,
    premiumRateOfApEtcWar02: null,
    premiumForeignCurrencyOfApEtcWar02: null,
    premiumYenCurrencyOfApEtcWar02: null,
    netPremiumForeignCurrencyOfApEtcWar02: null,
    netPremiumYenCurrencyOfApEtcWar02: null,
    bonusRateOfApEtcWar02: null,
    premiumRateOfApEtcWar03: null,
    premiumForeignCurrencyOfApEtcWar03: null,
    premiumYenCurrencyOfApEtcWar03: null,
    netPremiumForeignCurrencyOfApEtcWar03: null,
    netPremiumYenCurrencyOfApEtcWar03: null,
    bonusRateOfApEtcWar03: null,
    premiumRateOfApEtcWar04: null,
    premiumForeignCurrencyOfApEtcWar04: null,
    premiumYenCurrencyOfApEtcWar04: null,
    netPremiumForeignCurrencyOfApEtcWar04: null,
    netPremiumYenCurrencyOfApEtcWar04: null,
    bonusRateOfApEtcWar04: null,
    premiumRateOfApEtcWar05: null,
    premiumForeignCurrencyOfApEtcWar05: null,
    premiumYenCurrencyOfApEtcWar05: null,
    netPremiumForeignCurrencyOfApEtcWar05: null,
    netPremiumYenCurrencyOfApEtcWar05: null,
    bonusRateOfApEtcWar05: null,
    premiumRateOfApEtcWar06: null,
    premiumForeignCurrencyOfApEtcWar06: null,
    premiumYenCurrencyOfApEtcWar06: null,
    netPremiumForeignCurrencyOfApEtcWar06: null,
    netPremiumYenCurrencyOfApEtcWar06: null,
    bonusRateOfApEtcWar06: null,
  },
  rateOfApEtcTotalGrp:  {
    premiumRateOfApEtcTotal01: null,
    premiumForeignCurrencyOfApEtcTotal01: null,
    premiumYenCurrencyOfApEtcTotal01: null,
    netPremiumForeignCurrencyOfApEtcTotal01: null,
    netPremiumYenCurrencyOfApEtcTotal01: null,
    premiumRateOfApEtcTotal02: null,
    premiumForeignCurrencyOfApEtcTotal02: null,
    premiumYenCurrencyOfApEtcTotal02: null,
    netPremiumForeignCurrencyOfApEtcTotal02: null,
    netPremiumYenCurrencyOfApEtcTotal02: null,
    premiumRateOfApEtcTotal03: null,
    premiumForeignCurrencyOfApEtcTotal03: null,
    premiumYenCurrencyOfApEtcTotal03: null,
    netPremiumForeignCurrencyOfApEtcTotal03: null,
    netPremiumYenCurrencyOfApEtcTotal03: null,
    premiumRateOfApEtcTotal04: null,
    premiumForeignCurrencyOfApEtcTotal04: null,
    premiumYenCurrencyOfApEtcTotal04: null,
    netPremiumForeignCurrencyOfApEtcTotal04: null,
    netPremiumYenCurrencyOfApEtcTotal04: null,
    premiumRateOfApEtcTotal05: null,
    premiumForeignCurrencyOfApEtcTotal05: null,
    premiumYenCurrencyOfApEtcTotal05: null,
    netPremiumForeignCurrencyOfApEtcTotal05: null,
    netPremiumYenCurrencyOfApEtcTotal05: null,
    premiumRateOfApEtcTotal06: null,
    premiumForeignCurrencyOfApEtcTotal06: null,
    premiumYenCurrencyOfApEtcTotal06: null,
    netPremiumForeignCurrencyOfApEtcTotal06: null,
    netPremiumYenCurrencyOfApEtcTotal06: null,
  },
  totalPremiumGrp:  {
    totalPremiumForeignCurrency: null,
    totalPremiumYenCurrency: null,
    totalNetPremiumYenCurrency: null,
  },
  informationGrp: {
    careOf: null,
    agentforwarder: null,
    accountee: null,
    amountExchRate: null,
    minimumPremium: null,
    recordDate: null,
    exchRateOfDuty: null,
  },
}

// eslint-disable-next-line import/prefer-default-export
export const createIpDnData = (res, docType, ipDnTypeDn = undefined) => {
  const map = docType === 'DN' ? dnData : ipData;
  const processData = _.reduce(_.cloneDeep(map), (ret, group, key) => {
    _.forEach(group, (val, key) => {
      if (docType === 'DN' && key === 'ipDnType') {
        // DNデータのipDnType
        const ipDnType = _.get(res, 'ipDnTypeDn') || _.get(res, 'ipDnType');
        group[key] = ipDnType || ipDnType === 0 ? ipDnType : null;
      } else if (docType === 'IP' && key === 'ipDnType') {
        // IPデータのipDnType
        group[key] = res[key] || res[key] === 0 ? res[key] : null;
        // DNから追加したIPデータの場合（ipDnTypeDnがある場合）は、I/P登録元タイプを1でセットする
        ret.ipRegistType = ipDnTypeDn ? IP_REGIST_TYPE.DN : IP_REGIST_TYPE.IP;
        // DNから追加したIPデータの場合は、ipDnTypeDnも追加
        if (ipDnTypeDn) {
          group.ipDnTypeDn = ipDnTypeDn;
        }
      } else {
        group[key] = res[key] || res[key] === 0 ? res[key] : null;
      }
    });
    ret[key] = group;
    return ret;
  }, {});
  // console.log(processData);
  return processData;
};

export const excludeDuplicatesIpData = ippools => {
  // 重複するI/Pがある場合は、表示上は1レコードのみを表示する
  // https://tradewaltz.backlog.com/view/PB-643
  // 送信元企業ID、保険証券番号、証券番号枝番、I/P情報区分をキーとして、重複を除外する
  // キー項目: senderCompanyId, insurancePolicyNo, insurancePolicyBranchNo, ipDnType

  // キー項目が重複するものをグループ化
  const grouped = _.groupBy(ippools, (ip) => {
    const senderCompanyId = ip.senderCompanyId || _.get(ip, 'policyGrp.senderCompanyId');
    const insurancePolicyNo = ip.insurancePolicyNo || _.get(ip, 'policyGrp.insurancePolicyNo');
    const insurancePolicyBranchNo = ip.insurancePolicyBranchNo || _.get(ip, 'policyGrp.insurancePolicyBranchNo');
    const ipDnType = ip.ipDnType || _.get(ip, 'policyGrp.ipDnType');
    return `${senderCompanyId}_${insurancePolicyNo}_${insurancePolicyBranchNo}_${ipDnType}`;
  });

  // グループ化したものをループで回し、各グループごとに優先順位の高いものから1レコードのみ取得する
  // 優先順位: 1.ipRegistType: IP、2.ipDnTypeDn: GROSS、3.ipDnTypeDn: NET GROSS、4.ipDnTypeDn: NET
  let results = [];
  _.forEach(grouped, (group) => {
    const ip = _.find(group, (ip) => {
      const ipRegistType = ip.ipRegistType;
      return ipRegistType === IP_REGIST_TYPE.IP;
    }) || _.find(group, (ip) => {
      const ipDnTypeDn = ip.ipDnTypeDn || _.get(ip, 'policyGrp.ipDnTypeDn');
      return ipDnTypeDn === IP_DN_TYPE.GROSS;
    }) || _.find(group, (ip) => {
      const ipDnTypeDn = ip.ipDnTypeDn || _.get(ip, 'policyGrp.ipDnTypeDn');
      return ipDnTypeDn === IP_DN_TYPE.NET_GROSS;
    }) || _.find(group, (ip) => {
      const ipDnTypeDn = ip.ipDnTypeDn || _.get(ip, 'policyGrp.ipDnTypeDn');
      return ipDnTypeDn === IP_DN_TYPE.NET;
    }) || group[0];
    results.push(ip);
  });
  return results;
};

// I/P,D/N紐付け時、または、既に紐付け済みの場合に、該当の項目がBlankだったら1件目のI/P情報からプロセス情報へ転記する
export const copyIpDataFromIppools = (linkageMarineInsurance) => {
  // 重複するI/Pがある場合は除外した上で、1件目のI/P情報を取得する
  const targetIp = _.first(excludeDuplicatesIpData(_.get(linkageMarineInsurance, 'ippools', [])));
  // console.log('targetIp', targetIp);
  // ippoolsが1件以上ある、かつ、
  // MARINE INSURANCE (LINKAGE TABLE)の以下の項目がブランクの場合、1件目のI/P情報からプロセス情報へ転記する
  if (targetIp) {
    // Insurance Policy No 保険証券番号
    const insurancePolicyNo = _.get(linkageMarineInsurance, 'insurancePolicyGrp.insurancePolicyNo');
    if (!insurancePolicyNo) {
      linkageMarineInsurance.insurancePolicyGrp.insurancePolicyNo = _.get(targetIp, 'policyGrp.insurancePolicyNo', null);
      // console.log('policyGrp.insurancePolicyNo', _.get(targetIp, 'policyGrp.insurancePolicyNo', null));
    }
    // Insurance Open Policy No オープンポリシーNo
    const insuranceOpenPolicyNo = _.get(linkageMarineInsurance, 'insurancePolicyGrp.insuranceOpenPolicyNo');
    if (!insuranceOpenPolicyNo) {
      linkageMarineInsurance.insurancePolicyGrp.insuranceOpenPolicyNo = _.get(targetIp, 'policyGrp.opOcProvNo', null);
      // console.log('policyGrp.opOcProvNo', _.get(targetIp, 'policyGrp.opOcProvNo', null));
    }
    // Insurance Policy Branch No 証券番号枝番
    const insurancePolicyBranchNo = _.get(linkageMarineInsurance, 'insurancePolicyGrp.insurancePolicyBranchNo');
    if (!insurancePolicyBranchNo) {
      linkageMarineInsurance.insurancePolicyGrp.insurancePolicyBranchNo = _.get(targetIp, 'policyGrp.insurancePolicyBranchNo', null);
      // console.log('policyGrp.insurancePolicyBranchNo', _.get(targetIp, 'policyGrp.insurancePolicyBranchNo', null));
    }
    // Issue Date 証券発行日
    const dateOfIssue = _.get(linkageMarineInsurance, 'insurancePolicyGrp.dateOfIssue');
    if (!dateOfIssue) {
      const issueDate = _.get(targetIp, 'additionalInformationGrp.issueDate');
      // IP情報テーブル側のissueDateはNumber型なので、String型に変換してから型を合わせる
      const targetIssueDate = issueDate ? dayjs(String(issueDate)).format('YYYY-MM-DD') : null;
      // console.log('issueDate', issueDate)
      // console.log('targetIssueDate', targetIssueDate);
      linkageMarineInsurance.insurancePolicyGrp.dateOfIssue = targetIssueDate;
    }
  }
  // console.log('linkageMarineInsurance', linkageMarineInsurance);
  return linkageMarineInsurance;
};
