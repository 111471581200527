<template>
  <div class="tw_upload_drawer permit">
    <el-drawer class="drawer" :visible.sync="drawerShow" :withHeader="false" size="1082px" @close="close">
      <tw-collapse :title="$t('Label.I/P, D/N File Upload')" :initial-open="true" :to-top="true">
        <!-- アップロードボタン -->
        <div class="buttons" v-if="canUpload && !isError">
          <tw-button
            type="default"
            size="medium"
            @click="cancelConfirm"
          >
            Cancel
          </tw-button>
          <tw-button
            type="primary"
            size="medium"
            @click="confirm"
          >
            OK
          </tw-button>
        </div>

        <!-- アップロード失敗時のボタン -->
        <div class="buttons" v-if="isError">
          <tw-button
            type="primary"
            size="medium"
            @click="cancel"
          >
            OK
          </tw-button>
        </div>

        <!-- アップロードエリア -->
        <div v-show="!canUpload" class="attachment" @dragover.prevent="dragEnter" @dragleave.prevent="dragLeave" @drop.prevent="dropFile" :class="{enter: dragin}" multiple>
          <div class="attach">
            <tw-button type="secondary" size="medium" class="choose_file" @click="loadFiles">Choose Local File<input ref="filesInput" class="file_input" @change="setFile" type="file" multiple accept=".tsv"></tw-button>
          </div>
          <div class="droparea">
            <img src="@/assets/images/dropfile.svg">
            Drop file here to upload.
          </div>
          <!-- <div class="attach">
            <div>After file upload, please wait for data to be registered,  You may  confirm the data status by notification.</div>
          </div> -->
        </div>

        <div class="view_area" v-if="canUpload">
          <table v-if="res.ippools && res.linkageMarineInsurance" class="card">
            <tw-process-list
              :isPermitHistory="true"
              v-for="table in ipSchemas"
              :table="table"
              :key="table.name"
              :subtaskHide="true"
              :initialOpen="true"
              :initSelectIp="true"
              :initSelectDn="true"
            />
          </table>
          <table v-if="res.dnpools && res.marineInsuranceSeparate" class="card">
            <tw-process-list
              :isPermitHistory="true"
              v-for="table in dnSchemas"
              :table="table"
              :key="table.name"
              :subtaskHide="true"
              :initialOpen="true"
              :initSelectIp="true"
              :initSelectDn="true"
            />
          </table>
        </div>

        <div class="circle" @click="closeConfirm" />
      </tw-collapse>
    </el-drawer>
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import { decodeBuffer } from '@/utils/tsv';

// components
import TwButton from '@/components/atoms/TwButton';
import TwCollapse from '@/components/molecules/TwCollapse';
import TwProcessList from '@/components/organisms/TwProcessList';

import schemas from '@/dictionaries/processes/miip.json';
import { createIpDnData } from '@/utils/createIpDnData';
import { IP_REGIST_TYPE } from 'lib-tw-common';

// スタブ
// import ipExample from '@/../public/ipExample.json';
// import dnExample from '@/../public/dnExample.json';

export default {
  name: 'TwIpDnUpload',
  components: {
    TwProcessList,
    TwButton,
    TwCollapse
  },
  provide() {
    return {
      s: this,
      activeTab: null
    };
  },
  props: {
    isShowUpload: Boolean
  },
  data() {
    return {
      schemas: schemas,
      drawerShow: false,
      res: {}, // TSVファイルパースしたJSONデータ格納（画面表示用）
      uploadData: {}, // アップロード用のJSONデータ格納
      fileList: [], // 取得したファイルを格納するリスト
      dragin: false,
      documentsForm: {
        documents: [] // 最終的にBFFに渡すリスト
      },
      canUpload: false, // TSVファイルパース後、アップロード可能な状態か
      isError: false, // アップロード後にエラーがあった場合true
      docType: ''
    };
  },
  computed: {
    ipSchemas() {
      let tables = _.cloneDeep(this.schemas.tables);
      tables = _.reject(tables, table => {
        if (table.variableName === 'linkageMarineInsurance' && this.res.linkageMarineInsurance) {
          return false;
        } else return true;
      });
      const exclude = ['keyGrp', 'insuranceTermsGrp', 'insurancePolicyGrp', 'forwarderIpGrp', 'remarksGrp'];
      _.forEach(tables, table => {
        table.groups = _.filter(table.groups, group => {
          return !exclude.includes(group.variableName);
        });
      });
      return tables;
    },
    dnSchemas() {
      let tables = _.cloneDeep(this.schemas.tables);
      tables = _.reject(tables, table => {
        if (table.variableName === 'marineInsuranceSeparate' && this.res.marineInsuranceSeparate) {
          return false;
        } else return true;
      });
      const exclude = ['keyGrp', 'remarksGrp'];
      _.forEach(tables, table => {
        table.groups = _.filter(table.groups, group => {
          return !exclude.includes(group.variableName);
        });
      });
      return tables;
    },
    // I/P情報表示用のデータを生成します（プロセス詳細）
    ipPreviewItems() {
      if (!_.get(this.res, 'linkageMarineInsurance.ippools')) {
        return [];
      }
      const ret = _.map(this.res.linkageMarineInsurance.ippools, pool => {
        return {
          docType: 'I/P',
          senderCompanyId: pool.policyGrp.senderCompanyId,
          ipRegistType: pool.ipRegistType,
          ipDnType: pool.policyGrp.ipDnType,
          ipDnTypeDn: _.get(pool, 'ipDnTypeDn'),
          shipperReferenceNo: pool.policyGrp.shipperReferenceNo,
          opOcProvNo: pool.policyGrp.opOcProvNo,
          insurancePolicyNo: pool.policyGrp.insurancePolicyNo,
          insurancePolicyBranchNo: pool.policyGrp.insurancePolicyBranchNo,
          invoiceNo: pool.invoiceGrp.invoiceNo,
          assuredsEtc: pool.assuredGrp.assuredsEtc,
          // アップロード確認画面のエラー表示用
          errors: _.get(pool, 'errors', [])
        };
      });
      return ret;
    },
    // D/N情報表示用のデータを生成します（プロセス詳細）
    dnPreviewItems() {
      if (!_.get(this.res, 'marineInsuranceSeparate.dnpools')) {
        return [];
      }
      const ret = _.map(this.res.marineInsuranceSeparate.dnpools, dn => {
        const targetIp = _.find(this.res.linkageMarineInsurance.ippools, ip => {
          // DNから追加したIP情報を検索
          const ipPolicy = ip.policyGrp;
          const dnPolicy = dn.policyGrp;
          // ※ DNのIP共通情報は「ipDnType + ipDnTypeDn（2桁）」として登録
          // DNから追加したIPかどうか + IPとDNのキー情報が一致しているかどうか
          return (
            ip.ipRegistType === IP_REGIST_TYPE.DN &&
            ipPolicy.ipDnTypeDn === dnPolicy.ipDnType &&
            ipPolicy.senderCompanyId === dnPolicy.senderCompanyId &&
            ipPolicy.insurancePolicyNo === dnPolicy.insurancePolicyNo &&
            ipPolicy.insurancePolicyBranchNo === dnPolicy.insurancePolicyBranchNo &&
            ipPolicy.trx === dnPolicy.trx
          );
        });
        return {
          docType: 'D/N',
          senderCompanyId: dn.policyGrp.senderCompanyId,
          ipDnType: targetIp ? targetIp.policyGrp.ipDnType : '',
          ipDnTypeDn: dn.policyGrp.ipDnType,
          insurancePolicyNo: dn.policyGrp.insurancePolicyNo,
          insurancePolicyBranchNo: dn.policyGrp.insurancePolicyBranchNo,
          shipperReferenceNo: targetIp ? targetIp.policyGrp.shipperReferenceNo : '',
          opOcProvNo: targetIp ? targetIp.policyGrp.opOcProvNo : '',
          invoiceNo: targetIp ? targetIp.invoiceGrp.invoiceNo : '',
          assuredsEtc: targetIp ? targetIp.assuredGrp.assuredsEtc : '',
          // アップロード確認画面のエラー表示用
          errors: _.get(dn, 'errors', {})
        };
      });
      return ret;
    }
  },
  created() {},
  watch: {
    isShowUpload(val) {
      this.canUpload = false;
      this.isError = false;
      this.drawerShow = val;
    }
  },
  methods: {
    cloneDeep(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    close() {
      this.res = {};
      this.uploadData = {};
      this.canUpload = false;
      this.isError = false;
      this.$emit('close');
    },
    end() {
      this.close();
      this.$store.commit('END_PROCESS');
    },
    closeConfirm() {
      if (this.canUpload) {
        this.$store
          .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete this file?')
          .then(() => {
            this.close();
          })
          .catch(() => {});
      } else this.close();
    },
    // アップロード処理
    loadFiles() {
      this.$refs.filesInput.click();
    },
    loadDirectory() {
      this.$refs.directoryInput.click();
    },
    async setFile(e) {
      this.$store.commit('START_PROCESS');
      // ファイルを取得して、fileList[]に格納
      this.fileList = [];
      this.documentsForm.documents = [];
      if (_.get(e, 'target.files[0]')) {
        // ファイル選択（input）の場合の処理
        const files = e.target.files;
        _.forEach(files, file => {
          this.fileList.push(file);
        });
      } else if (_.get(e, 'dataTransfer.files') && _.get(e, 'dataTransfer.files').length > 0) {
        // ドラッグアンドドロップの場合の処理
        const files = _.get(e, 'dataTransfer.files');
        _.forEach(files, file => {
          this.fileList.push(file);
        });
      } else {
        this.end();
        this.$store.dispatch('Sorry, FileReader API not supported');
        return;
      }

      if (!this.fileList.length) {
        this.$store.commit('END_PROCESS');
        this.$store.dispatch('SHOW_ALERT', 'Only files with the following extensions are allowed: tsv');
        return;
      }

      // ファイルをチェック
      if (!this.fileCheck(this.fileList)) {
        this.$store.commit('END_PROCESS');
        return;
      }

      if (typeof FileReader === 'function') {
        for (let i = 0; i < this.fileList.length; i++) {
          const file = this.fileList[i];
          await this.createFile(file)
            .then(() => {})
            .catch(() => {
              this.end();
              this.$store.dispatch('Sorry, FileReader API not working');
              return;
            });
        }
        // アップロードBFF実行
        await this.tsvParse();
      } else {
        this.end();
        this.$store.dispatch('Sorry, FileReader API not supported');
      }
    },
    // ファイルを格納
    async createFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async () => {
          const decodedString = await decodeBuffer(reader, file);
          this.documentsForm.documents.push({
            // docType: this.docType,
            fileName: file.name,
            fileValue: this.formatBase64(decodedString)
          });
          resolve();
        };
        reader.onerror = () => {
          reject();
        };
        reader.readAsArrayBuffer(file);
      });
    },
    // 拡張子を取得します
    getFileExtension(filename) {
      const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
      return extension.toLowerCase();
    },
    // 読み取り結果をbase64に変換します
    formatBase64(str) {
      if (str) return Buffer.from(str).toString('base64');
      else return '';
    },
    // ファイルチェック
    fileCheck(files) {
      // console.log(files)
      if (files.length > 1) {
        this.$store.dispatch('SHOW_ALERT', 'Maximum number of files[1] exceeded.');
        return false;
      }
      if (
        _.some(files, file => {
          if (file.name.length > 100) {
            this.$store.dispatch('SHOW_ALERT', 'Maximum length of file name[100(including extensions)] exceeded.');
            return true;
          }
        })
      ) {
        return false;
      }
      if (
        _.some(files, file => {
          if (file.size > 10485760) {
            this.$store.dispatch('SHOW_ALERT', 'Your file exceeds the maximum upload size: 10.0MB');
            return true;
          }
          if (file.size === 0) {
            this.$store.dispatch('SHOW_ALERT', 'Your file cannot be read');
            return true;
          }
        })
      ) {
        return false;
      }
      if (
        _.some(files, file => {
          // console.log(this.getFileExtension(file.name))
          if (!['tsv'].includes(this.getFileExtension(file.name))) {
            this.$store.dispatch('SHOW_ALERT', 'Only files with the following extensions are allowed: tsv');
            return true;
          }
        })
      ) {
        return false;
      }
      return true;
    },
    dragEnter() {
      this.dragin = true;
    },
    dragLeave() {
      this.dragin = false;
    },
    dropFile(e) {
      this.dragin = false;
      this.setFile(e);
    },
    // TSV→JSON変換BFF
    async tsvParse() {
      // console.log(this.documentsForm.documents)
      // ----スタブ-----
      // await new Promise(resolve => setTimeout(resolve, 2000)); // BFF疎通したら削除 確認用にN秒待つ
      // this.res = {
      //   ippools: [{ ...ipExample, errors: [{ code: 'a', message: 'error' }] }, { ...ipExample }],
      //   dnpools: [{ ...dnExample }, { ...dnExample }],
      //   docType: 'DN'
      // };
      // if (this.res.docType === 'IP' && this.res.ippools && this.res.ippools.length > 0) {
      //   const ippools = _.map(this.res.ippools, pool => {
      //     return createIpDnData(pool, 'IP');
      //   });
      //   this.$set(this.res, 'linkageMarineInsurance', {
      //     ippools: ippools
      //   });
      // }
      // if (this.res.docType === 'DN' && this.res.dnpools && this.res.dnpools.length > 0) {
      //   const dnpools = _.map(this.res.dnpools, pool => {
      //     return createIpDnData(pool, 'DN');
      //   });
      //   this.$set(this.res, 'marineInsuranceSeparate', {
      //     dnpools: dnpools
      //   });
      //   const ippools = _.map(this.res.dnpools, pool => {
      //     const ipDnTypeDn = _.get(pool, 'ipDnTypeDn');
      //     return createIpDnData(pool, 'IP', ipDnTypeDn);
      //   });
      //   this.$set(this.res, 'linkageMarineInsurance', {
      //     ippools: ippools
      //   });
      // }
      // // console.log(this.res);
      // this.canUpload = true;
      // this.$store.commit('END_PROCESS');
      // return Promise.resolve();
      // ----スタブ-----

      // BFF_GMC01 業務TSVファイル入力共通BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: 'post_/v1/gm-pool-datas/upload' // v1/gm-pool-datas/upload
        },
        data: this.documentsForm.documents[0]
      };
      $api
        .request(params)
        .then(res => {
          this.uploadData = _.cloneDeep(res);
          this.res = res;
          if (this.res.docType === 'IP' && this.res.ippools && this.res.ippools.length > 0) {
            const ippools = _.map(this.res.ippools, pool => {
              return createIpDnData(pool, 'IP');
            });
            this.$set(this.res, 'linkageMarineInsurance', {
              ippools: ippools
            });
          }
          if (this.res.docType === 'DN' && this.res.dnpools && this.res.dnpools.length > 0) {
            const dnpools = _.map(this.res.dnpools, pool => {
              return createIpDnData(pool, 'DN');
            });
            this.$set(this.res, 'marineInsuranceSeparate', {
              dnpools: dnpools
            });
            const ippools = _.map(this.res.dnpools, pool => {
              const ipDnTypeDn = _.get(pool, 'ipDnTypeDn');
              return createIpDnData(pool, 'IP', ipDnTypeDn);
            });
            this.$set(this.res, 'linkageMarineInsurance', {
              ippools: ippools
            });
          }
          this.canUpload = true;
          this.$store.commit('END_PROCESS');
          return Promise.resolve(res);
        })
        .catch(err => {
          this.res = {};
          this.uploadData = {};
          this.canUpload = false;
          this.$store.commit('END_PROCESS');
          this.$store.dispatch('SHOW_ALERT', err.message);
          return Promise.reject(err);
        });
    },
    // アップロードキャンセル
    cancel() {
      this.res = {};
      this.uploadData = {};
      this.canUpload = false;
      this.isError = false;
    },
    cancelConfirm() {
      this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete this file?')
        .then(() => {
          this.cancel();
        })
        .catch(() => {});
    },
    // アップロードBFF
    async confirm() {
      this.$store.commit('START_PROCESS');

      // ----スタブ-----
      // await new Promise(resolve => setTimeout(resolve, 2000)); // TODO: BFF疎通したら削除 確認用にN秒待つ
      // this.res = {
      //   errors: { code: 'a', message: 'error' },
      //   ippools: [{ ...ipExample, errors: { message: 'error', meta: [{ code: 'a', message: 'error_a' }, { code: 'a', message: 'error_b' }, { code: 'a', message: 'error_c' }] } }, { ...ipExample }],
      //   dnpools: [{ ...dnExample, errors: { message: 'error', meta: [{ code: 'a', message: 'error_a' }, { code: 'a', message: 'error_b' }, { code: 'a', message: 'error_c' }] } }],
      //   docType: 'DN'
      // };
      // const errMessage = _.get(this.res, 'errors.message');
      // if (errMessage) {
      //   if (this.res.docType === 'IP' && this.res.ippools && this.res.ippools.length > 0) {
      //     const ippools = _.map(this.res.ippools, pool => {
      //       let ipdata = createIpDnData(pool, 'IP');
      //       ipdata.errors = pool.errors;
      //       return ipdata;
      //     });
      //     this.$set(this.res, 'linkageMarineInsurance', {
      //       ippools: ippools
      //     });
      //   }
      //   if (this.res.docType === 'DN' && this.res.dnpools && this.res.dnpools.length > 0) {
      //     const dnpools = _.map(this.res.dnpools, pool => {
      //       let dndata = createIpDnData(pool, 'DN');
      //       dndata.errors = pool.errors;
      //       return dndata;
      //     });
      //     this.$set(this.res, 'marineInsuranceSeparate', {
      //       dnpools: dnpools
      //     });
      //     const ippools = _.map(this.res.dnpools, pool => {
      //       const ipDnTypeDn = _.get(pool, 'ipDnTypeDn');
      //       return createIpDnData(pool, 'IP', ipDnTypeDn);
      //     });
      //      this.$set(this.res, 'linkageMarineInsurance', {
      //        ippools: ippools
      //      });
      //   }
      //   // console.log(this.res);
      //   this.$store.dispatch('SHOW_ALERT', errMessage);
      //   this.$store.commit('END_PROCESS');
      //   this.$emit('fetch');
      //   return;
      // } else {
      //   this.$store.dispatch('SHOW_COMPLETED');
      //   this.$emit('fetch');
      //   return;
      // }
      // ----スタブ-----

      // BFF_GMC03 業務プール登録BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: 'post_/v1/gm-pool-datas' // v1/gm-pool-datas
        },
        data: this.uploadData
      };
      await $api
        .request(params)
        .then(async res => {
          const errMessage = _.get(res, 'errors.message');
          if (errMessage) {
            this.res = res;
            if (this.res.ippools && this.res.ippools.length > 0) {
              const ippools = _.map(this.res.ippools, pool => {
                let ipdata = createIpDnData(pool.ipdata, 'IP');
                ipdata.errors = pool.errors;
                return ipdata;
              });
              this.$set(this.res, 'linkageMarineInsurance', {
                ippools: ippools
              });
            }
            if (this.res.dnpools && this.res.dnpools.length > 0) {
              const dnpools = _.map(this.res.dnpools, pool => {
                let dndata = createIpDnData(pool.dndata, 'DN');
                dndata.errors = pool.errors;
                return dndata;
              });
              this.$set(this.res, 'marineInsuranceSeparate', {
                dnpools: dnpools
              });
              const ippools = _.map(this.res.dnpools, pool => {
                const ipDnTypeDn = _.get(pool, 'dndata.ipDnTypeDn');
                return createIpDnData(pool.dndata, 'IP', ipDnTypeDn);
              });
              this.$set(this.res, 'linkageMarineInsurance', {
                ippools: ippools
              });
            }
            this.isError = true;
            this.$store.dispatch('SHOW_ALERT', errMessage);
            this.$store.commit('END_PROCESS');
            this.$emit('fetch');
          } else {
            this.$store.dispatch('SHOW_COMPLETED');
            this.end();
            this.$emit('fetch');
          }
        })
        .catch(async err => {
          this.end();
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.attach {
  display: flex;
  justify-content: flex-end;
  margin: 8px 0;
}

.attachment {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 40px;
  padding: 16px;

  .choose_file {
    position: relative;
    display: inline-block;

    .file_input {
      position: absolute;
      width: 1px;
      height: 1px;
      z-index: -1;
      pointer-events: none;
    }
  }

  .droparea {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 138px;
    margin: 16px 0 8px;
    background: $color_gray_100;
    border-radius: 6px;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    color: $color_dark_blue;
    pointer-events: none;
    z-index: 2;
    img {
      display: block;
      width: 84px;
      height: auto;
      margin-bottom: 9px;
    }
  }

  &.enter > * {
    pointer-events: none;
  }

  &.enter .droparea {
    border: 1px dashed $color_dark_blue;
  }
}

::v-deep .side_drawer {
  width: auto;
  width: 990px;

  > .inner {
    padding: 30px 40px 30px 35px;
  }
}

.tw_upload_drawer {
  margin: 40px 0 16px;
  max-width: 50%;
}

.view_area {
  padding: 24px;
  .card {
    margin-bottom: 16px;
  }
}

.buttons {
  display: flex;
  justify-content: right;
  padding: 16px 32px 0;
}
</style>
