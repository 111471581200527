<template>
  <div class="tw_input_number_string format" :content="formatInputNumber(numberModel)">
    <el-input
      placeholder=""
      v-model="numberModel"
      :disabled="disabled"
      @keydown.native="handleKeyDown"
      @change="handleChange"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import BigNumber from 'bignumber.js';
import { formatInputNumber } from '@/utils/searchUtil.js';

export default {
  name: 'TwInputFormatNumberString',
  props: {
    value: {
      type: String, // v-model
    },
    maxlength: {
      type: Number,
      default: 20,
    },
    disabled: {
      type: Boolean,
    }
  },
  data() {
    return {
      formatInputNumber,
      el: null,
      oldVal: this.value,
    };
  },
  computed: {
    numberModel: {
      get() {
        if (this.value === null || '') {
          return '';
        }
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
  },
  mounted() {
    this.el = this.$el.querySelector('input');
    this.handleKeyDown();
  },
  methods: {
    handleKeyDown(e) {
       if (e && e.key === '.' && String(e.target.value).includes('.')) {
        e.preventDefault();
        return;
      }
      if (e && e.key === '-' && String(e.target.value).includes('-')) {
        e.preventDefault();
        return;
      }
      let maxlength = this.maxlength;
      if (this.el.value && this.el.value.includes('.')) {
        maxlength++;
      }
      if (this.el.value && this.el.value.includes('-')) {
        maxlength++;
      }
      this.el.setAttribute('maxlength', maxlength);
    },
    handleChange(value) {
      if (_.isNaN(Number(value))) {
        value = this.oldValue;
      }
      if (value || value === 0) {
        value = BigNumber(value).toString();
      } else {
        value = null;
      }
      
      this.$emit('change', value)
      this.oldValue = value;
    }
  }
};
</script>