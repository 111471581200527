<template>
  <el-input-number
    type="number"
    :controls="false"
    placeholder=""
    :disabled="disabled"
    v-model="numberModel"
  />
</template>

<script>
export default {
  name: 'TwInputNumberMax',
  props: {
    value: {
      type: Number, // v-model
    },
    maxlength: {
      type: Number,
    },
    disabled: {
      type: Boolean,
    }
  },
  computed: {
    numberModel: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
  },
  mounted() {
    const el = this.$el.querySelector('input');
    if (el) {
      el.setAttribute('maxlength', this.maxlength);
    }  
  },
};
</script>