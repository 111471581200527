<template>
  <div
    v-if="isVisible && isSerialItem"
    class="tw_process_group_list_item"
    :class="{full: item.inputType === 'text_full' || item.inputType === 'textarea' || item.key === 'exCustomsTotalQuantity' || item.key === 'imCustomsTotalQuantity' }"
  >
    <template v-if="isInActiveTab">
      <div class="label">{{getLabel}}</div>
      <div v-if="hasModel && item.code === 'SYS_MST_CONTAINER_TYPE'" class="value">{{SYS_MST_CONTAINER_TYPE}}</div>
      <div v-else-if="hasModel && item.code === 'SOC_FLAG'" class="value">{{SOC_FLAG}}</div>
      <div v-else-if="hasModel && item.code === 'SEALING_PARTY'" class="value">{{SEALING_PARTY}}</div>
      <div v-else-if="hasModel && item.inputType === 'cargoGrossWeight'" class="value">{{number}} {{CARGO_GROSS_WEIGHT_UNIT}}</div>
      <div v-else-if="hasModel && item.inputType === 'cargoGrossVolume'" class="value">{{number}} {{CARGO_GROSS_VOLUME_UNIT}}</div>
      <div v-else-if="hasModel && item.inputType === 'containerTare'" class="value">{{number}} {{CONTAINER_WEIGHT_UNIT}}</div>
      <div v-else-if="hasModel && item.inputType === 'number'" class="value">{{number}}</div>
      <div v-else-if="hasModel && item.inputType === 'textarea'" class="value" v-html="sanitize(model)" />
      <div v-else-if="hasModel" class="value" v-html="sanitize(model)" />
    </template>
  </div>
</template>

<script>
import { SOC_FLAG_VARIABLES, SEALING_PARTY_VARIABLES, CARGO_GROSS_WEIGHT_UNIT_VARIABLES, CARGO_GROSS_VOLUME_UNIT_VARIABLES, CONTAINER_WEIGHT_UNIT_VARIABLES } from 'lib-tw-common';
import _ from 'lodash';
import { formatNumber } from '@/utils/searchUtil.js';
// import TwButton from '@/components/atoms/TwButton';
import { getKey, getSerialIndex, getItemIndex } from '@/utils/processedSerialItems';

export default {
  name: 'TwProcessGroupListInfiniteItem',
  props: {
    item: Object,
    tableName: String,
    groupName: String,
    arrayIndex: Number,
    arrayName: String,
    serialIndexes: Array,
    serialItems: Array,
  },
  inject: ['s', 'tab', 'activeTab'],
  components:{
    // TwButton,
  },
  data() {
    return {};
  },
  computed: {
    getLabel() {
      if (this.item.key === 'containerType') {
        return `${this.item.label} ${this.arrayIndex + 1}`;
      } else {
        return this.item.label;
      }
    },
    model() {
      return _.get(this.s.res, `${this.tableName}.${this.groupName}.${this.arrayName}[${this.arrayIndex}].${this.item.key}`);
    },
    hasModel() {
      return this.model === 0 || this.model;
    },
    isVisible() {
      return !this.item.hide;
    },
    number() {
      if (_.isNaN(Number(this.model))) {
        return this.model;
      }
      return formatNumber(this.model, this.item.validationId);
    },
    // コンテナタイプ区分
    SYS_MST_CONTAINER_TYPE() {
      const target = this.item.unitTarget.split('.');
      const unitTarget = target.length ? `${target[0]}.${target[1]}.${this.arrayName}[${this.arrayIndex}].${target[2]}` : '';
      return _.get(_.find(this.$store.getters.getSysMstContainerType, {type: this.model, size: _.get(this.s.res, unitTarget)}), 'description') || '';
    },
    // SOCフラグ
    SOC_FLAG() {
      return _.get(_.find(SOC_FLAG_VARIABLES, {code: this.model}), 'label') || '';
    },
    // シール実施者
    SEALING_PARTY() {
      return _.get(_.find(SEALING_PARTY_VARIABLES, {code: this.model}), 'label') || '';
    },
    // 貨物重量単位
    CARGO_GROSS_WEIGHT_UNIT() {
      return _.get(_.find(CARGO_GROSS_WEIGHT_UNIT_VARIABLES, {code: this.getTargetValue()}), 'label') || '';
    },
    // 貨物容積単位
    CARGO_GROSS_VOLUME_UNIT() {
      return _.get(_.find(CARGO_GROSS_VOLUME_UNIT_VARIABLES, {code: this.getTargetValue()}), 'label') || '';
    },
    // コンテナ重量単位
    CONTAINER_WEIGHT_UNIT() {
      return _.get(_.find(CONTAINER_WEIGHT_UNIT_VARIABLES, {code: this.getTargetValue()}), 'label') || '';
    },
    // 連番項目を可変にし追加可能にする対応--------------------------
    isSerialItem() {
      // console.log(this.serialIndex && this.itemIndex)
      if ((this.serialIndex && this.itemIndex) || this.serialIndex === 0) return this.serialIndex >= this.itemIndex;
      else return true;
    },
    serialIndex() {
      if (this.serialIndexes && this.serialIndexes.length) return getSerialIndex(this.serialIndexes[this.arrayIndex], this.serialGroupName ? this.serialGroupName : this.item.key);
      else return false;
    },
    itemIndex() {
      if (this.serialItems && this.serialItems.length) return getItemIndex(this.serialItems[this.arrayIndex], this.item.key);
      else return false;
    },
    serialGroupName() {
      return _.get(this, `serialItems[${this.arrayIndex}][${getKey(this.item.key)}].groupName`) || false;
    },
    // アクティブなタブ配下にいるか
    isInActiveTab() {
      if (!this.tab && this.tab !== 0) {
        return true;
      }
      
      return _.get(this.activeTab, 'value') === this.tab;
    },
  },
  watch: {},
  created() {},
  methods: {
    sanitize(str) {
      return this.$options.filters.newlines(str);
    },
    getTargetValue() {
      const target = _.last(this.item.unitTarget.split('.'));
      return _.get(this.s, `res[${this.tableName}][${this.groupName}][${this.arrayName}][${this.arrayIndex}][${target}]`)
    },
  }
};
</script>

<style lang="scss" scoped>
.tw_process_group_list_item {
  width: 50%;
  min-width: 300px;
  min-height: 19.9px;
  margin-bottom: 12px;
  display: flex;
  padding-right: 16px;
  &.full {
    width: 100%;
  }
  &:last-child {
    margin-bottom: 16px;
  }
  .label {
    width: 118px;
    font-size: 12px;
    line-height: 18px;
    color: #9191A4;
    margin-right: 8px;
    vertical-align: top;
    padding-top: 2px;
  }
  .value {
    font-size: 14px;
    line-height: 20px;
    color: #3E3A39;
    vertical-align: top;
    flex: 1;
  }
}
</style>
