<template>
  <div class='pool_list'>
    <tw-header :title="$t('BreadCrumbs.I/P, D/N List')">
      <template v-slot:column>
        <tw-button v-if='canUpload' type='secondary' size='small' icon='plus' style='margin-left: 24px'
                   @click='showUpload()'>
          Upload
        </tw-button>
      </template>
    </tw-header>
    <div class='tw_container'>
      <tw-breadcrumbs :pages='breadcrumbs' style='margin-bottom: 40px' />

      <TwIpDnUpload :isShowUpload='isShowUpload' @close='closeUpload' @fetch='fetch' />

      <el-form v-if='!forbidden' class='search_unit compact' ref='searchUnit' :model='searchForm' label-position='top'>
        <div class='row'>
          <el-form-item :label="$t('Label.I/P, D/N')" prop='docType'>
            <el-select v-model='searchForm.docType' placeholder='Select' clearable>
              <el-option
                v-for="docType in [{code: 'IP', label: 'I/P'},{code: 'DN', label: 'D/N'}]"
                :key='docType.code'
                :label='docType.label'
                :value='docType.code'>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('Label.Type')" prop='ipDnType'>
            <el-select v-model='searchForm.ipDnType' placeholder='Select' clearableclass='section'
                       :class='{multiple_selected: multipleSelect(searchForm.ipDnType)}' multiple collapse-tags
                       clearable>
              <el-option
                v-for='ipDnType in IP_DN_TYPE_VARIABLES'
                :key='ipDnType.code'
                :label='ipDnType.label'
                :value='ipDnType.code'>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('Label.Invoice No')" prop='invoiceNo'>
            <el-input type='text' v-model='searchForm.invoiceNo' placeholder='Enter keyword' maxlength='300'></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.O/P No')" prop='opOcProvNo'>
            <el-input type='text' v-model='searchForm.opOcProvNo' placeholder='Enter keyword'
                      maxlength='300'></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.I/P No')" prop='insurancePolicyNo'>
            <el-input type='text' v-model='searchForm.insurancePolicyNo' placeholder='Enter keyword'
                      maxlength='300'></el-input>
          </el-form-item>
          <tw-button type='secondary' size='small' class='toggle_button' :class='{ open: openForm }'
                     @click='openToggle'>
            <img src='@/assets/images/icons/view_all.svg'>
          </tw-button>
        </div>

        <transition
          name='content'
          @enter='enter'
          @after-enter='afterEnter'
          @leave='leave'
          @after-leave='afterLeave'
        >
          <div v-show='openForm'>
            <div class='row'>
              <el-form-item :label="$t('Label.Company')" prop='ownerCompanyName'>
                <el-input type='text' v-model='searchForm.ownerCompanyName' placeholder='Enter keyword'
                          maxlength='300'></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Section')" prop='ownerSectionName'>
                <el-input type='text' v-model='searchForm.ownerSectionName' placeholder='Enter keyword'
                          maxlength='300'></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Assured')" prop='assuredName'>
                <el-input type='text' v-model='searchForm.assuredName' placeholder='Enter keyword'
                          maxlength='300'></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Vessel Name')" prop='vesselName'>
                <el-input type='text' v-model='searchForm.vesselName' placeholder='Enter keyword'
                          maxlength='300'></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Sailing Date')" prop='sailingOnOrAbout'>
                <el-date-picker
                  v-model='searchForm.sailingOnOrAbout'
                  type='daterange'
                  range-separator='to'
                  format='yyyy-MM-dd'
                  :default-value='defaultDate'
                  @change.native='onDateRangeChange'
                  start-placeholder='Start date'
                  end-placeholder='End date'>
                </el-date-picker>
              </el-form-item>
              <el-form-item :label="$t('Label.Insurance Company')" prop='insuranceCompanyName'>
                <el-input type='text' v-model='searchForm.insuranceCompanyName' placeholder='Enter keyword'
                          maxlength='300'></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Goods')" prop='descriptionOfGoods'>
                <el-input type='text' v-model='searchForm.descriptionOfGoods' placeholder='Enter keyword'
                          maxlength='300'></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Registered Date')" prop='createDate'>
                <el-date-picker
                  v-model='searchForm.createDate'
                  type='daterange'
                  range-separator='to'
                  format='yyyy-MM-dd'
                  :default-value='defaultDate'
                  @change.native='onDateRangeChange'
                  start-placeholder='Start date'
                  end-placeholder='End date'>
                </el-date-picker>
              </el-form-item>
              <el-form-item v-show='!selection' :label="$t('Label.Linking Status')" prop='linkingFlag'>
                <el-select v-model='linkingFlag' placeholder='Select' collapse-tags clearable>
                  <el-option
                    v-for='status in IP_DN_LINK_FLG_VARIABLES'
                    :key='status.code'
                    :label='status.label'
                    :value='status.code'>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-show='!selection' label=' ' prop='includeDeletedData'>
                <el-checkbox border class='border_none' v-model='includeDeletedDataCheck'>
                  {{ $t('Label.Include Deleted Data') }}
                </el-checkbox>
              </el-form-item>
            </div>
          </div>
        </transition>

        <div class='row drop space_between'>
          <div>
            <tw-button
              type='secondary'
              size='medium'
              icon='search'
              @click='search'
            >
              Search
            </tw-button>
            <tw-button
              type='default'
              size='medium'
              @click='clear'
              style='margin: 8px 16px 16px;'
            >
              Clear
            </tw-button>
          </div>
          <div class='delete_area'>
            <tw-button
              v-if='selection && items'
              type='default'
              size='medium'
              @click='cancel'
            >
              Cancel
            </tw-button>
            <tw-button
              v-if='selection && items'
              type='primary'
              size='medium'
              :disabled='selectionItems.length <= 0'
              @click='deleteConfirm'
              class='delete_button'
            >
              Delete
            </tw-button>
          </div>
          <el-dropdown v-if='items && items.length > 0 && !selection' trigger='click' placement='bottom'
                       @command='onDropDown' :tabindex='-1'>
            <tw-button class='menu_button' type='secondary' size='small' icon='menu'>Menu</tw-button>
            <el-dropdown-menu class='nowrap'>
              <el-dropdown-item command='tsv'>{{ $t('Label.TSV Download') }}</el-dropdown-item>
              <el-dropdown-item v-if='canUpload' command='deleteMode'>{{ $t('Label.Delete Mode') }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-form>

      <tw-table-system
        :schemas='schemas'
        :items='items'
        :pageSize='limit'
        :totalCount='totalCount'
        :offset='offset'
        :selection='selection'
        :serverPagination='true'
        :rowKey='getRowKey'
        @cell-mouse-enter='cellMouseEnter'
        @cell-mouse-leave='cellMouseLeave'
        @selection-change='selectionChange'
        @row-click='rowClick'
        @paging='onPaging'
        ref='table'
        mode='POOL'
        :is-loading='isLoading'
      />
    </div>

    <mouse-following-tooltip
      v-if='isShowInvalidTooltip'
      content='This IP/DN is no longer valid.'
      :mouse='mouse'
    />

    <tw-drawer :close-confirm='true' @close='closeConfirm' mode='edit'>
      <template #default>
        <tw-ip-dn-detail-inner
          v-if='drawerShow'
          :ipdata='ipdata'
          :dndata='dndata'
          :senderCompanyId='senderCompanyId'
          :insurancePolicyNo='insurancePolicyNo'
          :insurancePolicyBranchNo='insurancePolicyBranchNo'
          :trx='trx'
          :ipDnType='ipDnType'
          :ipDnTypeDn='ipDnTypeDn'
          :docType='docType'
          :editableFlag='editableFlag'
          :isPic='isPic'
          :isLatest='isLatest'
          @deleted='deleted'
          @close='closeConfirm'
        />
      </template>
    </tw-drawer>
  </div>
</template>

<script>
import _ from 'lodash';
import {
  COMPANY_ROLE_CD,
  PROCESS_TYPE,
  DEFAULT_PAGENATION,
  DELETE_FLG,
  IP_DN_LINK_FLG,
  IP_DN_LINK_FLG_VARIABLES,
  IP_DN_TYPE_VARIABLES
} from 'lib-tw-common';
import { $api } from '@/store/ApiClient';
import mixinEntityList from '@/utils/mixinEntityList.js';
import { collapseTransition } from '@/utils/nextFrame';
import { clearSearchForm } from '@/utils/searchUtil.js';
import schemas from '@/dictionaries/ListIpDnPoolListSchema.json';
import TwIpDnUpload from '@/components/organisms/TwIpDnUpload';
import TwDrawer from '@/components/organisms/TwDrawer';
import TwIpDnDetailInner from '@/components/templates/TwIpDnDetailInner';
import { tsvDownLoad } from '@/utils/listToTsvUtils.js';
import dayjs from 'dayjs';
import { mapState } from 'vuex';
import MouseFollowingTooltip from '@/components/atoms/MouseFollowingTooltip.vue';
import mixinMouseFollowingTooltip from '@/utils/mixinMouseFollowingTooltip';

export default {
  name: 'ListIpDnPoolList',
  mixins: [mixinEntityList, collapseTransition, mixinMouseFollowingTooltip],
  components: {
    MouseFollowingTooltip,
    TwIpDnUpload,
    TwDrawer,
    TwIpDnDetailInner
  },
  data() {
    return {
      processType: PROCESS_TYPE.MIIP,
      schemas: schemas,
      items: null,
      selection: false,
      searchForm: {
        docType: '', // 検索項目としてのドキュメントタイプ
        ipDnType: [],
        invoiceNo: '',
        opOcProvNo: '',
        insurancePolicyNo: '',
        ownerCompanyName: '',
        ownerSectionName: '',
        vesselName: '',
        sailingOnOrAbout: '',
        assuredName: '',
        insuranceCompanyName: '',
        descriptionOfGoods: '',
        createDate: '',
        includeDeletedData: false
      },
      includeDeletedDataCheck: false,
      linkingFlag: null,
      IP_DN_LINK_FLG_VARIABLES: IP_DN_LINK_FLG_VARIABLES,
      DELETE_FLG: DELETE_FLG,
      limit: DEFAULT_PAGENATION.LIMIT,
      offset: 0,
      sort: null,
      count: 0,
      totalCount: 0,
      pageCount: 0,
      currentPage: 1,
      isShowUpload: false,
      drawerShow: false,
      ipdata: null,
      dndata: null,
      senderCompanyId: '',
      insurancePolicyNo: '',
      insurancePolicyBranchNo: '',
      ipDnType: '',
      ipDnTypeDn: '',
      trx: '',
      editableFlag: false, // 削除可能フラグ 一覧のrowごとに存在
      isDeleteMode: false,
      docType: '', // 詳細に渡すドキュメントタイプ
      lastQuery: {},
      selectionItems: [],
      maxNum: 10 ** 16, // 300桁
      forbidden: false, // 認可エラーフラグ
      isLatest: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapState(['roleInfo', 'companyRoles']),
    IP_DN_TYPE_VARIABLES() {
      return _.reject(_.cloneDeep(IP_DN_TYPE_VARIABLES), type => {
        // IP,DN種別のcode値が2桁の区分値は検索用ではない（実データには登録されていない表示用区分値）のため除外
        return String(type.code).length > 1;
      });
    },
    isPic() {
      // ユーザーロールが担当者か
      return this.$store.getters.isPic;
    },
    // 保険会社、FWD（保険代理店含む）ロールをもつかどうか
    isRoles() {
      return _.some(this.companyRoles, cd => {
        return [COMPANY_ROLE_CD.MARINE_INSURANCE, COMPANY_ROLE_CD.EXPORT_FREIGHT_FORWARDER].includes(cd);
      });
    },
    canUpload() {
      // アップロード可能・削除可能ユーザ
      // TSVファイルアップロード可能カンパニーロール 添付可能対象ユーザ：保険会社、FWD（保険代理店含む）
      // 担当者ロールかつ、プールセクションに属しているユーザのみアップロード可能：[IP]が先頭に含まれるセクションIDを持つユーザ
      return (
        this.isRoles &&
        this.isPic &&
        _.some(this.roleInfo.all, obj => {
          return obj.sectionId && obj.sectionId.startsWith('IP');
        })
      );
    },
    breadcrumbs() {
      return [{ label: this.$t('BreadCrumbs.Dashboard'), to: '/' }, { label: this.$t('BreadCrumbs.I/P, D/N List') }];
    }
  },
  created() {
    if (this.$store.state.drawerShow) this.$store.commit('SET_DRAWER', false);
    const queries = this.$store.getters.getQueries('IP_DN_POOL');
    if (queries) {
      this.searchForm = queries;
      this.includeDeletedDataCheck = this.searchForm.includeDeletedData;
    } else {
      // 検索初期条件：Receive Data直近1ヶ月昇順
      const today = dayjs()
        .hour(0)
        .minute(0)
        .second(0);
      this.searchForm.createDate = [today.subtract(1, 'month').format(), today.format()];
    }
    this.fetch();
  },
  methods: {
    // POSTパラメータを生成します
    transformQueries() {
      const f = this.searchForm;
      return {
        docType: f.docType,
        ipDnType: f.ipDnType,
        invoiceNo: f.invoiceNo,
        opOcProvNo: f.opOcProvNo,
        insurancePolicyNo: f.insurancePolicyNo,
        ownerCompanyName: f.ownerCompanyName,
        ownerSectionName: f.ownerSectionName,
        vesselName: f.vesselName,
        sailingOnOrAboutFrom: f.sailingOnOrAbout && f.sailingOnOrAbout.length ? dayjs(this.getStartDate(f.sailingOnOrAbout)).format('YYYYMMDD') : null,
        sailingOnOrAboutTo: f.sailingOnOrAbout && f.sailingOnOrAbout.length ? dayjs(this.getEndDate(f.sailingOnOrAbout)).format('YYYYMMDD') : null,
        assuredName: f.assuredName,
        insuranceCompanyName: f.insuranceCompanyName,
        descriptionOfGoods: f.descriptionOfGoods,
        createDateFrom: f.createDate ? this.getStartDate(f.createDate) : null,
        createDateTo: f.createDate ? this.getEndDate(f.createDate) : null,
        includeDeletedData: f.includeDeletedData,
        limit: this.limit,
        offset: this.offset,
        sortType: this.sort ? this.sort.sortType : null,
        target: this.sort ? this.sort.target : null
      };
    },
    async fetch(queries) {
      this.isLoading = true;

      // BFF_IPP01 I/P,D/Nプール一覧検索BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: 'get_/v1/ip-pool-datas/search',
          query: { ...(queries || this.transformQueries()), linkingFlag: this.linkingFlag }
        }
      };

      this.lastQuery = _.cloneDeep(params.lslConfig.query);

      await $api
        .request(params)
        .then(res => {
          if (this.isDeleteMode) {
            this.items = res.ipdnpools && res.ipdnpools.length ? _.filter(this.setIndex(this.flatten(res.ipdnpools)), o => o.editableFlag) : [];
          } else {
            this.items = res.ipdnpools && res.ipdnpools.length ? this.setIndex(this.flatten(res.ipdnpools)) : [];
          }
          this.totalCount = res.maxNum;
          return Promise.resolve();
        })
        .catch(err => {
          this.items = [];
          this.sort = null;
          if (err.statusCode === 403) {
            this.forbidden = true;
          }
          this.$store.dispatch('SHOW_ALERT', err.message);
          return Promise.reject();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    search() {
      this.offset = 0;
      this.searchForm.includeDeletedData = this.includeDeletedDataCheck;
      this.selectionItems = [];
      this.items = null;
      this.$store.commit('SET_QUERIES', { key: 'IP_DN_POOL', queries: _.cloneDeep(this.searchForm) });
      this.fetch();
    },
    clear() {
      this.searchForm = clearSearchForm(this.searchForm);
      this.includeDeletedDataCheck = false;
      if (!this.selection) this.linkingFlag = null;
      this.$store.commit('SET_QUERIES', { key: 'IP_DN_POOL', queries: _.cloneDeep(this.searchForm) });
    },
    onPaging({ target, sortType, currentPage }) {
      if (target && sortType) {
        this.sort = {
          target: target,
          sortType: sortType
        };
        // NOTE: 一覧の区分値がないためソートターゲットを変換
        if (this.sort.target === 'update') this.sort.target = 'updateUserName';
        else if (this.sort.target === 'tradingFlowId') this.sort.target = 'tradingId';
      } else {
        this.sort = null;
      }

      this.offset = currentPage * this.limit - this.limit;
      this.currentPage = currentPage;
      const queries = {
        ...this.lastQuery,
        limit: this.limit,
        offset: this.offset,
        sortType: this.sort ? this.sort.sortType : null,
        target: this.sort ? this.sort.target : null
      };
      this.fetch(queries);
    },
    // TSVダウンロード用に全件取得します
    fetchAll() {
      return new Promise((resolve, reject) => {
        // BFF_IPP01 I/P,D/Nプール一覧検索BFF
        const params = {
          lslConfig: {
            serviceCode: 'tw-pooldata-bff-api',
            apiCode: 'get_/v1/ip-pool-datas/search',
            query: { ...this.transformQueries(), offset: undefined, limit: undefined, linkingFlag: null }
          }
        };

        $api
          .request(params)
          .then(res => {
            resolve(this.flatten(res.ipdnpools));
          })
          .catch(err => {
            this.$store.dispatch('SHOW_ALERT', err.message);
            reject();
          });
      });
    },
    // アップロードエリアを表示します
    // eslint-disable-next-line no-unused-vars
    showUpload(docType) {
      // this.uploadDocType = docType;
      this.isShowUpload = true;
    },
    closeUpload() {
      this.isShowUpload = false;
    },
    rowClick(row) {
      this.ipdata = row.ipdata || {};
      this.dndata = row.dndata || {};
      this.senderCompanyId = row.senderCompanyId || '';
      this.insurancePolicyNo = row.insurancePolicyNo || '';
      this.insurancePolicyBranchNo = row.insurancePolicyBranchNo || '';
      this.ipDnType = row.docType === 'DN' ? _.get(row, 'dndata.ipDnType') : _.get(row, 'ipdata.ipDnType');
      this.ipDnTypeDn = row.docType === 'DN' ? _.get(row, 'dndata.ipDnTypeDn') : null;
      this.trx = row.trx;
      this.docType = row.docType;
      this.editableFlag = row.editableFlag || false;
      this.drawerShow = true;
      this.isLatest = row.latestFlg;
      this.$store.commit('SET_DRAWER', true);
    },
    // ドロワーを閉じる
    closeConfirm() {
      this.$store.commit('SET_DRAWER', false);
      this.drawerShow = false;
    },
    // ドロワー内でIP,DN削除後
    deleted() {
      this.items = null;
      this.linkingFlag = null;
      this.searchForm.includeDeletedData = this.includeDeletedDataCheck;
      this.isDeleteMode = false;
      this.fetch().finally(() => {
        this.closeConfirm();
        this.$store.commit('END_PROCESS');
        this.selection = false;
      });
    },
    // テーブルのrow-keyを返却します（チェック状態保持）
    getRowKey(row) {
      // NOTE: currentPageとindexで一意にして返却
      return `${row.currentPage}_${row.index}`;
    },
    // テーブル表示時にrow-keyを一意にするためにrowにcurrentPageとindexを付与します
    setIndex(array) {
      for (let i = 0; i < array.length; i++) {
        array[i] = { ...array[i], currentPage: this.currentPage, index: i };
      }
      return array;
    },
    // 許可書一括削除コンファーム
    deleteConfirm() {
      this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete the files?')
        .then(() => {
          this.selectionDelete();
        })
        .catch(() => {
        });
    },
    // 一括削除
    async selectionDelete() {
      this.$store.commit('START_PROCESS');
      // リクエスト生成
      let ipdnpools = [];
      const selectionItems = this.selectionItems;
      for (let i = 0; i < selectionItems.length; i++) {
        let item = {
          docType: selectionItems[i].docType,
          senderCompanyId: selectionItems[i].senderCompanyId,
          insurancePolicyNo: selectionItems[i].insurancePolicyNo,
          insurancePolicyBranchNo: selectionItems[i].insurancePolicyBranchNo,
          ipDnType: selectionItems[i].ipDnType,
          trx: selectionItems[i].trx
        };
        if (selectionItems[i].docType === 'DN') {
          item.ipDnTypeDn = selectionItems[i].ipDnTypeDn;
        }
        ipdnpools.push(item);
      }
      // console.log(ipdnpools)
      await this.deletePermit(ipdnpools); // 削除BFF呼び出し
      this.$refs.table.clearSelection(); // チェックボックスをクリア
      this.$store.commit('END_PROCESS');
      this.cancel();
    },
    // IPDNプール削除BFF
    async deletePermit(ipdnpools) {
      // BFF_IPP04 I/P,D/Nプール削除BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: 'post_/v1/ip-pool-datas/ip/delete'
        },
        data: {
          ipdnpools: ipdnpools
        }
      };
      await $api
        .request(params)
        .then(res => {
          // 結果を格納
          let results = { result: true, successCount: 0, errCount: 0 };
          const ipdnpools = res.ipdnpools;
          for (let i = 0; i < ipdnpools.length; i++) {
            const pool = ipdnpools[i];
            // httpステータスが正常系の200系か、それ以外で数えてカウント
            if (pool.status >= 200 && pool.status <= 299) {
              results.successCount += 1;
            } else {
              results.result = false;
              results.errCount += 1;
            }
          }
          // resultに応じてアラートかコンプリートを表示
          if (!results.result) this.$store.dispatch('SHOW_ALERT', `Data deleted successfully. ${results.successCount}<br />Failed to delete the data. ${results.errCount}`);
          else this.$store.dispatch('SHOW_COMPLETED');
          return Promise.resolve(res);
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          return Promise.reject(err);
        });
    },
    // セレクト切り替え
    selectionChange(multipleSelection) {
      this.selectionItems = multipleSelection;
    },
    // ドロップダウン
    async onDropDown(command) {
      if (command === 'tsv') {
        const items = await this.fetchAll();
        tsvDownLoad(this.schemas, items);
      } else if (command === 'deleteMode') {
        if (!this.selection) {
          this.searchForm.includeDeletedData = false;
          this.linkingFlag = String(IP_DN_LINK_FLG.UNLINKED);
        } else {
          this.linkingFlag = null;
        }
        this.selection = !this.selection;
        await this.$refs.table.clearSelection();
        this.items = null;
        this.isDeleteMode = true;
        await this.fetch(undefined);
      }
    },
    // 一括削除モード解除
    cancel() {
      this.items = null;
      this.linkingFlag = null;
      this.searchForm.includeDeletedData = this.includeDeletedDataCheck;
      this.isDeleteMode = false;
      this.fetch(); // 一覧を再取得
      this.selection = false;
    },
    // IP, DNの情報を一階層上に展開
    flatten(ary) {
      return _.map(ary, o => {
        const targetType = o.docType === 'DN' ? 'dn' : 'ip';
        return {
          ...o,
          ipDnType: _.get(o, `${targetType}data.ipDnType`) || null,
          ipDnTypeDn: _.get(o, `${targetType}data.ipDnTypeDn`) || null,
          senderCompanyId: _.get(o, `${targetType}data.senderCompanyId`, null),
          trx: _.get(o, `${targetType}data.trx`, null),
          tradingId: _.get(o, `${targetType}data.tradingId`, null),
          tradingFlowId: _.get(o, `${targetType}data.tradingFlowId`, null),
          processName: _.get(o, `${targetType}data.processName`, null),
          deleteFlg: _.get(o, `${targetType}data.deleteFlg`, null),
          ownerCompanyName: _.get(o, `${targetType}data.ownerCompanyName`, null),
          ownerSectionName: _.get(o, `${targetType}data.ownerSectionName`, null),
          shipperReferenceNo: _.get(o, `${targetType}data.shipperReferenceNo`, null),
          invoiceNo: _.get(o, `${targetType}data.invoiceNo`, null),
          opOcProvNo: _.get(o, `${targetType}data.opOcProvNo`, null),
          insurancePolicyNo: _.get(o, `${targetType}data.insurancePolicyNo`, null),
          insurancePolicyBranchNo: _.get(o, `${targetType}data.insurancePolicyBranchNo`, null),
          vesselName: _.get(o, `${targetType}data.vesselName`, null),
          sailingOnOrAbout: _.get(o, `${targetType}data.sailingOnOrAbout`, null),
          assuredsEtc: _.get(o, `${targetType}data.assuredsEtc`, null),
          portOfLoadingName: _.get(o, `${targetType}data.portOfLoadingName`, null),
          finalDestinationName: _.get(o, `${targetType}data.finalDestinationName`, null),
          insuranceCompanyName: _.get(o, `${targetType}data.insuranceCompanyName`, null),
          agentforwarder: _.get(o, `${targetType}data.agentforwarder`, null),
          descriptionOfGoods01: _.get(o, `${targetType}data.descriptionOfGoods01`, null),
          cargoAmountInsured: _.get(o, `${targetType}data.cargoAmountInsured`, null),
          createDate: _.get(o, `${targetType}data.createDate`, null),
          updateUserName: _.get(o, `${targetType}data.updateUserName`, null),
          updateUserIcon: _.get(o, `${targetType}data.updateUserIcon`, null)
        };
      });
    }
  }
};
</script>

<style lang='scss' scoped>
.delete_area {
  margin: 0 16px 0 auto;
}

::v-deep .side_drawer {
  width: auto;
  min-width: 682px;

  &.edit {
    width: 1082px;
  }
}
</style>
