<template>
  <el-input-number
    type="number"
    :controls="false"
    placeholder=""
    v-model="numberModel"
    :content="formatInputNumber(numberModel)"
    class="format"
    :disabled="disabled"
    @keydown.native="handleKeyDown"
    @change="handleChange"
  />
</template>

<script>
import { formatInputNumber } from '@/utils/searchUtil.js';

export default {
  name: 'TwInputFormatNumber',
  props: {
    value: {
      type: Number, // v-model
    },
    maxlength: {
      type: Number,
      default: 16,
    },
    disabled: {
      type: Boolean,
    }
  },
  data() {
    return {
      formatInputNumber,
      el: null,
    };
  },
  computed: {
    numberModel: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
  },
  mounted() {
    this.el = this.$el.querySelector('input');
    this.handleKeyDown();
  },
  methods: {
    handleKeyDown(e) {
      if (e && e.key === '.' && String(e.target.value).includes('.')) {
        e.preventDefault();
        return;
      }
      if (e && e.key === '-' && String(e.target.value).includes('-')) {
        e.preventDefault();
        return;
      }
      let maxlength = this.maxlength;
      if (this.el.value && this.el.value.includes('.')) {
        maxlength++;
      }
      if (this.el.value && this.el.value.includes('-')) {
        maxlength++;
      }
      this.el.setAttribute('maxlength', maxlength);
    },
    handleChange(value) {
      this.$emit('change', value);
    }
  }
};
</script>