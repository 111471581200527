<template>
  <div class="tw_subtask_icon">{{getNumber}}</div>
</template>

<script>

export default {
  name: 'TwSubtaskIcon',
  props: {
    number: {
      type: Number,
    },
  },
  computed: {
    getNumber() {
      return this.number >= 0 ? this.number : 0;
    }
  }
};
</script>

<style lang="scss" scoped>
  .tw_subtask_icon {
    display: inline-flex;
    align-items: center;
    height: 20px;
    font-size: 12px;
    line-height: 18px;
    color: $color_gray_800;
    padding: 0 17px;
    border-radius: 10px;
    background: url(../../assets/images/icons/tabler_subtask.svg) no-repeat 2px center, $color_gray_100 url(../../assets/images/icons/chevron_right_gray600.svg) no-repeat calc(100% - 2px) center;
    background-size: 15px auto, 13px auto;
  }
</style>
