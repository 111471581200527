<template>
  <div>
    <div class="twoColumn">
      <div class="tw_process_group_list_item goods_type">
        <div class="label">Goods Type</div>
        <div class="value">{{goodsType}}</div>
      </div>
    </div>
    <div class="twoColumn goods_line_type_table" v-if="filterHsCode.length && selectGoods.length">
      <template v-for="(item, index) in filterHsCode">
        <tw-process-group-list-preview-goods-line-type-item :key="`goods_line_type_1_${index}`" :item="item" />
      </template>
    </div>
    <div class="twoColumn goods_line_type_table" v-if="filterTableItems.length && selectGoods.length">
      <template v-for="(item, index) in filterTableItems">
        <tw-process-group-list-preview-goods-line-type-item :key="`goods_line_type_2_${index}`" :item="item" />
      </template>
    </div>
    <div class="twoColumn" v-if="filterNotTableItems.length && selectGoods.length">
      <template v-for="(item, index) in filterNotTableItems">
        <tw-process-group-list-preview-goods-line-type-item :key="`goods_line_type_3_${index}`" :item="item" />
      </template>
    </div>
    <div v-if="!selectGoods.length" class="empty_message">This Goods has no Goods Details.</div>
  </div>
</template>

<script>
import _ from 'lodash';
import { ATTR_TYPE, GOODS_ATTR_ID, GOODS_TYPE_VARIABLES } from 'lib-tw-common';
import TwProcessGroupListPreviewGoodsLineTypeItem from '@/components/molecules/TwProcessGroupListPreviewGoodsLineTypeItem';
import ORDER from '@/dictionaries/goodsLineTypeOrder.json';

export default {
  name: 'TwProcessGroupListPreviewGoodsLineType',
  props: {
    value: Object,
    selectGoodsLineSeq: Number,
  },
  inject: ['s'],
  components: {
    TwProcessGroupListPreviewGoodsLineTypeItem,
  },
  data() {
    return {
      customers: [],
      pageSize: 20,
      searchForm: {
        customerId: '',
        customerName: '',
        companyRole: [],
        keyword: '',
      },
      sections: [],
      init: false,
      items: [],
      override: false,
      ATTR_TYPE,
      GOODS_ATTR_ID,
      // 表形式で表示しない項目 NOTE: 一部項目は表外で入力を想定
      notTableItems: [
        'Official Name JP',
        'Official Name EN',
        'Emergency Contact',
        'Emergency Contact Mobile',
        'Remarks',
      ],
    };
  },
  computed: {
    // 選択した商品
    selectGoods() {
      if (!_.get(this.s.res, 'linkageGoodsLineType')) {
        return false;
      }
      const selectGoods = _.filter(this.s.res.linkageGoodsLineType, (goods, index) => {
        goods.index = index;
        return _.get(goods, 'keyGrp.goodsLineSeq') === this.selectGoodsLineSeq;
      });

      // マスタと同様に並べ替え
      const orderedSelectGoods = _.orderBy(selectGoods, goods => {
        const index = _.findIndex(ORDER, order => {
          return goods.goodsTypeMasterList.goodsTypeNm === order;
        });
        return index < 0 ? 1000 : index;
      }, ['asc']);
      return orderedSelectGoods;
    },
    // 表形式対応 項目をフィルタリング
    // HS Code
    filterHsCode() {
      return _.orderBy(_.filter(this.selectGoods, (item) => {
        return item.goodsTypeMasterList.attrId === GOODS_ATTR_ID.HS_CODE;
      }), ['keyGrp.seqNo'], ['asc']);
    },
    // HS Codeと表にしない項目以外
    filterTableItems() {
      return _.filter(this.selectGoods, (item) => {
        return !this.notTableItems.includes(item.goodsTypeMasterList.goodsTypeNm) && item.goodsTypeMasterList.attrId !== GOODS_ATTR_ID.HS_CODE;
      });
    },
    // 表にしない項目
    filterNotTableItems() {
      return _.filter(this.selectGoods, (item) => {
        return this.notTableItems.includes(item.goodsTypeMasterList.goodsTypeNm);
      });
    },
    goodsType() {
      const goods = _.find(this.s.res.linkageGoodsLine, goods => {
        return _.get(goods, 'keyGrp.goodsLineSeq') === this.selectGoodsLineSeq;
      });
      const type = _.get(goods, 'goodsGrp.goodsType');
      return _.get(_.find(GOODS_TYPE_VARIABLES, {code: type}), 'label') || '';
    },
  },
};
</script>

<style lang="scss" scoped>
  .twoColumn {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    padding-left: 16px;
  }

  .empty_message {
    font-weight: 500;
    color: $color_black;
    margin: 32px 16px;
  }

  .tw_process_group_list_item.goods_type {
    width: 50%;
    min-width: 300px;
    display: flex;
    margin: 0;
    padding-right: 0;

    .label {
      width: 118px;
      font-size: 12px;
      line-height: 18px;
      color: #9191A4;
      margin-right: 8px;
      padding: 0;
      background: none;
      border: 0;
      outline: 0;
    }

    .value {
      font-size: 14px;
      line-height: 20px;
      color: #3E3A39;
      flex: 1;
      outline: 0;
      border: 0;
      padding: 0;
    }
  }
</style>
