<template>
  <div>
    <div v-if="selectPackingIndex >= 0" class="twoColumn">
      <tw-process-group-list-preview-goods-line-packing-item :selectGoodsIndex="selectPackingIndex" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import TwProcessGroupListPreviewGoodsLinePackingItem from '@/components/molecules/TwProcessGroupListPreviewGoodsLinePackingItem';

export default {
  name: 'TwProcessGroupListPreviewGoodsLinePacking',
  props: {
    value: Object,
    selectPackingGoodsLineSeq: Number,
    selectPackingLineSeq: Number,
    selectPackingSublineSeq: Number,
  },
  inject: ['s'],
  components: {
    TwProcessGroupListPreviewGoodsLinePackingItem,
  },
  data() {
    return {

    };
  },
  computed: {
    selectPackingIndex() {
      if (!_.get(this.s, 'res.linkageGoodsLinePacking')) {
        return false;
      }
      const selectPackingIndex = _.findIndex(this.s.res.linkageGoodsLinePacking, packing => {
        // console.log(_.get(goods, 'goodsGrp.goodsId'));
        return _.get(packing, 'keyGrp.goodsLineSeq') === this.selectPackingGoodsLineSeq && _.get(packing, 'keyGrp.packingLineSeq') === this.selectPackingLineSeq && _.get(packing, 'keyGrp.packingSublineSeq') === this.selectPackingSublineSeq;
      });
      return selectPackingIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
  .twoColumn {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
  }

  .goods_line_packing ::v-deep .tw_table {
    width: calc(100% - 32px);
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 4px 8px rgba(170, 170, 204, 0.5)!important;
    border-radius: 8px!important;
  }
</style>
