import _ from 'lodash';
import { ATTR_TYPE, UNIT_ROUND_TYPE, CO_SUBMIT_FLG } from 'lib-tw-common';
import store from '@/store';

/**
 * ひな形
 */
export const goodsLinesTemplates = {
  linkageGoodsLine: {
    keyGrp: {
      entityId: null,
      tradingId: null,
      tradingFlowId: null,
      processId: null,
      processSeq: null,
      goodsLineId: null, // ←商品の初回登録時は不要、更新時はレスポンスの値を設定
      goodsLineSeq: null
    },
    goodsGrp: {
      goodsId: null,
      goodsShortName: null,
      goodsNameEn: null,
      productSerialNo: null,
      productLotNo: null,
      productSpec: null,
      countryOfOriginCode: null,
      countryOfOriginName: null
    },
    referenceNoGrp: {
      poNo: null,
      contractNo: null,
      exportInvoiceNo: null,
      bookingNo: null,
      blNo: null,
      commercialInvoiceNo: null,
      importInvoiceNo: null
    },
    quantityGrp: {
      quantity1: null,
      quantity2: null,
      unitId1: null,
      unitId2: null,
      unit1: null,
      unit2: null,
      unitRoundDigit1: null,
      unitRoundType1: UNIT_ROUND_TYPE.ROUNDING,
      unitRoundDigit2: null,
      unitRoundType2: UNIT_ROUND_TYPE.ROUNDING,
    },
    remarksGrp: {
      remarks1: null,
      remarks2: null,
      remarks3: null,
      remarks4: null,
      remarks5: null,
      remarks6: null,
      remarks7: null,
      remarks8: null,
      remarks9: null,
      remarks10: null
    }
  },
  linkageGoodsLineType: {
    keyGrp: {
      goodsLineId: null, // ←商品の初回登録時は不要、更新時はレスポンスの値を設定
      goodsLineSeq: null,
      goodsType: null,
      attrId: null,
      seqNo: null,
      goodsValue: null
    },
    goodsTypeMasterList: {
      // ←更新時は、更新時はレスポンスの値を設定（追加時についてLKI確認中）
      attrId: null,
      goodsTypeNm: null,
      attrType: null,
      goodsTypeLength: null,
      goodsTypeScale: null,
      goodsTypeKbv: null,
      goodsTypePrecision: null,
    }
  },
  linkageGoodsLinePrice: {
    keyGrp: {
      goodsLineId: null, // ←商品の初回登録時は不要、更新時はレスポンスの値を設定
      goodsLineSeq: null
    },
    priceGrp: {
      currencyId: null,
      currency: null,
      price: null,
      unitId: null,
      unit: null
    },
    amountGrp: {
      amount: null
    },
    commercialGrp: {
      noCommercialFlag: null
    },
    remarksGrp: {
      remarks1: null,
      remarks2: null,
      remarks3: null,
      remarks4: null,
      remarks5: null,
      remarks6: null,
      remarks7: null,
      remarks8: null,
      remarks9: null,
      remarks10: null
    }
  },
  linkageGoodsLinePacking: {
    keyGrp: {
      goodsLineId: null, // ←商品の初回登録時は不要、更新時はレスポンスの値を設定
      goodsLineSeq: null,
      packingLineSeq: null,
      packingSublineSeq: null
    },
    netGrossMeasurementGrp: {
      netTotal: null,
      grossTotal: null,
      weightUnitId: null,
      weightUnit: null,
      netLength: null,
      netWidth: null,
      netHeight: null,
      grossLength: null,
      grossWidth: null,
      grossHeight: null,
      lwhUnitId: null,
      lwhUnit: null,
      netMeasurementTotal: null,
      grossMeasurementTotal: null,
      measurementUnitId: null,
      measurementUnit: null,
      cartonNoFrom: null,
      cartonNoTo: null,
      shippingMark1: null,
      shippingMark2: null,
      shippingMark3: null,
      shippingMark4: null,
      shippingMark5: null,
      shippingMark6: null,
      shippingMark7: null,
      shippingMark8: null,
      shippingMark9: null,
      shippingMark10: null,
      numberOfContents: null,
      numberOfPackages: null,
      packingId: null,
      packing: null,
      innerPackingId: null,
      innerPacking: null,
      containerNo: null,
    },
    remarksGrp: {
      remarks1: null,
      remarks2: null,
      remarks3: null,
      remarks4: null,
      remarks5: null,
      remarks6: null,
      remarks7: null,
      remarks8: null,
      remarks9: null,
      remarks10: null
    }
  },
  importGoodsLinePriceSeparate: {
    keyGrp: {
      goodsLineId: null,
      goodsLineSeq: null
    },
    expensesGrp: {
      expenses1Title: null,
      expenses1Amount: null,
      expenses1Currency: null,
      expenses1CurrencyId: null,
      expenses2Title: null,
      expenses2Amount: null,
      expenses2Currency: null,
      expenses2CurrencyId: null,
      expenses3Title: null,
      expenses3Amount: null,
      expenses3Currency: null,
      expenses3CurrencyId: null,
      expenses4Title: null,
      expenses4Amount: null,
      expenses4Currency: null,
      expenses4CurrencyId: null,
      expenses5Title: null,
      expenses5Amount: null,
      expenses5Currency: null,
      expenses5CurrencyId: null,
      expenses6Title: null,
      expenses6Amount: null,
      expenses6Currency: null,
      expenses6CurrencyId: null,
      expenses7Title: null,
      expenses7Amount: null,
      expenses7Currency: null,
      expenses7CurrencyId: null,
      expenses8Title: null,
      expenses8Amount: null,
      expenses8Currency: null,
      expenses8CurrencyId: null,
      expenses9Title: null,
      expenses9Amount: null,
      expenses9Currency: null,
      expenses9CurrencyId: null,
      expenses10Title: null,
      expenses10Amount: null,
      expenses10Currency: null,
      expenses10CurrencyId: null
    },
    remarksGrp: {
      remarks1: null,
      remarks2: null,
      remarks3: null,
      remarks4: null,
      remarks5: null,
      remarks6: null,
      remarks7: null,
      remarks8: null,
      remarks9: null,
      remarks10: null
    }
  },
};

const getGoodsValue = (attrType, attr) => {
  if (attrType === ATTR_TYPE.TYPE_TXT_BOX) {
    return attr.stringVal;
  }
  if (attrType === ATTR_TYPE.TYPE_TXT_AREA) {
    return attr.stringVal;
  }
  if (attrType === ATTR_TYPE.TYPE_INT) {
    return (attr.numVal || attr.numVal === 0) ? '' + attr.numVal : attr.numVal;
  }
  if (attrType === ATTR_TYPE.TYPE_DATE) {
    return attr.dateVal;
  }
  if (attrType === ATTR_TYPE.TYPE_KBV) {
    return attr.kbvVal;
  }
  if (attrType === ATTR_TYPE.TYPE_FILE_PATH) {
    return attr.filepathVal;
  }
  return null;
};

/**
 * 商品情報を生成します
 * @param {Array}   selectGoods 選択した商品マスタデータ
 * @param {Array}   goodsTypeMasterList 商品属性マスタデータ
 * @param {Number}  lastGoodsLineSeq linkageGoodsLineテーブルの最後のgoodsLineSeq
 * @param {String}  entityId エンティティID
 * @param {String}  tradingId 取引ID
 * @param {String}  tradingFlowId フローID
 * @param {String}  processId プロセスID
 * @param {Number}  processSeq プロセスSeq
 */
export const createGoodsLine = ({ selectGoods, goodsTypeMasterList, lastGoodsLineSeq, goodsLineId, entityId, tradingId, tradingFlowId, processId, processSeq }) => {
  lastGoodsLineSeq = lastGoodsLineSeq > 0 ? 0 : lastGoodsLineSeq;
  // マスタ参照しない時
  if (!selectGoods || !selectGoods.length) {
    return _.cloneDeep({
      // 商品明細
      linkageGoodsLine: [
        {
          ...goodsLinesTemplates.linkageGoodsLine,
          keyGrp: {
            entityId: entityId,
            tradingId: tradingId,
            tradingFlowId: tradingFlowId,
            processId: processId,
            processSeq: processSeq,
            goodsLineId: goodsLineId,
            goodsLineSeq: lastGoodsLineSeq - 1
          }
        }
      ],
      // 商品価格
      linkageGoodsLinePrice: [
        {
          ...goodsLinesTemplates.linkageGoodsLinePrice,
          keyGrp: {
            ...goodsLinesTemplates.linkageGoodsLinePrice.keyGrp,
            goodsLineId: goodsLineId,
            goodsLineSeq: lastGoodsLineSeq - 1
          }
        }
      ],
      // 商品梱包
      linkageGoodsLinePacking: [
        {
          ...goodsLinesTemplates.linkageGoodsLinePacking,
          keyGrp: {
            goodsLineId: goodsLineId,
            goodsLineSeq: lastGoodsLineSeq -1,
            packingLineSeq: 1,
            packingSublineSeq: 1,
          }
        }
      ],
      // 輸入商品価格個別
      importGoodsLinePriceSeparate: [
        {
          ...goodsLinesTemplates.importGoodsLinePriceSeparate,
          keyGrp: {
            goodsLineId: goodsLineId,
            goodsLineSeq: lastGoodsLineSeq - 1
          }
        }
      ],
    });
  }

  // 商品マスタから商品を選択した時
  const goodsLines = selectGoods.reduce(
    (ret, goods, index) => {
      // 商品明細
      ret.linkageGoodsLine.push(_.cloneDeep({
        keyGrp: {
          entityId: entityId,
          tradingId: tradingId,
          tradingFlowId: tradingFlowId,
          processId: processId,
          processSeq: processSeq,
          goodsLineId: goodsLineId,
          goodsLineSeq: lastGoodsLineSeq - index - 1
        },
        goodsGrp: {
          ...goodsLinesTemplates.linkageGoodsLine.goodsGrp,
          goodsId: goods.goodsId,
          goodsShortName: goods.goodsShortName,
          goodsNameEn: goods.goodsNameEn,
          productSerialNo: null,
          productLotNo: null,
          productSpec: null,
          countryOfOriginCode: null,
          countryOfOriginName: null,
          goodsType: goods.goodsTypeId,
        },
        referenceNoGrp: goodsLinesTemplates.linkageGoodsLine.referenceNoGrp,
        quantityGrp: {
          ...goodsLinesTemplates.linkageGoodsLine.quantityGrp,
          unitId1: goods.unitId1,
          unitId2: goods.unitId2,
          unit1: _.get(_.find(store.getters.getSysMstUnit, {code: goods.unitId1}), 'value'),
          unit2: _.get(_.find(store.getters.getSysMstUnit, {code: goods.unitId2}), 'value'),
        },
        remarksGrp: goodsLinesTemplates.linkageGoodsLine.remarksGrp,
      }));

      // 商品属性
      const linkageGoodsLineType = _.compact(goods.attrs.map(attr => {
        // attrIdをキーに商品検索BFFのレスポンスのgoodsTypeMasterListから該当属性を検索
        const goodsTypeMaster =
          goodsTypeMasterList.find(master => {
            return goods.goodsTypeId === master.goodsTypeId && attr.attrId === master.attrId;
          }) || {};

        if (_.isEmpty(goodsTypeMaster)) {
          return null;
        }

        return {
          keyGrp: {
            goodsLineId: goodsLineId,
            goodsLineSeq: lastGoodsLineSeq - index - 1,
            goodsType: goods.goodsTypeId,
            attrId: attr.attrId,
            seqNo: parseInt(attr.seqNo),
            goodsValue: getGoodsValue(goodsTypeMaster.attrType, attr),
          },
          goodsTypeMasterList: {
            attrId: goodsTypeMaster.attrId,
            goodsTypeNm: goodsTypeMaster.goodsTypeNm,
            attrType: goodsTypeMaster.attrType,
            goodsTypeLength: goodsTypeMaster.goodsTypeLength,
            goodsTypeScale: goodsTypeMaster.goodsTypeScale,
            goodsTypeKbv: goodsTypeMaster.goodsTypeKbv,
            goodsTypePrecision: goodsTypeMaster.goodsTypePrecision,
          }
        };
      }));

      ret.linkageGoodsLineType = ret.linkageGoodsLineType.concat(_.cloneDeep(linkageGoodsLineType));


      // 商品価格
      ret.linkageGoodsLinePrice.push(_.cloneDeep({
        ...goodsLinesTemplates.linkageGoodsLinePrice,
        keyGrp: {
          ...goodsLinesTemplates.linkageGoodsLinePrice.keyGrp,
          goodsLineId: goodsLineId,
          goodsLineSeq: lastGoodsLineSeq - index - 1,
        }
      }));

      // 商品梱包
      ret.linkageGoodsLinePacking.push(_.cloneDeep({
        keyGrp: {
          goodsLineId: goodsLineId,
          goodsLineSeq: lastGoodsLineSeq - index - 1,
          packingLineSeq: 1,
          packingSublineSeq: 1,
        },
        netGrossMeasurementGrp: {
          ...goodsLinesTemplates.linkageGoodsLinePacking.netGrossMeasurementGrp,
          packingId: goods.packingId,
          packing: _.get(_.find(store.getters.getSysMstPackageType, {code: goods.packingId}), 'value'),
        },
        remarksGrp: goodsLinesTemplates.linkageGoodsLinePacking.remarksGrp,
      }));

      // 輸入商品価格個別
      ret.importGoodsLinePriceSeparate.push(_.cloneDeep({
        ...goodsLinesTemplates.importGoodsLinePriceSeparate,
        keyGrp: {
          goodsLineId: goodsLineId,
          goodsLineSeq: lastGoodsLineSeq - index - 1,
        }
      }));

      return ret;
    },
    {
      linkageGoodsLine: [],
      linkageGoodsLineType: [],
      linkageGoodsLinePrice: [],
      linkageGoodsLinePacking: [],
      importGoodsLinePriceSeparate: [],
    }
  );
  return goodsLines;
};

/**
 * サブフローでの商品情報を生成します
 */
 export const createGoodsLineSubFlow = ({ selectGoods, lastGoodsLineSeq, goodsLineId, entityId, tradingId, tradingFlowId, processId, processSeq }) => {
  lastGoodsLineSeq = lastGoodsLineSeq > 0 ? 0 : lastGoodsLineSeq;
  // サブフローでの商品追加
  // 親フローの商品のみ追加可能
  const goodsLines = selectGoods.reduce(
    (ret, goods, index) => {
      // console.log(goods)
      // 商品明細
      ret.linkageGoodsLine.push(_.cloneDeep({
        ...goods.parentGoodsLine,
        keyGrp: {
          entityId: entityId,
          tradingId: tradingId,
          tradingFlowId: tradingFlowId,
          processId: processId,
          processSeq: processSeq,
          goodsLineId: goods.keyGrp.goodsLineId || goodsLineId, // 親商品のgoodsLineId
          goodsLineSeq: lastGoodsLineSeq - index - 1 // goodsLineSeqはマイナスの値で採番
        },
        goodsGrp: {
          ...goods.goodsGrp,
          prevGoodsLineSeq: goods.goodsGrp.prevGoodsLineSeq || goods.keyGrp.goodsLineSeq, // 分割元取引商品SEQ
          originGoodsLineSeq: goods.goodsGrp.originGoodsLineSeq || goods.keyGrp.goodsLineSeq, // 起源取引商品SEQ
        },
        referenceNoGrp: goods.parentGoodsLine.referenceNoGrp,
        quantityGrp: goods.parentGoodsLine.quantityGrp,
        remarksGrp: goods.parentGoodsLine.remarksGrp,
      }));
      // 商品価格
      if (_.isEmpty(goods.parentGoodsLinePrice)) {
        ret.linkageGoodsLinePrice.push(_.cloneDeep({
          ...goodsLinesTemplates.linkageGoodsLinePrice,
          keyGrp: {
            ...goodsLinesTemplates.linkageGoodsLinePrice.keyGrp,
            goodsLineId: goods.keyGrp.goodsLineId || goodsLineId, // 親商品のgoodsLineId
            goodsLineSeq: lastGoodsLineSeq - index - 1, // goodsLineSeqはマイナスの値で採番
          }
        }));
      } else {
        ret.linkageGoodsLinePrice.push(_.cloneDeep({
          ...goods.parentGoodsLinePrice,
          keyGrp: {
            ...goods.parentGoodsLinePrice.keyGrp,
            goodsLineId: goods.keyGrp.goodsLineId || goodsLineId, // 親商品のgoodsLineId
            goodsLineSeq: lastGoodsLineSeq - index - 1, // goodsLineSeqはマイナスの値で採番
          }
        }));
      }
      // 商品属性
      if (!_.isEmpty(goods.parentGoodsLineType)) {
        const goodsLineType = _.cloneDeep(goods.parentGoodsLineType);
        _.forEach(goodsLineType, item => {
          item.keyGrp.goodsLineSeq = lastGoodsLineSeq - index - 1 // goodsLineSeqはマイナスの値で採番
          ret.linkageGoodsLineType.push(item)
        });
      }
      // 商品梱包
      if (_.isEmpty(goods.parentGoodsLinePacking)) {
        ret.linkageGoodsLinePacking.push(_.cloneDeep({
          ...goodsLinesTemplates.linkageGoodsLinePacking,
          keyGrp: {
            goodsLineId: goods.keyGrp.goodsLineId || goodsLineId, // 親商品のgoodsLineId
            goodsLineSeq: lastGoodsLineSeq - index - 1, // goodsLineSeqはマイナスの値で採番
            packingLineSeq: 1,
            packingSublineSeq: 1,
          }
        }));
      } else {
        ret.linkageGoodsLinePacking.push(_.cloneDeep({
          ...goods.parentGoodsLinePacking,
          keyGrp: {
            goodsLineId: goods.keyGrp.goodsLineId || goodsLineId, // 親商品のgoodsLineId
            goodsLineSeq: lastGoodsLineSeq - index - 1, // goodsLineSeqはマイナスの値で採番
            packingLineSeq: 1,
            packingSublineSeq: 1,
          }
        }));
      }
      // 輸入商品価格個別
      ret.importGoodsLinePriceSeparate.push(_.cloneDeep({
        ...goodsLinesTemplates.importGoodsLinePriceSeparate,
        keyGrp: {
          goodsLineId: goods.keyGrp.goodsLineId || goodsLineId, // 親商品のgoodsLineId
          goodsLineSeq: lastGoodsLineSeq - index - 1,
        }
      }));
      return ret;
    },
    {
      linkageGoodsLine: [],
      linkageGoodsLinePrice: [],
      linkageGoodsLineType: [],
      linkageGoodsLinePacking: [],
      importGoodsLinePriceSeparate: [],
    }
  );
  return goodsLines;
};

// 商品梱包を追加します
export const createGoodsLinePacking = (goodsLineSeq, goodsLineId, packingLineSeq, packingSublineSeq) => {
  return  _.cloneDeep({
    ...goodsLinesTemplates.linkageGoodsLinePacking,
    keyGrp: {
      goodsLineId: goodsLineId,
      goodsLineSeq: goodsLineSeq,
      packingLineSeq: packingLineSeq + 1,
      packingSublineSeq: packingSublineSeq + 1,
    }
  });
};

const commonGoodsDetailsGrpKeys = [
  'detailNo',
  'mainNo',
  'amountReconfirmationType',
  'goodsName',
  'naccsCode',
  'quantity1',
  'quantity1UnitId',
  'quantity2',
  'quantity2UnitId',
  'tariffExemptionCode',
  'tariffExemptionTermLaw',
  'tariffExemptionTermLawClause',
  'tariffExemptionTermRegulationsClause',
];

// 商品個別のデータを生成します
export const createGoodsLineSeparate = ({ entityId, processId, processSeq, goods }) => {
  return _.reduce(goods, (ret, o, index) => {
    const keyGrp = {
      entityId: entityId,
      processId: processId,
      processSeq: processSeq,
      goodsLineSeparateSeq: index + 1,
    };
    const commonGoodsDetailsGrp = _.pick(o, commonGoodsDetailsGrpKeys);

    if (processId === 'ECPERMIT') {
      // 輸出通関許可書保管
      const exportGoodsDetailsGrp = _.omit(o, [...commonGoodsDetailsGrpKeys, 'deleteFlag', '']);
      ret.push({
        keyGrp: keyGrp,
        commonGoodsDetailsGrp: commonGoodsDetailsGrp,
        exportGoodsDetailsGrp: exportGoodsDetailsGrp,
      });
      return ret;
    } else {
      // 輸入通関許可書保管
      const importGoodsDetailsGrp = _.omit(o, [...commonGoodsDetailsGrpKeys, 'deleteFlag', '']);
      ret.push({
        keyGrp: keyGrp,
        commonGoodsDetailsGrp: commonGoodsDetailsGrp,
        importGoodsDetailsGrp: importGoodsDetailsGrp,
      });
      return ret;
    }
  }, []);
};

// 新しい空の商品属性データを返却します
export const createGoodsLineType = ({goodsTypeId, goodsTypeMasterList, keyGrp}) => {
  const goodsTypeMaster = _.filter(goodsTypeMasterList, {goodsTypeId: goodsTypeId});
  return _.map(goodsTypeMaster, o => {
    return {
      keyGrp: {
        goodsLineId: keyGrp.goodsLineId,
        goodsLineSeq: keyGrp.goodsLineSeq,
        goodsType: o.goodsTypeId,
        attrId: o.attrId,
        seqNo: 1,
        goodsValue: null,
      },
      goodsTypeMasterList: {
        attrId: o.attrId,
        goodsTypeNm: o.goodsTypeNm,
        attrType: o.attrType,
        goodsTypeLength: o.goodsTypeLength,
        goodsTypeScale: o.goodsTypeScale,
        goodsTypeKbv: o.goodsTypeKbv,
        goodsTypePrecision: o.goodsTypePrecision,
      }
    }
  });
};

export const createHsCode = obj => {
  return _.cloneDeep({
    keyGrp: {
      ...obj.keyGrp,
      seqNo: obj.keyGrp.seqNo + 1,
      goodsValue: null,
    },
    goodsTypeMasterList: {
      ...obj.goodsTypeMasterList
    }
  });
}

export const createGoodsLineCo = (goodsLine, tradeManagement, approvedGoodsLineSeq) => {
  return _.map(goodsLine, goods => {
    return {
      keyGrp: {
        goodsLineId: goods.keyGrp.goodsLineId,
        goodsLineSeq: goods.keyGrp.goodsLineSeq,
        tradingId: tradeManagement.tradingId,
        tradingFlowId: tradeManagement.tradingFlowId,
        entityId: tradeManagement.entityId,
        processId: tradeManagement.processId,
        processSeq: tradeManagement.processSeq,
      },
      certificateOfOriginGrp: {
        coSubmitFlg: approvedGoodsLineSeq.includes(goods.keyGrp.goodsLineSeq) ? CO_SUBMIT_FLG.FALSE : CO_SUBMIT_FLG.TRUE,
      },
      goodsGrp: {
        goodsId: goods.goodsGrp.goodsId,
        goodsShortName: goods.goodsGrp.goodsShortName,
        countryOfOriginCode: goods.goodsGrp.countryOfOriginCode,
        countryOfOriginName: goods.goodsGrp.countryOfOriginName,
      },
      quantityGrp: {
        quantity1: goods.quantityGrp.quantity1,
        unitId1: goods.quantityGrp.unitId1,
        unit1: goods.quantityGrp.unit1,
        unitRoundType1: goods.quantityGrp.unitRoundType1
      },
      remarksGrp: {
        remarks1: goods.remarksGrp.remarks1,
        remarks2: goods.remarksGrp.remarks2,
        remarks3: goods.remarksGrp.remarks3,
        remarks4: goods.remarksGrp.remarks4,
        remarks5: goods.remarksGrp.remarks5,
        remarks6: goods.remarksGrp.remarks6,
        remarks7: goods.remarksGrp.remarks7,
        remarks8: goods.remarksGrp.remarks8,
        remarks9: goods.remarksGrp.remarks9,
        remarks10: goods.remarksGrp.remarks10
      }
    }
  });
};
