import { LATEST_FLG } from 'lib-tw-common';

export default {
  data() {
    return {
      isShowInvalidTooltip: false,
      mouse: {
        left: 0,
        top: 0
      }
    };
  },
  methods: {
    // カーソルに追従するツールチップのX/Yを計算
    // Todo: mixinファイルをutilsディレクトリから分ける
    cellMouseEnter(row, column, cell, event) {
      document.addEventListener('mousemove', this.onCellMouseMove);

      this.onCellMouseMove(event);

      if (row.latestFlg === LATEST_FLG.OFF) this.isShowInvalidTooltip = true;
    },

    cellMouseLeave() {
      document.removeEventListener('mousemove', this.onCellMouseMove);

      this.isShowInvalidTooltip = false;
    },

    onCellMouseMove(event) {
      this.mouse.left = event.pageX + 16;
      this.mouse.top = event.pageY + 16;
    }
  }
};
