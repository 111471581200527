<template>
  <div class="add_goods_sub">
    <div style="min-height: 388px; margin-top: 32px">
      <tw-table-system :height="360" :schemas="schemas" :items="items" :selection="selection" @selection-change="selectionChange" :rowKey="'goodsId'" :nopager="true" />
    </div>
    <div style="display: flex;justify-content: flex-end;padding-top: 12px">
      <tw-button type="primary" :disabled="!selectGoods.length"  @click="register">OK</tw-button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import TwButton from '@/components/atoms/TwButton';
import TwTableSystem from '@/components/organisms/TwTableSystem';
import schemas from '@/dictionaries/addGoodsSubFlowSchema.json';

export default {
  name: 'TwAddGoodsSubFlow',
  components: {
    TwButton,
    TwTableSystem,
  },
  props: {
    dialogAddGoodsVisible: Boolean,
    isSubFlow: Boolean,
    linkageGoodsLine: Array,
    parentGoodsLine: Array,
    parentGoodsLineType: Array,
    parentGoodsLinePrice: Array,
    parentGoodsLinePacking: Array,
  },
  data() {
    return {
      items: [],
      schemas: schemas,
      pageSize: 20,
      selection: true,
      sections: [],
      goodsTypeNms: [],
      selectGoods: [],
      isSearch: false,
      goodsTypeMasterList: [],
    };
  },
  created() {
    // サブフローの場合は親フローが持っている商品のみ追加可能
    _.forEach(this.parentGoodsLine, item => {
      const goodsLine = _.find(this.linkageGoodsLine, o => {
        return o.goodsGrp.prevGoodsLineSeq === item.goodsGrp.prevGoodsLineSeq
      })
      // 既に追加済の商品の場合はreturn
      if (goodsLine) return

      // 追加可能な商品情報
      const goods = {
        ...item.goodsGrp,
        keyGrp: item.keyGrp,
        goodsGrp: item.goodsGrp,
        parentGoodsLine: item,
        parentGoodsLineType: _.filter(this.parentGoodsLineType, o => {
          return o.keyGrp.goodsLineSeq === item.keyGrp.goodsLineSeq
        }) || [],
        parentGoodsLinePrice: _.find(this.parentGoodsLinePrice, o => {
          return o.keyGrp.goodsLineSeq === item.keyGrp.goodsLineSeq
        }) || {},
        parentGoodsLinePacking: _.find(this.parentGoodsLinePacking, o => {
          return o.keyGrp.goodsLineSeq === item.keyGrp.goodsLineSeq
        }) || {},
      }
      this.items.push(goods)
    });
    // console.log(this.items)
  },
  methods: {
    selectionChange(multipleSelection) {
      this.selectGoods = multipleSelection;
    },
    register() {
      this.$emit('add-goods-sub', this.selectGoods);
    },
  }
};
</script>

<style lang="scss">
</style>
