<template>
  <div>
    <div v-show="(selectGoodsLineSeq || selectGoodsLineSeq === 0)" class="twoColumn">
      <template v-for="(line, index) in localValue.linkageGoodsLine">
        <template v-for="group in linkageGoodsLine">
          <tw-process-group-list-edit-goods-line-item
            v-show="index === goodsLineIndex"
            :key="`${group.variableName}_${index}`"
            style="margin-bottom: 0"
            :item="group.children[0]"
            :selectGoodsIndex="index"
            :tableName="'linkageGoodsLine'"
            :groupName="group.variableName"
            :parentGroup="group"
            :index="index"
            v-model="localValue"
          />
        </template>
      </template>
    </div>
    <div class="twoColumn goods_line_type_table" v-if="filterHsCode.length" v-show="selectGoods.length">
      <template v-for="(item, index) in filterHsCode">
        <tw-process-group-list-edit-goods-line-type-item v-show="isSelect(item)" :item="item" :key="`goods_line_type_edit_1_${index}`" :selectGoodsLineSeq="selectGoodsLineSeq" :isHsCode="true" v-model="localValue" />
      </template>
    </div>
    <div class="twoColumn goods_line_type_table" v-if="filterTableItems.length" v-show="selectGoods.length">
      <template v-for="(item, index) in filterTableItems">
        <tw-process-group-list-edit-goods-line-type-item v-show="isSelect(item)" :item="item" :key="`goods_line_type_edit_2_${index}`" :selectGoodsLineSeq="selectGoodsLineSeq" v-model="localValue" />
      </template>
    </div>
    <div class="twoColumn" v-if="filterNotTableItems.length" v-show="selectGoods.length">
      <template v-for="(item, index) in filterNotTableItems">
        <tw-process-group-list-edit-goods-line-type-item v-show="isSelect(item)" :item="item" :key="`goods_line_type_edit_3_${index}`" :selectGoodsLineSeq="selectGoodsLineSeq" v-model="localValue" />
      </template>
    </div>
    <div v-if="(selectGoodsLineSeq || selectGoodsLineSeq === 0) && !selectGoods.length" class="empty_message">This Goods has no Goods Details.</div>
  </div>
</template>

<script>
import _ from 'lodash';
import { ATTR_TYPE, GOODS_ATTR_ID } from 'lib-tw-common';
import TwProcessGroupListEditGoodsLineTypeItem from '@/components/molecules/TwProcessGroupListEditGoodsLineTypeItem';
import TwProcessGroupListEditGoodsLineItem from '@/components/molecules/TwProcessGroupListEditGoodsLineItem';
import ORDER from '@/dictionaries/goodsLineTypeOrder.json';

export default {
  name: 'TwProcessGroupListEditGoodsLineType',
  props: {
    value: Object,
    selectGoodsLineSeq: Number,
  },
  inject: ['s'],
  components: {
    TwProcessGroupListEditGoodsLineTypeItem,
    TwProcessGroupListEditGoodsLineItem,
  },
  data() {
    return {
      customers: [],
      pageSize: 20,
      searchForm: {
        customerId: '',
        customerName: '',
        companyRole: [],
        keyword: '',
      },
      sections: [],
      init: false,
      items: [],
      override: false,
      ATTR_TYPE,
      GOODS_ATTR_ID,
      // 表形式で表示しない項目 NOTE: 一部項目は表外で入力を想定
      notTableItems: [
        'Official Name JP',
        'Official Name EN',
        'Emergency Contact',
        'Emergency Contact Mobile',
        'Remarks',
      ],
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    linkageGoodsLineType() {
      const linkageGoodsLine = _.find(this.s.schemas.tables, {variableName: 'linkageGoodsLineType'});
      if (!linkageGoodsLine) {
        return [];
      }
      const ret = [
        _.find(linkageGoodsLine.groups, {variableName: 'goodsTypeMasterList'}),
      ];
      return ret;
    },
    linkageGoodsLine() {
      const linkageGoodsLine = _.find(this.s.schemas.tables, {variableName: 'linkageGoodsLine'});
      if (!linkageGoodsLine) {
        return [];
      }
      let ret = [
        _.find(linkageGoodsLine.groups, {variableName: 'goodsGrp'}),
      ];
      ret[0] = {
        ...ret[0],
        children: _.filter(ret[0].children, o => {
          return o.key === 'goodsType';
        }),
      };
      return ret;
    },
    // 選択した商品
    selectGoods() {
      if (!_.get(this, 'localValue.linkageGoodsLineType')) {
        return false;
      }
      return _.filter(this.localValue.linkageGoodsLineType, (goods) => {
        return _.get(goods, 'keyGrp.goodsLineSeq') === this.selectGoodsLineSeq;
      });
    },
    // 並び変えた商品属性（すべて）
    orderedGoods() {
      if (!_.get(this, 'localValue.linkageGoodsLineType')) {
        return false;
      }
      const goods = _.map(this.localValue.linkageGoodsLineType, (goods, index) => {
        goods.index = index;
        return goods;
      });

      // マスタと同様に並べ替え
      const orderedGoods = _.orderBy(goods, goods => {
        const index = _.findIndex(ORDER, order => {
          return goods.goodsTypeMasterList.goodsTypeNm === order;
        });
        return index < 0 ? 1000 : index;
      }, ['asc']);
      return orderedGoods;
    },
    isSelect() {
      return item => {
        return _.get(item, 'keyGrp.goodsLineSeq') === this.selectGoodsLineSeq;
      }
    },
    // 表形式対応 項目をフィルタリング
    // HS Code seqNoで並べ替える
    filterHsCode() {
      return _.orderBy(_.filter(this.orderedGoods, (item) => {
        return item.goodsTypeMasterList.attrId === GOODS_ATTR_ID.HS_CODE;
      }), ['keyGrp.seqNo'], ['asc']);
    },
    // HS Codeと表にしない項目以外
    filterTableItems() {
      return _.filter(this.orderedGoods, (item) => {
        return !this.notTableItems.includes(item.goodsTypeMasterList.goodsTypeNm) && item.goodsTypeMasterList.attrId !== GOODS_ATTR_ID.HS_CODE;
      });
    },
    // 表にしない項目
    filterNotTableItems() {
      return _.filter(this.orderedGoods, (item) => {
        return this.notTableItems.includes(item.goodsTypeMasterList.goodsTypeNm);
      });
    },
    goodsLineIndex() {
      return _.findIndex(this.localValue.linkageGoodsLine, goods => {
        return _.get(goods, 'keyGrp.goodsLineSeq') === this.selectGoodsLineSeq;
      });
    },
  },
  watch: {

  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
  .twoColumn {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    padding-left: 16px;
  }

  .empty_message {
    font-weight: 500;
    color: $color_black;
    margin: 32px 16px;
  }
</style>
