<template>
  <tbody @click="$emit('click')" :class="{selected: selected}">
    <tr :class="{single: !processSchemas.length}">
      <td v-for="column in schemas" :key="column.label" :style="{width: column.width + 'px' || 'auto', maxWidth: column.width + 'px' || 'auto',textAlign: column.align}">
        <tw-overflow-tooltip v-if="row[column.key]" :content="row[column.key]" />
      </td>
    </tr>
    <tr v-if="processSchemas.length">
      <td class="goods_line_table_inner" :colspan="schemas.length" cellspacing="0" cellpadding="0">
        <!-- <img class="close_times hidden" src="@/assets/images/icons/times.svg" @click.stop.prevent="$emit('delete-goods')"> -->
        <table style="width: 100%" cellspacing="0" cellpadding="0" border="0">
          <tr>
            <th v-for="column in processSchemas" :key="column.label" :style="{width: column.width + 'px' || 'auto'}">{{column.label}}</th>
          </tr>
          <tr>
            <td v-for="column in processSchemas" :key="column.label" :style="{width: column.width + 'px' || 'auto', maxWidth: column.width + 'px' || 'auto', textAlign: column.align}">
              <tw-overflow-tooltip v-if="getValue(row, column)" :content="getValue(row, column)" />
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </tbody>
</template>

<script>
import TwOverflowTooltip from '@/components/atoms/TwOverflowTooltip';

export default {
  name: 'TwProcessGroupListPreviewGoodsLineTable',
  props: {
    selected: Boolean,
    row: Object,
    schemas: Array,
    processSchemas: Array,
  },
  components: {
    TwOverflowTooltip,
  },
  computed: {
    getValue() {
      return (row, column) => {
        return row[column.key];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  tbody:hover {
    background-color: $color_gray_background;

    td.goods_line_table_inner img.close_times {
      opacity: 1;
    }
  }

  tbody.selected {
    background-color: $color_selected_row;
  }

  tbody.invalid td:not(.goods_line_table_inner) {
    background-color: rgba(224, 0, 1, 0.07);
  }

  th {
    text-align: center;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    word-break: break-word;
    color: #5E5873;
    padding: 4px 5px;
    border: 1px solid $color_gray_300;
    background: $color_dark_blue;
    color: white;
    // font-weight: bold;
  }

  td {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    padding: 0;
    border: 1px solid $color_gray_300;
    border-bottom: 0;
    height: 30px;
    vertical-align: middle;
    padding: 0 5px;
    font-size: 12px;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }

  tr.single td {
    border-bottom: 1px solid $color_gray_400!important;
  }

  td.goods_line_table_inner {
    position: relative;
    padding: 0;
    border-bottom: 1px solid $color_gray_400!important;
    // border-top: 1px solid #666;

    img.close_times {
      position: absolute; 
      right: 4px;
      top: 50%;
      margin-top: -10px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      opacity: 0;
    }

    table  {
      table-layout: fixed;
      min-width: 100%;
      border: 0;
      border-collapse: collapse;

      th {
        border-top: 0!important;
        border-bottom: 0;
        padding: 0 5px;
        line-height: 0;
        height: 0;
        overflow: hidden;
        background: transparent;
      }

      td {
        border-top: 0!important;
        border-bottom: 0!important;
        padding: 0 5px;
      }

      tr th:first-child, tr td:first-child {
        border-left: 0!important;
      }

      tr th:last-child, tr td:last-child {
        border-right: 0!important;
      }
    }
  }

  
</style>