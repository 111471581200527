<template>
  <div v-if="visible && !exclude" ref="table" class="tw_process_list" :class="{open: fixedOpen || open, has_error: tableHasError}">
    <tr class="inner" @click="toggle">
      <td class="arrow">
        <img src="@/assets/images/icons/accordion_arrow.svg">
      </td>
      <td class="table_name">
        {{table.name}}
      </td>
      <td class="subtask">
        <div v-if="!table.variableName.includes('GoodsLine') && !table.variableName.includes('goodsLineSeparate') && !subtaskHide"><tw-subtask-icon :number="groupCount" /></div>
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <transition
          name="content"
          @enter="enter"
          @after-enter="afterEnter"
          @leave="leave"
          @after-leave="afterLeave"
        >
          <div v-show="fixedOpen || open" class="collapse">
            <!-- 商品明細 -->
            <template v-if="table.variableName === 'linkageGoodsLine'">

              <div v-if="isEditMode && canAddGoods()" style="display: flex;justify-content: flex-end;padding-top: 24px;">
                <tw-button v-if="isSubFlow" type="secondary" size="medium" icon="plus" @click="split" :disabled="!selectGoodsLineSeq">Split</tw-button>
                <tw-button v-if="!isSubFlow" type="secondary" size="medium" icon="plus" @click="newGoods">Free</tw-button>
                <tw-button type="secondary" size="medium" icon="plus" @click="showGoodsDialog">Select</tw-button>
              </div>

              <!-- 編集 -->
              <template v-if="isEditMode && localValue.linkageGoodsLine.length">
                <table class="goods_table fixed_header" :style="{width: scroller1Width, minWidth: scroller1Width}" cellspacing="0" cellpadding="0">
                  <thead>
                    <tr>
                      <th v-for="(column, index) in goodsLineTable" :key="index" :style="{width: column.width + 'px' || 'auto'}">{{column.label}}</th>
                    </tr>
                    <tr>
                      <td class="goods_line_table_inner" :colspan="goodsLineTable.length">
                        <table style="width: 100%" cellspacing="0" cellpadding="0" border="0">
                          <tr>
                            <th v-for="(column, index) in goodsLineTable2" :key="index" :style="{width: column.width + 'px' || 'auto'}">{{column.label}}</th>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </thead>
                </table>
                <div class="table_scroller edit" ref="scroller1">
                  <table class="goods_table" style="margin-top:0" cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th v-for="(column, index) in goodsLineTable" :key="index">{{column.label}}</th>
                      </tr>
                      <tr>
                        <td class="goods_line_table_inner" :colspan="goodsLineTable.length">
                          <table style="width: 100%" cellspacing="0" cellpadding="0" border="0">
                            <tr>
                              <th v-for="column in goodsLineTable2" :key="column.label" :style="{width: column.width + 'px' || 'auto'}">{{column.label}}</th>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </thead>
                    <template v-for="(line, index) in localValue.linkageGoodsLine">
                      <tw-process-group-list-edit-goods-line-table :index="index" :selectGoodsLineSeq="line.keyGrp.goodsLineSeq" :selected="line.keyGrp.goodsLineSeq === selectGoodsLineSeq" :hasError="hasError(['linkageGoodsLine', 'linkageGoodsLinePrice'])" v-model="localValue" @click="selectRow(index)" @delete-goods="deleteGoodsClick(line.keyGrp.goodsLineSeq)" />
                    </template>
                  </table>
                </div>
              </template>

              <!-- 閲覧 -->
              <template v-if="!isEditMode && s.res.linkageGoodsLine && s.res.linkageGoodsLine.length">
                <table class="goods_table fixed_header" :style="{width: scroller1Width, minWidth: scroller1Width}" cellspacing="0" cellpadding="0">
                  <thead>
                    <tr>
                      <th v-for="column in goodsLinePreviewTable" :key="column.label" style="padding: 4px 0" :style="{width: column.width + 'px' || 'auto'}">
                        <div style="padding: 0 5px">{{column.label}}</div>
                      </th>
                    </tr>
                    <tr v-if="goodsLinePreviewTable2.length">
                      <td class="goods_line_table_inner" :colspan="goodsLinePreviewTable.length">
                        <table style="width: 100%" cellspacing="0" cellpadding="0" border="0">
                          <tr>
                            <th v-for="column in goodsLinePreviewTable2" :key="column.label" :style="{width: column.width + 'px' || 'auto'}">
                              {{column.label}}
                            </th>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </thead>
                </table>
                <div class="table_scroller" ref="scroller1">
                  <table class="goods_table" style="margin-top: 0" cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th v-for="(column, index) in goodsLinePreviewTable" :key="index" style="padding: 4px 0" :style="{width: column.width + 'px' || 'auto'}">
                          <div style="padding: 0 5px">{{column.label}}</div>
                        </th>
                      </tr>
                      <tr v-if="goodsLinePreviewTable2.length">
                        <td class="goods_line_table_inner" :colspan="goodsLinePreviewTable.length">
                          <table style="width: 100%" cellspacing="0" cellpadding="0" border="0">
                            <tr>
                              <th v-for="(column, index) in goodsLinePreviewTable2" :key="index" :style="{width: column.width + 'px' || 'auto'}">
                                {{column.label}}
                              </th>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </thead>
                    <template v-for="(row, index) in s.goodsLineData">
                      <tw-process-group-list-preview-goods-line-table :row="row" :selected="row.goodsLineSeq === selectGoodsLineSeq" :schemas="goodsLinePreviewTable" :processSchemas="goodsLinePreviewTable2" @click="selectRow(index)" />
                    </template>
                  </table>
                </div>
              </template>

              <!-- amountGrp -->
              <tw-process-group-list v-for="group in table.extend" :tableName="table.extendTableName" :group="group" :key="group.variableName" :isEditMode="isEditMode" v-model="localValue" />

              <div v-if="selectGoodsLineSeq" class="table_name full" style="border: 0;padding-top: 24px;margin-bottom: 32px">Goods Line</div>
              <tw-process-group-list-edit-goods-line-price  v-if="selectGoodsLineSeq && isEditMode" :selectGoodsLineSeq="selectGoodsLineSeq" v-model="localValue" />
              <tw-process-group-list-preview-goods-line-price-item  v-if="selectGoodsLineSeq && !isEditMode" :selectGoodsLineSeq="selectGoodsLineSeq" />

              <!-- 商品属性 -->
              <div v-if="selectGoodsLineSeq" class="table_name full">Goods Line Type</div>
              <tw-process-group-list-edit-goods-line-type v-if="isEditMode" :selectGoodsLineSeq="selectGoodsLineSeq" v-model="localValue" />
              <tw-process-group-list-preview-goods-line-type v-if="selectGoodsLineSeq && !isEditMode" :selectGoodsLineSeq="selectGoodsLineSeq" />
            </template>

            <!-- 商品梱包 -->
            <template v-else-if="table.variableName === 'linkageGoodsLinePacking'">
              <div v-if="isEditMode" style="display: flex;justify-content: flex-end;padding-top: 24px;">
                <tw-button type="secondary" size="medium" icon="plus" @click="addPacking" :disabled="cannotAddPacking">Packing</tw-button>
              </div>

              <!-- 編集 -->
              <template v-if="isEditMode && localValue.linkageGoodsLinePacking.length">
                <table class="goods_table fixed_header" :style="{width: scroller2Width, minWidth: scroller2Width}" cellspacing="0" cellpadding="0">
                  <thead>
                    <tr>
                      <th v-for="(column, index) in goodsLinePackingTableSchema" :key="index" :style="{width: column.width + 'px' || 'auto'}" :class="{required: column.required && s.processType === PROCESS_TYPE.TPRBL}">{{column.label}}</th>
                    </tr>
                    <tr>
                      <td class="goods_line_table_inner" :colspan="goodsLinePackingTableSchema.length">
                        <table style="width: 100%" cellspacing="0" cellpadding="0" border="0">
                          <tr>
                            <th v-for="(column, index) in goodsLinePackingTableSchema2" :key="index" :style="{width: column.width + 'px' || 'auto'}">{{column.label}}</th>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </thead>
                </table>
                <div class="table_scroller edit" ref="scroller2">
                  <table class="goods_table" style="margin-top:0" cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th v-for="column in goodsLinePackingTableSchema" :key="column.label">{{column.label}}</th>
                      </tr>
                      <tr>
                        <td class="goods_line_table_inner" :colspan="goodsLinePackingTableSchema.length">
                          <table style="width: 100%" cellspacing="0" cellpadding="0" border="0">
                            <tr>
                              <th v-for="(column, index) in goodsLinePackingTableSchema2" :key="index" :style="{width: column.width + 'px' || 'auto'}">{{column.label}}</th>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </thead>
                    <template v-for="(line, index) in orderedGoodsLinePacking">
                      <tw-process-group-list-edit-goods-line-packing-table :index="index" :line="line" :selectGoodsLineSeq="line.keyGrp.goodsLineSeq" :selected="isEqualPacking(line)" :hasError="hasError(['linkageGoodsLinePacking'])" v-model="localValue" @click="selectPacking(line)" @delete-goods="removePacking(line)" />
                    </template>
                  </table>
                </div>
              </template>

              <!-- 閲覧 -->
              <template v-if="!isEditMode && s.res.linkageGoodsLinePacking && s.res.linkageGoodsLinePacking.length">
                <table  class="goods_table fixed_header" :style="{width: scroller2Width, minWidth: scroller2Width}" cellspacing="0" cellpadding="0">
                  <thead>
                    <tr>
                      <th v-for="(column, index) in goodsLinePreviewPackingTableSchema" :key="index" style="padding: 4px 0" :style="{width: column.width + 'px' || 'auto'}">
                        <div style="padding: 0 5px">{{column.label}}</div>
                      </th>
                    </tr>
                    <tr>
                      <td class="goods_line_table_inner" :colspan="goodsLinePreviewPackingTableSchema.length">
                        <table style="width: 100%" cellspacing="0" cellpadding="0" border="0">
                          <tr>
                            <th v-for="(column, index) in goodsLinePreviewPackingTableSchema2" :key="index" :style="{width: column.width + 'px' || 'auto'}">
                              {{column.label}}
                            </th>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </thead>
                </table>
                <div class="table_scroller" ref="scroller2">
                  <table class="goods_table" style="margin-top: 0" cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th v-for="column in goodsLinePreviewPackingTableSchema" :key="column.label" style="padding: 4px 0" :style="{width: column.width + 'px' || 'auto'}">
                          <div style="padding: 0 5px">{{column.label}}</div>
                        </th>
                      </tr>
                      <tr>
                        <td class="goods_line_table_inner" :colspan="goodsLinePreviewPackingTableSchema.length">
                          <table style="width: 100%" cellspacing="0" cellpadding="0" border="0">
                            <tr>
                              <th v-for="(column, index) in goodsLinePreviewPackingTableSchema2" :key="index" :style="{width: column.width + 'px' || 'auto'}">
                                {{column.label}}
                              </th>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </thead>
                    <template v-for="row in s.goodsLinePackingItems">
                      <tw-process-group-list-preview-goods-line-table :row="row" :selected="isEqualPacking(row)" :schemas="goodsLinePreviewPackingTableSchema" :processSchemas="goodsLinePreviewPackingTableSchema2" @click="selectPacking(row)" />
                    </template>
                  </table>
                </div>
              </template>

              <tw-process-group-list-edit-goods-line-packing v-if="isEditMode" :orderedGoodsLinePacking="orderedGoodsLinePacking" :selectPackingGoodsLineSeq="selectPackingGoodsLineSeq" :selectPackingLineSeq="selectPackingLineSeq" :selectPackingSublineSeq="selectPackingSublineSeq" v-model="localValue" />
              <tw-process-group-list-preview-goods-line-packing v-else-if="selectPackingGoodsLineSeq" :selectPackingGoodsLineSeq="selectPackingGoodsLineSeq" :selectPackingLineSeq="selectPackingLineSeq" :selectPackingSublineSeq="selectPackingSublineSeq" />
            </template>

            <template v-else-if="table.variableName === 'goodsLineSeparate'">
              <!-- ドロワー -->
              <template v-if="isEditMode && localValue.goodsLineSeparate.goods && localValue.goodsLineSeparate.goods.length">
                <table class="goods_table fixed_header" :style="{width: scroller1Width, minWidth: scroller1Width}" cellspacing="0" cellpadding="0">
                  <thead>
                    <tr>
                      <th v-for="column in goodsLinePreviewSeparateTable" :key="column.label" style="padding: 4px 0" :style="{width: column.width + 'px' || 'auto'}">
                        <div style="padding: 0 5px">{{column.label}}</div>
                      </th>
                    </tr>
                    <tr v-if="goodsLinePreviewSeparateTable2.length">
                      <td class="goods_line_table_inner" :colspan="goodsLinePreviewSeparateTable.length">
                        <table style="width: 100%" cellspacing="0" cellpadding="0" border="0">
                          <tr>
                            <th v-for="column in goodsLinePreviewSeparateTable2" :key="column.label" :style="{width: column.width + 'px' || 'auto'}">
                              {{column.label}}
                            </th>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </thead>
                </table>
                <div class="table_scroller" ref="scroller1">
                  <table class="goods_table" style="margin-top: 0" cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th v-for="(column, index) in goodsLinePreviewSeparateTable" :key="index" style="padding: 4px 0" :style="{width: column.width + 'px' || 'auto'}">
                          <div style="padding: 0 5px">{{column.label}}</div>
                        </th>
                      </tr>
                      <tr v-if="goodsLinePreviewSeparateTable2.length">
                        <td class="goods_line_table_inner" :colspan="goodsLinePreviewSeparateTable.length">
                          <table style="width: 100%" cellspacing="0" cellpadding="0" border="0">
                            <tr>
                              <th v-for="(column, index) in goodsLinePreviewSeparateTable2" :key="index" :style="{width: column.width + 'px' || 'auto'}">
                                {{column.label}}
                              </th>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </thead>
                    <template v-for="row in s.goodsLineSeparateItems">
                      <tw-process-group-list-preview-goods-line-table :row="row" :selected="row.goodsLineSeparateSeq === selectGoodsLineSeq" :schemas="goodsLinePreviewSeparateTable" :processSchemas="goodsLinePreviewSeparateTable2" @click="selectGoodsLineSeparate(row)" />
                    </template>
                  </table>
                </div>
              </template>

              <!-- 閲覧用 -->
              <template v-if="!isEditMode && s.res.goodsLineSeparate.goods && s.res.goodsLineSeparate.goods.length">
                <table class="goods_table fixed_header" :style="{width: scroller1Width, minWidth: scroller1Width}" cellspacing="0" cellpadding="0">
                  <thead>
                    <tr>
                      <th v-for="column in goodsLinePreviewSeparateTable" :key="column.label" style="padding: 4px 0" :style="{width: column.width + 'px' || 'auto'}">
                        <div style="padding: 0 5px">{{column.label}}</div>
                      </th>
                    </tr>
                    <tr v-if="goodsLinePreviewSeparateTable2.length">
                      <td class="goods_line_table_inner" :colspan="goodsLinePreviewSeparateTable.length">
                        <table style="width: 100%" cellspacing="0" cellpadding="0" border="0">
                          <tr>
                            <th v-for="column in goodsLinePreviewSeparateTable2" :key="column.label" :style="{width: column.width + 'px' || 'auto'}">
                              {{column.label}}
                            </th>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </thead>
                </table>
                <div class="table_scroller" ref="scroller1">
                  <table class="goods_table" style="margin-top: 0" cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th v-for="(column, index) in goodsLinePreviewSeparateTable" :key="index" style="padding: 4px 0" :style="{width: column.width + 'px' || 'auto'}">
                          <div style="padding: 0 5px">{{column.label}}</div>
                        </th>
                      </tr>
                      <tr v-if="goodsLinePreviewSeparateTable2.length">
                        <td class="goods_line_table_inner" :colspan="goodsLinePreviewSeparateTable.length">
                          <table style="width: 100%" cellspacing="0" cellpadding="0" border="0">
                            <tr>
                              <th v-for="(column, index) in goodsLinePreviewSeparateTable2" :key="index" :style="{width: column.width + 'px' || 'auto'}">
                                {{column.label}}
                              </th>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </thead>
                    <template v-for="row in this.s.goodsLineSeparatePreviewItems">
                      <tw-process-group-list-preview-goods-line-table :row="row" :selected="row.goodsLineSeparateSeq === selectGoodsLineSeq" :schemas="goodsLinePreviewSeparateTable" :processSchemas="goodsLinePreviewSeparateTable2" @click="selectGoodsLineSeparate(row)" />
                    </template>
                  </table>
                </div>
              </template>
              <!-- 商品個別表以外の項目 -->
              <tw-process-group-list-preview-goods-line-separate-item  v-if="selectGoodsLineSeq" :isEditMode="isEditMode" :selectGoodsLineSeq="selectGoodsLineSeq" :table="table" />
              <!-- 商品個別Remarks -->
              <tw-process-group-list v-if="getRemarksGrp" :tableName="table.variableName" :group="getRemarksGrp" :isEditMode="isEditMode" v-model="localValue" />
            </template>

            <!-- 海上保険 I/P -->
            <template v-else-if="table.variableName === 'linkageMarineInsurance'">
              <!-- I/P以外の情報群 -->
              <template v-if="getInsuranceGrp && getInsuranceGrp.length">
                <tw-process-group-list v-for="group in getInsuranceGrp" :tableName="table.variableName" :group="group" :isEditMode="isEditMode" :key="group.variableName" v-model="localValue" />
              </template>

              <div class="ip_dn">
                <!-- ドロワー -->
                <template v-if="visibleIp && !requiredGroup && isEditMode && localValue.linkageMarineInsurance.ippools && localValue.linkageMarineInsurance.ippools.length">
                  <table class="goods_table fixed_header" :style="{width: scroller1Width, minWidth: scroller1Width}" cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th v-for="column in ipPreviewTableSchema" :key="column.label" style="padding: 4px 0" :style="{width: column.width + 'px' || 'auto'}">
                          <div style="padding: 0 5px">{{column.label}}</div>
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <div class="table_scroller" ref="scroller1">
                    <table class="goods_table" style="margin-top: 0" cellspacing="0" cellpadding="0">
                      <thead>
                        <tr>
                          <th v-for="(column, index) in ipPreviewTableSchema" :key="index" style="padding: 4px 0" :style="{width: column.width + 'px' || 'auto'}">
                            <div style="padding: 0 5px">{{column.label}}</div>
                          </th>
                        </tr>
                      </thead>
                      <template v-for="(row, index) in s.ipItems">
                        <tw-process-group-list-preview-ip-dn-table :key="index" :row="row" :selected="index === selectIpIndex" :schemas="ipPreviewTableSchema" @click="selectIp(row, index)" />
                      </template>
                    </table>
                  </div>
                </template>

                <!-- 閲覧用 -->
                <template v-if="visibleIp && !requiredGroup && !isEditMode && s.res.linkageMarineInsurance && s.res.linkageMarineInsurance.ippools && s.res.linkageMarineInsurance.ippools.length">
                  <table class="goods_table fixed_header" :style="{width: scroller1Width, minWidth: scroller1Width}" cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th v-for="column in ipPreviewTableSchema" :key="column.label" style="padding: 4px 0" :style="{width: column.width + 'px' || 'auto'}">
                          <div style="padding: 0 5px">{{column.label}}</div>
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <div class="table_scroller" ref="scroller1">
                    <table class="goods_table" style="margin-top: 0" cellspacing="0" cellpadding="0">
                      <thead>
                        <tr>
                          <th v-for="(column, index) in ipPreviewTableSchema" :key="index" style="padding: 4px 0" :style="{width: column.width + 'px' || 'auto'}">
                            <div style="padding: 0 5px">{{column.label}}</div>
                          </th>
                        </tr>
                      </thead>
                      <template v-for="(row, index) in s.ipPreviewItems">
                        <tw-process-group-list-preview-ip-dn-table :key="index" :row="row" :selected="index === selectIpIndex" :schemas="ipPreviewTableSchema" @click="selectIp(row, index)" />
                      </template>
                    </table>
                  </div>
                </template>
                <!-- I/P情報 表以外の項目 -->
                <tw-process-group-list-preview-ip-dn v-if="selectIpIndex || selectIpIndex === 0" :isEditMode="isEditMode" :selectIndex="selectIpIndex" docType="IP" />
              </div>
            </template>

            <!-- 海上保険 D/N -->
            <template v-else-if="table.variableName === 'marineInsuranceSeparate'">
              <!-- D/N以外の情報群 -->
              <template v-if="getDnGrp && getDnGrp.length">
                <tw-process-group-list v-for="group in getDnGrp" :tableName="table.variableName" :group="group" :isEditMode="isEditMode" :key="group.variableName" v-model="localValue" />
              </template>

              <div class="ip_dn">
                <!-- ドロワー -->
                <template v-if="!requiredGroup && isEditMode && localValue.marineInsuranceSeparate.dnpools && localValue.marineInsuranceSeparate.dnpools.length">
                  <table class="goods_table fixed_header" :style="{width: scroller1Width, minWidth: scroller1Width}" cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th v-for="column in dnPreviewTableSchema" :key="column.label" style="padding: 4px 0" :style="{width: column.width + 'px' || 'auto'}">
                          <div style="padding: 0 5px">{{column.label}}</div>
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <div class="table_scroller" ref="scroller1">
                    <table class="goods_table" style="margin-top: 0" cellspacing="0" cellpadding="0">
                      <thead>
                        <tr>
                          <th v-for="(column, index) in dnPreviewTableSchema" :key="index" style="padding: 4px 0" :style="{width: column.width + 'px' || 'auto'}">
                            <div style="padding: 0 5px">{{column.label}}</div>
                          </th>
                        </tr>
                      </thead>
                      <template v-for="(row, index) in s.dnItems">
                        <tw-process-group-list-preview-ip-dn-table :key="index" :row="row" :selected="index === selectDnIndex" :schemas="dnPreviewTableSchema" @click="selectDn(row, index)" />
                      </template>
                    </table>
                  </div>
                </template>

                <!-- 閲覧用 -->
                <template v-if="!requiredGroup && !isEditMode && s.res.marineInsuranceSeparate.dnpools && s.res.marineInsuranceSeparate.dnpools.length">
                  <table class="goods_table fixed_header" :style="{width: scroller1Width, minWidth: scroller1Width}" cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th v-for="column in dnPreviewTableSchema" :key="column.label" style="padding: 4px 0" :style="{width: column.width + 'px' || 'auto'}">
                          <div style="padding: 0 5px">{{column.label}}</div>
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <div class="table_scroller" ref="scroller1">
                    <table class="goods_table" style="margin-top: 0" cellspacing="0" cellpadding="0">
                      <thead>
                        <tr>
                          <th v-for="(column, index) in dnPreviewTableSchema" :key="index" style="padding: 4px 0" :style="{width: column.width + 'px' || 'auto'}">
                            <div style="padding: 0 5px">{{column.label}}</div>
                          </th>
                        </tr>
                      </thead>
                      <template v-for="(row, index) in s.dnPreviewItems">
                        <tw-process-group-list-preview-ip-dn-table :key="index" :row="row" :selected="index === selectDnIndex" :schemas="dnPreviewTableSchema" @click="selectDn(row, index)" />
                      </template>
                    </table>
                  </div>
                </template>
                <!-- D/N情報 表以外の項目 -->
                <tw-process-group-list-preview-ip-dn v-if="selectDnIndex || selectDnIndex === 0" :isEditMode="isEditMode" :selectIndex="selectDnIndex" docType="DN" />
              </div>
            </template>

            <!-- 商品明細 (CO) -->
            <template v-else-if="table.variableName === 'linkageGoodsLineCo'">
              <div v-if="isEditMode && canAddGoodsLineCo" style="display: flex;justify-content: flex-end;padding-top: 24px;">
                <tw-button type="secondary" size="medium" @click="addGoodsLineCo">Set Data</tw-button>
              </div>

              <!-- ドロワー -->
              <template v-if="isEditMode && canAddGoodsLineCo && localValue.linkageGoodsLineCo && localValue.linkageGoodsLineCo.length">
                <table class="goods_table fixed_header" :style="{width: scroller1Width, minWidth: scroller1Width}" cellspacing="0" cellpadding="0">
                  <thead>
                    <tr>
                      <th v-for="column in goodsLineCoTableSchema" :key="column.label" style="padding: 4px 0" :style="{width: column.width + 'px' || 'auto'}">
                        <div style="padding: 0 5px" :class="{required: column.required}">{{column.label}}</div>
                      </th>
                    </tr>
                  </thead>
                </table>
                <div class="table_scroller edit" ref="scroller1" style="max-height: 151px;margin-bottom: 20px">
                  <table class="goods_table" style="margin-top: 0" cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th v-for="(column, index) in goodsLineCoTableSchema" :key="index" style="padding: 4px 0" :style="{width: column.width + 'px' || 'auto'}">
                          <div style="padding: 0 5px" :class="{required: column.required}">{{column.label}}</div>
                        </th>
                      </tr>
                    </thead>
                    <template v-for="(row, index) in localValue.linkageGoodsLineCo">
                      <TwProcessGroupListEditGoodsLineCoTable :key="index" :row="row" :index="index" :selected="index === selectGoodsLineCoIndex" :schemas="goodsLineCoTableSchema" v-model="localValue" @click="selectGoodsLineCo(row, index)" />
                    </template>
                  </table>
                </div>
              </template>
              <!-- 閲覧用 -->
              <template v-if="!isEditMode && s.goodsLineCoPreviewItems.length">
                <table class="goods_table fixed_header" :style="{width: scroller1Width, minWidth: scroller1Width}" cellspacing="0" cellpadding="0">
                  <thead>
                    <tr>
                      <th v-for="column in goodsLineCoPreviewTableSchema" :key="column.label" style="padding: 4px 0" :style="{width: column.width + 'px' || 'auto'}">
                        <div style="padding: 0 5px">{{column.label}}</div>
                      </th>
                    </tr>
                  </thead>
                </table>
                <div class="table_scroller" ref="scroller1" style="max-height: 151px">
                  <table class="goods_table" style="margin-top: 0" cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th v-for="(column, index) in goodsLineCoPreviewTableSchema" :key="index" style="padding: 4px 0" :style="{width: column.width + 'px' || 'auto'}">
                          <div style="padding: 0 5px">{{column.label}}</div>
                        </th>
                      </tr>
                    </thead>
                    <template v-for="(row, index) in s.goodsLineCoPreviewItems">
                      <TwProcessGroupListPreviewGoodsLineCoTable :key="index" :row="row" :selected="index === selectGoodsLineCoIndex" :schemas="goodsLineCoPreviewTableSchema" @click="selectGoodsLineCo(row, index)" />
                    </template>
                  </table>
                </div>
              </template>
              <!-- 商品明細 (CO) 表以外の項目 -->
              <TwProcessGroupListEditGoodsLineCoItem v-if="(selectGoodsLineCoIndex || selectGoodsLineCoIndex === 0) && isEditMode" :selectGoodsLineIndex="selectGoodsLineCoIndex" v-model="localValue" />
              <TwProcessGroupListPreviewGoodsLineCoItem v-if="(selectGoodsLineCoIndex || selectGoodsLineCoIndex === 0) && !isEditMode" :selectGoodsLineIndex="selectGoodsLineCoIndex" />
            </template>

            <template v-else>
              <tw-process-group-list v-for="group in table.groups" :tableName="table.variableName" :group="group" :key="group.variableName" :isEditMode="isEditMode" v-model="localValue" :initialOpen="initialOpen" ref="groupList" />
            </template>
          </div>
        </transition>
      </td>
    </tr>
    <el-dialog :title="$t('Label.Add Goods')" :visible.sync="dialogAddGoodsVisible" :close-on-click-modal="false" class="header_border" width="816px" aria-label="Add Goods" :append-to-body="true">
      <tw-add-goods v-if="dialogAddGoodsVisible && !isSubFlow" @add-goods="addGoods" @update-goods-type-master="updateGoodsTypeMaster" />
      <tw-add-goods-sub-flow v-if="dialogAddGoodsVisible && isSubFlow" @add-goods-sub="addGoodsSub" :isSubFlow="isSubFlow" :linkageGoodsLine="localValue.linkageGoodsLine" :parentGoodsLine="parentGoodsLine" :parentGoodsLineType="parentGoodsLineType" :parentGoodsLinePrice="parentGoodsLinePrice" :parentGoodsLinePacking="parentGoodsLinePacking" />
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { RESPONSIBLE_FLG, TYPE_OF_WORKS, PROCESS_TYPE, MAIN_FLOW_FLG, HAVE_SUB_FLOW_FLG, PAYMENT_TERMS } from 'lib-tw-common';
import TwSubtaskIcon from '@/components/atoms/TwSubtaskIcon.vue';
import TwButton from '@/components/atoms/TwButton';
import TwProcessGroupList from '@/components/molecules/TwProcessGroupList';
import TwProcessGroupListPreviewGoodsLinePriceItem from '@/components/molecules/TwProcessGroupListPreviewGoodsLinePriceItem';
import TwProcessGroupListPreviewGoodsLinePacking from '@/components/molecules/TwProcessGroupListPreviewGoodsLinePacking';
import TwProcessGroupListPreviewGoodsLineType from '@/components/molecules/TwProcessGroupListPreviewGoodsLineType';
import TwProcessGroupListEditGoodsLinePrice from '@/components/molecules/TwProcessGroupListEditGoodsLinePrice';
import TwProcessGroupListEditGoodsLinePacking from '@/components/molecules/TwProcessGroupListEditGoodsLinePacking';
import TwProcessGroupListEditGoodsLineType from '@/components/molecules/TwProcessGroupListEditGoodsLineType';
import TwProcessGroupListEditGoodsLineTable from '@/components/molecules/TwProcessGroupListEditGoodsLineTable';
import TwProcessGroupListEditGoodsLinePackingTable from '@/components/molecules/TwProcessGroupListEditGoodsLinePackingTable';
import TwProcessGroupListPreviewGoodsLineTable from '@/components/molecules/TwProcessGroupListPreviewGoodsLineTable';
import TwProcessGroupListPreviewGoodsLineSeparateItem from '@/components/molecules/TwProcessGroupListPreviewGoodsLineSeparateItem';
import TwAddGoods from '@/components/organisms/TwAddGoods';
import TwAddGoodsSubFlow from '@/components/organisms/TwAddGoodsSubFlow';
import { collapseTransition } from '@/utils/nextFrame';
import { createGoodsLine, createGoodsLinePacking, createGoodsLineSubFlow, createGoodsLineCo } from '@/utils/createGoodsLine';
// import goodsLineSchemas from '@/dictionaries/goodsLineSchema.json';
// import goodsLineTypeSchemas from '@/dictionaries/goodsLineTypeSchema.json';
import goodsLinePackingParentSchemas from '@/dictionaries/goodsLinePackingParentSchema.json';
import goodsLineTableSchema from '@/dictionaries/goodsLineTableSchema.json';
import goodsLineTableSchema2 from '@/dictionaries/goodsLineTableSchema2.json';
import goodsLinePackingTableSchema from '@/dictionaries/goodsLinePackingTableSchema.json';
import goodsLinePackingTableSchema2 from '@/dictionaries/goodsLinePackingTableSchema2.json';
import goodsLinePreviewTableSchema from '@/dictionaries/goodsLinePreviewTableSchema.json';
import goodsLinePreviewTableSchema2 from '@/dictionaries/goodsLinePreviewTableSchema2.json';
import goodsLinePreviewPackingTableSchema from '@/dictionaries/goodsLinePreviewPackingTableSchema.json';
import goodsLinePreviewPackingTableSchema2 from '@/dictionaries/goodsLinePreviewPackingTableSchema2.json';
import ecPermitgoodsLinePreviewTableSchema from '@/dictionaries/ecPermitgoodsLinePreviewTableSchema.json';
import ecPermitgoodsLinePreviewTableSchema2 from '@/dictionaries/ecPermitgoodsLinePreviewTableSchema2.json';
import icPermitgoodsLinePreviewTableSchema from '@/dictionaries/icPermitgoodsLinePreviewTableSchema.json';
import icPermitgoodsLinePreviewTableSchema2 from '@/dictionaries/icPermitgoodsLinePreviewTableSchema2.json';
import transportationControl from '@/dictionaries/transportationControl.json';
import ipPreviewTableSchema from '@/dictionaries/ipPreviewTableSchema.json';
import dnPreviewTableSchema from '@/dictionaries/dnPreviewTableSchema.json';
import goodsLineCoTableSchema from '@/dictionaries/goodsLineCoTableSchema.json';
import goodsLineCoPreviewTableSchema from '@/dictionaries/goodsLineCoPreviewTableSchema.json';
// import TwProcessGroupListPreviewIpDnTable from '@/components/molecules/TwProcessGroupListPreviewIpDnTable';
// import TwProcessGroupListPreviewIpDn from '@/components/molecules/TwProcessGroupListPreviewIpDn';

export default {
  name: 'TwProcessList',
  inject: ['s'],
  provide() {
    return {
      checkTypeOfWorks: this.checkTypeOfWorks,
      checkTransportation: this.checkTransportation,
      checkPaymentTerms: this.checkPaymentTerms,
      tab: this.tab,
    }
  },
  props: {
    table: Object,
    isEditMode: Boolean,
    value: Object,
    isPermitHistory: {
      // 許可書履歴（Customs Permit）ドロワーかどうか
      type: Boolean,
      default: false,
    },
    fixedOpen: {
      // タブ外の必須項目を含む情報群等で、常にopen状態にしておきたい場合true
      type: Boolean,
      default: false,
    },
    initialOpen: {
      type: Boolean,
      default: false,
    },
    requiredGroup: Boolean,
    subtaskHide: Boolean,
    initSelectIp: Boolean,
    initSelectDn: Boolean,
    visibleIp: {
      // I/P情報を表示しないタブはfalseを渡す
      type: Boolean,
      default: true,
    },
    tab: Number,
  },
  components: {
    TwSubtaskIcon,
    TwButton,
    TwProcessGroupList,
    TwProcessGroupListPreviewGoodsLinePriceItem,
    TwProcessGroupListPreviewGoodsLinePacking,
    TwProcessGroupListPreviewGoodsLineType,
    TwProcessGroupListEditGoodsLinePrice,
    TwProcessGroupListEditGoodsLinePacking,
    TwProcessGroupListEditGoodsLineType,
    TwProcessGroupListEditGoodsLineTable,
    TwProcessGroupListEditGoodsLinePackingTable,
    TwAddGoods,
    TwAddGoodsSubFlow,
    TwProcessGroupListPreviewGoodsLineTable,
    TwProcessGroupListPreviewGoodsLineSeparateItem,
    TwProcessGroupListPreviewIpDnTable: () => import('@/components/molecules/TwProcessGroupListPreviewIpDnTable'),
    TwProcessGroupListPreviewIpDn: () => import('@/components/molecules/TwProcessGroupListPreviewIpDn'),
    TwProcessGroupListPreviewGoodsLineCoTable: () => import('@/components/molecules/TwProcessGroupListPreviewGoodsLineCoTable'),
    TwProcessGroupListPreviewGoodsLineCoItem: () => import('@/components/molecules/TwProcessGroupListPreviewGoodsLineCoItem'),
    TwProcessGroupListEditGoodsLineCoTable: () => import('@/components/molecules/TwProcessGroupListEditGoodsLineCoTable'),
    TwProcessGroupListEditGoodsLineCoItem: () => import('@/components/molecules/TwProcessGroupListEditGoodsLineCoItem'),
  },
  mixins: [collapseTransition],
  data() {
    return {
      open: this.isEditMode || this.initialOpen,
      goodsLinePackingParentSchemas: goodsLinePackingParentSchemas,
      goodsLinePackingTableSchema: goodsLinePackingTableSchema,
      goodsLinePackingTableSchema2: goodsLinePackingTableSchema2,
      goodsLinePreviewPackingTableSchema: goodsLinePreviewPackingTableSchema,
      goodsLinePreviewPackingTableSchema2: goodsLinePreviewPackingTableSchema2,
      dnPreviewTableSchema: dnPreviewTableSchema,
      goodsLineCoTableSchema: goodsLineCoTableSchema,
      goodsLineCoPreviewTableSchema: goodsLineCoPreviewTableSchema,
      dialogAddGoodsVisible: false,
      selectGoodsLineSeq: null,
      selectGoodsLineId: null,
      selectGoodsId: null,
      selectGoodsShortName: null,
      selectPackingGoodsLineSeq: null,
      selectPackingGoodsLineId: null,
      selectPackingLineSeq: null,
      selectPackingSublineSeq: null,
      selectGoods: null,
      selectIpIndex: null,
      selectDnIndex: null,
      selectGoodsLineCoIndex: null,
      tableHasError: false,
      scroller1Width: '100%',
      scroller2Width: '100%',
      PROCESS_TYPE,
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    visible() {
      if (!this.groupCount) {
        return false;
      }

      if (!_.some(this.table.groups, group => {
        return this.checkTypeOfWorks(group);
      })) {
        return false;
      }
      if (!this.isEditMode) {
        return true;
      }
      if (this.table.variableName.includes('GoodsLine')) {
        return true;
      }
      return _.some(this.table.groups, group => {
        return _.some(group.children, item => {
          return item[this.s.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM ? 'from' : 'to'] && item.controlType !== false;
        });
      });
    },
    exclude() {
      return this.table.variableName.includes('GoodsLine') && !['linkageGoodsLine', 'linkageGoodsLinePacking', 'linkageGoodsLineCo'].includes(this.table.variableName);
    },
    // 梱包を追加できるかを返却します
    cannotAddPacking() {
      // 梱包が選択されてなければ非活性
      return !this.selectPackingGoodsLineSeq;
    },
    drawerShow() {
      return this.$store.state.drawerShow;
    },
    hasError() {
      return names => {
        const filtered = _.filter(this.s.errors, (e) => {
          return _.some(names, name => {
            if (_.isString(e)) {
              return e.startsWith(name + '[');
            }
            return false;
          });
        });
        return filtered;
      }
    },
    groupCount() {
      let groups = _.reject(this.table.groups, group => {
        return _.every(group.children, item => {return item.hide || !this.checkTypeOfWorks(group) || !this.checkTransportation(this.table.variableName, group.variableName) || !this.checkPaymentTerms(this.table.variableName) || (this.isEditMode && !(item[this.s.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM ? 'from' : 'to']));});
      })
      if (!this.subtaskHide && (this.table.variableName === 'linkageMarineInsurance' || this.table.variableName === 'marineInsuranceSeparate')) {
        // 海上保険の場合はIP,DN情報を除外してカウントする
        groups = _.reject(groups, group => { return !this.checkMarineInsurance(group.variableName) });
      }
      return _.size(groups);
    },
    // 商品明細テーブルデータ
    goodsLineTable() {
      return this.getTableData(goodsLineTableSchema);
    },
    goodsLineTable2() {
      return this.getTableData(goodsLineTableSchema2);
    },
    // 商品明細テーブル閲覧用データ
    goodsLinePreviewTable() {
      return this.getTableData(goodsLinePreviewTableSchema);
    },
    goodsLinePreviewTable2() {
      return this.getTableData(goodsLinePreviewTableSchema2);
    },
    // 商品個別テーブルデータ
    goodsLinePreviewSeparateTable() {
      return this.s.processType === PROCESS_TYPE.ECPERMIT ? ecPermitgoodsLinePreviewTableSchema : icPermitgoodsLinePreviewTableSchema;
    },
    goodsLinePreviewSeparateTable2() {
      return this.s.processType === PROCESS_TYPE.ECPERMIT ? ecPermitgoodsLinePreviewTableSchema2 : icPermitgoodsLinePreviewTableSchema2;
    },
    // 同じ梱包データかを返却します
    isEqualPacking() {
      return row => {
        if (this.isEditMode) {
          return this.selectPackingGoodsLineSeq === row.keyGrp.goodsLineSeq && this.selectPackingLineSeq === row.keyGrp.packingLineSeq && this.selectPackingSublineSeq === row.keyGrp.packingSublineSeq;
        } else {
          return this.selectPackingGoodsLineSeq === row.goodsLineSeq && this.selectPackingLineSeq === row.packingLineSeq && this.selectPackingSublineSeq === row.packingSublineSeq;
        }
      }
    },
    // 商品梱包をgoodsLine順に並べ替えます
    orderedGoodsLinePacking() {
      const goodsLine = this.isEditMode ? _.get(this.localValue, 'linkageGoodsLine') : _.get(this.s, 'res.linkageGoodsLine');
      const goodsLinePacking = this.isEditMode ? _.get(this.localValue, 'linkageGoodsLinePacking') : _.get(this.s, 'res.linkageGoodsLinePacking');
      const goodsLineIndexes = _.map(goodsLine, goods => {
        return _.get(goods, 'keyGrp.goodsLineSeq');
      });
      return _.sortBy(goodsLinePacking, (packing) => {
        const index = _.indexOf(goodsLineIndexes, packing.keyGrp.goodsLineSeq);
        return index === -1 ? undefined : index;
      });
    },
    // remarksGrpのスキーマを返却します
    getRemarksGrp() {
      return _.find(this.table.groups, {variableName: 'remarksGrp'});
    },
    // 海上保険 I/P情報以外のスキーマを返却します
    getInsuranceGrp() {
      if (this.table.variableName !== 'linkageMarineInsurance') return [];
      const table = this.s.cloneDeep(this.table);
      return _.filter(table.groups, (group) => {
        return this.checkMarineInsurance(group.variableName);
      });
    },
    // 海上保険 D/N情報以外のスキーマを返却します
    getDnGrp() {
      if (this.table.variableName !== 'marineInsuranceSeparate') return [];
      const table = this.s.cloneDeep(this.table);
      return _.filter(table.groups, (group) => {
        return this.checkMarineInsurance(group.variableName);
      });
    },
    // サブフローかどうか
    isSubFlow() {
      return _.get(this.s.res, 'tradeManagement.mainFlowFlg') === MAIN_FLOW_FLG.SUB;
    },
    // サブフローを保持しているかどうか
    isHaveSubFlow() {
      return _.get(this.s.res, 'tradeManagement.haveSubFlowFlg') === HAVE_SUB_FLOW_FLG.YES;
    },
    // 親フローの分納元商品明細
    parentGoodsLine() {
      return _.get(this.s, 'res.parentGoodsLine') || [];
    },
    // 親フローの分納元商品属性
    parentGoodsLineType() {
      return _.get(this.s, 'res.parentGoodsLineType') || [];
    },
    // 親フローの分納元商品価格
    parentGoodsLinePrice() {
      return _.get(this.s, 'res.parentGoodsLinePrice') || [];
    },
    // 親フローの分納元商品梱包
    parentGoodsLinePacking() {
      return _.get(this.s, 'res.parentGoodsLinePacking') || [];
    },
    // 商品明細COの表示フラグ
    canAddGoodsLineCo() {
      // CO申請プロセスのみ編集追加可能
      return this.s.processType === PROCESS_TYPE.FDCOR && this.localValue.linkageGoodsLine && this.localValue.linkageGoodsLine.length;
    },
    // IP表スキーマ
    ipPreviewTableSchema() {
      // プロセス詳細、登録変更の場合は、ipRegistType（I/P登録元タイプ）に応じてIPDN区分を表示する
      // プール側の画面では、ipRegistTypeはないため非表示
      if (!this.s.processType) {
        // processTypeがない場合はプール側の画面と判定し除外
        return _.reject(_.cloneDeep(ipPreviewTableSchema), row => {
          return row.key === 'ipRegistType';
        });
      }
      return ipPreviewTableSchema;
    },
  },
  watch: {
    drawerShow(val) {
      if (!val) {
        this.resetSelect();
        this.resetSelectPacking();
      }
    },
    's.errors': {
      handler() {
        if (!this.visible || !this.$refs.table) {
          return false;
        }
        requestAnimationFrame(() => {
          this.tableHasError = !!this.$refs.table.querySelector('.el-form-item.is-error:not(.hide_error)');
        });
      },
      deep: true,
    }
  },
  created() {
    this.setWidth();
    this.$parent.$on('openAll', this.openAll);
    this.$parent.$on('closeAll', this.closeAll);
    if (this.initSelectIp) this.selectIpIndex = 0;
    if (this.initSelectDn) this.selectDnIndex = 0;
  },
  beforeDestroy() {
    this.$parent.$off('openAll', this.openAll);
    this.$parent.$off('closeAll', this.closeAll);
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    showGoodsDialog() {
      getSelection().removeAllRanges(); // テキストの選択をクリアします
      this.dialogAddGoodsVisible = true;
    },
    hideGoodsDialog() {
      this.dialogAddGoodsVisible = false;
    },
    // 商品追加可能なプロセスか
    canAddGoods() {
      return ![
        PROCESS_TYPE.ECPERMIT,
        PROCESS_TYPE.ICPERMIT,
        // PROCESS_TYPE.TPATPRB,
        // PROCESS_TYPE.TPRBL,
        PROCESS_TYPE.TPBLI,
        PROCESS_TYPE.FDCOR,
        PROCESS_TYPE.FDCOI,
      ].includes(this.s.processType);
    },
    addGoods(selectGoods, goodsTypeMasterList) {
      const goodsLine = createGoodsLine({
        selectGoods: selectGoods,
        goodsTypeMasterList: goodsTypeMasterList,
        goodsLineId: _.isEmpty(this.localValue.linkageGoodsLine) ? null : this.localValue.linkageGoodsLine[0].keyGrp.goodsLineId,
        lastGoodsLineSeq: _.isEmpty(this.localValue.linkageGoodsLine) ? 0 : _.get(_.minBy(this.localValue.linkageGoodsLine, 'keyGrp.goodsLineSeq'), 'keyGrp.goodsLineSeq'),
        entityId: this.s.entityId,
        tradingId: this.s.tradeManagement.tradingId,
        tradingFlowId: this.s.tradeManagement.tradingFlowId,
        processId: this.s.processId,
        processSeq: parseInt(this.s.processSeq),
      });
      this.localValue.linkageGoodsLine = [...this.localValue.linkageGoodsLine, ...goodsLine.linkageGoodsLine];
      this.localValue.linkageGoodsLineType = [...this.localValue.linkageGoodsLineType, ...goodsLine.linkageGoodsLineType];
      if (this.localValue.linkageGoodsLinePrice) {
        this.localValue.linkageGoodsLinePrice = [...this.localValue.linkageGoodsLinePrice, ...goodsLine.linkageGoodsLinePrice];
      }
      this.localValue.linkageGoodsLinePacking = [...this.localValue.linkageGoodsLinePacking || [], ...goodsLine.linkageGoodsLinePacking];
      if (this.s.processType === PROCESS_TYPE.ICREQ) {
        // 輸入荷捌依頼プロセスの場合は輸入商品価格個別を追加する
        this.localValue.importGoodsLinePriceSeparate = [...this.localValue.importGoodsLinePriceSeparate || [], ...goodsLine.importGoodsLinePriceSeparate];
      }
      this.dialogAddGoodsVisible = false;
    },
    // サブフローでの商品追加
    addGoodsSub(selectGoods) {
      // console.log(selectGoods)
      const goodsLine = createGoodsLineSubFlow({
        selectGoods: selectGoods,
        goodsLineId: _.isEmpty(this.localValue.linkageGoodsLine) ? null : this.localValue.linkageGoodsLine[0].keyGrp.goodsLineId,
        lastGoodsLineSeq: _.isEmpty(this.localValue.linkageGoodsLine) ? 0 : _.get(_.minBy(this.localValue.linkageGoodsLine, 'keyGrp.goodsLineSeq'), 'keyGrp.goodsLineSeq'),
        entityId: this.s.entityId,
        tradingId: this.s.tradeManagement.tradingId,
        tradingFlowId: this.s.tradeManagement.tradingFlowId,
        processId: this.s.processId,
        processSeq: parseInt(this.s.processSeq),
      });
      // console.log(goodsLine)
      this.localValue.linkageGoodsLine = [...this.localValue.linkageGoodsLine, ...goodsLine.linkageGoodsLine];
      this.localValue.linkageGoodsLineType = [...this.localValue.linkageGoodsLineType, ...goodsLine.linkageGoodsLineType];
      if (this.localValue.linkageGoodsLinePrice) {
        this.localValue.linkageGoodsLinePrice = [...this.localValue.linkageGoodsLinePrice, ...goodsLine.linkageGoodsLinePrice];
      }
      this.localValue.linkageGoodsLinePacking = [...this.localValue.linkageGoodsLinePacking || [], ...goodsLine.linkageGoodsLinePacking];
      if (this.s.processType === PROCESS_TYPE.ICREQ) {
        // 輸入荷捌依頼プロセスの場合は輸入商品価格個別を追加する
        this.localValue.importGoodsLinePriceSeparate = [...this.localValue.importGoodsLinePriceSeparate || [], ...goodsLine.importGoodsLinePriceSeparate];
      }
      this.dialogAddGoodsVisible = false;
    },
    newGoods() {
      const goodsLine = createGoodsLine({
        selectGoods: null,
        goodsTypeMasterList: null,
        goodsLineId: _.isEmpty(this.localValue.linkageGoodsLine) ? null : this.localValue.linkageGoodsLine[0].keyGrp.goodsLineId,
        lastGoodsLineSeq: _.isEmpty(this.localValue.linkageGoodsLine) ? 0 : _.get(_.minBy(this.localValue.linkageGoodsLine, 'keyGrp.goodsLineSeq'), 'keyGrp.goodsLineSeq'),
        entityId: this.s.entityId,
        tradingId: this.s.tradeManagement.tradingId,
        tradingFlowId: this.s.tradeManagement.tradingFlowId,
        processId: this.s.processId,
        processSeq: parseInt(this.s.processSeq),
      });
      this.localValue.linkageGoodsLine = [...this.localValue.linkageGoodsLine, ...goodsLine.linkageGoodsLine];
      if (this.localValue.linkageGoodsLinePrice) {
        this.localValue.linkageGoodsLinePrice = [...this.localValue.linkageGoodsLinePrice, ...goodsLine.linkageGoodsLinePrice];
      }
      this.localValue.linkageGoodsLinePacking = [...this.localValue.linkageGoodsLinePacking || [], ...goodsLine.linkageGoodsLinePacking];
      if (this.s.processType === PROCESS_TYPE.ICREQ) {
        // 輸入荷捌依頼プロセスの場合は輸入商品価格個別を追加する
        this.localValue.importGoodsLinePriceSeparate = [...this.localValue.importGoodsLinePriceSeparate || [], ...goodsLine.importGoodsLinePriceSeparate];
      }
    },
    addPacking() {
      let packingLineSeq = 0; // 1商品が重複するたびに加算
      if (this.localValue.linkageGoodsLinePacking.length) {
        const filteredGoodsLinePacking = _.filter(this.localValue.linkageGoodsLinePacking, packing => packing.keyGrp.goodsLineSeq === this.selectPackingGoodsLineSeq);
        if (filteredGoodsLinePacking.length) {
          packingLineSeq = _.get(_.maxBy(this.localValue.linkageGoodsLinePacking, 'keyGrp.packingLineSeq'), 'keyGrp.packingLineSeq');
        }
      }
      const packingSublineSeq = 0; // 1梱包をさらに分割する場合に加算。2021/10は固定値
      const packing = createGoodsLinePacking(this.selectPackingGoodsLineSeq, this.selectPackingGoodsLineId, packingLineSeq, packingSublineSeq);
      this.localValue.linkageGoodsLinePacking.push(packing);
    },
    // 商品明細（CO）を生成します
    addGoodsLineCo() {
      const goodsLineCo = createGoodsLineCo(this.localValue.linkageGoodsLine, this.s.tradeManagement, this.localValue.approvedGoodsLineSeq || []);
      this.s.$set(this.s.cloneItems, 'linkageGoodsLineCo', goodsLineCo);
      this.s.setCoGoodsLinePacking();
      // console.log(this.s.cloneItems.linkageGoodsLineCo);
    },
    deleteGoodsClick(goodsLineSeq) {
      this.removeGoodsLine({goodsLineSeq: goodsLineSeq});
    },
    selectRow(index) {
      if (this.isEditMode) {
        this.selectGoodsLineSeq = this.localValue.linkageGoodsLine[index].keyGrp.goodsLineSeq;
        this.selectGoodsId = this.localValue.linkageGoodsLine[index].goodsGrp.goodsId;
        this.selectGoodsShortName = this.localValue.linkageGoodsLine[index].goodsGrp.goodsShortName;
        this.selectGoodsLineId = this.localValue.linkageGoodsLine[index].keyGrp.goodsLineId;
        this.selectGoods = this.localValue.linkageGoodsLine[index];
      } else {
        this.selectGoodsLineSeq = this.s.res.linkageGoodsLine[index].keyGrp.goodsLineSeq;
        this.selectGoodsId = this.s.res.linkageGoodsLine[index].goodsGrp.goodsId;
        this.selectGoodsShortName = this.s.res.linkageGoodsLine[index].goodsGrp.goodsShortName;
        this.selectGoodsLineId = this.s.res.linkageGoodsLine[index].keyGrp.goodsLineId;
      }
    },
    // 商品個別の商品選択時
    selectGoodsLineSeparate(row) {
      this.selectGoodsLineSeq = row.goodsLineSeparateSeq;
    },
    // I/P選択時
    selectIp(row, index) {
      if (this.selectIpIndex === index) this.selectIpIndex = null;
      else this.selectIpIndex = index;
    },
    // D/N選択時
    selectDn(row, index) {
      if (this.selectDnIndex === index) this.selectDnIndex = null;
      else this.selectDnIndex = index;
    },
    // 商品明細 (CO)選択時
    selectGoodsLineCo(row, index) {
      // if (this.selectGoodsLineCoIndex === index) this.selectGoodsLineCoIndex = null;
      // else this.selectGoodsLineCoIndex = index;
      this.selectGoodsLineCoIndex = index;
    },
    resetSelect() {
      this.selectGoodsLineSeq = null;
      this.selectGoodsId = null;
      this.selectGoodsShortName = null;
      this.selectGoodsLineId = null;
      this.selectGoods = null;
      this.selectIpIndex = null;
      this.selectDnIndex = null;
      this.selectGoodsLineCoIndex = null;
    },
    removeGoodsLine(row) {
      const message = this.isHaveSubFlow ? '<br />(After deletion, you will not be able to manage the remaining quantity of goods.)' : '';
      this.$store
        .dispatch('SHOW_CONFIRM', `Are you sure you want to delete the goods data?${message}`)
        .then(() => {
          if (this.selectGoodsLineSeq === row.goodsLineSeq) {
            this.resetSelect();
          }
          // 商品明細から削除
          const goodsLineIndex = _.findIndex(this.localValue.linkageGoodsLine, goods => {
            return goods.keyGrp.goodsLineSeq === row.goodsLineSeq;
          });
          this.localValue.linkageGoodsLine.splice(goodsLineIndex, 1);
          // 商品価格から削除
          const goodsLinePriceIndex = _.findIndex(this.localValue.linkageGoodsLinePrice, goods => {
            return goods.keyGrp.goodsLineSeq === row.goodsLineSeq;
          });
          if (this.localValue.linkageGoodsLinePrice) {
            this.localValue.linkageGoodsLinePrice.splice(goodsLinePriceIndex, 1);
          }
          // 商品属性から削除
          this.localValue.linkageGoodsLineType = _.reject(this.localValue.linkageGoodsLineType, goods => {
            return goods.keyGrp.goodsLineSeq === row.goodsLineSeq;
          });
          // 商品梱包から削除
          this.localValue.linkageGoodsLinePacking = _.reject(this.localValue.linkageGoodsLinePacking, goods => {
            return goods.keyGrp.goodsLineSeq === row.goodsLineSeq;
          });
          // 輸入商品価格個別から削除
          if (this.localValue.importGoodsLinePriceSeparate) {
            this.localValue.importGoodsLinePriceSeparate = _.reject(this.localValue.importGoodsLinePriceSeparate, goods => {
              return goods.keyGrp.goodsLineSeq === row.goodsLineSeq;
            });
          }

          requestAnimationFrame(() => {
            this.s.$refs.form.clearValidate();
            this.s.errors = [];
          });
        })
        .catch((e) => {console.log(e)});
    },
    selectPacking(row) {
      if (this.isEditMode) {
        this.selectPackingGoodsLineSeq =  row.keyGrp.goodsLineSeq;
        this.selectPackingGoodsLineId = row.keyGrp.goodsLineId;
        this.selectPackingLineSeq = row.keyGrp.packingLineSeq;
        this.selectPackingSublineSeq = row.keyGrp.packingSublineSeq;
      } else {
        this.selectPackingGoodsLineSeq = row.goodsLineSeq;
        this.selectPackingGoodsLineId = row.goodsLineId;
        this.selectPackingLineSeq = row.packingLineSeq;
        this.selectPackingSublineSeq = row.packingSublineSeq;
      }
    },
    resetSelectPacking() {
      this.selectPackingGoodsLineSeq = null;
      this.selectPackingGoodsLineId = null;
      this.selectPackingLineSeq = null;
      this.selectPackingSublineSeq = null;
    },
    removePacking(row) {
      this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete the goods data?')
        .then(() => {
          // 商品明細から削除
          const packingLineIndex = _.findIndex(this.localValue.linkageGoodsLinePacking, packing => {
            return packing.keyGrp.goodsLineSeq === row.keyGrp.goodsLineSeq && packing.keyGrp.packingLineSeq === row.keyGrp.packingLineSeq && packing.keyGrp.packingSublineSeq === row.keyGrp.packingSublineSeq;
          });
          this.localValue.linkageGoodsLinePacking.splice(packingLineIndex, 1);
          if (this.selectPackingLineSeq === row.keyGrp.packingLineSeq) {
            this.resetSelectPacking();
          }
        })
        .catch(() => {});
    },
    openAll() {
      this.open = true;
      this.$emit('openAll');
    },
    closeAll() {
      this.open = false;
      this.$emit('closeAll');
    },
    // 依頼区分をチェックします
    checkTypeOfWorks(group) {
      let typeOfWorks;
      if (this.isEditMode) {
        typeOfWorks = _.get(this.localValue, 'processSeparate.typeOfWorksGrp');
      } else {
        typeOfWorks = _.get(this.s, 'res.processSeparate.typeOfWorksGrp');
      }
      if (!typeOfWorks) {
        return true;
      }
      return _.some(typeOfWorks, (value, key) => {
        return value === TYPE_OF_WORKS.ON && group[key];
      });
    },
    // 輸送手段に対する項目の出し分け
    checkTransportation(tableName, groupName) {
      let value;
      if (this.isEditMode) {
        value = _.get(this.localValue, 'linkageTransportation.transferTermsGrp.transportation');
      } else {
        value = _.get(this.s, 'res.linkageTransportation.transferTermsGrp.transportation');
      }

      if (!_.isNumber(value)) {
        return true;
      }

      const rule = _.find(transportationControl, o => {
        const names = o.variableName.split('.');
        return tableName === names[0] && groupName === names[1];
      });

      if (!rule) {
        return true;
      } else {
        return rule.value.includes(value);
      }
    },
    // 決済手段による項目の出し分け
    checkPaymentTerms(tableName) {
      if (this.s.processType === PROCESS_TYPE.LCACCEPT) {
        return true;
      }
      let value;
      if (this.isEditMode) {
        value = _.get(this.localValue, 'linkageContract.paymentTermsGrp.paymentId');
      } else {
        value = _.get(this.s, 'res.linkageContract.paymentTermsGrp.paymentId');
      }

      if (!value) {
        return true;
      }
      if (tableName === 'linkageLc' && value !== PAYMENT_TERMS.LC) {
        return false;
      }
      return true;
    },
    getTableData(schema) {
      return _.compact(_.map(schema, column => {
        const table = _.find(this.s.schemas.tables, {variableName: column.table});
        if (!table) {
          return null;
        }
        const group = _.find(table.groups, {variableName: column.group});
        const item = _.find(group.children, {key: column.key});
        if (this.isEditMode) {
          if (!_.some(table.groups, group => {
            return this.checkTypeOfWorks(group);
          })) {
            if (column.label === 'Price') {
              return {
                width: 136,
              }
            } else {
              return null;
            }
          } else {
            return {
              key: column.key,
              table: column.table,
              group: column.group,
              item: item,
              parentGroup: group,
              width: column.width,
              label: column.label,
              align: column.align,
            }
          }
        } else {
          if (!_.some(table.groups, group => {
            return this.checkTypeOfWorks(group);
          })) {
            if (column.label === 'Price') {
              return {
                width: 100,
              }
            } else {
              return null;
            }
          } else {
            return column;
          }
        }
      }));
    },
    setWidth() {
      const width = `calc(100% - ${this.$store.state.scrollbarWidth}px)`;
      this.scroller1Width = width;
      this.scroller2Width = width;
    },

    // 海上保険 I/P,D/N情報以外の情報群かどうか
    checkMarineInsurance(groupName) {
      const groups = ['keyGrp', 'insuranceTermsGrp', 'insurancePolicyGrp', 'forwarderIpGrp', 'remarksGrp'];
      return groups.includes(groupName);
    },

    /**
     * 商品を分割します（分納後のサブフローでのみ可能）
     * NOTE: 選択した商品のコピーを生成し、選択した商品とコピーのgoodsLineSeqを負の値で採番します
     */
    split() {
      // prevGoodsLineSeq、originGoodsLineSeq
      // 選択中の商品が分割商品でない場合、prevとoriginには自身のgoodsLineSeqと同じ値が入る
      const prevGoodsLineSeq = this.selectGoods.goodsGrp.prevGoodsLineSeq || this.selectGoods.keyGrp.goodsLineSeq;
      const originGoodsLineSeq = this.selectGoods.goodsGrp.originGoodsLineSeq || this.selectGoods.keyGrp.goodsLineSeq;
      const selectGoodsLineSeq = this.selectGoods.keyGrp.goodsLineSeq; // 選択中の商品の現在のgoodsLineSeq
      let lastGoodsLineSeq = _.get(_.minBy(this.localValue.linkageGoodsLine, 'keyGrp.goodsLineSeq'), 'keyGrp.goodsLineSeq')
      lastGoodsLineSeq = (lastGoodsLineSeq > 0 ? 0 : lastGoodsLineSeq) - 1; // 新規に採番するgoodsLineSeq

      // 分割先の商品を追加
      const goodsArray = ['linkageGoodsLinePrice', 'linkageGoodsLineType', 'linkageGoodsLinePacking', 'linkageGoodsLine', 'importGoodsLinePriceSeparate'];
      for (let i = 0; i < goodsArray.length; i++) {
        if (goodsArray[i] === 'linkageGoodsLineType') {
          // 商品属性の場合
          const goodsLine = _.filter(this.localValue[goodsArray[i]], goods => {
            return goods.keyGrp.goodsLineSeq === selectGoodsLineSeq;
          })
          const splitGoodsLine = this.s.cloneDeep(goodsLine);
          if (goodsLine.length > 0) {
            _.forEach(splitGoodsLine, item => {
              item.keyGrp.goodsLineSeq = selectGoodsLineSeq < 0 ? lastGoodsLineSeq : lastGoodsLineSeq - 1;
            });
            this.localValue[goodsArray[i]] = [...this.localValue[goodsArray[i]], ...splitGoodsLine]
            if (selectGoodsLineSeq > 0) {
              _.forEach(goodsLine, item => {
                item.keyGrp.goodsLineSeq = lastGoodsLineSeq;
              });
            }
          }
        } else {
          // TODO 輸入商品価格個別は現状複製しているが、空のレコードにするかTW確認中
          if (goodsArray[i] === 'importGoodsLinePriceSeparate' && this.s.processType !== PROCESS_TYPE.ICREQ) {
            // 輸入荷捌依頼プロセス以外の場合は、輸入商品価格個別は除外
            continue;
          }
          const goodsLine = _.find(this.localValue[goodsArray[i]], goods => { // 選択中の商品情報
            return goods.keyGrp.goodsLineSeq === selectGoodsLineSeq;
          });
          if (!goodsLine) {
            // 選択中の商品情報がない場合はスキップ
            continue;
          }
          const goodsLineIndex = _.findIndex(this.localValue[goodsArray[i]], goods => { // 選択中の商品情報のindex番号
            return goods.keyGrp.goodsLineSeq === selectGoodsLineSeq;
          });
          const splitGoodsLine = this.s.cloneDeep(goodsLine); //  選択中の商品情報をコピーし、分割先の商品情報を生成
          if (goodsLine && goodsLineIndex !== -1) {
            // 分割先の商品情報に、goodsLineSeqを設定
            splitGoodsLine.keyGrp.goodsLineSeq = selectGoodsLineSeq < 0 ? lastGoodsLineSeq : lastGoodsLineSeq - 1; // 選択中の商品のgoodsLineSeqがマイナスかどうかで設定する値を変える
            if (goodsArray[i] === 'linkageGoodsLine') {
              // 商品詳細の場合は、prevとoriginのgoodsLineSeqも設定
              splitGoodsLine.goodsGrp.prevGoodsLineSeq = prevGoodsLineSeq; // 分割元取引商品SEQ
              splitGoodsLine.goodsGrp.originGoodsLineSeq = originGoodsLineSeq; // 起源取引商品SEQ
            }
            // 分割先の商品情報を選択中の商品情報の下に追加
            this.localValue[goodsArray[i]].splice(goodsLineIndex + 1, 0, splitGoodsLine);
          }

          // 選択中の商品のgoodsLineSeqを変更 選択中の商品のgoodsLineSeqがマイナスの場合は、分割中の商品と見なし変更しない
          if (goodsLine && selectGoodsLineSeq > 0) {
            goodsLine.keyGrp.goodsLineSeq = lastGoodsLineSeq;
          }
        }
      }
    },
    // 属性辞書（goodsTypeMasterListForResponse）を更新します
    updateGoodsTypeMaster(goodsTypeMasterList) {
      this.s.res.goodsTypeMasterListForResponse = _.uniqBy([...this.s.res.goodsTypeMasterListForResponse, ...goodsTypeMasterList], o => `${o.goodsTypeId}-${o.attrId}`);
    },
    // 連番項目init処理
    initSerial(edit) {
      _.forEach(this.$refs.groupList, list => {
        list.initSerial(edit);
      })
    },
  }
};
</script>

<style lang="scss" scoped>
  .tw_process_list {
    display: table-row-group;
    position: relative;
    background: $color_white;
    @include card_shadow;

    &.has_error {
      background: rgba($color_warning, 0.03);

      > tr.inner {
        background: rgba($color_warning, 0.1);
      }
    }

    &:first-child > tr > td {
      border: none!important;
    }

    .inner {
      display: table-row;
      cursor: pointer;

      td.arrow {
        width: 20px;
        vertical-align: middle;
        padding: 10px 0 10px 8px;
        border-top: 1px solid $color_gray_300;

        img {
          display: block;
          width: 12px;
          height: 12px;
          transition: transform .3s;
        }
      }
    }

    .table_name {
      width: 206px;
      vertical-align: middle;
      padding: 10px 8px 10px 16px;
      font-size: 16px;
      line-height: 24px;
      color: $color_gray_800;
      white-space: nowrap;
      border-top: 1px solid $color_gray_300;

      &.full {
        width: 100%;
        padding-bottom: 0;
      }
    }

    td.subtask {
      padding: 10px 0;
      vertical-align: middle;
      border-top: 1px solid $color_gray_300;
    }

    .collapse {
      padding: 0 22px 10px;
    }

    &.open {
      .inner td.arrow img {
        transform: rotate(90deg);
      }

      .inner {
        @include list_shadow;
      }
    }
  }

  .content-enter-active,
  .content-leave-active {
    transition: height .3s;
  }

  .table_scroller {
    max-width: 100%;
    max-height: 275px;
    overflow: hidden;
    overflow-y: scroll;

    &.edit {
      max-height: 370px;
      max-width: 1065px;
    }

    table.goods_table thead {
      display: none;
    }
  }

  table.goods_table {
    table-layout: fixed;
    min-width: 100%;
    // border: 1px solid #BFBFCD;
    border-collapse: collapse;
    background: #FFFFFF;
    margin-top: 24px;

    td.goods_line_table_inner {
      position: relative;

      img.close_times {
        position: absolute;
        right: 4px;
        top: 50%;
        margin-top: calc(13px - 10px);
        width: 20px;
        height: 20px;
        cursor: pointer;
        opacity: 0;
      }

      table  {
        table-layout: fixed;
        min-width: 100%;
        border: 0;
        border-collapse: collapse;

        th {
          border-top: 0!important;
          padding: 4px 5px;
          white-space: normal;
        }

        td {
          border-top: 0!important;
          border-bottom: 0!important;
          padding: 0;
        }

        tr th:first-child, tr td:first-child {
          border-left: 0!important;
        }

        tr th:last-child, tr td:last-child {
          border-right: 0!important;
        }
      }
    }

    th {
      text-align: center;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      word-break: break-word;
      padding: 4px 5px;
      border-left: 1px solid $color_gray_300;
      border-right: 1px solid $color_gray_300;
      background: $color_dark_blue;
      color: white;
      // font-weight: bold;
      &.required:after, >div.required:after {
        content: '*';
        color: #E00001;
        margin-left: 4px;
      }
    }

    td {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      white-space: nowrap;
      padding: 0;
      border: 1px solid $color_gray_300!important;
    }

    .el-form-item {
      width: 100%;
      padding-right: 0;
      margin: 0;

      ::v-deep {
        label.el-form-item__label {
          display: none;
        }

        .el-input, .el-select, .el-input-number, .el-input-number.amount {
          width: 100%!important;
        }

        .el-input-number .el-input__inner {
          text-align: right;
        }

        .el-input__inner {
          font-size: 12px;
          width: 100%;
          background: transparent;
          box-shadow: none;
          border-radius: 0;
          padding: 0 5px;

          &:not(:focus) {
            border: 0;
          }
        }

        .el-select {
          .el-input__inner {
            padding-right: 20px;
            overflow: hidden;
            white-space: nowrap;
          }
          .el-input__suffix {
            right: 0;
          }
        }

        .el-form-item__error {
          white-space: normal;
          font-size: 10px;
          left: 5px;
          margin-bottom: 5px;
        }

        .warning_message {
          font-size: 10px;
          background-position: 5px 2px;
          padding-left: 20px;
        }
      }
    }
  }

  .ip_dn {
    margin-bottom: 16px;
  }
</style>
