<template>
  <el-select ref="elSelect" v-model="localValue" :placeholder="placeholder" :filterable="filterable" :allow-create="allowCreate" :default-first-option="defaultFirstOption" :clearable="clearable" :disabled="disabled" @visible-change="onSelectVisibleChange" @change="onChange">
    <template v-if="isOptionVisible">
      <slot />
    </template>
  </el-select>
</template>

<script>
export default {
  name: 'TwSelect',
  props: {
    value: {
      required: true,
    },
    disabled: Boolean,
    clearable: Boolean,
    filterable: Boolean,
    allowCreate: Boolean,
    placeholder: {
      type: String,
      required: false
    },
    defaultFirstOption: Boolean,
  },
  data() {
    return {
      isOptionVisible: false,
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
  },
  methods: {
    onChange(val) {
      this.$emit('change', val);
    },
    blur() {
      this.$refs.elSelect.blur();
    },
    onSelectVisibleChange(val) {
      this.isOptionVisible = val;
    }
  }
};
</script>