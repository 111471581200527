<!-- eslint-disable vue/require-v-for-key -->
<template>
  <div v-if="visible" class="tw_process_group_list" :class="{open: open}">
    <div class="inner" @click="toggle">
      <img v-if="!isGraphicalMode" class="arrow" src="@/assets/images/icons/accordion_arrow.svg">
      <div class="group_name">{{group.name}}</div>
      <tw-button v-if="isBeneficiaryItem" type="secondary" size="small" @click="copyFromBeneficiary">Copy from Beneficiary</tw-button>
      <tw-button v-if="isApplicantItem" type="secondary" size="small" @click="copyFromApplicant">Copy from Applicant</tw-button>
      <tw-button v-if="isDocumentSigner" type="secondary" size="small" @click="s.setCoInformation">Set CO Data </tw-button>
    </div>
    <transition
      name="content"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
      @after-leave="afterLeave"
    >
      <div v-show="open" class="collapse">
        <!-- CO クーポン情報 -->
        <TwCouponInformation v-if="isDocumentSigner" />

        <template v-if="isEditMode && !isPermitSeparate">
          <template v-if="group.type === 'CUSTOMER'">
            <tw-process-group-list-edit-customer
              :items="group.children"
              :tableName="tableName"
              :groupName="group.variableName"
              v-model="localValue"
              :serialIndexes="serialIndexes"
              :serialItems="serialItems"
              @add-index="addIndex"
            />
          </template>
          <template v-else>
            <div class="twoColumn">
              <template v-for="item in group.children">
                <hr v-if="item.key === 'documentsRequired1Name'" class="edit_document_payment_terms_hr" />
                <tw-process-group-list-edit
                  :item="item"
                  :tableName="tableName"
                  :groupName="group.variableName"
                  v-model="localValue"
                  :serialIndexes="serialIndexes"
                  :serialItems="serialItems"
                  @add-index="addIndex"
                />
              </template>
            </div>
          </template>
        </template>
        <template v-else-if="group.type === 'CUSTOMER'">
          <tw-process-group-list-item-customer :items="group.children" :tableName="tableName" :groupName="group.variableName"  />
        </template>
        <template v-else>
          <div class="twoColumn" style="margin-top:0">
            <template v-for="item in group.children">
              <hr v-if="item.key === 'documentsRequired1Name'" class="document_payment_terms_hr" />
              <tw-process-group-list-item
                :item="item"
                :tableName="tableName"
                :groupName="group.variableName"
                :serialIndexes="serialIndexes"
                :serialItems="serialItems"
                :isEditMode="isEditMode"
              />
            </template>

          </div>
        </template>

      </div>
    </transition>
  </div>
</template>

<script>
import _ from 'lodash';
import { RESPONSIBLE_FLG, PAYMENT_TERMS } from 'lib-tw-common';
import TwButton from '@/components/atoms/TwButton';
import TwProcessGroupListEdit from '@/components/molecules/TwProcessGroupListEdit';
import TwProcessGroupListItem from '@/components/molecules/TwProcessGroupListItem';
import TwProcessGroupListItemCustomer from '@/components/molecules/TwProcessGroupListItemCustomer';
import TwProcessGroupListEditCustomer from '@/components/molecules/TwProcessGroupListEditCustomer';
import { collapseTransition } from '@/utils/nextFrame';
import { serialGrpName, getSerialGrpItems, getSerialGrpIndex, getKey } from '@/utils/processedSerialItems';

const BENEFICIARY_ITEMS = ['sellerGrp', 'shipperGrp'];
const APPLICANT_ITEMS = ['buyerGrp', 'consigneeGrp'];

export default {
  name: 'TwProcessGroupList',
  inject: ['s', 'checkTypeOfWorks', 'checkTransportation', 'checkPaymentTerms'],
  props: {
    group: Object,
    tableName: String,
    isEditMode: Boolean,
    value: Object,
    isGraphicalMode: {
      type: Boolean,
      default: false,
    },
    initialOpen: Boolean,
  },
  components: {
    TwButton,
    TwProcessGroupListEdit,
    TwProcessGroupListItem,
    TwProcessGroupListItemCustomer,
    TwProcessGroupListEditCustomer,
    TwCouponInformation: () => import('@/components/organisms/TwCouponInformation'),
  },
  mixins: [collapseTransition],
  data() {
    return {
      open: this.isEditMode || this.isGraphicalMode || this.initialOpen,
      serialIndexes: {}, // 連番項目グループindex
      serialItems: {}, // 連番項目グループ
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    // 許可書保管プロセスの、エンティティ個別テーブルかどうか
    isPermitSeparate() {
      return this.s.cloneItems.permitLatest && this.s.cloneItems.permitLatest.declarationNo && (this.group.variableName === 'exPermitNotifyDataGrp' || this.group.variableName === 'imPermitNotifyDataGrp');
    },
    visible() {
      if (!this.checkPaymentTerms(this.tableName)) {
        return false;
      }
      if (!this.checkTypeOfWorks(this.group)) {
        return false;
      }
      if (!this.checkTransportation(this.tableName, this.group.variableName)) {
        return false;
      }
      if (_.every(this.group.children, item => {return item.hide;})) {
        return false;
      }
      if (this.isEditMode && this.isPermitSeparate) {
        return true;
      }
      if (!this.isEditMode) {
        // 許可書情報群で、紐づいてないときは非表示
        if ((this.group.variableName === 'exPermitNotifyDataGrp' || this.group.variableName === 'imPermitNotifyDataGrp') && !(this.s.res.permitLatest && this.s.res.permitLatest.declarationNo)) {
          return false;
        }
        return true;
      }

      return _.some(this.group.children, item => {
        return item[this.s.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM ? 'from' : 'to'] && item.controlType !== false;
      });
    },
    isSerialGrp() { // 連番項目グループがあるかどうか
      return serialGrpName.includes(this.group.variableName);
    },
    // Copy from Beneficiaryボタンの対象か
    isBeneficiaryItem() {
      return this.needCopyButton() && BENEFICIARY_ITEMS.includes(this.group.variableName);
    },
    // Copy from Applicantボタンの対象か
    isApplicantItem() {
      return this.needCopyButton() && APPLICANT_ITEMS.includes(this.group.variableName);
    },
    // Document Signerの枠か
    isDocumentSigner() {
      return this.group.variableName === 'certificateOfOriginGrp' && this.group.name === 'CO Document Signer';
    }
  },
  watch: {
    's.res': {
      handler() {
        this.initSerial();
      },
    }
  },
  created() {
    this.$parent.$on('openAll', this.openAll);
    this.$parent.$on('closeAll', this.closeAll);
    this.initSerial(); // 連番項目init処理
  },
  beforeDestroy() {
    this.$parent.$off('openAll', this.openAll);
    this.$parent.$off('closeAll', this.closeAll);
  },
  methods: {
    toggle() {
      if (this.isGraphicalMode) this.open = true;
      else this.open = !this.open;
    },
    openAll() {
      this.open = true;
    },
    closeAll() {
      this.open = false;
    },
    initSerial(edit = false) { // remarks等の連番項目をグループ化し可変にする
      if (this.isSerialGrp) {
        const res = edit ? _.get(this, `s.cloneItems[${this.tableName}].${this.group.variableName}`, {}) : _.get(this.s.res, `${this.tableName}.${this.group.variableName}`, {});
        // if (!res) return
        this.serialItems = getSerialGrpItems(res, this.group.variableName);
        if (Object.keys(this.serialItems).length > 0) this.serialIndexes = getSerialGrpIndex(this.serialItems);
      }
    },
    addIndex(name, num) { // 連番項目を追加 or 削除する
      const key = getKey(name);
      this.serialIndexes[key] += num;
    },
    // コピーボタン表示条件
    needCopyButton() {
      // 決済手段がLCの場合かつLCが紐づいている場合のみ表示、それ以外は非表示
      const lcpools = _.get(this.localValue, 'linkageLc.lcpools');
      return  this.isEditMode && _.get(this.localValue, 'linkageContract.paymentTermsGrp.paymentId') === PAYMENT_TERMS.LC && _.isArray(lcpools) && lcpools.length;
    },
    copyFromBeneficiary() {
      const value = _.get(this.localValue, 'linkageLc.lcBeneficiaryGrp.lcBeneficiaryInfo') || '';
      const item = _.find(this.group.children, child => child.key.endsWith('Info'));
      this.s.setValue(this.tableName, this.group.variableName, item.key, value);
    },
    copyFromApplicant() {
      const value = _.get(this.localValue, 'linkageLc.applicantGrp.applicantInfo') || '';
      const item = _.find(this.group.children, child => child.key.endsWith('Info'));
      this.s.setValue(this.tableName, this.group.variableName, item.key, value);
    },
  },
};
</script>

<style lang="scss" scoped>
  .tw_process_group_list {
    .inner {
      display: flex;
      align-items: center;
      padding: 22px 18px 10px 0;
      cursor: pointer;

      img {
        display: block;
        width: 12px;
        height: 12px;
        transition: transform .3s;
      }

      .group_name {
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        margin-left: 8px;
      }

      .tw_button {
        padding-right: 17px;
        padding-left: 17px;
        margin-left: auto;
      }
    }

    .collapse {
      border-top: 1px solid #EDEDF4;
      padding: 12px 0 10px;
    }

    &.open {
      .inner img.arrow {
        transform: rotate(90deg);
      }
    }
  }

  .content-enter-active,
  .content-leave-active {
    transition: height .3s;
  }

  .twoColumn {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    padding-left: 16px;

    .edit_document_payment_terms_hr {
      display: block;
      width: 100%;
      height: 1px;
      background: $color_gray_300;
      margin: 0 0 24px;
      border: 0;
    }

    .document_payment_terms_hr {
      display: block;
      width: 100%;
      height: 1px;
      background: $color_gray_300;
      margin: 0 0 12px;
      border: 0;
    }
  }
</style>
