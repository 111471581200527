<template>
  <div class="twoColumn" style="position: relative;">
    <tw-process-group-list-edit v-for="item in headerItems" :key="item.key" :item="item" :tableName="tableName" :groupName="groupName" v-model="localValue" @search="showDialog" :serialIndexes="serialIndexes" :serialItems="serialItems" @add-index="addIndex($event)" @on-name-change="onNameChange"  @delete-player-info="deletePlayerInfo" />

    <div v-if="groupName !== 'surveyorGrp'" class="arrow_button" @click="toggle">
      <img class="arrow" :class="{open: open}" src="@/assets/images/icons/accordion_arrow.svg" alt=''>
    </div>
    <transition
      name="content"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
      @after-leave="afterLeave"
    >
      <div v-show="open" class="collapse twoColumn">
        <tw-process-group-list-edit v-for="item in detailItems" :key="item.key" :item="item" :tableName="tableName" :groupName="groupName" v-model="localValue" @search="showDialog" :serialIndexes="serialIndexes" :serialItems="serialItems" @add-index="addIndex($event)" />
      </div>
    </transition>
    <el-dialog :title="$t('Label.Select Customer')" :visible.sync="dialogAddGoodsVisible" :close-on-click-modal="false" class="header_border" width="944px" aria-label="Select Customer" :append-to-body="true">
      <el-form class="search_unit" ref="searchUnit" :model="searchForm" label-position="top">
        <div class="row">
          <el-form-item :label="$t('Label.Customer ID')" prop="customerId">
            <el-input type="text" v-model="searchForm.customerId" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Customer Name')" prop="customerName">
            <el-input type="text" v-model="searchForm.customerName" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Branch Name')" prop="branchName">
            <el-input type="text" v-model="searchForm.branchName" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Company Role')" prop="companyRole">
            <el-select v-model="searchForm.companyRole" placeholder="Select" :class="{multiple_selected: multipleSelect(searchForm.companyRole)}" multiple collapse-tags clearable>
              <el-option
                v-for="role in COMPANY_ROLE_CD_VARIABLES_OPTIONS"
                :key="role.code"
                :label="role.label"
                :value="role.code">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('Label.Keyword')" prop="keyword" style="margin-right:0">
            <el-input type="text" v-model="searchForm.keyword" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <div>
            <tw-button
              type="secondary"
              size="medium"
              icon="search"
              @click="search"
              style="margin:8px 0 16px"
            >
              Search
            </tw-button>
            <tw-button
              type="default"
              size="medium"
              @click="clear"
              style="margin: 8px 16px 16px;"
            >
              Clear
            </tw-button>
          </div>
        </div>
      </el-form>
      <div style="min-height: 388px">
        <tw-table-system :height="300" :schemas="schemas" :items="customers" :pageSize="limit" :totalCount="totalCount" :offset="offset" :serverPagination="true" @row-click="register" @paging="onPaging" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import { COMPANY_ROLE_CD, OWN_COMPANY_FLG, CUSTOMERS_SELECT_SORT_TARGET, DEFAULT_PAGENATION, DELETE_FLG } from 'lib-tw-common';
import TwButton from '@/components/atoms/TwButton';
import TwProcessGroupListEdit from '@/components/molecules/TwProcessGroupListEdit';
import TwTableSystem from '@/components/organisms/TwTableSystem';
import schemas from '@/dictionaries/addCustomerSchema.json';
import customerRole from '@/dictionaries/customerRole.json';
import { clearSearchForm } from '@/utils/searchUtil.js';
import { collapseTransition } from '@/utils/nextFrame';

const ORDER = [
  'customerId',
  'name',
  'branchId',
  'branchName',
  'address1',
  'addressStreet1',
  'address2',
  'addressStreet2',
  'address3',
  'cityId',
  'cityName',
  'address4',
  'countrySubdivision',
  'tel',
  'postalCode',
  'fax',
  'countryId',
  'countryName',
  'iataCode',
  'carrierScac',
  'remarks',
  'info',
];

export default {
  name: 'TwProcessGroupListEditCustomer',
  props: {
    items: Array,
    tableName: String,
    groupName: String,
    value: Object,
    serialIndexes: Object,
    serialItems: Object,
  },
  inject: ['s'],
  mixins: [collapseTransition],
  components: {
    TwButton,
    TwProcessGroupListEdit,
    TwTableSystem,
  },
  data() {
    return {
      customers: [],
      schemas: schemas,
      dialogAddGoodsVisible: false,
      searchForm: {
        customerId: '',
        customerName: '',
        branchName: '',
        companyRole: [],
        keyword: '',
      },
      sections: [],
      init: false,
      limit: DEFAULT_PAGENATION.LIMIT,
      offset: 0,
      sort: null,
      count: 0,
      totalCount: 0,
      pageCount: 0,
      lastQuery: {},
      open: false, // アコーディオン開閉フラグ
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    multipleSelect() {
      return (model) => {
        return _.isArray(model) && model.length > 1;
      };
    },
    keys() {
      return _.map(this.items, 'key');
    },
    customerId() {
      return _.find(this.keys, key => key.endsWith('Id') && !key.endsWith('BranchId') && !key.endsWith('CityId') && !key.endsWith('CountryId'));
    },
    name() {
      return _.find(this.keys, key => key.endsWith('Name') && !key.endsWith('BranchName') && !key.endsWith('CityName') && !key.endsWith('CountryName'));
    },
    branchId() {
      return _.find(this.keys, key => key.endsWith('BranchId'));
    },
    branchName() {
      return _.find(this.keys, key => key.endsWith('BranchName'));
    },
    address1() {
      return _.find(this.keys, key => key.endsWith('Address1'));
    },
    address2() {
      return _.find(this.keys, key => key.endsWith('Address2'));
    },
    address3() {
      return _.find(this.keys, key => key.endsWith('Address3'));
    },
    address4() {
      return _.find(this.keys, key => key.endsWith('Address4'));
    },
    addressStreet1() {
      return _.find(this.keys, key => key.endsWith('AddressStreet1'));
    },
    addressStreet2() {
      return _.find(this.keys, key => key.endsWith('AddressStreet2'));
    },
    cityId() {
      return _.find(this.keys, key => key.endsWith('CityId'));
    },
    cityName() {
      return _.find(this.keys, key => key.endsWith('CityName'));
    },
    countryId() {
      return _.find(this.keys, key => key.endsWith('CountryId'));
    },
    countryName() {
      return _.find(this.keys, key => key.endsWith('CountryName'));
    },
    countrySubdivision() {
      return _.find(this.keys, key => key.endsWith('CountrySubdivision'));
    },
    postalCode() {
      return _.find(this.keys, key => key.endsWith('PostalCode'));
    },
    tel() {
      return _.find(this.keys, key => key.endsWith('Tel'));
    },
    fax() {
      return _.find(this.keys, key => key.endsWith('Fax'));
    },
    iataCode() {
      return _.find(this.keys, key => key.endsWith('IataCode'));
    },
    carrierScac() {
      return _.find(this.keys, key => key.endsWith('carrierScac'));
    },
    remarks() {
      return _.find(this.keys, key => key.endsWith('Remarks'));
    },
    info() {
      return _.find(this.keys, key => key.endsWith('Info'));
    },
    inputName() {
      return this.localValue[this.tableName][this.groupName][this.name];
    },
    hasId() {
      return !!this.localValue[this.tableName][this.groupName][this.customerId];
    },
    orderedItems() {
      const items = _.compact(_.map(ORDER, key => {
        return _.find(this.items, {key: this[key]});
      }));
      return items;
    },
    headerItems() {
      return _.take(this.orderedItems, 4);
    },
    detailItems() {
      return _.drop(this.orderedItems, 4);
    },
    COMPANY_ROLE_CD_VARIABLES_OPTIONS() {
      return this.$store.getters.getCompanyRoleCdVariablesOptions;
    },
  },
  // watch: {
  //   inputName(newVal, oldVal) {
  //     if (!oldVal && newVal) {
  //       this.open = true;
  //     }
  //   }
  // },
  created() {
    // 取引先名が入力されていたらアコーディオンを開く
    if (this.inputName) {
      this.open = true;
    }
  },
  methods: {
    fetch(query) {
      // bff_customer_10 取引先検索(トラン用) BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-user-entity-bff-api',
          apiCode: 'get_/v1/{companyId}/customers/search/transaction',
          path: {
            companyId: this.$store.state.companyId,
          },
          query: query || {
            ownCompanyFlg: ['sellerGrp', 'buyerGrp', 'shipperGrp'].includes(this.groupName) ? OWN_COMPANY_FLG.ON : OWN_COMPANY_FLG.OFF,
            companyRole: this.searchForm.companyRole,
            limit: this.limit,
            offset: this.offset,
            sort: this.sort,
            deleteFlg: DELETE_FLG.NOT_DELETED,
          }
        }
      };

      this.lastQuery = this.s.cloneDeep(params.lslConfig.query);

      $api
        .request(params)
        .then(res => {
          // const lang = this.$store.getters.getLanguageKey;
          this.customers = res.customers ? _.map(res.customers, o => {
            return {
              ...o,
              ...o.en,
            };
          }) : [];
          this.totalCount = res.totalCount;
        })
        .catch(err => {
          this.customers = [];
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
    search() {
      this.offset = 0;
      const query = {
        customerId: this.searchForm.customerId ? [this.searchForm.customerId] : [],
        customerName: this.searchForm.customerName,
        branchName: this.searchForm.branchName,
        companyRole: this.searchForm.companyRole,
        keyword: this.searchForm.keyword,
        ownCompanyFlg: ['sellerGrp', 'buyerGrp', 'shipperGrp'].includes(this.groupName) ? OWN_COMPANY_FLG.ON : OWN_COMPANY_FLG.OFF,
        limit: this.limit,
        offset: this.offset,
        sort: this.sort,
        deleteFlg: DELETE_FLG.NOT_DELETED,
      };
      this.$store.commit('SET_QUERIES', {key: 'CUSTOMER', queries: this.s.cloneDeep(this.searchForm)});
      this.customers = null;
      this.fetch(query);
    },
    clear() {
      this.searchForm = clearSearchForm(this.searchForm);
      this.$store.commit('SET_QUERIES', {key: 'CUSTOMER', queries: this.s.cloneDeep(this.searchForm)});
    },
    onPaging({target, sortType, currentPage}) {
      if (target && sortType) {
        const key = _.get(_.find(this.schemas, {key: target}), 'sortTarget');
        this.sort = {
          target: [CUSTOMERS_SELECT_SORT_TARGET[key] || target],
          sortType: [sortType],
        };
      } else {
        this.sort = null;
      }

      this.offset = currentPage * this.limit - this.limit;
      const queries = {
        ...this.lastQuery,
        limit: this.limit,
        offset: this.offset,
        sort: this.sort,
      };
      this.fetch(queries);
    },
    register(row) {
      if (this.customerId) {
        this.s.setValue(this.tableName, this.groupName, this.customerId, row.customerId || row.companyId || null);
      }
      if (this.name) {
        this.s.setValue(this.tableName, this.groupName, this.name, row.customerShortName || null);
      }
      if (this.address1) {
        this.s.setValue(this.tableName, this.groupName, this.address1, row.address || null);
      }
      if (this.address2) {
        this.s.setValue(this.tableName, this.groupName, this.address2, row.address2 || null);
      }
      if (this.address3) {
        this.s.setValue(this.tableName, this.groupName, this.address3, row.address3 || null);
      }
      if (this.tel) {
        this.s.setValue(this.tableName, this.groupName, this.tel, row.phoneNumber || null);
      }
      if (this.fax) {
        this.s.setValue(this.tableName, this.groupName, this.fax, row.fax || null);
      }
      if (this.branchId) {
        this.s.setValue(this.tableName, this.groupName, this.branchId, row.customerBranchId || null);
      }
      if (this.branchName) {
        this.s.setValue(this.tableName, this.groupName, this.branchName, row.branchName || null);
      }
      this.dialogAddGoodsVisible = false;
      this.s.tsValidationCheck(this.s.$refs.form);
      this.s.$refs.form.validateField(`${this.tableName}.${this.groupName}.${this.name}`);
      // アコーディオン
      this.open = true;
    },
    showDialog() {
      getSelection().removeAllRanges(); // テキストの選択をクリアします
      const queries = this.$store.getters.getQueries('CUSTOMER');
      if (queries) {
        this.searchForm = queries;
        this.setCompanyRole();
        this.search();
      } else if (!this.init) {
        this.setCompanyRole();
        this.fetch();
      }

      this.init = true;
      this.dialogAddGoodsVisible = true;
    },
    addIndex(e) {
      this.$emit('add-index', e);
    },
    // 検索元の情報群毎にカンパニーロールの初期値をセットします
    setCompanyRole() {
      const filter = _.find(customerRole, o => {
        const ary = o.group.split('.');
        return this.tableName === ary[0] && this.groupName === ary[1];
      }) || [];
      const roles = _.compact(_.map(filter.roles, key => {
        return COMPANY_ROLE_CD[key];
      }));
      this.searchForm.companyRole = roles;
    },
    // アコーディオンの開閉を切り変える
    toggle() {
      this.open = !this.open;
    },
    // アコーディオン
    onNameChange() {
      this.open = true;
    },
    // 取引先の情報をクリアします
    deletePlayerInfo() {
      if (this.customerId) {
        this.s.setValue(this.tableName, this.groupName, this.customerId, null);
      }
      if (this.name) {
        this.s.setValue(this.tableName, this.groupName, this.name, null);
      }
      if (this.address1) {
        this.s.setValue(this.tableName, this.groupName, this.address1, null);
      }
      if (this.address2) {
        this.s.setValue(this.tableName, this.groupName, this.address2, null);
      }
      if (this.address3) {
        this.s.setValue(this.tableName, this.groupName, this.address3, null);
      }
      if (this.address4) {
        this.s.setValue(this.tableName, this.groupName, this.address4, null);
      }
      if (this.tel) {
        this.s.setValue(this.tableName, this.groupName, this.tel, null);
      }
      if (this.fax) {
        this.s.setValue(this.tableName, this.groupName, this.fax, null);
      }
      if (this.branchId) {
        this.s.setValue(this.tableName, this.groupName, this.branchId, null);
      }
      if (this.branchName) {
        this.s.setValue(this.tableName, this.groupName, this.branchName, null);
      }
      if (this.addressStreet1) {
        this.s.setValue(this.tableName, this.groupName, this.addressStreet1, null);
      }
      if (this.addressStreet2) {
        this.s.setValue(this.tableName, this.groupName, this.addressStreet2, null);
      }
      if (this.cityId) {
        this.s.setValue(this.tableName, this.groupName, this.cityId, null);
      }
      if (this.cityName) {
        this.s.setValue(this.tableName, this.groupName, this.cityName, null);
      }
      if (this.countryId) {
        this.s.setValue(this.tableName, this.groupName, this.countryId, null);
      }
      if (this.countryName) {
        this.s.setValue(this.tableName, this.groupName, this.countryName, null);
      }
      if (this.countrySubdivision) {
        this.s.setValue(this.tableName, this.groupName, this.countrySubdivision, null);
      }
      if (this.postalCode) {
        this.s.setValue(this.tableName, this.groupName, this.postalCode, null);
      }
      if (this.iataCode) {
        this.s.setValue(this.tableName, this.groupName, this.iataCode, null);
      }
      if (this.carrierScac) {
        this.s.setValue(this.tableName, this.groupName, this.carrierScac, null);
      }
      this.s.tsValidationCheck(this.s.$refs.form);
      this.s.$refs.form.validateField(`${this.tableName}.${this.groupName}.${this.name}`);
    },
  }
};
</script>

<style lang="scss">
  .el-dialog__wrapper[aria-label="Select Customer"] .el-dialog {
    .el-dialog__body {
      padding-top: 0;
    }

    .row {
      padding: 12px 0 8px;
    }
  }

  .tw_button__icon.icon_search {
    padding-left: 12px;
    padding-right: 20px;
  }
</style>
<style lang="scss" scoped>
  .arrow_button {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 120px;
    height: 40px;
    cursor: pointer;
  }
  img.arrow {
    transition: transform .3s;

    &.open {
      transform: rotate(90deg);
    }
  }

  .collapse.twoColumn {
    display: flex;
    flex-wrap: wrap;
  }
</style>
