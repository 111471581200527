<template>
  <div
    class="el-select"
    :class="[selectSize ? 'el-select--' + selectSize : '']"
    @click.stop="toggleMenu"
    v-clickoutside="handleClose"
  >
    <el-input
      ref="reference"
      v-model="selectedLabel"
      :id="id"
      type="text"
      :name="name"
      :placeholder="currentPlaceholder"
      :autocomplete="autoComplete || autocomplete"
      :size="selectSize"
      :disabled="selectDisabled"
      :readonly="readonly"
      :validate-event="false"
      :class="{ 'is-focus': visible }"
      :tabindex="(multiple && filterable) ? '-1' : null"
      @focus="handleFocus"
      @blur="handleBlur"
      @keyup.native="handleDebouncedOnInputChange"
      @keydown.native.down.stop.prevent="navigateOptions('next')"
      @keydown.native.up.stop.prevent="navigateOptions('prev')"
      @keydown.native.enter.prevent="selectOption"
      @keydown.native.esc.stop.prevent="visible = false"
      @keydown.native.tab.stop="visible = false"
      @paste.native="debouncedOnInputChange"
      @mouseenter.native="inputHovering = true"
      @mouseleave.native="inputHovering = false">
      <template slot="prefix" v-if="$slots.prefix">
        <slot name="prefix" />
      </template>
      <template slot="suffix">
        <i v-show="!showClose"
          :class="['el-select__caret', 'el-input__icon', 'el-icon-' + iconClass]"
        />
        <i v-if="showClose"
          class="el-select__caret el-input__icon el-icon-circle-close"
          @click="handleClearClick"
        />
      </template>
    </el-input>
    <transition
      name="el-zoom-in-top"
      @before-enter="handleMenuEnter"
      @after-leave="doDestroy">
      <el-select-menu
        ref="popper"
        :append-to-body="popperAppendToBody"
        v-show="visible && emptyText !== false"
        >
        <div
          class="el-select-dropdown__wrap el-scrollbar__wrap"
          style="overflow: hidden;position: relative;"
        >
            
            <recycle-scroller
              ref="virtualScroller"
              :items="data"
              :buffer="buffer"
              key-field="id"
              :item-size="itemSize"
              class="scroller"
              :style="scrollerStyle"
              :emitUpdate="true"
              @update="onUpdate"
            >
              <template #before>
                <slot name="before" />
                <el-option
                  :value="query"
                  created
                  v-if="showNewOption">
                </el-option>
              </template>
              <template #default="{ item, index, active }">
                <slot :item="item" :index="index" :active="active">

                </slot>
              </template>
              <template #after>
                <slot name="after" />
              </template>
            </recycle-scroller>
            <template v-if="emptyText && (!allowCreate || loading || (allowCreate && options.length === 0 ))">
              <slot name="empty" v-if="$slots.empty"></slot>
              <p class="el-select-dropdown__empty" v-else>
                {{ emptyText }}
              </p>
            </template>
          </div>
      </el-select-menu>
    </transition>
  </div>
</template>

<script>
import _ from 'lodash'
import { Select } from 'element-ui'
import { RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

let t;

export default {
  name: 'VirtualSelect',
  components: {
    ...Select.components,
    RecycleScroller
  },
  directives: {
    ...Select.directives
  },
  mixins: [Select],
  props: {
    ...Select.props,
    ...RecycleScroller.props,
    filterKey: String,
  },
  data() {
    const selectDataFn = Select.data.bind(this)

    const defaultData = selectDataFn()
    return {
      ...defaultData,
      data: [...this.items],
      maxWidth: 320,
    }
  },
  computed: {
    ...Select.computed,
    scrollerStyle() {
      // const count = Math.min(this.data.length, 5)
      const height = Math.min(this.itemSize * (this.data.length + (this.showNewOption ? 1 : 0)), 257);//this.showNewOption ? 223 : 257)
      return `maxHeight: ${height}px;minWidth: 100%;width: ${this.maxWidth}px`
    },
    showNewOption() {
      let hasExistingOption = this.data.some(option => option.name === this.query);
      return this.filterable && this.allowCreate && this.query !== '' && !hasExistingOption;
    },
  },
  watch: {
    items(val) {
      this.data = [...val]
    },
    visible(val) {
      if (val) {
        this.data = [...this.items]
      }
    },
    // options(val) {
    //   console.log(_.map(this.options, o => o.label))
    // },
    hoverIndex(val) {
      if (typeof val === 'number' && val > -1) {
        this.hoverOption = this.options[val] || {};
      }
      this.options.forEach(option => {
        option.hover = this.hoverOption.currentLabel === option.currentLabel;
      });
    }
  },
  created() {
    const createdFn = Select.created.bind(this)
    createdFn()
  },
  mounted() {
    const mountedFn = Select.mounted.bind(this)
    mountedFn()
  },
  beforeDestroy() {
    clearTimeout(t);
    const beforeDestroyFn = Select.beforeDestroy.bind(this)
    beforeDestroyFn();
  },
  methods: {
    ...Select.methods,
    handleDebouncedOnInputChange(e) {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        return;
      }
      this.debouncedOnInputChange();
    },
    handleQueryChange(val) {
      // console.log('query', this.previousQuery, val)
      val = val ? val.toLowerCase() : val;
      if (this.previousQuery === val || this.isOnComposition) return
      if (
        this.previousQuery === null
        && (typeof this.filterMethod === 'function' || typeof this.remoteMethod === 'function')
      ) {
        this.previousQuery = val
        return
      }
      this.previousQuery = val
      this.$nextTick(() => {
        if (this.visible) this.broadcast('ElSelectDropdown', 'updatePopper')
      })
      this.hoverIndex = -1
      if (this.multiple && this.filterable) {
        this.$nextTick(() => {
          const length = this.$refs.input.value.length * 15 + 20
          this.inputLength = this.collapseTags ? Math.min(50, length) : length
          this.managePlaceholder()
          this.resetInputHeight()
        })
      }
      if (this.remote && typeof this.remoteMethod === 'function') {
        this.hoverIndex = -1
        this.remoteMethod(val)
      } else if (typeof this.filterMethod === 'function') {
        this.filterMethod(val)
        this.broadcast('ElOptionGroup', 'queryChange')
      } else if (this.filterKey) {
        // console.log('queryChanged...', 'query', this.query)
        if (val) {
          this.data = this.items.filter((item) => {
            const { filterKey } = this
            return item.hasOwnProperty(filterKey) && item[filterKey].indexOf(val) > -1
          })
        } else {
          this.data = [...this.items]
        }
        this.filteredOptionsCount = this.optionsCount
        this.broadcast('ElOption', 'queryChange', val)
        this.broadcast('ElOptionGroup', 'queryChange')
      } else {
        // do nothing
      }
      if (this.defaultFirstOption && (this.filterable || this.remote) && this.filteredOptionsCount) {
        this.checkDefaultFirstOption()
      }
    },
    scrollToOption(option) {
      const $option = Array.isArray(option) ? option[0] : option
      if ($option) {
        const index = _.findIndex(this.data, {name: $option.currentLabel});
        if (index < 0) {
          return;
        }
        this.scrollToPosition(index, $option.currentLabel);
      }
    },
    navigateOptions(direction) {
      if (!this.visible) {
        this.visible = true;
        return;
      }
      if (this.options.length === 0 || this.filteredOptionsCount === 0) return;
      const addOption = this.showNewOption ? 1 : 0;
      if (!this.optionsAllDisabled) {
        let _hoverIndex = _.findIndex(this.data, {name: _.get(this.options[this.hoverIndex], 'currentLabel')});
        if (direction === 'next') {
          _hoverIndex++;
          if (_hoverIndex >= this.data.length) {
            _hoverIndex = 0;
            this.hoverIndex = 0;
            const label = this.showNewOption ? this.query : _.get(this.data[_hoverIndex], 'name');
            this.scrollToPosition(_hoverIndex, label);
            return;
          } else {
            const label = _.get(this.data[_hoverIndex], 'name');
            this.hoverIndex = _.findIndex(this.options, o => {
              return _.get(o, 'currentLabel') === label;
            });
          }
        } else if (direction === 'prev') {
          _hoverIndex--;
          if (_hoverIndex < 0) {
            if (this.showNewOption && this.hoverIndex !== 0) {
              this.hoverIndex = 0;
            } else {
              _hoverIndex = this.data.length - 1;
              const label = _.get(this.data[_hoverIndex], 'name');
              this.scrollToPosition(_hoverIndex, label);
              return;
            }
          } else {
            const label = _.get(this.data[_hoverIndex], 'name');
            this.hoverIndex = _.findIndex(this.options, o => {
              return _.get(o, 'currentLabel') === label;
            });
          }
        }
        const label = _.get(this.data[_hoverIndex], 'name');
        const { start, end } = this.$refs.virtualScroller.getScroll();
        this.$nextTick(() => {
          if (direction === 'next') {
            if ((_hoverIndex + 1 + addOption) * this.itemSize > end) {
              this.scrollToPosition(_hoverIndex - 6, label);
            }
          } else if (_hoverIndex * this.itemSize < start) {
            this.scrollToPosition(_hoverIndex, label);
          }
        });
      }
    },
    scrollToPosition(index, label) {
      const position =  index ? (index + (this.showNewOption ? 1 : 0)) * this.itemSize : 0;
      this.$refs.virtualScroller.scrollToPosition(position)
      clearTimeout(t);
      t = setTimeout(() => {
        const hoverIndex = _.findIndex(this.options, o => {
          return _.get(o, 'currentLabel') === label;
        });
        this.hoverIndex = hoverIndex < 0 ? 0 : hoverIndex;
      }, 100);
    },
    onUpdate() {
      const elements = this.$refs.virtualScroller.$el.querySelectorAll('li.el-select-dropdown__item > span');
      let maxWidth = 0;
      for (let i = 0 ; i < elements.length ; i ++ ) {
        if (!elements[i].parentElement.parentElement.style.transform.includes('translateY(-9999px)') && elements[i].parentElement.style.display !== 'none' && maxWidth < elements[i].offsetWidth) {
          maxWidth = elements[i].offsetWidth;
        }
      }
      this.maxWidth = maxWidth + 50;
    }
  }

}
</script>
<style lang="scss">
.virtual-select {
  width: 300px;
}
.scroller {
  width: 100%;
  height: 257px;
  border-radius: 4px;
  background-color: rgba(0,0,0,0);
  -webkit-background-clip: text;
  transition: background-color .3s;

  scrollbar-color: rgba(0, 0, 0, 0) transparent;
  scrollbar-width: thin;
  transition: scrollbar-color .3s;

  &::-webkit-scrollbar {
    position: absolute;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 4px;
    margin-top: 2px;
    margin-bottom: 2px;
  }
 
  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: inherit;
    background-color: inherit;
    border-right: 2px solid transparent;
    border-left: 2px solid transparent;
    background-clip: padding-box;
  }

  &:hover {
    background-color: rgba(145, 145, 164, 0.3);
    scrollbar-color: rgba(145, 145, 164, 0.3) transparent;
  }

  li.el-select-dropdown__item {
    text-overflow: initial;
  }
}

  li.el-select-dropdown__item {
    list-style: none;
  }
</style>