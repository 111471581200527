/**
 * TwProcessGroupListの、セット項目繰り返し用辞書
 * containerNo、sealNo 等の無制限追加可能なセット項目を定義します
 * NOTE:
 *    該当の項目を、通常のgroupListItemでは非表示にし、
 *    TwProcessGroupListInfiniteItem、TwProcessGroupListInfiniteEditItemで表示する
 * 		BFFのレスポンスからは、配列形式で返却される
 */

// ※※ 通常表示しない項目の変数名を追加 ※※
// addInfiniteItemsのキーに設定する変数名は追加不要（例：containerType）
export const normallyHiddenItem = [
  'containerSize',
  'socFlag',
  // 'numberOfContainers',
  'containerComment',
  'temperatureInformation',
  'containerNo',
  'sealNo1',
  'sealNo2',
  'sealNo3',
  'sealNo4',
  'sealNo5',
  'sealNo6',
  'sealingParty1',
  'sealingParty2',
  'sealingParty3',
  'sealingParty4',
  'sealingParty5',
  'sealingParty6',
  'cargoGrossWeight',
  'cargoGrossWeightUnit',
  'cargoGrossVolume',
  'cargoGrossVolumeUnit',
  'containerTare',
  'containerWeightUnit',
]
// ※※ 通常表示しない項目の変数名の被りがある場合は以下に例外を記述する ※※
const exceptionallyItems = [
  {
    // 輸出許可書 numberOfContainers
    tableName: 'exportCustomsSeparate',
    groupName: 'exPermitNotifyDataGrp',
    key: 'numberOfContainers',
  },
  {
    // 輸入許可書 numberOfContainers
    tableName: 'importCustomsSeparate',
    groupName: 'imPermitNotifyDataGrp',
    key: 'numberOfContainers',
  },
]
// normallyHiddenItemに変数名があり、exceptionallyItemsに一致する例外がない場合は非表示
export const checkExceptionallyItems = (tableName, groupName, key) => {
  if (!(tableName && groupName && key)) return false;
  if (
    exceptionallyItems.some(item => {
      return tableName === item.tableName && groupName === item.groupName && key === item.key
    })
  ) return false;
  else return true;
}


// ※※ セット項目を追加するのに必要な情報を定義 ※※
// 配列形式のセット項目が増えた場合は、containerNoをベースに随時追加
export const addInfiniteItems = {
  // arrayContainerSeals -----------------------------
  containerType: { // 大元になる+ボタンが表示される基準となる変数名
    tableName: 'linkageTransportation',
    groupName: 'containerInfoGrp',
    setItems: [
      'containerSize',
      'socFlag',
      // 'numberOfContainers',
      'containerComment',
      'temperatureInformation',
      'containerNo',
      'sealNo1',
      'sealingParty1',
      'sealNo2',
      'sealingParty2',
      'sealNo3',
      'sealingParty3',
      'sealNo4',
      'sealingParty4',
      'sealNo5',
      'sealingParty5',
      'sealNo6',
      'sealingParty6',
      'cargoGrossWeight',
      'cargoGrossWeightUnit',
      'cargoGrossVolume',
      'cargoGrossVolumeUnit',
      'containerTare',
      'containerWeightUnit',
    ], // セット項目（複数追加可）
    arrayName: 'arrayContainerSeals', // BFFレスポンスの配列名
    max: 500, // 追加可能なMax値
  },
}
