<template>
  <div
    v-if="isVisible"
    class="tw_process_group_list_item"
    :class="{full: item.goodsTypeMasterList.attrType === ATTR_TYPE.TYPE_TXT_AREA}"
  >
    <div class="label">{{item.goodsTypeMasterList.goodsTypeNm}}</div>
    <div v-if="item.goodsTypeMasterList.attrType === ATTR_TYPE.TYPE_KBV && item.goodsTypeMasterList.goodsTypeKbv === 'SYS_MST_UNIT'" class="value">{{SYS_MST_UNIT}}</div>
    <div v-else-if="item.goodsTypeMasterList.attrType === ATTR_TYPE.TYPE_KBV && item.goodsTypeMasterList.goodsTypeKbv === 'UNIT_ROUND_TYPE'" class="value">{{UNIT_ROUND_TYPE}}</div>
    <div v-else-if="item.goodsTypeMasterList.attrType === ATTR_TYPE.TYPE_INT" class="value">{{number}}</div>
    <div v-else-if="item.goodsTypeMasterList.attrType === ATTR_TYPE.TYPE_TXT_AREA" class="value" v-html="sanitize(model)" />
    <div v-else class="value">{{model}}</div>
  </div>
</template>

<script>
import _ from 'lodash';
import { ATTR_TYPE, GOODS_ATTR_ID, UNIT_ROUND_TYPE_VARIABLES } from 'lib-tw-common';
import { formatQuantity } from '@/utils/searchUtil.js';

export default {
  name: 'TwProcessGroupListPreviewGoodsLineTypeItem',
  props: {
    item: Object,
  },
  inject: ['s'],
  components:{

  },
  data() {
    return {
      ATTR_TYPE,
      GOODS_ATTR_ID,
    };
  },
  computed: {
    model() {
      return _.get(this.s.res, `linkageGoodsLineType[${this.item.index}].keyGrp.goodsValue`);
    },
    isVisible() {
      if (this.item.goodsTypeMasterList.attrType === ATTR_TYPE.TYPE_FILE_PATH) {
        return false;
      }
      return this.item.goodsTypeMasterList.goodsTypeNm;
    },
    SYS_MST_UNIT() {
      return this.$store.getters.getSysMstUnit;
    },
    UNIT_ROUND_TYPE() {
      return _.get(_.find(UNIT_ROUND_TYPE_VARIABLES, {code: this.model}), 'label') || '';
    },
    number() {
      return formatQuantity(this.model)
    }
  },
  created() {
  },
  methods: {
    sanitize(str) {
      return this.$options.filters.newlines(str);
    },
  }
};
</script>

<style lang="scss" scoped>
  .tw_process_group_list_item {
    width: 50%;
    min-width: 300px;
    display: flex;
    margin-bottom: 12px;
    padding-right: 16px;

    &.full {
      width: 100%;
    }

    &:last-child {
      margin-bottom: 24px;
    }

    .label {
      width: 118px;
      font-size: 12px;
      line-height: 18px;
      color: #9191A4;
      margin-right: 8px;
      vertical-align: top;
      padding-top: 2px;
    }

    .value {
      font-size: 14px;
      line-height: 20px;
      color: #3E3A39;
      vertical-align: top;
      flex: 1;
    }
  }
</style>
