<template>
  <div class="ipdn_detail_inner pool_detail" ref="inner">
    <header :class="{ deleted: isDelete }">
      <div class='title'>
        <h1>
          {{$t('Label.I/P, D/N File')}}
        </h1>
        <tw-status-label v-if='isLatest === LATEST_FLG.OFF' statusName='INVALID' size="large" />
      </div>
      <img v-if="isDelete" class="delete" src="@/assets/images/delete.png" alt=''>
      <el-dropdown v-if="res && Object.keys(res).length" class="drawer" trigger="click" placement="bottom" @command="onDropDownClick" :tabindex="-1">
        <tw-button class="menu_button" type="secondary" size="small" icon="menu">Menu</tw-button>
        <el-dropdown-menu class="permit_drawer nowrap">
          <el-dropdown-item v-if="(documentsForm.attachLinks && documentsForm.attachLinks.length) || canAttach" command="upload">Attach File</el-dropdown-item>
          <el-dropdown-item command="tsv">{{$t('Label.TSV Download')}}</el-dropdown-item>
          <el-dropdown-item v-if="canAttach" command="delete">Delete</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </header>

    <tw-button v-if="res && Object.keys(res).length" type="secondary" size="small" class="toggle_button" :class="{ open: expand }" @click="openToggle">
      <img src="@/assets/images/icons/view_all.svg">
    </tw-button>

    <table class="card">
      <template v-if="res && Object.keys(res).length">
        <tw-process-list
          :isPermitHistory="true"
          v-for="table in filteredSchemas"
          :table="table" :key="table.name"
          :initialOpen="true"
          :initSelectIp="true"
          :initSelectDn="true"
          :subtaskHide="true"
        />
      </template>
    </table>
    <div v-if="isLinking" class="card _linking">
      <div class="item">
        <div class="title">Trading/FlowID</div>
        <div class="value">{{getTradingFlowId()}}</div>
      </div>
      <div class="item">
        <div class="title">Trading Name</div>
        <div class="value">{{res.tradingName}}</div>
      </div>
      <div class="item">
        <div class="title">Flow Name</div>
        <div class="value">{{res.tradingManageName}}</div>
      </div>
      <!-- TODO: Ph3で非表示に 次Ph以降で対応 -->
      <!-- <div class="item">
        <div class="title">Invoice No</div>
        <div class="value">{{res.invoiceNo}}</div>
      </div> -->
      <div class="item process">
        <div class="title">Process</div>
        <div class="value link" @click="link">{{res.processName}}</div>
      </div>
      <div class="item">
        <div class="title">From - To</div>
        <div class="value flex">
          <div class="company">
            <figure class="avatar">
              <img :src="res.tradingFromCompanyIcon" v-default-src="'user'">
            </figure>
            <el-tooltip placement="bottom" popper-class="mcu header_tooltip" :tabindex="-1">
              <div slot="content">
                <div class="company_name">{{res.tradingFromCompanyName}}<br />{{res.tradingFromSectionName}}</div>
              </div>
              <p class="name">{{res.tradingFromCompanyShortName}}</p>
            </el-tooltip>
          </div>
          <div class="while">-</div>
          <div class="company">
            <figure class="avatar">
              <img :src="res.tradingToCompanyIcon" v-default-src="'user'">
            </figure>
            <el-tooltip placement="bottom" popper-class="mcu header_tooltip" :tabindex="-1">
              <div slot="content">
                <div class="company_name">{{res.tradingToCompanyName}}<br />{{res.tradingToSectionName}}</div>
              </div>
              <p class="name">{{res.tradingToCompanyShortName}}</p>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>

    <!-- アップロードエリア -->
    <div class="attachment" @dragover.prevent="dragEnter" @dragleave.prevent="dragLeave" @drop.prevent="dropFile" :class="{enter: dragin}" multiple ref="attachment">
      <div class="attach">
        <div>Attach File</div>
        <div v-if="canAttach" class="choose_file">
          <tw-button type="secondary" size="medium" class="choose_file" @click="loadFile">
            Choose Local File
            <input ref="fileInput" class="file_input" @change="setFile" type="file" multiple :accept="'.' + WHITE_LIST.join(',.')">
          </tw-button>
        </div>
      </div>
      <!-- 添付済のファイルエリア -->
      <div v-for="(doc, index) in documentsForm.attachLinks" :key="`attach_${index}`" class="attach_links">
        <div class="file">
          <div class="inner">
            <div class="user">
              <figure class="avatar table"><img :src="doc.createUserIcon"></figure>
              <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
                <div slot="content">{{doc.createUserName}}</div>
                <div class="from_name">{{doc.createUserName}}</div>
              </el-tooltip>
            </div>
            <el-tooltip placement="top-start" popper-class="mcu" :tabindex="-1">
              <div slot="content">{{doc.attachFileName}}</div>
              <span>
                <a class="file_name" @click.prevent="download(doc)" tabindex="-1">{{doc.attachFileName}}</a>
              </span>
            </el-tooltip>
            <span class="meta">{{doc.attachFileSize | fileSize}},&nbsp;{{doc.createDate | dateTimeFormat}}</span>
          </div>
          <img v-if="canAttach" class="close_times" src="@/assets/images/icons/times_black.svg" @click.stop.prevent="removeFile(doc, index, 'after')">
        </div>
      </div>
      <template v-if="canAttach">
        <!-- 添付ファイルエリア -->
        <div v-for="(doc, index) in documentsForm.documents" :key="`documents_${index}`" class="attach_links">
          <div class="file">
            <div class="inner">
              <a class="file_name" tabindex="-1">{{doc.fileName}}</a>
              <span class="meta">{{doc.fileSize | fileSize}},&nbsp;{{doc.updateDate | dateTimeFormat}}</span>
            </div>
            <img class="close_times" src="@/assets/images/icons/times_black.svg" @click.stop.prevent="removeFile(doc, index, 'before')">
          </div>
        </div>
        <div class="droparea">
          <img src="@/assets/images/dropfile.svg">
          Drop files here to upload.
        </div>
        <!-- アップロードボタン -->
        <div class="buttons" v-if="documentsForm.documents.length > 0 || documentsForm.deleteDocuments.length > 0">
          <tw-button
            type="primary"
            size="medium"
            @click="update"
          >
            Update
          </tw-button>
        </div>
      </template>
    </div>

    <!-- <tw-to-top v-if="isIpDnHistory && mounted" :container="$refs.inner" class="detail-to-top" /> -->
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import { mapState } from 'vuex';
import TwProcessList from '@/components/organisms/TwProcessList';
// import TwToTop from '@/components/atoms/TwToTop';
import TwButton from '@/components/atoms/TwButton';
import { DELETE_FLG, IP_DN_LINK_FLG, IP_DN_TYPE_VARIABLES, LATEST_FLG, IP_REGIST_TYPE } from 'lib-tw-common';

import schemas from '@/dictionaries/processes/miip.json';

import { createIpDnData } from '@/utils/createIpDnData';
import TwStatusLabel from '@/components/atoms/TwStatusLabel.vue';

// スタブ
// import ipExample from '@/../public/ipExample.json';
// import dnExample from '@/../public/dnExample.json';

export default {
  name: 'TwIpDnDetailInner',
  components: {
    TwStatusLabel,
    TwProcessList,
    // TwToTop,
    TwButton
  },
  provide() {
    return {
      s: this,
      activeTab: null
    };
  },
  props: {
    ipdata: Object,
    dndata: Object,
    senderCompanyId: String,
    insurancePolicyNo: [String, Number],
    insurancePolicyBranchNo: [String, Number],
    trx: String,
    docType: String,
    ipDnType: [String, Number],
    ipDnTypeDn: [String, Number],
    editableFlag: Boolean,
    isPic: Boolean,
    isLatest: Number,
  },
  data() {
    return {
      WHITE_LIST: ['pdf', 'docx', 'doc', 'xlsx', 'xls', 'pptx', 'ppt', 'jpg', 'jpeg', 'png', 'gif', 'txt', 'zip', 'lzh', 'msg', 'eml'],
      res: {},
      schemas: schemas,
      expand: true,
      mounted: false,
      dragin: false,
      documentsForm: {
        attachLinks: [],
        documents: [],
        deleteDocuments: []
      },
      LATEST_FLG,
      // スタブ
      // dndata: dnExample,
      // attachLinks: [
      //   {
      //     attachLinkId: 'attachLinkId',
      //     docType: 'IP',
      //     keyCode1: 'keyCode1',
      //     keyCode2: 'keyCode2',
      //     keyCode3: 'keyCode3',
      //     keyCode4: 'keyCode4',
      //     keyCode5: 'keyCode5',
      //     attachFilePath: 'attachFilePath',
      //     attachFileName: 'attachFileName',
      //     attachFileSize: 10284,
      //     deleteFlg: 0,
      //     createUserId: 'createUserId',
      //     createUserName: 'createUserName',
      //     createDate: '20230101',
      //     createUserIcon: 'createUserIcon',
      //     updateUserId: 'updateUserId',
      //     updateDate: '20230101'
      //   },
      //   {
      //     attachLinkId: 'attachLinkId',
      //     docType: 'IP',
      //     keyCode1: 'keyCode1',
      //     keyCode2: 'keyCode2',
      //     keyCode3: 'keyCode3',
      //     keyCode4: 'keyCode4',
      //     keyCode5: 'keyCode5',
      //     attachFilePath: 'attachFilePath',
      //     attachFileName: 'attachFileName',
      //     attachFileSize: 10284,
      //     deleteFlg: 0,
      //     createUserId: 'createUserId',
      //     createUserName: 'createUserName',
      //     createDate: '20230101',
      //     createUserIcon: 'createUserIcon',
      //     updateUserId: 'updateUserId',
      //     updateDate: '20230101'
      //   }
      // ]
    };
  },
  computed: {
    ...mapState(['companyRoles']),
    // 添付可能フラグ 編集可能かつ担当者ロールのみ
    canAttach() {
      return !this.isLinking && !this.isDelete && this.editableFlag && this.isPic;
      // NOTE: 編集可能フラグeditableFlag サーバ側の判定処理（BFF設計書引用）
      // 書類タイプがIPの場合、IPのセクションID（登録者）
      // DNの場合、DNのセクションID（登録者）で判定する。
      // 3.所属セクションが、セクションID（登録者）に含まれている。
      // 且つ、削除フラグ＝0である。
      // 且つ、紐づけフラグが0である。場合、true
      // 上記以外場合、false
    },
    // スキーマを表示するテーブルのみにフィルタ NOTE: 輸出(輸入)通関個別、商品個別 remarksGrpは除く
    filteredSchemas() {
      let tables = _.cloneDeep(this.schemas.tables);
      tables = _.reject(tables, table => {
        if (table.variableName === 'linkageMarineInsurance' && this.res.linkageMarineInsurance && this.docType === 'IP') {
          return false;
        } else if (table.variableName === 'marineInsuranceSeparate' && this.res.marineInsuranceSeparate && this.docType === 'DN') {
          return false;
        } else return true;
      });
      const exclude = this.docType === 'DN' ? ['keyGrp', 'remarksGrp'] : ['keyGrp', 'insuranceTermsGrp', 'insurancePolicyGrp', 'forwarderIpGrp', 'remarksGrp'];
      _.forEach(tables, table => {
        table.groups = _.filter(table.groups, group => {
          return !exclude.includes(group.variableName);
        });
      });
      // console.log(tables)
      return tables;
    },
    // I/P情報表示用のデータを生成します（プロセス詳細）
    ipPreviewItems() {
      if (!_.get(this.res, 'linkageMarineInsurance.ippools')) {
        return [];
      }
      const ret = _.map(this.res.linkageMarineInsurance.ippools, pool => {
        return {
          docType: 'I/P',
          senderCompanyId: pool.policyGrp.senderCompanyId,
          ipRegistType: pool.ipRegistType,
          ipDnType: pool.policyGrp.ipDnType,
          ipDnTypeDn: _.get(pool, 'ipDnTypeDn'),
          shipperReferenceNo: pool.policyGrp.shipperReferenceNo,
          opOcProvNo: pool.policyGrp.opOcProvNo,
          insurancePolicyNo: pool.policyGrp.insurancePolicyNo,
          insurancePolicyBranchNo: pool.policyGrp.insurancePolicyBranchNo,
          invoiceNo: pool.invoiceGrp.invoiceNo,
          assuredsEtc: pool.assuredGrp.assuredsEtc
        };
      });
      return ret;
    },
    // D/N情報表示用のデータを生成します（プロセス詳細）
    dnPreviewItems() {
      if (!_.get(this.res, 'marineInsuranceSeparate.dnpools')) {
        return [];
      }
      const ret = _.map(this.res.marineInsuranceSeparate.dnpools, dn => {
        const targetIp = _.find(this.res.linkageMarineInsurance.ippools, ip => {
          // DNから追加したIP情報を検索
          const ipPolicy = ip.policyGrp;
          const dnPolicy = dn.policyGrp;
          // DNから追加したIPかどうか + IPとDNのキー情報が一致しているかどうか
          return (
            ip.ipRegistType === IP_REGIST_TYPE.DN &&
            ipPolicy.ipDnTypeDn === dnPolicy.ipDnType &&
            ipPolicy.senderCompanyId === dnPolicy.senderCompanyId &&
            ipPolicy.insurancePolicyNo === dnPolicy.insurancePolicyNo &&
            ipPolicy.insurancePolicyBranchNo === dnPolicy.insurancePolicyBranchNo &&
            ipPolicy.trx === dnPolicy.trx
          );
        });
        return {
          docType: 'D/N',
          senderCompanyId: dn.policyGrp.senderCompanyId,
          ipDnType: targetIp ? targetIp.policyGrp.ipDnType : '',
          ipDnTypeDn: dn.policyGrp.ipDnType,
          insurancePolicyNo: dn.policyGrp.insurancePolicyNo,
          insurancePolicyBranchNo: dn.policyGrp.insurancePolicyBranchNo,
          shipperReferenceNo: targetIp ? targetIp.policyGrp.shipperReferenceNo : '',
          opOcProvNo: targetIp ? targetIp.policyGrp.opOcProvNo : '',
          invoiceNo: targetIp ? targetIp.invoiceGrp.invoiceNo : '',
          assuredsEtc: targetIp ? targetIp.assuredGrp.assuredsEtc : ''
        };
      });
      return ret;
    },
    // 削除済かどうか
    isDelete() {
      return _.get(this.res, 'deleteFlg') === DELETE_FLG.DELETED;
    },
    // 紐付け済かどうか
    isLinking() {
      return _.get(this.res, 'linkingFlag') === String(IP_DN_LINK_FLG.LINKED);
    }
  },
  created() {
    // 添付ファイル一覧取得
    this.attachFetch();
    // IP,DN詳細情報をセット 一覧で詳細情報は全て受け取っているため個別のfetchは行わない
    if (this.docType === 'DN') {
      const ip = createIpDnData(this.dndata, 'IP', _.get(this, 'dndata.ipDnTypeDn'));
      const dn = createIpDnData(this.dndata, this.docType);
      this.$set(this.res, 'linkageMarineInsurance', {
        ippools: [ip]
      });
      this.$set(this.res, 'marineInsuranceSeparate', {
        dnpools: [dn]
      });
      this.res = _.merge(this.res, this.dndata);
    } else {
      const ip = createIpDnData(this.ipdata, this.docType);
      this.$set(this.res, 'linkageMarineInsurance', {
        ippools: [ip]
      });
      this.res = _.merge(this.res, this.ipdata);
    }
  },
  mounted() {
    this.mounted = true;
  },
  methods: {
    cloneDeep(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    openToggle() {
      this.expand = !this.expand;
      if (this.expand) this.$emit('openAll');
      else this.$emit('closeAll');
    },
    // I/P, D/Nタイプ
    getIpDnType(val) {
      const target = IP_DN_TYPE_VARIABLES.find(o => o.code === val);
      return target ? target.label : '';
    },
    // プロセス詳細へ遷移
    link() {
      this.$router.push({
        name: `Process${_.capitalize(this.res.processId.replace(/\d/g, ''))}`,
        params: { entityId: this.res.entityId, processSeq: this.res.processSeq, _processId: this.res.processId.toLowerCase() }
      });
    },
    getTradingFlowId() {
      const tradingId = this.res.tradingId;
      const flowId = this.res.tradingFlowId;
      return `${tradingId ? tradingId + '/' : ''}${flowId || ''}`;
    },
    // ドロップダウンメニュー
    onDropDownClick(command) {
      if (command === 'tsv') this.tsvDownload();
      else if (command === 'upload') {
        this.$refs.inner.scrollIntoView({ behavior: 'smooth', block: 'end' });
      } else {
        this.$store
          .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete this file?')
          .then(() => this.deletePermit())
          .catch(() => {});
      }
    },
    // TSVダウンロード
    tsvDownload() {
      // console.log(this.docType, this.res)
      const query = {
        docType: this.docType,
        ippools: [this.ipdata],
        dnpools: this.docType === 'DN' ? [this.dndata] : null
      };
      const ipDnType = this.docType === 'DN' ? this.ipDnTypeDn : this.ipDnType;
      const fileName = `${this.docType}_${this.getIpDnType(ipDnType)}.tsv`;
      // BFF_GMC02 業務TSVファイル出力共通BFF
      const apiCode = 'post_/v1/gm-pool-datas/download';
      $api
        .tsvDownload(query, fileName, 'tw-pooldata-bff-api', apiCode)
        .then(() => {})
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
    // IP,DN削除BFF
    async deletePermit() {
      this.$store.commit('START_PROCESS');
      let deleteItem = {
        docType: this.docType || '',
        senderCompanyId: this.senderCompanyId || '',
        insurancePolicyNo: this.insurancePolicyNo || '',
        insurancePolicyBranchNo: this.insurancePolicyBranchNo || '',
        ipDnType: this.ipDnType || '',
        trx: this.trx || ''
      };
      if (this.docType === 'DN') {
        deleteItem.ipDnTypeDn = this.ipDnTypeDn || null;
      }
      // BFF_IPP04 I/P,D/Nプール削除BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: 'post_/v1/ip-pool-datas/ip/delete'
        },
        data: {
          ipdnpools: [deleteItem]
        }
      };
      await $api
        .request(params)
        .then(async res => {
          const status = _.get(res, 'ippools[0].status') ||  _.get(res, 'ipdnpools[0].status');
          const message = _.get(res, 'ippools[0].errors.message') || _.get(res, 'ipdnpools[0].errors[0].message');
          if ((status < 200 || 299 < status) && message) {
            this.$store.dispatch('SHOW_ALERT', message);
          }
          this.$emit('deleted');
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          this.$store.commit('END_PROCESS');
        });
    },
    // 添付ファイル更新処理
    async update() {
      this.$store.commit('START_PROCESS');
      await this.attachDelete();
      await this.upload();
      await this.attachFetch();
      this.$store.commit('END_PROCESS');
    },
    // 添付ファイル一覧取得BFF
    async attachFetch() {
      this.documentsForm.attachLinks = [];
      // BFF_GMC04 添付ファイル一覧BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: 'get_/v1/gm-pool-datas/attach/search',
          query: {
            docType: this.docType,
            keyCode1: this.senderCompanyId,
            keyCode2: this.insurancePolicyNo,
            keyCode3: this.insurancePolicyBranchNo,
            keyCode4: this.docType === 'IP' ? this.trx : String(this.ipDnTypeDn),
            keyCode5: this.docType === 'IP' ? null : this.trx
          }
        }
      };
      await $api
        .request(params)
        .then(res => {
          this.documentsForm.attachLinks = _.sortBy(_.filter(res.attachLinks, attach => attach.deleteFlg === DELETE_FLG.NOT_DELETED), 'createDate').reverse();
          return Promise.resolve();
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          return Promise.reject();
        });
    },
    // 添付ファイル削除BFF
    async attachDelete() {
      if (!this.documentsForm.deleteDocuments.length) return Promise.resolve();
      // BFF_GMC07 添付ファイル削除BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: 'post_/v1/gm-pool-datas/attach/delete'
        },
        data: { attachLinkId: this.documentsForm.deleteDocuments }
      };
      await $api
        .request(params)
        .then(() => {
          this.documentsForm.deleteDocuments = [];
          return Promise.resolve();
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          return Promise.reject();
        });
    },
    // 添付ファイルアップロードBFF
    async upload() {
      if (!this.documentsForm.documents.length) return Promise.resolve();
      // BFF_GMC05 添付ファイル入力BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: 'post_/v1/gm-pool-datas/attach/upload'
        },
        data: {
          docType: this.docType,
          fileList: _.map(this.documentsForm.documents, doc => {
            return { fileName: doc.fileName, fileValue: doc.fileValue };
          }),
          keyCode1: this.senderCompanyId,
          keyCode2: this.insurancePolicyNo,
          keyCode3: this.insurancePolicyBranchNo,
          keyCode4: this.docType === 'IP' ? this.trx : String(this.ipDnTypeDn),
          keyCode5: this.docType === 'IP' ? null : this.trx
        }
      };
      await $api
        .request(params)
        .then(() => {
          this.documentsForm.documents = [];
          return Promise.resolve();
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          return Promise.reject();
        });
    },
    // 添付ファイル削除（type 登録前 = before、登録後 = after）
    removeFile(doc, index, type = 'before') {
      this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete this file?')
        .then(() => {
          if (type === 'after') {
            this.documentsForm.deleteDocuments.push(doc.attachLinkId);
            this.documentsForm.attachLinks.splice(index, 1);
          } else {
            this.documentsForm.documents.splice(index, 1);
          }
        })
        .catch(() => {});
    },
    // 添付ファイルダウンロード
    download(doc) {
      const query = { attachLinkId: doc.attachLinkId };
      const fileName = doc.attachFileName;
      // BFF_GMC06 添付ファイル出力BFF
      const apiCode = 'get_/v1/gm-pool-datas/attach/download';
      $api
        .txtDownload(query, fileName, 'tw-pooldata-bff-api', apiCode)
        .then(() => {})
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
    // アップロード処理
    loadFile() {
      this.$refs.fileInput.click();
    },
    async setFile(e) {
      if (!this.canAttach) return;
      // ファイルを取得して、fileList[]に格納
      this.fileList = [];
      if (_.get(e, 'target.files[0]')) {
        // ファイル選択（input）の場合の処理
        const files = e.target.files;
        _.forEach(files, file => {
          this.fileList.push(file);
        });
      } else if (_.get(e, 'dataTransfer.files') && _.get(e, 'dataTransfer.files').length > 0) {
        // ドラッグアンドドロップの場合の処理
        const files = _.get(e, 'dataTransfer.files');
        _.forEach(files, file => {
          this.fileList.push(file);
        });
      } else {
        this.$store.dispatch('Sorry, FileReader API not supported');
        return;
      }

      // ファイルをチェック
      // console.log(this.fileCheck(this.fileList))
      if (!this.fileCheck(this.fileList)) {
        return;
      }

      if (typeof FileReader === 'function') {
        for (let i = 0; i < this.fileList.length; i++) {
          const file = this.fileList[i];
          await this.createFile(file)
            .then(() => {})
            .catch(() => {
              this.$store.dispatch('Sorry, FileReader API not working');
              return;
            });
        }
      } else {
        this.$store.dispatch('Sorry, FileReader API not supported');
      }
    },
    // ファイルを格納
    async createFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = event => {
          // リクエスト生成
          this.documentsForm.documents.push({
            fileName: file.name,
            // 添付ファイルの場合はdecodeBuffer変換を行わず、readAsArrayBufferで読み取ったファイルをそのままBase64変換する
            fileValue: this.formatBase64(event.target.result),
            fileSize: file.size,
            updateDate: new Date()
          });
          resolve();
        };
        reader.onerror = () => {
          reject();
        };
        reader.readAsArrayBuffer(file);
        // console.log(this.documentsForm.documents);
      });
    },
    // 拡張子を取得します
    getFileExtension(filename) {
      const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
      return extension.toLowerCase();
    },
    // 読み取り結果をbase64に変換します
    formatBase64(str) {
      if (str) return Buffer.from(str).toString('base64');
      else return '';
    },
    // ファイルチェック
    fileCheck(files) {
      // console.log(files)
      if (files.length + this.documentsForm.documents.length > 10) {
        this.$store.dispatch('SHOW_ALERT', 'Maximum number of files[10] exceeded.');
        return false;
      }
      if (
        _.some(files, file => {
          if (file.name.length > 100) {
            this.$store.dispatch('SHOW_ALERT', 'Maximum length of file name[100(including extensions)] exceeded.');
            return true;
          }
        })
      ) {
        return false;
      }
      if (
        _.some(files, file => {
          if (file.size > 10485760) {
            this.$store.dispatch('SHOW_ALERT', 'Your file exceeds the maximum upload size: 10.0MB');
            return true;
          }
          if (file.size === 0) {
            this.$store.dispatch('SHOW_ALERT', 'Your file cannot be read');
            return true;
          }
        })
      ) {
        return false;
      }
      if (
        _.some(files, file => {
          if (!this.WHITE_LIST.includes(this.getFileExtension(file.name))) {
            this.$store.dispatch('SHOW_ALERT', 'Only files with the following extensions are allowed: pdf,docx,doc,xlsx,xls,pptx,ppt,jpg,jpeg,png,gif,txt,zip,lzh,eml,msg');
            return true;
          }
        })
      ) {
        return false;
      }
      return true;
    },
    // ファイル名取得
    getFileName(path) {
      return this.$options.filters.fileName(path);
    },
    dragEnter() {
      this.dragin = true;
    },
    dragLeave() {
      this.dragin = false;
    },
    dropFile(e) {
      this.dragin = false;
      this.setFile(e);
    }
  }
};
</script>

<style lang="scss" scoped>
.ipdn_detail_inner {
  padding: 30px 35px;
  position: relative;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .delete {
      margin: -30px 32px 0 auto;
    }

    &.deleted {
      flex-wrap: wrap;
      .drawer.el-dropdown {
        // width: 100%;
        .el-dropdown-link.ellipsis {
          margin: 0 16px 0 auto;
        }
      }
    }

    .title {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .column {
      height: 41px;
      display: flex;
      align-items: center;
      margin-bottom: 22px;
      &:last-child {
        justify-content: flex-end;
      }
    }
    .tw_entity_icon {
      margin-right: 8px;
    }
    .title {
      font-weight: bold;
      font-size: 24px;
      line-height: 35px;
      margin-right: 8px;
    }
    .tw_status_icon {
      margin-right: 24px;
    }
    .tw_button {
      margin-right: 24px;
    }
  }
  table.card {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    box-shadow: 0;
    overflow: hidden;
    margin-bottom: 20px;
    // box-shadow: 0px 4.11278px 8.22556px rgba(170, 170, 204, 0.5);
    &.excustom {
      margin-bottom: 32px;
      ::v-deep .tw_process_list > .inner {
        display: none;
      }
    }
  }
  .card._linking {
    margin: 40px 0 24px;
    padding: 24px;
    box-shadow: none;
    border: 1px solid $color_gray_300;
    border-radius: 6px;
    .item {
      display: flex;
      font-size: 14px;
      margin-bottom: 24px;
      &:hover {
        background: white;
        .value {
          color: $color_black;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      .title {
        width: 40%;
        color: $color_gray_800;
      }
      .value.flex {
        display: flex;
        align-items: center;
        .while {
          margin: 0 16px;
        }
      }
      .company {
        display: flex;
        align-items: center;
        figure.avatar {
          width: 32px;
          height: 32px;
          margin-right: 8px;
        }
        .name {
          max-width: 58px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &.process {
        .value {
          color: $color_dark_blue;
          &.link {
            cursor: pointer;
          }
        }
      }
    }
  }

  &.ipdn_history {
    height: 100vh;
    overflow-y: scroll;
  }
}

.attach_links {
  font-size: 14px;
  line-height: 20px;
  .file {
    position: relative;
    margin-top: 8px;
    padding-left: 16px;
    padding-right: 48px;
    border-radius: 6px;
    padding-top: 12px;
    padding-bottom: 12px;
    background: $color_gray_100;

    &:first-child {
      margin-top: 12px;
    }

    .inner {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .close_times {
      position: absolute;
      width: 24px;
      height: 24px;
      top: 50%;
      right: 16px;
      margin-top: -12px;
      cursor: pointer;
    }

    .user {
      max-width: 200px;
      margin-right: 12px;
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 20px;
      color: $color_black;
      .from_name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    a.file_name {
      display: block;
      max-width: 400px;
      color: $color_dark_blue;
      margin-right: 10px;
      cursor: pointer;
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.attach {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.attachment {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 40px;

  .choose_file {
    position: relative;
    display: inline-block;

    > button {
      margin-left: auto;
      display: block;
    }
    > span {
      color: $color_dark_blue;
      cursor: pointer;
    }

    .file_input {
      position: absolute;
      width: 1px;
      height: 1px;
      z-index: -1;
      pointer-events: none;
    }
  }

  .droparea {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 138px;
    margin: 16px 0 8px;
    background: $color_gray_100;
    border-radius: 6px;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    color: $color_dark_blue;
    pointer-events: none;
    z-index: 2;
    img {
      display: block;
      width: 84px;
      height: auto;
      margin-bottom: 9px;
    }
  }

  &.enter > * {
    pointer-events: none;
  }

  &.enter .droparea {
    border: 1px dashed $color_dark_blue;
  }
}
.buttons {
  display: flex;
  justify-content: right;
  padding: 24px;
}
</style>
<style lang="scss">
.el-dropdown-menu.el-popper.permit_drawer {
  z-index: 3000 !important;
}
</style>
