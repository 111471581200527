<template>
  <el-form-item
    v-if="isVisible && isSerialItem"
    :class="{ add_serial: (isAddIcon && itemIndex === 0 && arrayIndex + 1 < max) || (isAddSerial && !serialGroupName), remove_serial: (isAddIcon && isRemoveIcon) || (isRemoveSerial && !serialGroupName), height_auto: item.inputType === 'textarea' }"
    :label="getLabel"
    :prop="`${tableName}.${groupName}.${arrayName}[${arrayIndex}].${item.key}`"
    :rules="rules"
    ref="formItem"
  >
    <template v-if="isInActiveTab">
      <!-- コンテナタイプ区分 -->
      <el-select v-if="item.code === 'SYS_MST_CONTAINER_TYPE'" v-model="containerTypeModel" filterable clearable>
        <el-option
          v-for="type in SYS_MST_CONTAINER_TYPE"
          :key="type.code"
          :label="type.description"
          :value="type.code"
        />
      </el-select>
      <!-- SOCフラグ -->
      <el-radio-group v-else-if="item.code === 'SOC_FLAG'" v-model="inputModel">
        <el-radio v-for="radio in SOC_FLAG_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
      </el-radio-group>
      <!-- シール実施者 -->
      <el-select v-else-if="item.code === 'SEALING_PARTY'" v-model="pullDownModel" filterable clearable>
        <el-option
          v-for="unit in SEALING_PARTY_VARIABLES"
          :key="unit.code"
          :label="unit.label"
          :value="unit.code">
        </el-option>
      </el-select>
      <!-- 貨物重量（コンテナ） -->
      <template v-else-if="item.inputType === 'cargoGrossWeight'">
        <TwInputFormatNumber
          v-model="numberModel"
          class="narrow"
        />
        <el-select class="unit" v-model="unitTargetModel" @change="validateField" filterable clearable>
          <el-option
            v-for="unit in CARGO_GROSS_WEIGHT_UNIT_VARIABLES"
            :key="unit.code"
            :label="unit.label"
            :value="unit.code">
          </el-option>
        </el-select>
      </template>
      <!-- 貨物容積 -->
      <template v-else-if="item.inputType === 'cargoGrossVolume'">
        <TwInputFormatNumber
          v-model="numberModel"
          class="narrow"
        />
        <el-select class="unit" v-model="unitTargetModel" filterable clearable>
          <el-option
            v-for="unit in CARGO_GROSS_VOLUME_UNIT_VARIABLES"
            :key="unit.code"
            :label="unit.label"
            :value="unit.code">
          </el-option>
        </el-select>
      </template>
      <!-- コンテナ自重 -->
      <template v-else-if="item.inputType === 'containerTare'">
        <TwInputFormatNumber
          v-model="numberModel"
          class="narrow"
        />
        <el-select class="unit" v-model="unitTargetModel" filterable clearable>
          <el-option
            v-for="unit in CONTAINER_WEIGHT_UNIT_VARIABLES"
            :key="unit.code"
            :label="unit.label"
            :value="unit.code">
          </el-option>
        </el-select>
      </template>
      <!-- 数値 -->
      <TwInputFormatNumber
        v-else-if="item.inputType === 'number'"
        v-model="numberModel"
      />
      <!-- テキストエリア -->
      <el-input
        v-else-if="item.inputType === 'textarea'"
        type="textarea"
        :autosize="{ minRows: 2, maxRows: 6}"
        placeholder=""
        v-model="inputModel"
        show-word-limit
        :maxlength="getMaxLength"
      />
      <!-- 通常INPUT -->
      <el-input v-else style="width: 320px" v-model="inputModel" class="show_word_limit" show-word-limit :maxlength="getMaxLength" placeholder="" />

      <!-- × 連番項目削除ボタン（親） -->
      <img class="remove_button" src="@/assets/images/icons/times.svg" v-if="isAddIcon && isRemoveIcon" @click="addItem('remove')">
      <!-- + 連番項目追加ボタン（親） -->
      <tw-button v-if="isAddIcon && itemIndex === 0 && arrayIndex + 1 < max" type="secondary" size="medium" icon="plus" @click="addItem" class="add_button group_button"></tw-button>

      <!-- × 連番項目削除ボタン（子） -->
      <img class="remove_button" src="@/assets/images/icons/times.svg" v-if="isRemoveSerial && !serialGroupName" @click="addIndex(-1)">
      <!-- + 連番項目追加ボタン（子） -->
      <tw-button v-if="isAddSerial && !serialGroupName" type="secondary" size="medium" icon="plus" @click="addIndex(1)" class="add_button"></tw-button>
    </template>
  </el-form-item>
</template>

<script>
import _ from 'lodash';
import { RESPONSIBLE_FLG, SOC_FLAG_VARIABLES, SEALING_PARTY_VARIABLES, CARGO_GROSS_WEIGHT_UNIT_VARIABLES, CARGO_GROSS_VOLUME_UNIT_VARIABLES, CONTAINER_WEIGHT_UNIT_VARIABLES } from 'lib-tw-common';
// import { formatRawDate, formatRawDateTime, formatUtcDate, formatUtcOffsetDate, formatUtcOffsetDateTime, getMaxLength, onDateChange, onDateTimeChange, onTimeChange } from '@/utils/searchUtil.js';
import TwButton from '@/components/atoms/TwButton';
import TwInputFormatNumber from '@/components/atoms/TwInputFormatNumber';
// import TwInputNumberMax from '@/components/atoms/TwInputNumberMax';
import { getMaxLength } from '@/utils/searchUtil.js';
import { getKey, getSerialIndex, getItemIndex, getMaxIndex } from '@/utils/processedSerialItems';
// import dynamicRequiredItem from '@/dictionaries/dynamicRequiredItem.js';

export default {
  name: 'TwProcessGroupListInfiniteEditItem',
  props: {
    item: Object,
    tableName: String,
    groupName: String,
    value: Object,
    arrayIndex: Number,
    arrayName: String,
    itemIndex: Number,
    isAddIcon: Boolean,
    max: Number,
    serialIndexes: Array,
    serialItems: Array,
  },
  inject: ['s', 'tab', 'activeTab'],
  components:{
    TwInputFormatNumber,
    // TwInputNumberMax,
    TwButton,
  },
  data() {
    return {
      rules: [],
      SOC_FLAG_VARIABLES: SOC_FLAG_VARIABLES,
      SEALING_PARTY_VARIABLES: SEALING_PARTY_VARIABLES,
      CARGO_GROSS_WEIGHT_UNIT_VARIABLES: CARGO_GROSS_WEIGHT_UNIT_VARIABLES,
      CARGO_GROSS_VOLUME_UNIT_VARIABLES: CARGO_GROSS_VOLUME_UNIT_VARIABLES,
      CONTAINER_WEIGHT_UNIT_VARIABLES: CONTAINER_WEIGHT_UNIT_VARIABLES,
    };
  },
  computed: {
    isVisible() {
      if (!this.localValue) {
        return false;
      }
      if (this.item.hide) {
        return false;
      }
      return this.item[this.s.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM ? 'from' : 'to'];
    },
    getLabel() {
      if (this.item.key === 'containerType') {
        return `${this.item.label} ${this.arrayIndex + 1}`;
      } else {
        return this.item.label;
      }
    },
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    model() {
      return this.localValue[this.tableName][this.groupName][this.arrayName][this.arrayIndex][this.item.key];
    },
    // 通常インプット
    inputModel: {
      get() {
        return this.model;
      },
      set(value) {
        this.setValue(value);
      }
    },
    // 数値入力
    numberModel: {
      get() {
        return this.nullFormat(this.model);
      },
      set(value) {
        if (value === undefined) {
          this.setValue(null);
        } else {
          this.setValue(value);
        }
      }
    },
    // コンテナタイプ区分
    containerTypeModel: {
      get() {
        const target = this.item.unitTarget.split('.');
        const unitTarget = target.length ? `${target[0]}.${target[1]}.${this.arrayName}[${this.arrayIndex}].${target[2]}` : '';
        return (_.get(this.localValue, unitTarget) || '') + (this.model ? this.model : '');
      },
      set(value) {
        const type = _.get(_.find(_.get(this, '$store.state.systemProperties.SYS_MST_CONTAINER_TYPE') || [], {code: value}), 'type') || null;
        const size = _.get(_.find(_.get(this, '$store.state.systemProperties.SYS_MST_CONTAINER_TYPE') || [], {code: value}), 'size') || null;
        this.setValue(type);
        this.setTargetValue(this.getTarget(), size);
      }
    },
    pullDownModel: {
      get() {
        return this.model;
      },
      set(value) {
        if (!value) {
          this.setValue(null);
        } else {
          this.setValue(value);
        }
      }
    },
    unitTargetModel: {
      get() {
        return this.localValue[this.tableName][this.groupName][this.arrayName][this.arrayIndex][this.getTarget()];
      },
      set(value) {
        if (!value) {
          this.setTargetValue(this.getTarget(), null);
        } else {
          this.setTargetValue(this.getTarget(), value);
        }
      }
    },
    // コンテナタイプ区分値
    SYS_MST_CONTAINER_TYPE() {
      return this.$store.getters.getSysMstContainerType;
    },
    getMaxLength() {
      return getMaxLength(this.item.validationId);
    },
    // 配列削除ボタン表示
    isRemoveIcon() {
      return this.itemIndex === 0 && this.arrayIndex > 0 && this.checkGroupValues();
    },
    // 連番項目を可変にし追加可能にする対応--------------------------
    isSerialGrp() {
      return this.serialItems.length > 0;
    },
    isSerialItem() {
      if (this.serialIndex && this.selfIndex || this.serialIndex === 0) {
        return this.serialIndex >= this.selfIndex;
      }
      else return true;
    },
    serialIndex() {
      if (this.serialIndexes && this.serialIndexes.length) {
        return getSerialIndex(this.serialIndexes[this.arrayIndex], this.serialGroupName ? this.serialGroupName : this.item.key);
      } else return false;
    },
    selfIndex() {
      if (this.serialItems && this.isSerialGrp) {
        return getItemIndex(this.serialItems[this.arrayIndex], this.item.key);
      }
      else return false;
    },
    isAddSerial() {
      return this.isSerialGrp && this.selfIndex === this.serialIndex && this.serialIndex < getMaxIndex(this.serialItems[this.arrayIndex], this.item.key);
    },
    isRemoveSerial() {
      return this.checkChildGroupValues();
    },
    serialGroupName() {
      return _.get(this, `serialItems[${this.arrayIndex}][${getKey(this.item.key)}].groupName`) || false;
    },
    // // 動的必須バリデーション情報
    // requiredInfo() {
    //   const requiredInfo = _.get(dynamicRequiredItem, `${this.item.key}`, null);
    //   if (requiredInfo) {
    //     if (requiredInfo.processes && requiredInfo.processes.length) {
    //       // 該当プロセス指定がある場合はプロセスチェック
    //       if (requiredInfo.processes.includes(this.s.processType)) return requiredInfo;
    //       else return null;
    //     } else {
    //       // 該当プロセスがない場合は全プロセス対象
    //       return requiredInfo;
    //     }
    //   } else return null;
    // },
    // // 必須相関チェックターゲット値
    // requiredTarget() {
    //   return this.requiredInfo ? _.get(this, `localValue[${this.requiredInfo.tableName}][${this.requiredInfo.groupName}][${this.requiredInfo.arrayName}][${this.arrayIndex}][${this.requiredInfo.targetKey}]`, null) : undefined;
    // },
    // アクティブなタブ配下にいるか
    isInActiveTab() {
      if (!this.tab && this.tab !== 0) {
        return true;
      }
      
      return _.get(this.activeTab, 'value') === this.tab;
    },
  },
  watch: {
    // async requiredTarget(val) {
    //   // 必須バリデーション変更
    //   await this.checkRequired(val);
    //   // バリデーションルール変更時にバリデーションを走らせる
    //   this.s.$refs.form.validateField(`${this.tableName}.${this.groupName}.${this.arrayName}[${this.arrayIndex}].${this.item.key}`);
    // },
    's.draftValid': {
      handler() {
        this.rules = this.createRules();
        // this.checkRequired(this.requiredTarget);
      },
    },
  },
  created() {
    this.rules = this.createRules();
    // this.checkRequired(this.requiredTarget);
  },
  methods: {
    // スコープのモデルに値をセットします
    setValue(value) {
      this.s.setArrayValue(this.tableName, this.groupName, this.arrayName, this.arrayIndex, this.item.key, value);
    },
    // スコープ外のモデルに値をセットします
    setTargetValue(target, value) {
      this.s.setArrayValue(this.tableName, this.groupName, this.arrayName, this.arrayIndex, target, value);
    },
    getTarget(target = this.item) {
      return _.last(target.unitTarget.split('.'));
    },
    nullFormat(value) {
      if (value === null || '') return undefined;
      else if (_.isNaN(Number(value))) return undefined;
      else return Number(value);
    },
    onRadioClick(val, key) {
      if (this.model === val) {
        this.setTargetValue(key, null);
      } else {
        this.setTargetValue(key, val);
      }
    },
    // 配列にオブジェクト追加
    addItem(type) {
      this.$emit('add-item', type);
    },
    // 連番項目を追加・削除します
    addIndex(num) {
      this.$emit('add-index', this.item.key, num, this.arrayIndex);
    },
    // 連番項目の同じグループに値が入っているかチェックします（親となる配列）
    checkGroupValues() {
      const obj = this.localValue[this.tableName][this.groupName][this.arrayName][this.arrayIndex];
      if (_.some(obj, item => {
        if (_.isObject(item)) return false
        else if (item || item === 0) return true;
        else return false;
      })) {
        return false;
      } else return true;
    },
    // 連番項目削除のグループチェック（sealNo等の子となるオブジェクト）
    checkChildGroupValues() {
      if (!(this.isSerialGrp && this.selfIndex === this.serialIndex && this.serialIndex > 0)) {
        return false;
      }
      if (_.some(this.serialItems[this.arrayIndex], o => {
        const key = _.get(o, `items[${this.selfIndex}].key`);
        const value = _.get(this.localValue, `[${this.tableName}][${this.groupName}][${this.arrayName}][${this.arrayIndex}][${key}]`);
        if (key && (value || value === 0)) return true;
        else return false;
      })) {
        return false;
      } else return true;
    },
    createRules() {
      const rules = [
        {
          required: this.s.draftValid ? false : this.item.validationId.includes('tw-isNotEmpty'),
          lakeelMessageSourceFlg: true,
          validationId: this.s.draftValid ? _.reject(this.item.validationId, s => s === 'tw-isNotEmpty') : this.item.validationId,
          validator: this.s.onEventValidation, trigger: (['select', 'radio', 'checkbox', 'unit', 'currencyId'].includes(this.item.inputType) || ['SYS_MST_CONTAINER_TYPE', 'SYS_MST_PORT_AND_PLACE', 'SYS_MST_COUNTRY'].includes(this.item.code)) ? 'change' : 'blur',
        }
      ];
      if (this.item.tsValidationId) {
        const tsValidationIds = _.map(this.item.tsValidationId, tsValidationId => {
          return {
            required: this.s.draftValid ?  false : tsValidationId.startsWith('ts-isNotEmpty'),
            validator: this.s.tsValidation,
            tsValidationId: this.s.draftValid && (tsValidationId.startsWith('ts-isNotEmpty') && tsValidationId !== 'ts-isNotEmptyInvoiceAmountOrIpreqCargoAmount') ? undefined : tsValidationId,
            trigger: (['select', 'radio', 'checkbox', 'unit', 'currencyId'].includes(this.item.inputType) || ['SYS_MST_CONTAINER_TYPE','SYS_MST_PORT_AND_PLACE', 'SYS_MST_COUNTRY'].includes(this.item.code)) ? 'change' : 'blur',
          }
        });
        return [...tsValidationIds, ...rules];
      }
      return rules;
    },
    validateField() {
      if (this.item.tsValidationId) {
        this.s.$refs.form.validateField(`${this.tableName}.${this.groupName}.${this.arrayName}[${this.arrayIndex}].${this.item.key}`);
      }
    },
    // // 動的必須バリデーションチェック
    // async checkRequired(val) {
    //   if (val === undefined || this.s.draftValid) return;
    //   const resultValid = this.requiredInfo.validation(val);
    //   if (resultValid) await this.changeRequired(this.s.draftValid ? false : true);
    //   else await this.changeRequired(false);
    // },
    // // 必須バリデーション切替
    // async changeRequired (bool) {
    //   // 直接blurされたときの対策 blurでバリデーションが先に走り処理後にエラーがサーバから返ってくるため処理をずらす
    //   await new Promise((resolve) => setTimeout(resolve, 500));
    //   let rule = this.rules[0];
    //   rule.required = bool;
    //   if (bool) rule.validationId.push('tw-isNotEmpty');
    //   else rule.validationId = _.reject(rule.validationId, s => s === 'tw-isNotEmpty');
    // }
  }
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 320px;
}
.el-select.unit {
  width: 90px;
  margin-left: 12px;
}
.el-input-number.narrow {
  width: 218px;
}
.el-input-number, .el-input-number.amount {
  width: 320px;
}
.el-radio-group {
  width: 320px;
}
.el-form-item {
  min-width: 462px;
  height: 40px;
  &.height_auto {
    height: auto;
    min-height: 54px;
  }
  margin-bottom: 24px !important;
  // width: 50%;
  display: flex;
  padding-right: 16px;
  &:last-child {
    margin-bottom: 20px;
  }
  &.full {
    width: 100%;
  }
  ::v-deep label.el-form-item__label {
    display: block;
    width: 118px !important;
    font-size: 12px;
    line-height: 18px;
    color: #9191A4;
    margin-right: 8px;
    padding-top: 2px;
  }
  &.remove_serial {
    .el-input,
    .el-select {
      width: calc(320px - 20px) !important;
    }
    .el-textarea,
    .text_full {
      width: calc(100% - 20px) !important;
    }
    .remove_button {
      cursor: pointer;
      width: 20px;
      vertical-align: middle;
    }
    &.full {
      .remove_button {
        margin-bottom: 16px;
      }
    }
  }
  &.add_serial {
    .el-input,
    .el-select {
      width: calc(320px - 48px) !important;
    }
    .el-textarea,
    .text_full {
      width: calc(480px - 48px) !important;
    }
    .add_button {
      vertical-align: initial;
      margin: 0 0 0 8px;
      padding-left: 9px;
      padding-right: 1px;
      &.group_button {
        border-radius: 10px;
      }
    }
    &.remove_serial {
      .el-input,
      .el-select {
        width: calc(320px - 68px) !important;
      }
      .el-textarea,
      .text_full {
        width: calc(100% - 68px) !important;
      }
    }
    &.full {
      .add_button {
        margin-bottom: 8px;
      }
      .remove_button {
        margin-bottom: 6px;
      }
    }
  }
}
</style>
