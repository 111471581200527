<template>
  <div>
    <div class="twoColumn">
      <template v-for="(goods, index) in localValue.linkageGoodsLinePacking">
        <template v-for="group in linkageGoodsLinePacking">
          <tw-process-group-list-edit-goods-line-item
            v-for="item in group.children"
            v-show="index === selectPackingIndex"
            :key="item.key + '_' + index"
            :item="item" :selectGoodsIndex="index"
            :tableName="'linkageGoodsLinePacking'"
            :groupName="group.variableName"
            :parentGroup="group"
            v-model="localValue"
            :serialItems="serialsParent.linkageGoodsLinePacking[index][group.variableName].serialItems"
            :serialIndexes="serialsParent.linkageGoodsLinePacking[index][group.variableName].serialIndexes"
            :index="index"
            @add-index="addIndex"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import BigNumber from 'bignumber.js';
import TwProcessGroupListEditGoodsLineItem from '@/components/molecules/TwProcessGroupListEditGoodsLineItem';
import { serialGrpName, getSerialGrpItems, getSerialGrpIndex, getKey } from '@/utils/processedSerialItems';

export default {
  name: 'TwProcessGroupListEditGoodsLinePacking',
  props: {
    value: Object,
    selectPackingGoodsLineSeq: Number,
    selectPackingLineSeq: Number,
    selectPackingSublineSeq: Number,
    selectGoodsId: String,
    selectGoodsShortName: String,
    orderedGoodsLinePacking: Array,
  },
  inject: ['s'],
  components: {
    TwProcessGroupListEditGoodsLineItem,
  },
  data() {
    return {
      override: false,
      serialsParent: {}, // 連番項目グループ
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    linkageGoodsLinePacking() {
      const linkageGoodsLinePacking = _.find(this.s.schemas.tables, {variableName: 'linkageGoodsLinePacking'});
      let ret = [
        _.find(linkageGoodsLinePacking.groups, {variableName: 'netGrossMeasurementGrp'}),
        _.find(linkageGoodsLinePacking.groups, {variableName: 'remarksGrp'}),
      ];
      ret[0] = {
        ...ret[0],
        children: _.orderBy(_.filter(ret[0].children, o => {
          return o.key.startsWith('shippingMark') || o.key.startsWith('package') || o.key.startsWith('innerPacking');
        }), 'key').concat(_.filter(ret[0].children, {key: 'containerNo'})),
      };
      return ret;
    },
    selectPackingIndex() {
      if (!_.get(this, 'localValue.linkageGoodsLinePacking')) {
        return false;
      }
      const selectPackingIndex = _.findIndex(this.localValue.linkageGoodsLinePacking, packing => {
        // console.log(_.get(goods, 'goodsGrp.goodsId'));
        return _.get(packing, 'keyGrp.goodsLineSeq') === this.selectPackingGoodsLineSeq && _.get(packing, 'keyGrp.packingLineSeq') === this.selectPackingLineSeq && _.get(packing, 'keyGrp.packingSublineSeq') === this.selectPackingSublineSeq;
      });
      return selectPackingIndex;
    },
    _length() {
      return _.get(this, `localValue.linkageGoodsLinePacking[${this.selectPackingIndex}].netGrossMeasurementGrp.netLength`);
    },
    width() {
      return _.get(this, `localValue.linkageGoodsLinePacking[${this.selectPackingIndex}].netGrossMeasurementGrp.netWidth`);
    },
    height() {
      return _.get(this, `localValue.linkageGoodsLinePacking[${this.selectPackingIndex}].netGrossMeasurementGrp.netHeight`);
    },
    grossLength() {
      return _.get(this, `localValue.linkageGoodsLinePacking[${this.selectPackingIndex}].netGrossMeasurementGrp.grossLength`);
    },
    grossWidth() {
      return _.get(this, `localValue.linkageGoodsLinePacking[${this.selectPackingIndex}].netGrossMeasurementGrp.grossWidth`);
    },
    grossHeight() {
      return _.get(this, `localValue.linkageGoodsLinePacking[${this.selectPackingIndex}].netGrossMeasurementGrp.grossHeight`);
    },
    drawerShow() {
      return this.$store.state.drawerShow;
    },
    hasError() {
      return names => {
        const filtered = _.filter(this.s.errors, (e) => {
          return _.some(names, name => {
            if (_.isString(e)) {
              return e.startsWith(name + '[');
            }
            return false;
          });
        });
        return filtered;
      }
    }
  },
  created() {
     // 連番項目init処理
    this.initSerial('linkageGoodsLinePacking');
  },
  watch: {
    // _length: {
    //   handler() {
    //     requestAnimationFrame(() => {
    //       this.setMeasurementTotal();
    //     });
    //   },
    //   // immediate: true,
    // },
    // width() {
    //   requestAnimationFrame(() => {
    //     this.setMeasurementTotal();
    //   });
    // },
    // height() {
    //   requestAnimationFrame(() => {
    //     this.setMeasurementTotal();
    //   });
    // },
    // grossLength: {
    //   handler() {
    //     requestAnimationFrame(() => {
    //       this.setGrossMeasurementTotal();
    //     });
    //   },
    //   // immediate: true,
    // },
    // grossWidth() {
    //   requestAnimationFrame(() => {
    //     this.setGrossMeasurementTotal();
    //   });
    // },
    // grossHeight() {
    //   requestAnimationFrame(() => {
    //     this.setGrossMeasurementTotal();
    //   });
    // },
    drawerShow(val) {
      if (!val) {
        this.resetSelect();
      }
    },
    // goods追加時、連番項目を初期化
    'localValue.linkageGoodsLinePacking': {
      handler() {
        this.initSerial('linkageGoodsLinePacking');
      }
    },
  },
  methods: {
    isOverFlow(value, integer = 9, decimal = 6) {
      const ary = (value + '').split('.');
      if (ary[0] && ary[0].length > integer) {
        return true;
      } else if (ary[1] && ary[1].length > decimal) {
        return true;
      }
      return false;
    },
    calcMeasurementTotal() {
      if (!this._length && !this.width && !this.height) {
        return undefined;
      }
      const _length = _.isNumber(this._length) ? this._length : 1;
      const width = _.isNumber(this.width) ? this.width : 1;
      const height = _.isNumber(this.height) ? this.height : 1;
      const result = BigNumber(_length).times(BigNumber(width)).times(BigNumber(height)).dp(6).toNumber();
      return result;
    },
    setMeasurementTotal() {
      if (!_.isNumber(this.selectPackingIndex) || this.selectPackingIndex < 0) {
        return;
      }
      const value = this.calcMeasurementTotal();
      if (this.isOverFlow(value)) {
        this.s.setGoodsValue('linkageGoodsLinePacking', this.selectPackingIndex, 'netGrossMeasurementGrp', 'netMeasurementTotal',  null);
        if (value) this.$set(this.s.measurementOverflow, this.selectPackingIndex, true);
      } else {
        this.s.setGoodsValue('linkageGoodsLinePacking', this.selectPackingIndex, 'netGrossMeasurementGrp', 'netMeasurementTotal',  value);
        this.$set(this.s.measurementOverflow, this.selectPackingIndex, false);
      }
      this.override = false;
    },
    calcGrossMeasurementTotal() {
      if (!this.grossLength && !this.grossWidth && !this.grossHeight) {
        return undefined;
      }
      const _length = _.isNumber(this.grossLength) ? this.grossLength : 1;
      const width = _.isNumber(this.grossWidth) ? this.grossWidth : 1;
      const height = _.isNumber(this.grossHeight) ? this.grossHeight : 1;
      const result = BigNumber(_length).times(BigNumber(width)).times(BigNumber(height)).dp(6).toNumber();
      return result;
    },
    setGrossMeasurementTotal() {
      if (!_.isNumber(this.selectPackingIndex) || this.selectPackingIndex < 0) {
        return;
      }
      const value = this.calcGrossMeasurementTotal();
      if (this.isOverFlow(value)) {
        this.s.setGoodsValue('linkageGoodsLinePacking', this.selectPackingIndex, 'netGrossMeasurementGrp', 'grossMeasurementTotal',  null);
        // this.$set(this.s.measurementGrossOverflow, this.selectPackingIndex, true);
      } else {
        this.s.setGoodsValue('linkageGoodsLinePacking', this.selectPackingIndex, 'netGrossMeasurementGrp', 'grossMeasurementTotal',  value);
        // this.$set(this.s.measurementGrossOverflow, this.selectPackingIndex, false);
      }
      this.override = false;
    },
    showDialog() {
      const queries = this.$store.getters.getQueries('CUSTOMER');
      if (queries) {
        this.searchForm = queries;
        this.search();
      } else if (!this.init) {
        this.fetch();
      }

      this.init = true;
      this.dialogAddGoodsVisible = true;
    },
    editPackingClick(row, column, event) {
      if (column.property === 'Delete' && event.target.tagName.toLowerCase() === 'img') {
        event.preventDefault();
        event.stopPropagation();
        this.removePacking(row);
        return;
      }
      // 商品梱包の識別子はpackingLineSeq
      if (row) {
        this.selectPackingLineSeq = row.packingLineSeq;
        this.selectPackingSublineSeq = row.packingSublineSeq;
      }
    },
    resetSelect() {
      this.selectPackingLineSeq = null;
      this.selectPackingSublineSeq = null;
    },
    removePacking(row) {
      // console.log(this.selectPackingLineSeq, row.packingLineSeq);
      this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete the goods data?')
        .then(() => {
          // 商品明細から削除
          const packingLineIndex = _.findIndex(this.localValue.linkageGoodsLinePacking, packing => {
            return packing.keyGrp.goodsLineSeq === row.goodsLineSeq && packing.keyGrp.packingLineSeq === row.packingLineSeq && packing.keyGrp.packingSublineSeq === row.packingSublineSeq;
          });
          this.localValue.linkageGoodsLinePacking.splice(packingLineIndex, 1);
          if (this.selectPackingLineSeq === row.packingLineSeq) {
            this.resetSelect();
          }
        })
        .catch(() => {});
    },
    // 同じ梱包データかを返却します
    isEqualPacking(o, row) {
      return o.goodsLineSeq === row.goodsLineSeq && o.packingLineSeq === row.packingLineSeq && o.packingSublineSeq === row.packingSublineSeq;
    },
    // remarks等の連番項目をグループ化し可変にします
    initSerial(t_name) {
      this.serialsParent[t_name] = [];
      _.forEach(this.localValue[t_name], (items, parent_key) => {
        this.serialsParent[t_name][parent_key] = {};
        _.forEach(items, (item, key) => {
          const res = this.localValue[t_name][parent_key][key];
          const serialItems = serialGrpName.includes(key) ? getSerialGrpItems(res, key) : {};
          let serialIndexes = {};
          if (Object.keys(serialItems).length > 0) serialIndexes = getSerialGrpIndex(serialItems);
          this.serialsParent[t_name][parent_key][key] = { serialItems: serialItems, serialIndexes: serialIndexes };
        });
      });
    },
    // 連番項目を追加 or 削除します
    addIndex(t_name, index, g_name, name, num) {
      const key = getKey(name);
      this.serialsParent[t_name][index][g_name].serialIndexes[key] += num;
      this.serialsParent = this.s.cloneDeep(this.serialsParent);
    },
  }
};
</script>

<style lang="scss" scoped>
  .twoColumn {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    padding-left: 16px;
  }

  .goods_line_packing ::v-deep .tw_table {
    width: calc(100% - 32px);
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 4px 8px rgba(170, 170, 204, 0.5)!important;
    border-radius: 8px!important;
  }
</style>
