<template>
  <tbody @click="$emit('click', index)" :class="{selected: selected, invalid: invalid}">
    <tr>
      <td v-for="column in goodsLineTable" :key="column.label" :style="{width: column.width + 'px' || 'auto'}">
        <tw-process-group-list-edit-goods-line-item :item="column.item" :selectGoodsIndex="getIndex(column)" :tableName="column.table" :groupName="column.group" :parentGroup="column.parentGroup" :readonly="column.readonly" :tableView="true" v-model="localValue" />
      </td>
    </tr>
    <tr>
      <td class="goods_line_table_inner" :colspan="goodsLineTable.length" cellspacing="0" cellpadding="0">
        <img class="close_times hidden" src="@/assets/images/icons/times.svg" @click.stop.prevent="$emit('delete-goods')">
        <table style="width: 100%" cellspacing="0" cellpadding="0" border="0">
          <tr>
            <th v-for="column in goodsLineTable2" :key="column.label" :style="{width: column.width + 'px' || 'auto'}">{{column.label}}</th>
          </tr>
          <tr>
            <td v-for="column in goodsLineTable2" :key="column.label">
              <tw-process-group-list-edit-goods-line-item :item="column.item" :selectGoodsIndex="getIndex(column)" :tableName="column.table" :groupName="column.group" :parentGroup="column.parentGroup" :tableView="true" v-model="localValue" />
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </tbody>
</template>

<script>
import _ from 'lodash';
import BigNumber from 'bignumber.js';
import TwProcessGroupListEditGoodsLineItem from '@/components/molecules/TwProcessGroupListEditGoodsLineItem';
import goodsLinePackingTableSchema from '@/dictionaries/goodsLinePackingTableSchema.json';
import goodsLinePackingTableSchema2 from '@/dictionaries/goodsLinePackingTableSchema2.json';

export default {
  name: 'TwProcessGroupListEditGoodsLinePackingTable',
  props: {
    value: Object,
    selectGoodsLineSeq: Number,
    index: Number,
    selected: Boolean,
    hasError: Array,
    line: Object,
  },
  inject: ['s'],
  components: {
    TwProcessGroupListEditGoodsLineItem,
  },
  data() {
    return {
      goodsLinePackingTableSchema: goodsLinePackingTableSchema,
      goodsLinePackingTableSchema2: goodsLinePackingTableSchema2,
      override: false,
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    goodsLineTable() {
      return this.getTableData(this.goodsLinePackingTableSchema);
    },
    goodsLineTable2() {
      return this.getTableData(this.goodsLinePackingTableSchema2);
    },
    // 選択した商品ラインのインデックス
    getIndex() {
      return column => {
        if (!_.get(this, `localValue[${column.table}]`)) {
          return false;
        }
        const selectGoodsIndex = _.findIndex(this.localValue[column.table], packing => {
          if (column.table === 'linkageGoodsLine') {
            return _.get(packing, 'keyGrp.goodsLineSeq') === this.line.keyGrp.goodsLineSeq;
          }
          return _.get(packing, 'keyGrp.goodsLineSeq') === this.line.keyGrp.goodsLineSeq &&  _.get(packing, 'keyGrp.packingLineSeq') === this.line.keyGrp.packingLineSeq && _.get(packing, 'keyGrp.packingSublineSeq') === this.line.keyGrp.packingSublineSeq;
        });
        return selectGoodsIndex;
      }
    },
    selectPackingIndex() {
      if (!_.get(this, 'localValue.linkageGoodsLinePacking')) {
        return false;
      }
      const selectPackingIndex = _.findIndex(this.localValue.linkageGoodsLinePacking, packing => {
        // console.log(_.get(goods, 'goodsGrp.goodsId'));
        return _.get(packing, 'keyGrp.goodsLineSeq') === this.line.keyGrp.goodsLineSeq && _.get(packing, 'keyGrp.packingLineSeq') === this.line.keyGrp.packingLineSeq && _.get(packing, 'keyGrp.packingSublineSeq') === this.line.keyGrp.packingSublineSeq;
      });
      return selectPackingIndex;
    },
    // 選択した商品価格のインデックス
    selectGoodsLinePriceIndex() {
      if (!_.get(this, 'localValue.linkageGoodsLinePrice')) {
        return false;
      }
      const selectGoodsLinePriceIndex = _.findIndex(this.localValue.linkageGoodsLinePrice, price => {
        return _.get(price, 'keyGrp.goodsLineSeq') === this.selectGoodsLineSeq;
      });
      return selectGoodsLinePriceIndex;
    },
    _length() {
      return _.get(this, `localValue.linkageGoodsLinePacking[${this.selectPackingIndex}].netGrossMeasurementGrp.netLength`);
    },
    width() {
      return _.get(this, `localValue.linkageGoodsLinePacking[${this.selectPackingIndex}].netGrossMeasurementGrp.netWidth`);
    },
    height() {
      return _.get(this, `localValue.linkageGoodsLinePacking[${this.selectPackingIndex}].netGrossMeasurementGrp.netHeight`);
    },
    grossLength() {
      return _.get(this, `localValue.linkageGoodsLinePacking[${this.selectPackingIndex}].netGrossMeasurementGrp.grossLength`);
    },
    grossWidth() {
      return _.get(this, `localValue.linkageGoodsLinePacking[${this.selectPackingIndex}].netGrossMeasurementGrp.grossWidth`);
    },
    grossHeight() {
      return _.get(this, `localValue.linkageGoodsLinePacking[${this.selectPackingIndex}].netGrossMeasurementGrp.grossHeight`);
    },
    invalid() {
      if (_.isEmpty(this.hasError)) {
        return false;
      } 
      return _.some(this.hasError, e => {
        return e.startsWith(`linkageGoodsLinePacking[${this.selectPackingIndex}]`);
      });
    }
  },
  watch: {
    _length: {
      handler() {
        requestAnimationFrame(() => {
          this.setMeasurementTotal();
        });
      },
      // immediate: true,
    },
    width() {
      requestAnimationFrame(() => {
        this.setMeasurementTotal();
      });
    },
    height() {
      requestAnimationFrame(() => {
        this.setMeasurementTotal();
      });
    },
    grossLength: {
      handler() {
        requestAnimationFrame(() => {
          this.setGrossMeasurementTotal();
        });
      },
      // immediate: true,
    },
    grossWidth() {
      requestAnimationFrame(() => {
        this.setGrossMeasurementTotal();
      });
    },
    grossHeight() {
      requestAnimationFrame(() => {
        this.setGrossMeasurementTotal();
      });
    },
  },
  methods: {
    // 値が指定桁を超えているかどうかを返却します
    isOverFlow(value, integer = 9, decimal = 6) {
      const ary = (value + '').split('.');
      if (ary[0] && ary[0].length > integer) {
        return true;
      } else if (ary[1] && ary[1].length > decimal) {
        return true;
      }
      return false;
    },
    calcMeasurementTotal() {
      if (!this._length && !this.width && !this.height) {
        return undefined;
      }
      const _length = _.isNumber(this._length) ? this._length : 1;
      const width = _.isNumber(this.width) ? this.width : 1;
      const height = _.isNumber(this.height) ? this.height : 1;
      const result = BigNumber(_length).times(BigNumber(width)).times(BigNumber(height)).dp(6).toNumber();
      return result;
    },
    setMeasurementTotal() {
      if (!_.isNumber(this.selectPackingIndex) || this.selectPackingIndex < 0) {
        return;
      }
      const value = this.calcMeasurementTotal();
      if (this.isOverFlow(value)) {
        this.s.setGoodsValue('linkageGoodsLinePacking', this.selectPackingIndex, 'netGrossMeasurementGrp', 'netMeasurementTotal',  null);
        this.$set(this.s.measurementOverflow, this.selectPackingIndex, true);
      } else {
        this.s.setGoodsValue('linkageGoodsLinePacking', this.selectPackingIndex, 'netGrossMeasurementGrp', 'netMeasurementTotal',  value);
        this.$set(this.s.measurementOverflow, this.selectPackingIndex, false);
      }
      this.override = false;
    },
    calcGrossMeasurementTotal() {
      if (!this.grossLength && !this.grossWidth && !this.grossHeight) {
        return undefined;
      }
      const _length = _.isNumber(this.grossLength) ? this.grossLength : 1;
      const width = _.isNumber(this.grossWidth) ? this.grossWidth : 1;
      const height = _.isNumber(this.grossHeight) ? this.grossHeight : 1;
      const result = BigNumber(_length).times(BigNumber(width)).times(BigNumber(height)).dp(6).toNumber();
      return result;
    },
    setGrossMeasurementTotal() {
      if (!_.isNumber(this.selectPackingIndex) || this.selectPackingIndex < 0) {
        return;
      }
      const value = this.calcGrossMeasurementTotal();
      if (this.isOverFlow(value)) {
        this.s.setGoodsValue('linkageGoodsLinePacking', this.selectPackingIndex, 'netGrossMeasurementGrp', 'grossMeasurementTotal',  null);
        // this.$set(this.s.measurementGrossOverflow, this.selectPackingIndex, true);
      } else {
        this.s.setGoodsValue('linkageGoodsLinePacking', this.selectPackingIndex, 'netGrossMeasurementGrp', 'grossMeasurementTotal',  value);
        // this.$set(this.s.measurementGrossOverflow, this.selectPackingIndex, false);
      }
      this.override = false;
    },
    getTableData(schema) {
      return _.compact(_.map(schema, column => {
        const table = _.find(this.s.schemas.tables, {variableName: column.table});
        if (!_.some(table.groups, group => {
          return this.s.checkTypeOfWorks(group);
        })) {
          return null;
        }
        const group = _.find(table.groups, {variableName: column.group});
        const item = _.find(group.children, {key: column.key});
        return {
          table: column.table,
          group: column.group,
          item: item,
          parentGroup: group,
          width: column.width,
          label: column.label,
          readonly: column.readonly,
        }
      }));
    },
  }
};
</script>

<style lang="scss" scoped>
  tbody:hover {
    background-color: $color_gray_background;

    td.goods_line_table_inner img.close_times {
      opacity: 1;
    }
  }

  tbody.selected {
    background-color: $color_selected_row;
  }

  tbody.invalid td:not(.goods_line_table_inner) {
    background-color: rgba(224, 0, 1, 0.07);
  }

  th {
    text-align: center;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    word-break: break-word;
    color: #5E5873;
    padding: 4px 5px;
    border: 1px solid $color_gray_300;
    background: $color_dark_blue;
    color: white;
    // font-weight: bold;
  }

  td {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    padding: 0;
    border: 1px solid $color_gray_300;
    border-bottom: 0;

    .el-form-item {
      width: 100%;
      padding-right: 0;
      margin: 0;
      margin-bottom: 0!important;

      ::v-deep {
        label.el-form-item__label {
          display: none;
        }

        .el-input, .el-select, .el-input-number, .el-input-number.amount {
          width: 100%!important;
        }

        .el-input-number .el-input__inner {
          text-align: right;
        }

        .el-input__inner {
          font-size: 12px;
          width: 100%;
          background: transparent;
          box-shadow: none;
          border-radius: 0;
          padding: 0 5px;
          
          &:not(:focus) {
            border: 0;
          }
        }

        .el-input.is-disabled .el-input__inner {
          color: $color_black;
          background-color: rgba($color_gray_300, .75);
        }

        .el-select {
          .el-input__inner {
            padding-right: 20px;
            overflow: hidden;
            white-space: nowrap;
          }
          .el-input__suffix {
            right: 0;
          }
        }

        .el-form-item__error {
          white-space: normal;
          font-size: 10px;
          left: 0;
          padding: 0 5px;
          margin-bottom: 5px;
        }

        .warning_message {
          width: 120px;
          font-size: 10px;
          background-position: 5px 2px;
          padding-left: 20px;
          margin-bottom: 5px;
          white-space: normal;
        }
      }
    }
  }

  td.goods_line_table_inner {
    position: relative;
    border-bottom: 1px solid $color_gray_400!important;
    // border-top: 1px solid #666;

    img.close_times {
      position: absolute; 
      right: 4px;
      top: 50%;
      margin-top: -10px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      opacity: 0;
    }

    table  {
      table-layout: fixed;
      min-width: 100%;
      border: 0;
      border-collapse: collapse;

      th {
        border-top: 0!important;
        border-bottom: 0;
        padding: 0 5px;
        line-height: 0;
        height: 0;
        overflow: hidden;
        background: transparent;
      }

      td {
        border-top: 0!important;
        border-bottom: 0!important;
        padding: 0;
      }

      tr th:first-child, tr td:first-child {
        border-left: 0!important;
      }

      tr th:last-child, tr td:last-child {
        border-right: 0!important;
      }

      tr td:last-child {
        padding-right: 28px;
      }
    }
  }

  
</style>