<template>
  <tbody @click="$emit('click', index)" :class="{selected: selected, invalid: invalid}">
    <tr>
      <td v-for="column in goodsLineTable" :key="column.label" :style="{width: column.width + 'px' || 'auto'}">
        <tw-process-group-list-edit-goods-line-item v-if="column.item" :item="column.item" :selectGoodsIndex="selectGoodsIndex" :tableName="column.table" :groupName="column.group" :parentGroup="column.parentGroup" :readonly="column.readonly" :tableView="true" v-model="localValue" />
      </td>
    </tr>
    <tr>
      <td class="goods_line_table_inner" :colspan="goodsLineTable.length" cellspacing="0" cellpadding="0">
        <img class="close_times hidden" src="@/assets/images/icons/times.svg" @click.stop.prevent="$emit('delete-goods')">
        <table style="width: 100%" cellspacing="0" cellpadding="0" border="0">
          <tr>
            <th v-for="column in goodsLineTable2" :key="column.label" :style="{width: column.width + 'px' || 'auto'}">{{column.label}}</th>
          </tr>
          <tr>
            <td v-for="column in goodsLineTable2" :key="column.label">
              <tw-process-group-list-edit-goods-line-item v-if="column.item" :item="column.item" :selectGoodsIndex="selectGoodsIndex" :tableName="column.table" :groupName="column.group" :parentGroup="column.parentGroup" :tableView="true" v-model="localValue" />
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </tbody>
</template>

<script>
import _ from 'lodash';
import BigNumber from 'bignumber.js';
import { FREE_CHARGE_FLG } from 'lib-tw-common';
import TwProcessGroupListEditGoodsLineItem from '@/components/molecules/TwProcessGroupListEditGoodsLineItem';
import goodsLineTableSchema from '@/dictionaries/goodsLineTableSchema.json';
import goodsLineTableSchema2 from '@/dictionaries/goodsLineTableSchema2.json';

const ROUND_TYPES = {
  1: 'ROUND_UP',
  2: 'ROUND_DOWN',
  3: 'ROUND_HALF_UP'
};

export default {
  name: 'TwProcessGroupListEditGoodsLineTable',
  props: {
    value: Object,
    selectGoodsLineSeq: Number,
    index: Number,
    selected: Boolean,
    hasError: Array,
  },
  inject: ['s'],
  components: {
    TwProcessGroupListEditGoodsLineItem,
  },
  data() {
    return {
      customers: [],

      goodsLineTableSchema: goodsLineTableSchema,
      goodsLineTableSchema2: goodsLineTableSchema2,
      pageSize: 20,
      dialogAddGoodsVisible: false,
      searchForm: {
        customerId: '',
        customerName: '',
        companyRole: [],
        keyword: '',
      },
      sections: [],
      init: false,
      items: [],
      override: false,
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    SYS_MST_CURRENCY() {
      return this.$store.getters.getSysMstCurrency;
    },
    goodsLineTable() {
      return this.getTableData(this.goodsLineTableSchema);
    },
    goodsLineTable2() {
      return this.getTableData(this.goodsLineTableSchema2);
    },
    // 選択した商品
    selectGoods() {
      if (!_.get(this, 'localValue.linkageGoodsLine')) {
        return false;
      }
      const selectGoods = _.find(this.localValue.linkageGoodsLine, goods => {
        return _.get(goods, 'keyGrp.goodsLineSeq') === this.selectGoodsLineSeq;
      });
      return selectGoods;
    },
    // 選択した商品ラインのインデックス
    selectGoodsIndex() {
      if (!_.get(this, 'localValue.linkageGoodsLine')) {
        return false;
      }
      const selectGoodsIndex = _.findIndex(this.localValue.linkageGoodsLine, goods => {
        return _.get(goods, 'keyGrp.goodsLineSeq') === this.selectGoodsLineSeq;
      });
      return selectGoodsIndex;
    },
    // 選択した商品価格のインデックス
    selectGoodsLinePriceIndex() {
      if (!_.get(this, 'localValue.linkageGoodsLinePrice')) {
        return false;
      }
      const selectGoodsLinePriceIndex = _.findIndex(this.localValue.linkageGoodsLinePrice, price => {
        return _.get(price, 'keyGrp.goodsLineSeq') === this.selectGoodsLineSeq;
      });
      return selectGoodsLinePriceIndex;
    },
    goodsLineQuantity1() {
      return _.get(this, `localValue.linkageGoodsLine[${this.selectGoodsIndex}].quantityGrp.quantity1`);
    },
    unitRoundType1() {
      return _.get(this, `localValue.linkageGoodsLine[${this.selectGoodsIndex}].quantityGrp.unitRoundType1`);
    },
    goodsLinePrice() {
      return _.get(this, `localValue.linkageGoodsLinePrice[${this.selectGoodsLinePriceIndex}].priceGrp.price`);
    },
    currencyId() {
      return _.get(this, `localValue.linkageGoodsLinePrice[${this.selectGoodsLinePriceIndex}].priceGrp.currencyId`);
    },
    noCommercialFlag() {
      return _.get(this, `localValue.linkageGoodsLinePrice[${this.selectGoodsLinePriceIndex}].commercialGrp.noCommercialFlag`) === FREE_CHARGE_FLG.ON;
    },
    invalid() {
      if (_.isEmpty(this.hasError)) {
        return false;
      } 
      return _.some(this.hasError, e => {
        return e.startsWith(`linkageGoodsLine[${this.index}]`) || e.startsWith(`linkageGoodsLinePrice[${this.index}]`);
      });
    }
  },
  watch: {
    goodsLineQuantity1(val) {
      if (_.isNumber(val) && _.isNumber(this.goodsLinePrice)) {
        requestAnimationFrame(() => {
          this.setCalcAmount();
        });
      }
      this.override = false;
    },
    goodsLinePrice: {
      handler(val) {
        if (_.isNumber(val) && _.isNumber(this.goodsLineQuantity1)) {
          requestAnimationFrame(() => {
            this.setCalcAmount();
          });
        }
        this.override = false;
      },
      // immediate: true,
    },
    unitRoundType1: {
      handler() {
        requestAnimationFrame(() => {
          this.setCalcAmount();
        });
        this.override = false;
      },
    },
    currencyId: {
      handler() {
        requestAnimationFrame(() => {
          this.setCalcAmount();
        });
        this.override = false;
      },
    },
    noCommercialFlag: {
      handler(val) {
        if (val === true) {
          // this.s.setGoodsValue('linkageGoodsLinePrice', this.selectGoodsLinePriceIndex, 'amountGrp', 'amount', 0);
        } else {
          requestAnimationFrame(() => {
            this.setCalcAmount();
          });
        }
        this.override = false;
      },
      // immediate: true,
    },
  },
  methods: {
    // 値が指定桁を超えているかどうかを返却します
    isOverFlow(value, integer = 13, decimal = 2) {
      const ary = (value + '').split('.');
      if (ary[0] && ary[0].length > integer) {
        return true;
      } else if (ary[1] && ary[1].length > decimal) {
        return true;
      }
      return false;
    },
    getCalcAmount() {
      let digit = 2;
      // const currency = this.$store.getters.getCurrency(this.currencyId);
      // if (currency) {
      //   digit = currency.minorUnit;
      // }
      // 単位1丸め区分で丸め方を変える
      return BigNumber(this.goodsLinePrice).times(this.goodsLineQuantity1).dp(digit, BigNumber[ROUND_TYPES[this.unitRoundType1]]).toNumber();
    },
    // Amountに計算値を代入します
    setCalcAmount() {
      const value = this.getCalcAmount();
      if (this.isOverFlow(value)) {
        this.s.setGoodsValue('linkageGoodsLinePrice', this.selectGoodsLinePriceIndex, 'amountGrp', 'amount',  null);
        this.$set(this.s.amountOverflow, this.selectGoodsLinePriceIndex, true);
      } else {
        this.s.setGoodsValue('linkageGoodsLinePrice', this.selectGoodsLinePriceIndex, 'amountGrp', 'amount',  value);
        this.$set(this.s.amountOverflow, this.selectGoodsLinePriceIndex, false);
      }
    },
    getTableData(schema) {
      return _.compact(_.map(schema, column => {
        const table = _.find(this.s.schemas.tables, {variableName: column.table});
        if (!table) {
          return null;
        }
        if (!_.some(table.groups, group => {
          return this.s.checkTypeOfWorks(group);
        })) {
          if (column.label === 'Price') {
            return {
              width: 136,
            }
          } else {
            return null;
          }
        }
        const group = _.find(table.groups, {variableName: column.group});
        const item = _.find(group.children, {key: column.key});
        return {
          table: column.table,
          group: column.group,
          item: item,
          parentGroup: group,
          width: column.width,
          label: column.label,
          readonly: column.readonly,
        }
      }));
    },
  }
};
</script>

<style lang="scss" scoped>
  tbody:hover {
    background-color: $color_gray_background;

    td.goods_line_table_inner img.close_times {
      opacity: 1;
    }
  }

  tbody.selected {
    background-color: $color_selected_row;
  }

  tbody.invalid td:not(.goods_line_table_inner) {
    background-color: rgba(224, 0, 1, 0.07);
  }

  th {
    text-align: center;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    word-break: break-word;
    color: #5E5873;
    padding: 4px 5px;
    border: 1px solid $color_gray_300;
    background: $color_dark_blue;
    color: white;
    // font-weight: bold;
  }

  td {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    padding: 0;
    border: 1px solid $color_gray_300;
    border-bottom: 0;

    .el-form-item {
      width: 100%;
      padding-right: 0;
      margin: 0;
      margin-bottom: 0!important;

      ::v-deep {
        label.el-form-item__label {
          display: none;
        }

        .el-input, .el-select, .el-input-number, .el-input-number.amount {
          width: 100%!important;
        }

        .el-input-number .el-input__inner {
          text-align: right;
        }

        .el-input__inner {
          font-size: 12px;
          width: 100%;
          background: transparent;
          box-shadow: none;
          border-radius: 0;
          padding: 0 5px;
          
          &:not(:focus) {
            border: 0;
          }
        }

        .el-input.is-disabled .el-input__inner {
          color: $color_black;
          background-color: rgba($color_gray_300, .75);
        }

        .el-select {
          .el-input__inner {
            padding-right: 20px;
            overflow: hidden;
            white-space: nowrap;
          }
          .el-input__suffix {
            right: 0;
          }
        }

        .el-form-item__error {
          white-space: normal;
          font-size: 10px;
          left: 0;
          padding: 0 5px;
          margin-bottom: 5px;
        }

        .warning_message {
          width: 120px;
          font-size: 10px;
          background-position: 5px 2px;
          padding-left: 20px;
          margin-bottom: 5px;
          white-space: normal;
        }
      }
    }
  }

  td.goods_line_table_inner {
    position: relative;
    border-bottom: 1px solid $color_gray_400!important;
    // border-top: 1px solid #666;

    img.close_times {
      position: absolute; 
      right: 4px;
      top: 50%;
      margin-top: -10px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      opacity: 0;
    }

    table  {
      table-layout: fixed;
      min-width: 100%;
      border: 0;
      border-collapse: collapse;

      th {
        border-top: 0!important;
        border-bottom: 0;
        padding: 0 5px;
        line-height: 0;
        height: 0;
        overflow: hidden;
        background: transparent;
      }

      td {
        border-top: 0!important;
        border-bottom: 0!important;
        padding: 0;
      }

      tr th:first-child, tr td:first-child {
        border-left: 0!important;
      }

      tr th:last-child, tr td:last-child {
        border-right: 0!important;
      }

      tr td:last-child {
        padding-right: 28px;
      }
    }
  }

  
</style>
