<template>
  <div>
    <el-form class="search_unit" ref="searchUnit" :model="searchForm" label-position="top">
      <div class="row" style="padding-bottom: 0">
        <el-form-item :label="$t('Label.Section')" prop="sectionIds">
          <el-checkbox-group v-model="searchForm.sectionIds">
            <el-checkbox v-for="section in sections" :key="section.sectionId" :label="section.sectionId">{{section.sectionShortName}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item :label="$t('Label.Goods Short Name')" prop="goodsNm">
          <el-input type="text" v-model="searchForm.goodsShortName" placeholder="Enter keyword"></el-input>
        </el-form-item>
        <el-form-item :label="$t('Label.Goods Type')" prop="goodsTypeId">
          <el-select v-model="searchForm.goodsTypeId" placeholder="Select" clearable>
            <el-option
              v-for="goodsType in goodsTypeNms"
              :key="goodsType.goodsTypeId"
              :label="goodsType.goodsTypeNm"
              :value="goodsType.goodsTypeId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('Label.HS Code')" prop="goodsName">
          <el-input type="text" v-model="searchForm.hsCode" placeholder="Enter keyword"></el-input>
        </el-form-item>
        <div>
          <tw-button
            type="secondary"
            size="medium"
            icon="search"
            @click="search"
          >
            Search
          </tw-button>
        </div>
      </div>
    </el-form>
    <div style="min-height: 388px">
      <tw-table-system v-if="isSearch" :height="300" :schemas="schemas" :items="items" :pageSize="limit" :totalCount="totalCount" :offset="offset" :selection="selection" :rowKey="'goodsId'"  :serverPagination="true" @selection-change="selectionChange" @select-file="selectFile" @paging="onPaging" @row-click="rowClick" ref="table" />
    </div>
    <div style="display: flex;justify-content: flex-end;padding-top: 12px">
      <tw-button type="primary" :disabled="!selectGoods.length"  @click="register">OK</tw-button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { GOODS_ATTR_ID, INITIAL_VIEW_FLG, DEFAULT_PAGENATION, TRANSACTION_GOODS_SEARCH_SORT_TARGET } from 'lib-tw-common';
import { $api } from '@/store/ApiClient';
import mixinEntityList from '@/utils/mixinEntityList.js';
import TwButton from '@/components/atoms/TwButton';
import TwTableSystem from '@/components/organisms/TwTableSystem';
import schemas from '@/dictionaries/addGoodsSchema.json';

export default {
  name: 'AddGoods',
  mixins: [mixinEntityList],
  components: {
    TwButton,
    TwTableSystem,
  },
  props: {
    dialogAddGoodsVisible: Boolean,
  },
  data() {
    return {
      items: null,
      schemas: schemas,
      selection: true,
      searchForm: {
        sectionIds: [],
        goodsShortName: '',
        goodsTypeId: '',
        hsCode: '',
      },
      sections: [],
      goodsTypeNms: [],
      selectGoods: [],
      isSearch: false,
      goodsTypeMasterList: [],
      limit: DEFAULT_PAGENATION.LIMIT,
      offset: 0,
      sort: null,
      count: 0,
      totalCount: 0,
      pageCount: 0,
      lastQuery: {},
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    selectionChange(multipleSelection) {
      this.selectGoods = multipleSelection;
    },
    fetch(query) {
      // bff_gd_1 商品詳細初期表示BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/{companyId}/goods/search',
          path: {
            companyId: this.$store.state.companyId,
          },
          query: query || {
            initialFlag: INITIAL_VIEW_FLG.ON,
          }
        },
        tag: this.pushCancelTag(),
      };

      $api
        .request(params)
        .then(res => {
          if (res.sections) {
            this.sections = res.sections;
          }
          if (res.goodsTypeNms) {
            this.goodsTypeNms = res.goodsTypeNms;
          }
          if (res.totalCount !== undefined) {
            this.totalCount = res.totalCount;
          }
          if (res.goods) {
            // const lang = this.$store.getters.getLanguageKey;
            this.items = _.map(res.goods, o => {
              return {
                ...o,
                goodsNameEn: o.en.goodsName,
                goodsShortName: o.en.goodsShortName,
                // attrsから添付ファイルのリストを生成
                files: _.filter(o.attrs, attr => attr.filepathVal),
              }
            });
          }
          if (res.goodsTypeMasterList) {
            // attrIdの重複を取り除く
            this.goodsTypeMasterList = _.uniqBy([...this.goodsTypeMasterList, ...res.goodsTypeMasterList], o => `${o.goodsTypeId}-${o.attrId}`);
            this.$emit('update-goods-type-master', this.goodsTypeMasterList);
          }
        })
        .catch(err => {
          if (err.isCanceled) {
            return;
          }
          this.items = [];
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
    search() {
      const query = {
        sectionIds: this.searchForm.sectionIds,
        goodsShortName: this.searchForm.goodsShortName,
        goodsTypeId: this.searchForm.goodsTypeId,
        hsCode: this.searchForm.hsCode,
        attrId: this.searchForm.hsCode ? GOODS_ATTR_ID.HS_CODE : undefined,
        initialFlag: INITIAL_VIEW_FLG.OFF,
        limit: this.limit,
        offset: this.offset,
        sort: this.sort,
      };
      this.offset = 0;
      this.isSearch = true;
      this.selectGoods = [];
      this.items = null;
      this.lastQuery = _.cloneDeep(query);
      this.fetch(query);
    },
    onPaging({target, sortType, currentPage}) {
      if (target && sortType) {
        const key = _.get(_.find(this.schemas, {key: target}), 'sortTarget');
        this.sort = {
          target: [TRANSACTION_GOODS_SEARCH_SORT_TARGET[key] || target],
          sortType: [sortType],
        };
      } else {
        this.sort = null;
      }

      this.offset = currentPage * this.limit - this.limit;
      const queries = {
        ...this.lastQuery,
        limit: this.limit,
        offset: this.offset,
        sort: this.sort,
      };
      this.fetch(queries);
    },
    register() {
      this.$emit('add-goods', this.selectGoods, this.goodsTypeMasterList);
    },
    selectFile(file) {
      // bff_goods_13 商品ファイルダウンロード（トラン用）BFF
      const fileName = this.$options.filters.fileName(file.filepathVal);
      const serviceCode = 'tw-user-entity-bff-api';
      const apiCode = 'get_/v1/{companyId}/goods/file-download/{goodsId}/{attrId}/{seqNo}/{goodsAttrManageSeq}/transaction';
      const path = {
        companyId: this.$store.state.companyId,
        goodsId: file.goodsId,
        seqNo: file.seqNo,
        goodsAttrManageSeq: file.goodsAttrManageSeq,
        attrId: file.attrId,
      };

      $api
      .download(path, fileName, apiCode, serviceCode)
      .then(() => {
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    rowClick(row) {
      this.$refs.table.toggleRowSelection(row);
    },
  }
};
</script>

<style lang="scss">
  .el-dialog__wrapper[aria-label="Add Goods"] .el-dialog {
    .el-dialog__body {
      padding-top: 0;
    }

    .row {
      padding: 12px 0 8px;
    }
  }

  .tw_button__icon.icon_search {
    padding-left: 12px;
    padding-right: 20px;
  }
</style>
